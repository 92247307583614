import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { styled } from "@mui/styles";
import "./style.scss";
import CurrencyNumber from "Components/CurrencyNumber";

const TableHeadCell = styled(TableCell)(({ theme }) => ({
    color: "#939393 !important",
    fontFamily: "Work Sans !important",
    fontSize: "15px !important",
    borderBottom: "none !important",
}));

const TableBodyCell = styled(TableCell)(({ theme }) => ({
    fontFamily: "Work Sans !important",
    fontSize: "16px !important",
    // display: "flex !important",
    // flexWrap: "wrap",
    // gap: "8px"
}));

const RenderCell: React.FunctionComponent<{
    type: string;
    value: string;
}> = ({ type, value }) => {
    switch (type) {
        case "currency":
            return <CurrencyNumber value={Number(value)} />;
        default:
            return <>{value}</>;
    }
};

const DataTable: React.FunctionComponent<{
    collums?: any;
    rows?: any;
    colors?: any;
    colorField?: string;
    onClickRow?: Function;
}> = ({ collums = [], rows = [], colors, colorField, onClickRow }) => {
    const handeClickRow = (event: MouseEvent, id: string) => {
        if (!onClickRow) {
            return;
        }
        onClickRow(id);
    };

    return (
        <TableContainer component={Box}>
            <Table
                aria-label="simple table"
                sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0 16px",
                    background: "#F3F4F7",
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            height: "70px",
                            // background: "#FFF",
                            borderRadius: "5px",
                        }}
                    >
                        {collums.map((collum: any) => (
                            <TableHeadCell
                                key={collum.id}
                                className={collum?.className}
                            >
                                {collum.name}
                            </TableHeadCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                height: "100px",
                                background: "#FFF",
                                borderRadius: "5px",
                                // cursor: "pointer",
                                // "&:hover": {
                                //     background: "#EFEFEF",
                                // }
                            }}
                            onClick={(event: any): any => {
                                return handeClickRow(event, row.id);
                            }}
                        >
                            {collums.map((collum: any) => (
                                <TableBodyCell
                                    key={collum.id}
                                    align="left"
                                    sx={{
                                        color:
                                            collum.checkStatus &&
                                            colors &&
                                            colorField
                                                ? colors.find((color: any) => {
                                                      return (
                                                          color.id ===
                                                          row[colorField]
                                                      );
                                                  })?.color
                                                : "",
                                        fontWeight:
                                            collum.checkStatus &&
                                            colors &&
                                            colorField
                                                ? 500
                                                : "",
                                    }}
                                >
                                    <RenderCell
                                        type={collum.type as string}
                                        value={row[collum.id] as string}
                                    />
                                </TableBodyCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;
