import { useState } from "react";
import { gameServerApi } from 'services/gameServerV2';
import {CompareStatisticsWorkoutMapping} from './CompareStatisticsWorkoutMapping'
const useCompareStatisticWorkoutHook = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const getCompareStatisticWorkoutSummary = async (timeRangeId: number) => {
        setLoading(true)
        try {
            gameServerApi.workoutHistory.getCompareStatisticWorkoutSummary(timeRangeId).then((res)=>{
                if(res && res.status === 200) {
                    // console.log('res========', res);
                    //@ts-ignore
                    setData(CompareStatisticsWorkoutMapping(res.data));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch((err)=>{
                console.log('error when getCompareStatisticWorkoutSummary', err)
                setLoading(false);
            });
        } catch (err) {
            console.log('error when handle getCompareStatisticWorkoutSummary', err)
            setLoading(false);
        }
    }
    // console.log({GameUserInfo})
    return {
        getCompareStatisticWorkoutSummary,
        data,
        loading,
    };
};
export default useCompareStatisticWorkoutHook;