import React, { useRef, useState } from "react";
import UploadImageModal from "./UploadImageModal";
import { v4 as uuidV4 } from "uuid";
import "./style.scss";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import { IUserProfile } from "routes/useRouteElement.hook";

interface UploadAvatarProps {
    userData: IUserProfile;
    onSubmitting?: Function;
    disableUpload?: boolean;
    isSubmitting?: boolean;
}
const UploadAvatar = ({
    userData,
    onSubmitting,
    disableUpload,
    isSubmitting
}: UploadAvatarProps) => {
    const inputRef: any = useRef(null);
    const [open, setOpen] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState("");

    // Really I have idea what is going on in this function
    const getImageUrl = (url: string) => {
        if (!url) {
            return "/images/user/no-avatar.png";
        }
        if (url && url.includes("blob:")) {
            return url;
        }
        return `${url}?ignoreCache=${uuidV4()}`;
    };

    const handleClickInput = () => {
        if (inputRef && !disableUpload) {
            inputRef.current.click();
        }
    };

    const handleGetFile = async (event: any) => {
        if (event.target.files[0]) {
            if (
                [
                    "image/png",
                    "image/jpeg",
                    "image/apng",
                    "image/webp",
                    "image/avif",
                    "image/gif",
                ].indexOf(event.target.files[0].type as string) === -1
            ) {
                Swal.fire(
                    "File format not supported",
                    "Uploaded file is not a valid image. Only JPG, PNG and GIF files are allowed.",
                    "warning"
                );
                return;
            }
            // get file from computer
            const url = await URL.createObjectURL(event.target.files[0]);

            // set url state for resize
            setAvatarUrl(url);
            setOpen(true);
        }
    };

    const handleSubmitImage = (values: any) => {
        onSubmitting && onSubmitting(values);
    };

    const getDisplayName = () => {
        return userData?.username || "";
    };

  

    return (
        <div className="upload-avatar-component">
            <UploadImageModal
                open={open}
                state={{ avatarUrl }}
                setOpen={setOpen}
                onSubmit={handleSubmitImage}
                isSubmitting={isSubmitting}
            />
            <Stack
                spacing={3}
                direction={{ xs: "column", md: "row" }}
                alignItems={{ xs: "center", md: "start" }}
                justifyContent="flex-start"
                width="100%"
                sx={{
                    float: "right",
                    margin: {
                        xs: "32px 0",
                        md: 0,
                    },
                }}
                className="Mui-Stack-Wrapper"
            >
                <div>
                    <input
                        id="avatar"
                        name="avatar"
                        type="file"
                        hidden
                        ref={inputRef}
                        onChange={handleGetFile}
                    />

                    <button
                        onClick={handleClickInput}
                        className="upload-button"
                    >
                        <div className="user-avatar-container">
                            <img
                                className="user-avatar-img"
                                loading="eager"
                                src={getImageUrl(
                                    userData?.avatarUrl
                                )}
                                // alt="img"
                            />
                        </div>
                        <div className="icon-container">
                            <img
                                className="icon-img"
                                loading="eager"
                                src="/images/icons/pencil.svg"
                                alt="img"
                            />
                        </div>
                    </button>
                </div>
                <div className="upload-avatar-component__name">
                    <div className="upload-avatar-component__name__main">
                        <Typography
                            variant="h3"
                            component="h1"
                            fontWeight={700}
                            sx={{
                                "-webkit-box-orient": "vertical",
                                /* autoprefixer: ignore next */
                                textOverflow: "ellipsis",
                                /* autoprefixer: ignore next */
                                overflow: "hidden",
                                /* autoprefixer: ignore next */
                                display: "-webkit-box",
                                /* autoprefixer: ignore next */
                                "-webkit-line-clamp": "1",
                            }}
                        >
                            {getDisplayName()}
                        </Typography>
                    </div>
                </div>
            </Stack>
        </div>
    );
};

export default UploadAvatar;
