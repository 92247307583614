import axios, { AxiosRequestConfig } from "axios"

export interface IUploadAvatarResponse {
    signedUrl: string
    url: string
}

export class UploadAPI {
    host: string = process.env.REACT_APP_UPLOAD_API_URL || "https://upload.dev.rowaviron.com" as string
    clientId: string = process.env.REACT_APP_UPLOAD_CLIENT_ID || "e860eada-6940-40b6-a77b-1fc78d08afa1" as string
    clientSecret: string = process.env.REACT_APP_UPLOAD_CLIENT_SECRET || "cFxVT55GesY8kEcY0kC0HqGYog2fYlZP" as string

    public async getSignedUploadAvatarUrl(userId: string): Promise<IUploadAvatarResponse> {
        const options: AxiosRequestConfig = {
            method: "POST",
            url: `${this.host}/api/upload/get-signed-url`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: JSON.stringify({
                module: "avatar",
                customId: userId,
                clientId: this.clientId,
                secretKey: this.clientSecret
            })
        }

        const res = await axios(options);
        return res.data
    }

    public async upload(signedUrl: string, image: any): Promise<IUploadAvatarResponse> {
        const options: AxiosRequestConfig = {
            method: "PUT",
            url: signedUrl,
            headers: {
                'Content-Type': 'image/png'
            },
            data: image
        }

        const res = await axios(options);
        return res.data
    }

}

export const uploadApi = new UploadAPI();