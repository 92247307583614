import { makeStyles } from "@mui/styles";
const useStyles: any = makeStyles(
    (theme: any) =>
    ({
        root: {
            backgroundColor: "#fff",
            height: "100vh",
        },
        grid: {
            height: "100%",
        },
        content: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                background: "#fff",
            },
        },
        logoImage: {
            marginLeft: theme.spacing(4),
        },
        contentBody: {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
                justifyContent: "center",
                width: "100%"
            },
        },
        form: {
            background: "#fff",
            flexBasis: "100%",
            [theme.breakpoints.down("sm")]: {
                paddingTop: 0,
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                width: "100%",
                boxShadow: "none"
            },
        },
        logo: {
            width: "100%",
            textAlign: "center"
        },
        title: {
            marginTop: theme.spacing(3),
            textAlign: "center",
            letterSpacing: "4px"
        },
        socialButtons: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
        socialButon: {
            width: "100%",
        },
        socialIcon: {
            marginRight: theme.spacing(1),
        },
        sugestion: {
            marginTop: theme.spacing(2),
        },
        textField: {
            marginTop: theme.spacing(2),
        },
        signInButton: {
            margin: theme.spacing(2, 0),
            height: "62px"
        },
        loginError: {
            color: "#f5490f",
            textAlign: "start",
            fontSize: "14px"
        }
    } as any)
);

export default useStyles;
