import React from "react";
import { BrowserRouter } from "react-router-dom";
import { allPreRoutes } from "./useRouteElement.constant";
import { handleRenderRouteElement } from "./useRouteElement.hook";

const useRouteElement = () => {
    return (
        <BrowserRouter>{handleRenderRouteElement(allPreRoutes)}</BrowserRouter>
    );
};
export default useRouteElement;
