import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import CompareChart from "Components/WorkoutComparePeriodPage//CompareChart";
import CompareStats from "Components/WorkoutComparePeriodPage/CompareStats";
import { useComparePeriodHook } from "./comparePeriod.hook";
import { AvironLoading } from "Components/Icons/AvironLoading";
import useWindowSize from "Hooks/useWindowSize";
import style from "./style.module.scss";
import { CONSTANTS } from "consts";

const ComparePeriod = () => {
    const { workoutCompare, loading, refetchWorkoutCompare } =
        useComparePeriodHook();
        
    const [selectValue, setSelectValue] = useState(0);
    const windowSize = useWindowSize();
    const timeOptions = useMemo(() => {
        return [
            {
                value: 0,
                label: {
                    valueApi: CONSTANTS.TIME_RANGE_ID.today,
                    nameCompare: ["TODAY", "YESTERDAY"],
                    number: windowSize?.width < 1000 ? 4 : 8,
                },
                headerTable: [<div>TODAY</div>, <div>YESTERDAY</div>],
                name: "TODAY",
                modeCompare: "today",
            },
            {
                label: {
                    valueApi: CONSTANTS.TIME_RANGE_ID.day7,
                    nameCompare: ["LAST 7 DAYS", "PREVIOUS 7 DAYS"],
                    number: 6,
                },
                headerTable:
                    windowSize.width < 600
                        ? [
                              <div>
                                  LAST
                                  <br />7 DAYS
                              </div>,
                              <div>
                                  PREVIOUS
                                  <br />7 DAYS
                              </div>,
                          ]
                        : [
                              <div>LAST 7 DAYS</div>,
                              <div>
                                  PREVIOUS
                                  <br />7 DAYS
                              </div>,
                          ],
                name: "LAST 7 DAYS",
                value: 1,
                modeCompare: "7day",
            },
            {
                label: {
                    number: 30,
                    valueApi: CONSTANTS.TIME_RANGE_ID.day30,
                    nameCompare: ["LAST 30 DAYS", "PREVIOUS 30 DAYS"],
                },
                name: "LAST 30 DAYS",
                value: 2,
                modeCompare: "30day",
                headerTable:
                    windowSize.width < 600
                        ? [
                              <div>
                                  LAST
                                  <br />
                                  30 DAYS
                              </div>,
                              <div>
                                  PREVIOUS
                                  <br />
                                  30 DAYS
                              </div>,
                          ]
                        : [
                              <div>LAST 30 DAYS</div>,
                              <div>
                                  PREVIOUS
                                  <br />
                                  30 DAYS
                              </div>,
                          ],
            },
            {
                label: {
                    number: 90,
                    valueApi: CONSTANTS.TIME_RANGE_ID.day90,
                    nameCompare: ["LAST 90 DAYS", "PREVIOUS 90 DAYS"],
                },
                name: "LAST 90 DAYS",
                value: 3,
                modeCompare: "90day",
                headerTable:
                    windowSize.width < 600
                        ? [
                              <div>
                                  LAST
                                  <br />
                                  90 DAYS
                              </div>,
                              <div>
                                  PREVIOUS
                                  <br />
                                  90 DAYS
                              </div>,
                          ]
                        : [
                              <div>LAST 90 DAYS</div>,
                              <div>
                                  PREVIOUS
                                  <br />
                                  90 DAYS
                              </div>,
                          ],
            },
            {
                label: {
                    number: 180,
                    valueApi: CONSTANTS.TIME_RANGE_ID.day180,
                    nameCompare: ["LAST 180 DAYS", "PREVIOUS 180 DAYS"],
                },
                name: "LAST 180 DAYS",
                value: 4,
                modeCompare: "180day",
                headerTable:
                    windowSize.width < 600
                        ? [
                              <div>
                                  LAST
                                  <br />
                                  180 DAYS
                              </div>,
                              <div>
                                  PREVIOUS
                                  <br />
                                  180 DAYS
                              </div>,
                          ]
                        : [
                              <div>LAST 180 DAYS</div>,
                              <div>
                                  PREVIOUS
                                  <br />
                                  180 DAYS
                              </div>,
                          ],
            },
            {
                label: {
                    valueApi: CONSTANTS.TIME_RANGE_ID.day365,
                    nameCompare: ["LAST 365 DAYS", "PREVIOUS 365 DAYS"],
                    number: 53,
                },
                value: 5,
                name: "LAST 365 DAYS",
                modeCompare: "365day",
                headerTable:
                    windowSize.width < 600
                        ? [
                              <div>
                                  LAST
                                  <br />
                                  365 DAYS
                              </div>,
                              <div>
                                  PREVIOUS
                                  <br />
                                  365 DAYS
                              </div>,
                          ]
                        : [
                              <div>LAST 365 DAYS</div>,
                              <div>
                                  PREVIOUS
                                  <br />
                                  365 DAYS
                              </div>,
                          ],
            },
        ];
    }, []);

    useEffect(() => {
        refetchWorkoutCompare(CONSTANTS.TIME_RANGE_ID.today);
    }, []);
    const handleChangeSelect = (event: any) => {
        const currentSelect = Number(event.target.value);
        //@ts-ignore
        refetchWorkoutCompare(timeOptions[currentSelect]?.label?.valueApi);
        setSelectValue(currentSelect);
    };
    return (
        <div>
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Workout Compare Peroid | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                {loading && <AvironLoading />}

                <div className="rightMainContent">
                    <Grid container xs={12}>
                        <Grid
                            item
                            xs={12}
                            mb={"24px"}
                            className={style["compareTableItem"]}
                        >
                            <CompareStats
                                //@ts-ignore
                                summary={workoutCompare?.summary}
                                refetchWorkoutCompare={refetchWorkoutCompare}
                                handleChangeSelect={handleChangeSelect}
                                selectValue={selectValue}
                                timeOptions={timeOptions}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CompareChart
                                //@ts-ignore
                                thisPeriod={workoutCompare?.thisPeriod}
                                //@ts-ignore
                                lastPeriod={workoutCompare?.lastPeriod}
                                peroidOptions={timeOptions[selectValue]}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default ComparePeriod;
