import * as yup from "yup";

export const validationRegisterAccessFormSchema = yup.object().shape({
    discord: yup.string().required("Required "),
    workoutPerWeek: yup.string().required("Required "),
    machineSerial: yup
        .string()
        .required("Required ")
        .test(
            "checkValue",
            "Serial Number should start with “ATS”",
            (value: string): boolean => {
                return (
                    value &&
                    (value.trim().toUpperCase().startsWith("ATS") as any)
                );
            }
        ),
    isAgree: yup.boolean().required(""),
});
