export const GET_WORKOUT_LIST_REQUEST = "@profile/GET_WORKOUT_LIST_REQUEST";
export const GET_WORKOUT_LIST_SUCCESS = "@profile/GET_WORKOUT_LIST_SUCCESS";
export const GET_WORKOUT_LIST_FAILED = "@profile/GET_WORKOUT_LIST_FAILED";
export const CLEAR_WORKOUT_LIST = "@profile/CLEAR_WORKOUT_LIST";

export const GET_WORKOUT_DETAIL_REQUEST = "@profile/GET_WORKOUT_DETAIL_REQUEST";
export const GET_WORKOUT_DETAIL_SUCCESS = "@profile/GET_WORKOUT_DETAIL_SUCCESS";
export const GET_WORKOUT_DETAIL_FAILED = "@profile/GET_WORKOUT_DETAIL_FAILED";

export const CHANGE_PAGE_REQUEST = "@profile/CHANGE_PAGE_REQUEST";
export const CHANGE_PAGE_SUCCESS = "@profile/CHANGE_PAGE_SUCCESS";
export const CHANGE_PAGE_FAILED = "@profile/CHANGE_PAGE_FAILED";

export const SELECT_CHART_OPTION = "@profile/SELECT_CHART_OPTION";

export const SELECT_WORKOUT_UNIT = "@profile/SELECT_WORKOUT_UNIT"

// export const EXPORT_CSV_TO_MAIL = "@profile/EXPORT_CSV_TO_MAIL";