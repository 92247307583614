import GLOBAL_FUNCTIONS from "scripts/global-functions";
import { ACTION_TYPES } from "../types/action-types";
import { BaseReducer } from '../base/base.reducer';

class HeaderReducer extends BaseReducer {
    constructor() {
        super(
            "HEADER",
            {
                current_page: 0,
                isDrawerMenuOpen: false,
            },
            {
                toogleDrawerMenu: ACTION_TYPES.HEADER.TOOGLE_DRAWER_MENU,
                navigatePage: ACTION_TYPES.HEADER.NAVIGATE_PAGE,
            }
        );
    }
    reducer = (
        state: any = this.initState,
        action: {
            type: string;
            payload: any;
        }
    ) => {
        switch (action.type) {
            case this.actions.setState:
                state = GLOBAL_FUNCTIONS.SetObject(state, action.payload);
                break;
            case this.actions.toogleDrawerMenu:
                state = this.toogleDrawerMenu(state, action);
                break;
            case this.actions.navigatePage:
                state.current_page = action.payload;
                break;
            case this.actions.fetch:
                state = action.payload;
                break;
            default:
                break;
        }
        return state;
    };
    toogleDrawerMenu = (
        state: any,
        action: {
            type: string;
            payload: any;
        }
    ) => {
        const newState = Object.assign({}, state);
        newState.isDrawerMenuOpen = action.payload;
        return newState;
    };
}

const headerReducer = new HeaderReducer();

export { headerReducer };
