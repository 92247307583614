import style from "./LeftContent.module.scss";
import React from "react";
import desktopLoginBg from "./images/desktopLogin2.png";
import mobileLoginBg from "./images/mobileLoginBg.png";
import { useWindowSize } from "scripts/useWindowSize";
const LeftContent = () => {
    const windowSize = useWindowSize();
    return (
        <div className={style["LeftContentWrapper"]}>
            {/* <div className={style["title"]}>
                <h1 className={style["mainTitle"]}>AVIRON</h1>
                <div className={style["subTitle"]}>TRACK YOUR WORKOUT</div>
            </div> */}
            {windowSize.width > 1023 && (
                <div className={style["mainFeatures"]}>
                    <img
                        className={style["desktopLoginBg"]}
                        src={desktopLoginBg}
                        alt="img"
                    />
                </div>
            )}
            {windowSize.width < 1024 && (
                <div className={style["mainFeatures"]}>
                    <img
                        className={style["mobileLoginBg"]}
                        src={mobileLoginBg}
                        alt="img"
                    />
                </div>
            )}
        </div>
    );
};

export default LeftContent;
