import { Dispatch } from 'react';
import { getTimezoneRequest } from 'contexts/timezone/timezone.action'
import Swal from 'sweetalert2';
export const handleGetTimezone = async (timezone: any, dispatch: Dispatch<any>) => {
  try {
      dispatch(getTimezoneRequest(timezone))
  } catch (err) {
    console.log('errr', err)
    Swal.fire('Get Timezone Error', err as string, 'error');
  }
}