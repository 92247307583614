import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';


const OneLastStep = ({ checkoutMembershipLink }: any) => {
  return (
    <Box padding="15px 20px">
      <a href={checkoutMembershipLink}>
        <Button variant="contained" color="secondary" fullWidth>ADD CREDIT CARD TO COMPLETE CHECKOUT</Button>
        {/* <Typography component="h3" fontFamily="Work Sans" fontSize="14px" fontWeight="bold" color={"#ff4106"}>ADD CREDIT CARD TO COMPLETE CHECKOUT</Typography> */}
      </a>
      <Typography component="h3" fontFamily="Work Sans" fontSize="14px" mt="12px" textAlign="center">A credit card is required to activate the subscription(s) in the Order Summary</Typography>
    </Box>
  )
}

export default OneLastStep;