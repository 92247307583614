import { gameServerApi } from "services/gameServerV2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { ENV } from "scripts/environment";

export const useQueryMembershipHistoryListC2 = () => {
    const handleQueryData = async () => {
        const data = await gameServerApi.user.getMembershipHistoriesListForC2();
        return data;
    };
    return {
        handleQueryData,
    };
};

export const useQueryPlanListForC2 = () => {
    const handleQueryDataPlan = async () => {
        const data = await gameServerApi.user.getPlanListForC2();
        return data;
    };
    return {
        handleQueryDataPlan,
    };
};

export const RenderEmptyDataMembershipWithAPK = () => {
    return (
        <Box
            sx={{
                width: "fit-content",
                margin: "32px auto",
                textAlign: "center",
            }}
        >
            <img src="/images/icons/document.svg" />
            <Typography mt="24px" mb="16px" variant="h4" component="h4">
                You don't have any memberships yet.
            </Typography>
            <Typography mb="16px" variant="body1" component="p">
                You can go to shop page to purchase a membership.
            </Typography>
            <a
                href={`${ENV.STOREFRONT_DOMAIN}/shop/home-fitness/personal-membership/`}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ width: "167px" }}
                >
                    SHOP NOW
                </Button>
            </a>
        </Box>
    );
};

export const RenderEmptyDataMembershipWithC2 = () => {
    return (
        <Box
            sx={{
                width: "fit-content",
                margin: "32px auto",
                textAlign: "center",
            }}
        >
            <img src="/images/icons/document.svg" />
            <Typography mt="24px" mb="16px" variant="h4" component="h4">
                You don't have any memberships yet.
            </Typography>
        </Box>
    );
};
