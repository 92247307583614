import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import style from './pataramer.module.scss';

const CardFieldTitle = styled(Typography)(({ theme }) => ({
    color: "#4F4F4F !important",
    fontFamily: "Work Sans !important",
    fontSize: "1.143rem !important",
    ["@media (max-width:900px)"]: {
        fontSize: "16px !important",
    },
    ["@media (max-width:414px)"]: {
        fontSize: "3.623vw !important",
    }
}));
const CardFieldContent = styled(Typography)(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    marginBottom: "1.4286rem !important",
    fontFamily: "MonoSpec !important",
    fontSize: "1.429rem !important",
    fontWeight: '500 !important',
    color: "#F5490F !important",
    ["@media (max-width:900px)"]: {
        fontSize: "24px !important",
        marginBottom: "16px !important",
        lineHeight: '30px !important',
    },
    ["@media (max-width:414px)"]: {
        fontSize: "5vw !important",
    }
}));

const Parameter: React.FunctionComponent<{
    title: string;
    parameter: number | string;
    rankLetter?: string;
    className?: string
}> = ({ title, parameter, rankLetter, className}) => {
    return (
        <div className={style["pataramerWrapper"] + ` ${className}`}>
            <Paper
                sx={{
                    position: "relative",
                    borderRadius: "0.571rem",
                    height: "78px",
                    padding: "1.143rem",
                    minWidth: "calc((100% - 32px)/2)",
                    ["@media (max-width:900px)"]: {
                        height: "68px",
                        padding: "16px",
                    },
                }}
                className="pataramerPaper"
            >
                <CardFieldTitle>{title}</CardFieldTitle>
                <CardFieldContent>
                    {parameter}
                    {rankLetter && <sup>{rankLetter}</sup>}
                </CardFieldContent>
            </Paper>
        </div>
    );
};

export default Parameter;
