import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
};

export type AuthenticateInput = {
  idToken: Scalars['String'];
};

/** BuffTypesInput */
export type BuffTypesInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  value_type: Scalars['String'];
};

export type ConnectInput = {
  gameUserId?: Maybe<Scalars['String']>;
  webUserId: Scalars['String'];
};


/** ExchangeToken */
export type ExchangeFitbitToken = {
  __typename?: 'ExchangeFitbitToken';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

/** ExchangeToken */
export type ExchangeLogbookToken = {
  __typename?: 'ExchangeLogbookToken';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

/** ExchangeToken */
export type ExchangeToken = {
  __typename?: 'ExchangeToken';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type FitbitConnectDataInput = {
  code: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  webUserId?: Maybe<Scalars['String']>;
};

export type FitbitGameSyncWorkoutInput = {
  machine?: Maybe<Scalars['String']>;
  webUserId: Scalars['String'];
  workoutId: Scalars['String'];
  workoutName?: Maybe<Scalars['String']>;
};

/** FitbitInfoPayload */
export type FitbitInfoPayload = {
  __typename?: 'FitbitInfoPayload';
  fitbitUserId: Scalars['String'];
};

/** GameLevelsDeleteInput */
export type GameLevelsDeleteInput = {
  game_type: Scalars['Int'];
  level: Scalars['Int'];
};

/** GameLevelsInput */
export type GameLevelsInput = {
  buff?: Maybe<Scalars['JSON']>;
  game_type: Scalars['Int'];
  level: Scalars['Float'];
  level_total_require?: Maybe<Scalars['JSON']>;
  level_up_require: Scalars['JSON'];
};

/** GameLevelsPayload */
export type GameLevelsPayload = {
  __typename?: 'GameLevelsPayload';
  buffTypes: Scalars['JSON'];
  gameLevels: Scalars['JSON'];
  gameTypes: Scalars['JSON'];
  requirementTypes: Scalars['JSON'];
};

/** GameUser */
export type GameUserInfo = {
  __typename?: 'GameUserInfo';
  id: Scalars['String'];
  level: GameUserLevelInfo;
  timezone?: Maybe<Scalars['String']>;
  wallet: Scalars['JSON'];
};

/** GameUserLevelInfo */
export type GameUserLevelInfo = {
  __typename?: 'GameUserLevelInfo';
  exp: Scalars['Float'];
  level: Scalars['String'];
  levelId: Scalars['Float'];
  levelProgress: Scalars['Float'];
  levelProgressFromStart: Scalars['Float'];
  nextExp: Scalars['Float'];
  nextLevel: Scalars['String'];
  nextLevelId: Scalars['Float'];
  remaining: LevelRemaining;
};


/** LevelRemaining */
export type LevelRemaining = {
  __typename?: 'LevelRemaining';
  hour: Scalars['Float'];
  minute: Scalars['Float'];
  second: Scalars['Float'];
};

export type LogbookConnectDataInput = {
  code: Scalars['String'];
  redirectUrl: Scalars['String'];
  scope: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  webUserId?: Maybe<Scalars['String']>;
};

export type LogbookGameSyncWorkoutInput = {
  machine?: Maybe<Scalars['String']>;
  webUserId: Scalars['String'];
  workoutId: Scalars['String'];
  workoutName?: Maybe<Scalars['String']>;
};

/** LogbookInfoPayload */
export type LogbookInfoPayload = {
  __typename?: 'LogbookInfoPayload';
  logbookUserId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticate: TokenPayload;
  checkFitbitConnected: Scalars['Boolean'];
  checkLogbookConnected: Scalars['Boolean'];
  checkStravaConnected: Scalars['Boolean'];
  checkWorkoutSynced: Scalars['Boolean'];
  checkWorkoutsSynced: Array<Scalars['String']>;
  connect: User;
  deleteAnGameLevels: Scalars['Boolean'];
  deleteBuff: Scalars['Boolean'];
  deleteRequirement: Scalars['Boolean'];
  fitbitCancelConnect: ResultStatusPayload;
  fitbitCheckWorkoutSynced: Scalars['Boolean'];
  fitbitCheckWorkoutsSynced: Array<Scalars['String']>;
  fitbitConnect: ExchangeFitbitToken;
  fitbitSyncWorkout: ResultStatusPayload;
  gameSyncWorkout: SyncWorkoutPayload;
  logbookCancelConnect: ResultStatusPayload;
  logbookCheckWorkoutSynced: Scalars['Boolean'];
  logbookCheckWorkoutsSynced: Array<Scalars['String']>;
  logbookConnect: ExchangeLogbookToken;
  logbookSyncWorkout: ResultStatusPayload;
  manualGameSyncWorkout: SyncWorkoutPayload;
  refreshToken: TokenPayload;
  stravaCancelConnect: ResultStatusPayload;
  stravaConnect: ExchangeToken;
  stravaSyncWorkoutsStatus: SyncWorkoutProgressPayload;
  stravaSyncWorkoutsV3: SyncWorkoutPayload;
  updateBuffs: Scalars['Boolean'];
  updateGameLevels: Scalars['Boolean'];
  updateRequirements: Scalars['Boolean'];
};


export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


export type MutationCheckWorkoutSyncedArgs = {
  workoutId: Scalars['String'];
};


export type MutationCheckWorkoutsSyncedArgs = {
  workoutIds: Array<Scalars['String']>;
};


export type MutationConnectArgs = {
  authConnectData: ConnectInput;
};


export type MutationDeleteAnGameLevelsArgs = {
  data: GameLevelsDeleteInput;
};


export type MutationDeleteBuffArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRequirementArgs = {
  id: Scalars['Int'];
};


export type MutationFitbitCheckWorkoutSyncedArgs = {
  workoutId: Scalars['String'];
};


export type MutationFitbitCheckWorkoutsSyncedArgs = {
  workoutIds: Array<Scalars['String']>;
};


export type MutationFitbitConnectArgs = {
  fitbitConnectData: FitbitConnectDataInput;
};


export type MutationFitbitSyncWorkoutArgs = {
  input: FitbitGameSyncWorkoutInput;
};


export type MutationGameSyncWorkoutArgs = {
  input: StravaGameSyncWorkoutInput;
};


export type MutationLogbookCheckWorkoutSyncedArgs = {
  workoutId: Scalars['String'];
};


export type MutationLogbookCheckWorkoutsSyncedArgs = {
  workoutIds: Array<Scalars['String']>;
};


export type MutationLogbookConnectArgs = {
  logbookConnectData: LogbookConnectDataInput;
};


export type MutationLogbookSyncWorkoutArgs = {
  input: LogbookGameSyncWorkoutInput;
};


export type MutationManualGameSyncWorkoutArgs = {
  input: StravaGameSyncWorkoutInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationStravaConnectArgs = {
  stravaConnectData: StravaConnectDataInput;
};


export type MutationUpdateBuffsArgs = {
  data: Array<BuffTypesInput>;
};


export type MutationUpdateGameLevelsArgs = {
  data: Array<GameLevelsInput>;
};


export type MutationUpdateRequirementsArgs = {
  data: Array<RequirementInput>;
};

export type Query = {
  __typename?: 'Query';
  compareStatistic: WorkoutCompare;
  countWorkoutPerDay: WorkoutCalendarPayload;
  countWorkoutWeekStreak: WorkoutWeekStreak;
  getBuffs: Scalars['JSON'];
  getFitbitInfo: FitbitInfoPayload;
  getGameLevelMasterData: GameLevelsPayload;
  getGameUserInfo: GameUserInfo;
  getLastUserWorkout?: Maybe<Workout>;
  getListUserConnectedLogbook: Scalars['JSON'];
  getListUserConnectedStrava: Scalars['JSON'];
  getLogbookInfo: LogbookInfoPayload;
  getRequirements: Scalars['JSON'];
  getStravaInfo: StravaInfoPayload;
  statisticWorkoutInWeek: WorkoutInWeek;
  statisticWorkoutLast7Day: WorkoutInWeek;
};


export type QueryCompareStatisticArgs = {
  period: Scalars['String'];
};


export type QueryCountWorkoutPerDayArgs = {
  input: WorkoutCalendarInput;
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

/** RequirementInput */
export type RequirementInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** ResultStatusPayload */
export type ResultStatusPayload = {
  __typename?: 'ResultStatusPayload';
  status: Scalars['String'];
};

export type StravaConnectDataInput = {
  stravaAuthCode: Scalars['String'];
  userId: Scalars['String'];
  webUserId?: Maybe<Scalars['String']>;
};

export type StravaGameSyncWorkoutInput = {
  machine?: Maybe<Scalars['String']>;
  webUserId: Scalars['String'];
  workoutId: Scalars['String'];
  workoutName?: Maybe<Scalars['String']>;
};

/** StravaInfoPayload */
export type StravaInfoPayload = {
  __typename?: 'StravaInfoPayload';
  stravaAtheleteId: Scalars['Float'];
};

/** SyncWorkoutPayload */
export type SyncWorkoutPayload = {
  __typename?: 'SyncWorkoutPayload';
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

/** SyncWorkoutProgressPayload */
export type SyncWorkoutProgressPayload = {
  __typename?: 'SyncWorkoutProgressPayload';
  completed: Scalars['Float'];
  failed: Scalars['Float'];
  inprogress: Scalars['Float'];
  retrying: Scalars['Float'];
};

/** TokenPayload */
export type TokenPayload = {
  __typename?: 'TokenPayload';
  accessToken: Scalars['String'];
  expires: Scalars['Float'];
  refreshToken: Scalars['String'];
};

/** User */
export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  gameUserId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  webUserId: Scalars['String'];
};

/** Workout */
export type Workout = {
  __typename?: 'Workout';
  create_time?: Maybe<Scalars['DateTime']>;
  diffDay?: Maybe<Scalars['Float']>;
  diffHour?: Maybe<Scalars['Float']>;
  diffMinute?: Maybe<Scalars['Float']>;
  diffMonth?: Maybe<Scalars['Float']>;
  diffYear?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<WorkoutValue>;
};

export type WorkoutCalendarInput = {
  isIncludeNextMonth: Scalars['Boolean'];
  isIncludePreviousMonth: Scalars['Boolean'];
  month: Scalars['Float'];
  year: Scalars['Float'];
};

/** WorkoutCalendarView */
export type WorkoutCalendarPayload = {
  __typename?: 'WorkoutCalendarPayload';
  items: Array<WorkoutDateCount>;
  month: Scalars['Float'];
  streaks: Scalars['JSON'];
  year: Scalars['Float'];
};

/** WorkoutCompare */
export type WorkoutCompare = {
  __typename?: 'WorkoutCompare';
  lastPeriod: Array<WorkoutDateStatistic>;
  summary: WorkoutCompareSummary;
  thisPeriod: Array<WorkoutDateStatistic>;
};

/** WorkoutCompareSummary */
export type WorkoutCompareSummary = {
  __typename?: 'WorkoutCompareSummary';
  different: WorkoutDateStatisticSummary;
  lastPeriod: WorkoutDateStatisticSummary;
  thisPeriod: WorkoutDateStatisticSummary;
};

/** WorkoutDateCount */
export type WorkoutDateCount = {
  __typename?: 'WorkoutDateCount';
  date: Scalars['String'];
  no: Scalars['Float'];
  workouts: Scalars['Float'];
};

/** WorkoutDateStatistic */
export type WorkoutDateStatistic = {
  __typename?: 'WorkoutDateStatistic';
  calories: Scalars['Float'];
  date: Scalars['String'];
  meters: Scalars['Float'];
  no: Scalars['Float'];
  outputs: Scalars['Float'];
  strokes: Scalars['Float'];
  time: Scalars['Float'];
  workouts: Scalars['Float'];
};

/** WorkoutDateStatisticSummary */
export type WorkoutDateStatisticSummary = {
  __typename?: 'WorkoutDateStatisticSummary';
  avg500m: Scalars['Float'];
  avgCal: Scalars['Float'];
  avgSpm: Scalars['Float'];
  avgWatt: Scalars['Float'];
  calories: Scalars['Float'];
  meters: Scalars['Float'];
  outputs: Scalars['Float'];
  strokes: Scalars['Float'];
  time: Scalars['Float'];
  workouts: Scalars['Float'];
};

/** WorkoutInWeek */
export type WorkoutInWeek = {
  __typename?: 'WorkoutInWeek';
  items: Array<WorkoutDateStatistic>;
};

/** WorkoutValue */
export type WorkoutValue = {
  __typename?: 'WorkoutValue';
  Calories: Scalars['Float'];
  GameId: Scalars['Float'];
  GameLevel: Scalars['Float'];
  GameParam: Scalars['Float'];
  Meter: Scalars['Float'];
  Output: Scalars['Float'];
  ParamId: Scalars['Float'];
  Rank: Scalars['Float'];
  Strokes: Scalars['Float'];
  Time: Scalars['Float'];
  TotalTime: Scalars['Float'];
  Type: Scalars['Float'];
};

/** WorkoutWeekStreak */
export type WorkoutWeekStreak = {
  __typename?: 'WorkoutWeekStreak';
  streak: Scalars['Float'];
};

export type CompareStatisticQueryVariables = Exact<{
  period: Scalars['String'];
}>;


export type CompareStatisticQuery = (
  { __typename?: 'Query' }
  & { compareStatistic: (
    { __typename?: 'WorkoutCompare' }
    & { summary: (
      { __typename?: 'WorkoutCompareSummary' }
      & { different: (
        { __typename?: 'WorkoutDateStatisticSummary' }
        & Pick<WorkoutDateStatisticSummary, 'workouts' | 'meters' | 'time' | 'calories' | 'outputs' | 'strokes' | 'avgCal' | 'avgSpm' | 'avg500m' | 'avgWatt'>
      ), thisPeriod: (
        { __typename?: 'WorkoutDateStatisticSummary' }
        & Pick<WorkoutDateStatisticSummary, 'workouts' | 'meters' | 'time' | 'calories' | 'outputs' | 'strokes' | 'avgCal' | 'avgSpm' | 'avg500m' | 'avgWatt'>
      ), lastPeriod: (
        { __typename?: 'WorkoutDateStatisticSummary' }
        & Pick<WorkoutDateStatisticSummary, 'workouts' | 'meters' | 'time' | 'calories' | 'outputs' | 'strokes' | 'avgCal' | 'avgSpm' | 'avg500m' | 'avgWatt'>
      ) }
    ), thisPeriod: Array<(
      { __typename?: 'WorkoutDateStatistic' }
      & Pick<WorkoutDateStatistic, 'no' | 'date' | 'workouts' | 'meters' | 'time' | 'calories' | 'outputs' | 'strokes'>
    )>, lastPeriod: Array<(
      { __typename?: 'WorkoutDateStatistic' }
      & Pick<WorkoutDateStatistic, 'no' | 'date' | 'workouts' | 'meters' | 'time' | 'calories' | 'outputs' | 'strokes'>
    )> }
  ) }
);

export type GetGameUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGameUserInfoQuery = (
  { __typename?: 'Query' }
  & { getGameUserInfo: (
    { __typename?: 'GameUserInfo' }
    & Pick<GameUserInfo, 'id' | 'timezone' | 'wallet'>
    & { level: (
      { __typename?: 'GameUserLevelInfo' }
      & Pick<GameUserLevelInfo, 'level' | 'levelId' | 'levelProgress' | 'levelProgressFromStart' | 'exp' | 'nextExp' | 'nextLevelId' | 'nextLevel'>
      & { remaining: (
        { __typename?: 'LevelRemaining' }
        & Pick<LevelRemaining, 'hour' | 'minute'>
      ) }
    ) }
  ) }
);

export type GetLastWorkoutQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLastWorkoutQuery = (
  { __typename?: 'Query' }
  & { getLastUserWorkout?: Maybe<(
    { __typename?: 'Workout' }
    & Pick<Workout, 'key' | 'create_time' | 'diffYear' | 'diffMonth' | 'diffDay' | 'diffHour' | 'diffMinute'>
    & { value?: Maybe<(
      { __typename?: 'WorkoutValue' }
      & Pick<WorkoutValue, 'Rank' | 'Time' | 'Type' | 'Meter'>
    )> }
  )> }
);

export type StatisticWorkoutLast7DayQueryVariables = Exact<{ [key: string]: never; }>;


export type StatisticWorkoutLast7DayQuery = (
  { __typename?: 'Query' }
  & { statisticWorkoutLast7Day: (
    { __typename?: 'WorkoutInWeek' }
    & { items: Array<(
      { __typename?: 'WorkoutDateStatistic' }
      & Pick<WorkoutDateStatistic, 'no' | 'date' | 'workouts' | 'meters' | 'time' | 'calories' | 'outputs'>
    )> }
  ) }
);

export type GetWorkoutByUserQueryVariables = Exact<{
  month: Scalars['Float'];
  year: Scalars['Float'];
  isIncludeNextMonth: Scalars['Boolean'];
  isIncludePreviousMonth: Scalars['Boolean'];
}>;


export type GetWorkoutByUserQuery = (
  { __typename?: 'Query' }
  & { countWorkoutPerDay: (
    { __typename?: 'WorkoutCalendarPayload' }
    & Pick<WorkoutCalendarPayload, 'year' | 'month' | 'streaks'>
    & { items: Array<(
      { __typename?: 'WorkoutDateCount' }
      & Pick<WorkoutDateCount, 'no' | 'date' | 'workouts'>
    )> }
  ) }
);

export type GetWorkoutWeekStreakQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkoutWeekStreakQuery = (
  { __typename?: 'Query' }
  & { countWorkoutWeekStreak: (
    { __typename?: 'WorkoutWeekStreak' }
    & Pick<WorkoutWeekStreak, 'streak'>
  ) }
);

export type CheckWorkoutSyncedMutationVariables = Exact<{
  workoutId: Scalars['String'];
}>;


export type CheckWorkoutSyncedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkWorkoutSynced'>
);

export type CheckWorkoutsSyncedMutationVariables = Exact<{
  workoutIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type CheckWorkoutsSyncedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkWorkoutsSynced'>
);

export type ConnectMutationVariables = Exact<{
  webUserId: Scalars['String'];
  gameUserId?: Maybe<Scalars['String']>;
}>;


export type ConnectMutation = (
  { __typename?: 'Mutation' }
  & { connect: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type CancelConnectFitbitMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelConnectFitbitMutation = (
  { __typename?: 'Mutation' }
  & { fitbitCancelConnect: (
    { __typename?: 'ResultStatusPayload' }
    & Pick<ResultStatusPayload, 'status'>
  ) }
);

export type FitbitCheckWorkoutSyncedMutationVariables = Exact<{
  workoutId: Scalars['String'];
}>;


export type FitbitCheckWorkoutSyncedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'fitbitCheckWorkoutSynced'>
);

export type CheckFitbitConnectedMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckFitbitConnectedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkFitbitConnected'>
);

export type FitbitCheckWorkoutsSyncedMutationVariables = Exact<{
  workoutIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type FitbitCheckWorkoutsSyncedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'fitbitCheckWorkoutsSynced'>
);

export type ConnectFitbitMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type ConnectFitbitMutation = (
  { __typename?: 'Mutation' }
  & { fitbitConnect: (
    { __typename?: 'ExchangeFitbitToken' }
    & Pick<ExchangeFitbitToken, 'accessToken' | 'refreshToken'>
  ) }
);

export type FitbitSyncWorkoutMutationVariables = Exact<{
  workoutId: Scalars['String'];
  webUserId: Scalars['String'];
  machine?: Maybe<Scalars['String']>;
  workoutName?: Maybe<Scalars['String']>;
}>;


export type FitbitSyncWorkoutMutation = (
  { __typename?: 'Mutation' }
  & { fitbitSyncWorkout: (
    { __typename?: 'ResultStatusPayload' }
    & Pick<ResultStatusPayload, 'status'>
  ) }
);

export type GameSyncWorkoutMutationVariables = Exact<{
  webUserId: Scalars['String'];
  workoutId: Scalars['String'];
  machine?: Maybe<Scalars['String']>;
  workoutName?: Maybe<Scalars['String']>;
}>;


export type GameSyncWorkoutMutation = (
  { __typename?: 'Mutation' }
  & { gameSyncWorkout: (
    { __typename?: 'SyncWorkoutPayload' }
    & Pick<SyncWorkoutPayload, 'status'>
  ) }
);

export type CancelConnectLogBookMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelConnectLogBookMutation = (
  { __typename?: 'Mutation' }
  & { logbookCancelConnect: (
    { __typename?: 'ResultStatusPayload' }
    & Pick<ResultStatusPayload, 'status'>
  ) }
);

export type CheckLogbookConnectedMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckLogbookConnectedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkLogbookConnected'>
);

export type LogbookCheckWorkoutSyncedMutationVariables = Exact<{
  workoutId: Scalars['String'];
}>;


export type LogbookCheckWorkoutSyncedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logbookCheckWorkoutSynced'>
);

export type LogbookCheckWorkoutsSyncedMutationVariables = Exact<{
  workoutIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type LogbookCheckWorkoutsSyncedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logbookCheckWorkoutsSynced'>
);

export type ConnectLogbookMutationVariables = Exact<{
  scope: Scalars['String'];
  redirectUrl: Scalars['String'];
  code: Scalars['String'];
}>;


export type ConnectLogbookMutation = (
  { __typename?: 'Mutation' }
  & { logbookConnect: (
    { __typename?: 'ExchangeLogbookToken' }
    & Pick<ExchangeLogbookToken, 'accessToken' | 'refreshToken'>
  ) }
);

export type LogbookSyncWorkoutMutationVariables = Exact<{
  workoutId: Scalars['String'];
  webUserId: Scalars['String'];
  machine?: Maybe<Scalars['String']>;
  workoutName?: Maybe<Scalars['String']>;
}>;


export type LogbookSyncWorkoutMutation = (
  { __typename?: 'Mutation' }
  & { logbookSyncWorkout: (
    { __typename?: 'ResultStatusPayload' }
    & Pick<ResultStatusPayload, 'status'>
  ) }
);

export type StravaCancelConnectMutationVariables = Exact<{ [key: string]: never; }>;


export type StravaCancelConnectMutation = (
  { __typename?: 'Mutation' }
  & { stravaCancelConnect: (
    { __typename?: 'ResultStatusPayload' }
    & Pick<ResultStatusPayload, 'status'>
  ) }
);

export type CheckStravaConnectedMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckStravaConnectedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkStravaConnected'>
);

export type StravaConnectMutationVariables = Exact<{
  crmUserId: Scalars['String'];
  stravaAuthCode: Scalars['String'];
}>;


export type StravaConnectMutation = (
  { __typename?: 'Mutation' }
  & { stravaConnect: (
    { __typename?: 'ExchangeToken' }
    & Pick<ExchangeToken, 'accessToken' | 'refreshToken'>
  ) }
);

export type StravaSyncWorkoutsMutationVariables = Exact<{ [key: string]: never; }>;


export type StravaSyncWorkoutsMutation = (
  { __typename?: 'Mutation' }
  & { stravaSyncWorkoutsV3: (
    { __typename?: 'SyncWorkoutPayload' }
    & Pick<SyncWorkoutPayload, 'status' | 'total'>
  ) }
);


export const CompareStatisticDocument = gql`
    query compareStatistic($period: String!) {
  compareStatistic(period: $period) {
    summary {
      different {
        workouts
        meters
        time
        calories
        outputs
        strokes
        avgCal
        avgSpm
        avg500m
        avgWatt
      }
      thisPeriod {
        workouts
        meters
        time
        calories
        outputs
        strokes
        avgCal
        avgSpm
        avg500m
        avgWatt
      }
      lastPeriod {
        workouts
        meters
        time
        calories
        outputs
        strokes
        avgCal
        avgSpm
        avg500m
        avgWatt
      }
    }
    thisPeriod {
      no
      date
      workouts
      meters
      time
      calories
      outputs
      strokes
    }
    lastPeriod {
      no
      date
      workouts
      meters
      time
      calories
      outputs
      strokes
    }
  }
}
    `;

/**
 * __useCompareStatisticQuery__
 *
 * To run a query within a React component, call `useCompareStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareStatisticQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useCompareStatisticQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompareStatisticQuery, CompareStatisticQueryVariables>) {
        return ApolloReactHooks.useQuery<CompareStatisticQuery, CompareStatisticQueryVariables>(CompareStatisticDocument, baseOptions);
      }
export function useCompareStatisticLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompareStatisticQuery, CompareStatisticQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompareStatisticQuery, CompareStatisticQueryVariables>(CompareStatisticDocument, baseOptions);
        }
export type CompareStatisticQueryHookResult = ReturnType<typeof useCompareStatisticQuery>;
export type CompareStatisticLazyQueryHookResult = ReturnType<typeof useCompareStatisticLazyQuery>;
export type CompareStatisticQueryResult = ApolloReactCommon.QueryResult<CompareStatisticQuery, CompareStatisticQueryVariables>;
export const GetGameUserInfoDocument = gql`
    query getGameUserInfo {
  getGameUserInfo {
    id
    timezone
    wallet
    level {
      level
      levelId
      levelProgress
      levelProgressFromStart
      exp
      nextExp
      nextLevelId
      nextLevel
      remaining {
        hour
        minute
      }
    }
  }
}
    `;

/**
 * __useGetGameUserInfoQuery__
 *
 * To run a query within a React component, call `useGetGameUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameUserInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGameUserInfoQuery, GetGameUserInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGameUserInfoQuery, GetGameUserInfoQueryVariables>(GetGameUserInfoDocument, baseOptions);
      }
export function useGetGameUserInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGameUserInfoQuery, GetGameUserInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGameUserInfoQuery, GetGameUserInfoQueryVariables>(GetGameUserInfoDocument, baseOptions);
        }
export type GetGameUserInfoQueryHookResult = ReturnType<typeof useGetGameUserInfoQuery>;
export type GetGameUserInfoLazyQueryHookResult = ReturnType<typeof useGetGameUserInfoLazyQuery>;
export type GetGameUserInfoQueryResult = ApolloReactCommon.QueryResult<GetGameUserInfoQuery, GetGameUserInfoQueryVariables>;
export const GetLastWorkoutDocument = gql`
    query getLastWorkout {
  getLastUserWorkout {
    key
    create_time
    value {
      Rank
      Time
      Type
      Meter
    }
    diffYear
    diffMonth
    diffDay
    diffHour
    diffMinute
  }
}
    `;

/**
 * __useGetLastWorkoutQuery__
 *
 * To run a query within a React component, call `useGetLastWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastWorkoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastWorkoutQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLastWorkoutQuery, GetLastWorkoutQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLastWorkoutQuery, GetLastWorkoutQueryVariables>(GetLastWorkoutDocument, baseOptions);
      }
export function useGetLastWorkoutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastWorkoutQuery, GetLastWorkoutQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLastWorkoutQuery, GetLastWorkoutQueryVariables>(GetLastWorkoutDocument, baseOptions);
        }
export type GetLastWorkoutQueryHookResult = ReturnType<typeof useGetLastWorkoutQuery>;
export type GetLastWorkoutLazyQueryHookResult = ReturnType<typeof useGetLastWorkoutLazyQuery>;
export type GetLastWorkoutQueryResult = ApolloReactCommon.QueryResult<GetLastWorkoutQuery, GetLastWorkoutQueryVariables>;
export const StatisticWorkoutLast7DayDocument = gql`
    query statisticWorkoutLast7Day {
  statisticWorkoutLast7Day {
    items {
      no
      date
      workouts
      meters
      time
      calories
      outputs
    }
  }
}
    `;

/**
 * __useStatisticWorkoutLast7DayQuery__
 *
 * To run a query within a React component, call `useStatisticWorkoutLast7DayQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticWorkoutLast7DayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticWorkoutLast7DayQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticWorkoutLast7DayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatisticWorkoutLast7DayQuery, StatisticWorkoutLast7DayQueryVariables>) {
        return ApolloReactHooks.useQuery<StatisticWorkoutLast7DayQuery, StatisticWorkoutLast7DayQueryVariables>(StatisticWorkoutLast7DayDocument, baseOptions);
      }
export function useStatisticWorkoutLast7DayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatisticWorkoutLast7DayQuery, StatisticWorkoutLast7DayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatisticWorkoutLast7DayQuery, StatisticWorkoutLast7DayQueryVariables>(StatisticWorkoutLast7DayDocument, baseOptions);
        }
export type StatisticWorkoutLast7DayQueryHookResult = ReturnType<typeof useStatisticWorkoutLast7DayQuery>;
export type StatisticWorkoutLast7DayLazyQueryHookResult = ReturnType<typeof useStatisticWorkoutLast7DayLazyQuery>;
export type StatisticWorkoutLast7DayQueryResult = ApolloReactCommon.QueryResult<StatisticWorkoutLast7DayQuery, StatisticWorkoutLast7DayQueryVariables>;
export const GetWorkoutByUserDocument = gql`
    query getWorkoutByUser($month: Float!, $year: Float!, $isIncludeNextMonth: Boolean!, $isIncludePreviousMonth: Boolean!) {
  countWorkoutPerDay(input: {month: $month, year: $year, isIncludeNextMonth: $isIncludeNextMonth, isIncludePreviousMonth: $isIncludePreviousMonth}) {
    year
    month
    streaks
    items {
      no
      date
      workouts
    }
  }
}
    `;

/**
 * __useGetWorkoutByUserQuery__
 *
 * To run a query within a React component, call `useGetWorkoutByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutByUserQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      isIncludeNextMonth: // value for 'isIncludeNextMonth'
 *      isIncludePreviousMonth: // value for 'isIncludePreviousMonth'
 *   },
 * });
 */
export function useGetWorkoutByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkoutByUserQuery, GetWorkoutByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkoutByUserQuery, GetWorkoutByUserQueryVariables>(GetWorkoutByUserDocument, baseOptions);
      }
export function useGetWorkoutByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkoutByUserQuery, GetWorkoutByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkoutByUserQuery, GetWorkoutByUserQueryVariables>(GetWorkoutByUserDocument, baseOptions);
        }
export type GetWorkoutByUserQueryHookResult = ReturnType<typeof useGetWorkoutByUserQuery>;
export type GetWorkoutByUserLazyQueryHookResult = ReturnType<typeof useGetWorkoutByUserLazyQuery>;
export type GetWorkoutByUserQueryResult = ApolloReactCommon.QueryResult<GetWorkoutByUserQuery, GetWorkoutByUserQueryVariables>;
export const GetWorkoutWeekStreakDocument = gql`
    query getWorkoutWeekStreak {
  countWorkoutWeekStreak {
    streak
  }
}
    `;

/**
 * __useGetWorkoutWeekStreakQuery__
 *
 * To run a query within a React component, call `useGetWorkoutWeekStreakQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutWeekStreakQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutWeekStreakQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkoutWeekStreakQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkoutWeekStreakQuery, GetWorkoutWeekStreakQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkoutWeekStreakQuery, GetWorkoutWeekStreakQueryVariables>(GetWorkoutWeekStreakDocument, baseOptions);
      }
export function useGetWorkoutWeekStreakLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkoutWeekStreakQuery, GetWorkoutWeekStreakQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkoutWeekStreakQuery, GetWorkoutWeekStreakQueryVariables>(GetWorkoutWeekStreakDocument, baseOptions);
        }
export type GetWorkoutWeekStreakQueryHookResult = ReturnType<typeof useGetWorkoutWeekStreakQuery>;
export type GetWorkoutWeekStreakLazyQueryHookResult = ReturnType<typeof useGetWorkoutWeekStreakLazyQuery>;
export type GetWorkoutWeekStreakQueryResult = ApolloReactCommon.QueryResult<GetWorkoutWeekStreakQuery, GetWorkoutWeekStreakQueryVariables>;
export const CheckWorkoutSyncedDocument = gql`
    mutation checkWorkoutSynced($workoutId: String!) {
  checkWorkoutSynced(workoutId: $workoutId)
}
    `;
export type CheckWorkoutSyncedMutationFn = ApolloReactCommon.MutationFunction<CheckWorkoutSyncedMutation, CheckWorkoutSyncedMutationVariables>;

/**
 * __useCheckWorkoutSyncedMutation__
 *
 * To run a mutation, you first call `useCheckWorkoutSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckWorkoutSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkWorkoutSyncedMutation, { data, loading, error }] = useCheckWorkoutSyncedMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *   },
 * });
 */
export function useCheckWorkoutSyncedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckWorkoutSyncedMutation, CheckWorkoutSyncedMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckWorkoutSyncedMutation, CheckWorkoutSyncedMutationVariables>(CheckWorkoutSyncedDocument, baseOptions);
      }
export type CheckWorkoutSyncedMutationHookResult = ReturnType<typeof useCheckWorkoutSyncedMutation>;
export type CheckWorkoutSyncedMutationResult = ApolloReactCommon.MutationResult<CheckWorkoutSyncedMutation>;
export type CheckWorkoutSyncedMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckWorkoutSyncedMutation, CheckWorkoutSyncedMutationVariables>;
export const CheckWorkoutsSyncedDocument = gql`
    mutation checkWorkoutsSynced($workoutIds: [String!]!) {
  checkWorkoutsSynced(workoutIds: $workoutIds)
}
    `;
export type CheckWorkoutsSyncedMutationFn = ApolloReactCommon.MutationFunction<CheckWorkoutsSyncedMutation, CheckWorkoutsSyncedMutationVariables>;

/**
 * __useCheckWorkoutsSyncedMutation__
 *
 * To run a mutation, you first call `useCheckWorkoutsSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckWorkoutsSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkWorkoutsSyncedMutation, { data, loading, error }] = useCheckWorkoutsSyncedMutation({
 *   variables: {
 *      workoutIds: // value for 'workoutIds'
 *   },
 * });
 */
export function useCheckWorkoutsSyncedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckWorkoutsSyncedMutation, CheckWorkoutsSyncedMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckWorkoutsSyncedMutation, CheckWorkoutsSyncedMutationVariables>(CheckWorkoutsSyncedDocument, baseOptions);
      }
export type CheckWorkoutsSyncedMutationHookResult = ReturnType<typeof useCheckWorkoutsSyncedMutation>;
export type CheckWorkoutsSyncedMutationResult = ApolloReactCommon.MutationResult<CheckWorkoutsSyncedMutation>;
export type CheckWorkoutsSyncedMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckWorkoutsSyncedMutation, CheckWorkoutsSyncedMutationVariables>;
export const ConnectDocument = gql`
    mutation connect($webUserId: String!, $gameUserId: String) {
  connect(authConnectData: {webUserId: $webUserId, gameUserId: $gameUserId}) {
    id
  }
}
    `;
export type ConnectMutationFn = ApolloReactCommon.MutationFunction<ConnectMutation, ConnectMutationVariables>;

/**
 * __useConnectMutation__
 *
 * To run a mutation, you first call `useConnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectMutation, { data, loading, error }] = useConnectMutation({
 *   variables: {
 *      webUserId: // value for 'webUserId'
 *      gameUserId: // value for 'gameUserId'
 *   },
 * });
 */
export function useConnectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectMutation, ConnectMutationVariables>) {
        return ApolloReactHooks.useMutation<ConnectMutation, ConnectMutationVariables>(ConnectDocument, baseOptions);
      }
export type ConnectMutationHookResult = ReturnType<typeof useConnectMutation>;
export type ConnectMutationResult = ApolloReactCommon.MutationResult<ConnectMutation>;
export type ConnectMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectMutation, ConnectMutationVariables>;
export const CancelConnectFitbitDocument = gql`
    mutation cancelConnectFitbit {
  fitbitCancelConnect {
    status
  }
}
    `;
export type CancelConnectFitbitMutationFn = ApolloReactCommon.MutationFunction<CancelConnectFitbitMutation, CancelConnectFitbitMutationVariables>;

/**
 * __useCancelConnectFitbitMutation__
 *
 * To run a mutation, you first call `useCancelConnectFitbitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelConnectFitbitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelConnectFitbitMutation, { data, loading, error }] = useCancelConnectFitbitMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelConnectFitbitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelConnectFitbitMutation, CancelConnectFitbitMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelConnectFitbitMutation, CancelConnectFitbitMutationVariables>(CancelConnectFitbitDocument, baseOptions);
      }
export type CancelConnectFitbitMutationHookResult = ReturnType<typeof useCancelConnectFitbitMutation>;
export type CancelConnectFitbitMutationResult = ApolloReactCommon.MutationResult<CancelConnectFitbitMutation>;
export type CancelConnectFitbitMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelConnectFitbitMutation, CancelConnectFitbitMutationVariables>;
export const FitbitCheckWorkoutSyncedDocument = gql`
    mutation fitbitCheckWorkoutSynced($workoutId: String!) {
  fitbitCheckWorkoutSynced(workoutId: $workoutId)
}
    `;
export type FitbitCheckWorkoutSyncedMutationFn = ApolloReactCommon.MutationFunction<FitbitCheckWorkoutSyncedMutation, FitbitCheckWorkoutSyncedMutationVariables>;

/**
 * __useFitbitCheckWorkoutSyncedMutation__
 *
 * To run a mutation, you first call `useFitbitCheckWorkoutSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFitbitCheckWorkoutSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fitbitCheckWorkoutSyncedMutation, { data, loading, error }] = useFitbitCheckWorkoutSyncedMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *   },
 * });
 */
export function useFitbitCheckWorkoutSyncedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FitbitCheckWorkoutSyncedMutation, FitbitCheckWorkoutSyncedMutationVariables>) {
        return ApolloReactHooks.useMutation<FitbitCheckWorkoutSyncedMutation, FitbitCheckWorkoutSyncedMutationVariables>(FitbitCheckWorkoutSyncedDocument, baseOptions);
      }
export type FitbitCheckWorkoutSyncedMutationHookResult = ReturnType<typeof useFitbitCheckWorkoutSyncedMutation>;
export type FitbitCheckWorkoutSyncedMutationResult = ApolloReactCommon.MutationResult<FitbitCheckWorkoutSyncedMutation>;
export type FitbitCheckWorkoutSyncedMutationOptions = ApolloReactCommon.BaseMutationOptions<FitbitCheckWorkoutSyncedMutation, FitbitCheckWorkoutSyncedMutationVariables>;
export const CheckFitbitConnectedDocument = gql`
    mutation checkFitbitConnected {
  checkFitbitConnected
}
    `;
export type CheckFitbitConnectedMutationFn = ApolloReactCommon.MutationFunction<CheckFitbitConnectedMutation, CheckFitbitConnectedMutationVariables>;

/**
 * __useCheckFitbitConnectedMutation__
 *
 * To run a mutation, you first call `useCheckFitbitConnectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckFitbitConnectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkFitbitConnectedMutation, { data, loading, error }] = useCheckFitbitConnectedMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckFitbitConnectedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckFitbitConnectedMutation, CheckFitbitConnectedMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckFitbitConnectedMutation, CheckFitbitConnectedMutationVariables>(CheckFitbitConnectedDocument, baseOptions);
      }
export type CheckFitbitConnectedMutationHookResult = ReturnType<typeof useCheckFitbitConnectedMutation>;
export type CheckFitbitConnectedMutationResult = ApolloReactCommon.MutationResult<CheckFitbitConnectedMutation>;
export type CheckFitbitConnectedMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckFitbitConnectedMutation, CheckFitbitConnectedMutationVariables>;
export const FitbitCheckWorkoutsSyncedDocument = gql`
    mutation fitbitCheckWorkoutsSynced($workoutIds: [String!]!) {
  fitbitCheckWorkoutsSynced(workoutIds: $workoutIds)
}
    `;
export type FitbitCheckWorkoutsSyncedMutationFn = ApolloReactCommon.MutationFunction<FitbitCheckWorkoutsSyncedMutation, FitbitCheckWorkoutsSyncedMutationVariables>;

/**
 * __useFitbitCheckWorkoutsSyncedMutation__
 *
 * To run a mutation, you first call `useFitbitCheckWorkoutsSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFitbitCheckWorkoutsSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fitbitCheckWorkoutsSyncedMutation, { data, loading, error }] = useFitbitCheckWorkoutsSyncedMutation({
 *   variables: {
 *      workoutIds: // value for 'workoutIds'
 *   },
 * });
 */
export function useFitbitCheckWorkoutsSyncedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FitbitCheckWorkoutsSyncedMutation, FitbitCheckWorkoutsSyncedMutationVariables>) {
        return ApolloReactHooks.useMutation<FitbitCheckWorkoutsSyncedMutation, FitbitCheckWorkoutsSyncedMutationVariables>(FitbitCheckWorkoutsSyncedDocument, baseOptions);
      }
export type FitbitCheckWorkoutsSyncedMutationHookResult = ReturnType<typeof useFitbitCheckWorkoutsSyncedMutation>;
export type FitbitCheckWorkoutsSyncedMutationResult = ApolloReactCommon.MutationResult<FitbitCheckWorkoutsSyncedMutation>;
export type FitbitCheckWorkoutsSyncedMutationOptions = ApolloReactCommon.BaseMutationOptions<FitbitCheckWorkoutsSyncedMutation, FitbitCheckWorkoutsSyncedMutationVariables>;
export const ConnectFitbitDocument = gql`
    mutation connectFitbit($code: String!) {
  fitbitConnect(fitbitConnectData: {code: $code}) {
    accessToken
    refreshToken
  }
}
    `;
export type ConnectFitbitMutationFn = ApolloReactCommon.MutationFunction<ConnectFitbitMutation, ConnectFitbitMutationVariables>;

/**
 * __useConnectFitbitMutation__
 *
 * To run a mutation, you first call `useConnectFitbitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectFitbitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectFitbitMutation, { data, loading, error }] = useConnectFitbitMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConnectFitbitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectFitbitMutation, ConnectFitbitMutationVariables>) {
        return ApolloReactHooks.useMutation<ConnectFitbitMutation, ConnectFitbitMutationVariables>(ConnectFitbitDocument, baseOptions);
      }
export type ConnectFitbitMutationHookResult = ReturnType<typeof useConnectFitbitMutation>;
export type ConnectFitbitMutationResult = ApolloReactCommon.MutationResult<ConnectFitbitMutation>;
export type ConnectFitbitMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectFitbitMutation, ConnectFitbitMutationVariables>;
export const FitbitSyncWorkoutDocument = gql`
    mutation fitbitSyncWorkout($workoutId: String!, $webUserId: String!, $machine: String, $workoutName: String) {
  fitbitSyncWorkout(input: {workoutId: $workoutId, webUserId: $webUserId, machine: $machine, workoutName: $workoutName}) {
    status
  }
}
    `;
export type FitbitSyncWorkoutMutationFn = ApolloReactCommon.MutationFunction<FitbitSyncWorkoutMutation, FitbitSyncWorkoutMutationVariables>;

/**
 * __useFitbitSyncWorkoutMutation__
 *
 * To run a mutation, you first call `useFitbitSyncWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFitbitSyncWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fitbitSyncWorkoutMutation, { data, loading, error }] = useFitbitSyncWorkoutMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *      webUserId: // value for 'webUserId'
 *      machine: // value for 'machine'
 *      workoutName: // value for 'workoutName'
 *   },
 * });
 */
export function useFitbitSyncWorkoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FitbitSyncWorkoutMutation, FitbitSyncWorkoutMutationVariables>) {
        return ApolloReactHooks.useMutation<FitbitSyncWorkoutMutation, FitbitSyncWorkoutMutationVariables>(FitbitSyncWorkoutDocument, baseOptions);
      }
export type FitbitSyncWorkoutMutationHookResult = ReturnType<typeof useFitbitSyncWorkoutMutation>;
export type FitbitSyncWorkoutMutationResult = ApolloReactCommon.MutationResult<FitbitSyncWorkoutMutation>;
export type FitbitSyncWorkoutMutationOptions = ApolloReactCommon.BaseMutationOptions<FitbitSyncWorkoutMutation, FitbitSyncWorkoutMutationVariables>;
export const GameSyncWorkoutDocument = gql`
    mutation gameSyncWorkout($webUserId: String!, $workoutId: String!, $machine: String, $workoutName: String) {
  gameSyncWorkout(input: {webUserId: $webUserId, workoutId: $workoutId, machine: $machine, workoutName: $workoutName}) {
    status
  }
}
    `;
export type GameSyncWorkoutMutationFn = ApolloReactCommon.MutationFunction<GameSyncWorkoutMutation, GameSyncWorkoutMutationVariables>;

/**
 * __useGameSyncWorkoutMutation__
 *
 * To run a mutation, you first call `useGameSyncWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGameSyncWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gameSyncWorkoutMutation, { data, loading, error }] = useGameSyncWorkoutMutation({
 *   variables: {
 *      webUserId: // value for 'webUserId'
 *      workoutId: // value for 'workoutId'
 *      machine: // value for 'machine'
 *      workoutName: // value for 'workoutName'
 *   },
 * });
 */
export function useGameSyncWorkoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GameSyncWorkoutMutation, GameSyncWorkoutMutationVariables>) {
        return ApolloReactHooks.useMutation<GameSyncWorkoutMutation, GameSyncWorkoutMutationVariables>(GameSyncWorkoutDocument, baseOptions);
      }
export type GameSyncWorkoutMutationHookResult = ReturnType<typeof useGameSyncWorkoutMutation>;
export type GameSyncWorkoutMutationResult = ApolloReactCommon.MutationResult<GameSyncWorkoutMutation>;
export type GameSyncWorkoutMutationOptions = ApolloReactCommon.BaseMutationOptions<GameSyncWorkoutMutation, GameSyncWorkoutMutationVariables>;
export const CancelConnectLogBookDocument = gql`
    mutation cancelConnectLogBook {
  logbookCancelConnect {
    status
  }
}
    `;
export type CancelConnectLogBookMutationFn = ApolloReactCommon.MutationFunction<CancelConnectLogBookMutation, CancelConnectLogBookMutationVariables>;

/**
 * __useCancelConnectLogBookMutation__
 *
 * To run a mutation, you first call `useCancelConnectLogBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelConnectLogBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelConnectLogBookMutation, { data, loading, error }] = useCancelConnectLogBookMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelConnectLogBookMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelConnectLogBookMutation, CancelConnectLogBookMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelConnectLogBookMutation, CancelConnectLogBookMutationVariables>(CancelConnectLogBookDocument, baseOptions);
      }
export type CancelConnectLogBookMutationHookResult = ReturnType<typeof useCancelConnectLogBookMutation>;
export type CancelConnectLogBookMutationResult = ApolloReactCommon.MutationResult<CancelConnectLogBookMutation>;
export type CancelConnectLogBookMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelConnectLogBookMutation, CancelConnectLogBookMutationVariables>;
export const CheckLogbookConnectedDocument = gql`
    mutation checkLogbookConnected {
  checkLogbookConnected
}
    `;
export type CheckLogbookConnectedMutationFn = ApolloReactCommon.MutationFunction<CheckLogbookConnectedMutation, CheckLogbookConnectedMutationVariables>;

/**
 * __useCheckLogbookConnectedMutation__
 *
 * To run a mutation, you first call `useCheckLogbookConnectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckLogbookConnectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkLogbookConnectedMutation, { data, loading, error }] = useCheckLogbookConnectedMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckLogbookConnectedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckLogbookConnectedMutation, CheckLogbookConnectedMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckLogbookConnectedMutation, CheckLogbookConnectedMutationVariables>(CheckLogbookConnectedDocument, baseOptions);
      }
export type CheckLogbookConnectedMutationHookResult = ReturnType<typeof useCheckLogbookConnectedMutation>;
export type CheckLogbookConnectedMutationResult = ApolloReactCommon.MutationResult<CheckLogbookConnectedMutation>;
export type CheckLogbookConnectedMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckLogbookConnectedMutation, CheckLogbookConnectedMutationVariables>;
export const LogbookCheckWorkoutSyncedDocument = gql`
    mutation logbookCheckWorkoutSynced($workoutId: String!) {
  logbookCheckWorkoutSynced(workoutId: $workoutId)
}
    `;
export type LogbookCheckWorkoutSyncedMutationFn = ApolloReactCommon.MutationFunction<LogbookCheckWorkoutSyncedMutation, LogbookCheckWorkoutSyncedMutationVariables>;

/**
 * __useLogbookCheckWorkoutSyncedMutation__
 *
 * To run a mutation, you first call `useLogbookCheckWorkoutSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogbookCheckWorkoutSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logbookCheckWorkoutSyncedMutation, { data, loading, error }] = useLogbookCheckWorkoutSyncedMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *   },
 * });
 */
export function useLogbookCheckWorkoutSyncedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogbookCheckWorkoutSyncedMutation, LogbookCheckWorkoutSyncedMutationVariables>) {
        return ApolloReactHooks.useMutation<LogbookCheckWorkoutSyncedMutation, LogbookCheckWorkoutSyncedMutationVariables>(LogbookCheckWorkoutSyncedDocument, baseOptions);
      }
export type LogbookCheckWorkoutSyncedMutationHookResult = ReturnType<typeof useLogbookCheckWorkoutSyncedMutation>;
export type LogbookCheckWorkoutSyncedMutationResult = ApolloReactCommon.MutationResult<LogbookCheckWorkoutSyncedMutation>;
export type LogbookCheckWorkoutSyncedMutationOptions = ApolloReactCommon.BaseMutationOptions<LogbookCheckWorkoutSyncedMutation, LogbookCheckWorkoutSyncedMutationVariables>;
export const LogbookCheckWorkoutsSyncedDocument = gql`
    mutation logbookCheckWorkoutsSynced($workoutIds: [String!]!) {
  logbookCheckWorkoutsSynced(workoutIds: $workoutIds)
}
    `;
export type LogbookCheckWorkoutsSyncedMutationFn = ApolloReactCommon.MutationFunction<LogbookCheckWorkoutsSyncedMutation, LogbookCheckWorkoutsSyncedMutationVariables>;

/**
 * __useLogbookCheckWorkoutsSyncedMutation__
 *
 * To run a mutation, you first call `useLogbookCheckWorkoutsSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogbookCheckWorkoutsSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logbookCheckWorkoutsSyncedMutation, { data, loading, error }] = useLogbookCheckWorkoutsSyncedMutation({
 *   variables: {
 *      workoutIds: // value for 'workoutIds'
 *   },
 * });
 */
export function useLogbookCheckWorkoutsSyncedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogbookCheckWorkoutsSyncedMutation, LogbookCheckWorkoutsSyncedMutationVariables>) {
        return ApolloReactHooks.useMutation<LogbookCheckWorkoutsSyncedMutation, LogbookCheckWorkoutsSyncedMutationVariables>(LogbookCheckWorkoutsSyncedDocument, baseOptions);
      }
export type LogbookCheckWorkoutsSyncedMutationHookResult = ReturnType<typeof useLogbookCheckWorkoutsSyncedMutation>;
export type LogbookCheckWorkoutsSyncedMutationResult = ApolloReactCommon.MutationResult<LogbookCheckWorkoutsSyncedMutation>;
export type LogbookCheckWorkoutsSyncedMutationOptions = ApolloReactCommon.BaseMutationOptions<LogbookCheckWorkoutsSyncedMutation, LogbookCheckWorkoutsSyncedMutationVariables>;
export const ConnectLogbookDocument = gql`
    mutation connectLogbook($scope: String!, $redirectUrl: String!, $code: String!) {
  logbookConnect(logbookConnectData: {scope: $scope, redirectUrl: $redirectUrl, code: $code}) {
    accessToken
    refreshToken
  }
}
    `;
export type ConnectLogbookMutationFn = ApolloReactCommon.MutationFunction<ConnectLogbookMutation, ConnectLogbookMutationVariables>;

/**
 * __useConnectLogbookMutation__
 *
 * To run a mutation, you first call `useConnectLogbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectLogbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectLogbookMutation, { data, loading, error }] = useConnectLogbookMutation({
 *   variables: {
 *      scope: // value for 'scope'
 *      redirectUrl: // value for 'redirectUrl'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConnectLogbookMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectLogbookMutation, ConnectLogbookMutationVariables>) {
        return ApolloReactHooks.useMutation<ConnectLogbookMutation, ConnectLogbookMutationVariables>(ConnectLogbookDocument, baseOptions);
      }
export type ConnectLogbookMutationHookResult = ReturnType<typeof useConnectLogbookMutation>;
export type ConnectLogbookMutationResult = ApolloReactCommon.MutationResult<ConnectLogbookMutation>;
export type ConnectLogbookMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectLogbookMutation, ConnectLogbookMutationVariables>;
export const LogbookSyncWorkoutDocument = gql`
    mutation logbookSyncWorkout($workoutId: String!, $webUserId: String!, $machine: String, $workoutName: String) {
  logbookSyncWorkout(input: {workoutId: $workoutId, webUserId: $webUserId, machine: $machine, workoutName: $workoutName}) {
    status
  }
}
    `;
export type LogbookSyncWorkoutMutationFn = ApolloReactCommon.MutationFunction<LogbookSyncWorkoutMutation, LogbookSyncWorkoutMutationVariables>;

/**
 * __useLogbookSyncWorkoutMutation__
 *
 * To run a mutation, you first call `useLogbookSyncWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogbookSyncWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logbookSyncWorkoutMutation, { data, loading, error }] = useLogbookSyncWorkoutMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *      webUserId: // value for 'webUserId'
 *      machine: // value for 'machine'
 *      workoutName: // value for 'workoutName'
 *   },
 * });
 */
export function useLogbookSyncWorkoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogbookSyncWorkoutMutation, LogbookSyncWorkoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogbookSyncWorkoutMutation, LogbookSyncWorkoutMutationVariables>(LogbookSyncWorkoutDocument, baseOptions);
      }
export type LogbookSyncWorkoutMutationHookResult = ReturnType<typeof useLogbookSyncWorkoutMutation>;
export type LogbookSyncWorkoutMutationResult = ApolloReactCommon.MutationResult<LogbookSyncWorkoutMutation>;
export type LogbookSyncWorkoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogbookSyncWorkoutMutation, LogbookSyncWorkoutMutationVariables>;
export const StravaCancelConnectDocument = gql`
    mutation StravaCancelConnect {
  stravaCancelConnect {
    status
  }
}
    `;
export type StravaCancelConnectMutationFn = ApolloReactCommon.MutationFunction<StravaCancelConnectMutation, StravaCancelConnectMutationVariables>;

/**
 * __useStravaCancelConnectMutation__
 *
 * To run a mutation, you first call `useStravaCancelConnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStravaCancelConnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stravaCancelConnectMutation, { data, loading, error }] = useStravaCancelConnectMutation({
 *   variables: {
 *   },
 * });
 */
export function useStravaCancelConnectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StravaCancelConnectMutation, StravaCancelConnectMutationVariables>) {
        return ApolloReactHooks.useMutation<StravaCancelConnectMutation, StravaCancelConnectMutationVariables>(StravaCancelConnectDocument, baseOptions);
      }
export type StravaCancelConnectMutationHookResult = ReturnType<typeof useStravaCancelConnectMutation>;
export type StravaCancelConnectMutationResult = ApolloReactCommon.MutationResult<StravaCancelConnectMutation>;
export type StravaCancelConnectMutationOptions = ApolloReactCommon.BaseMutationOptions<StravaCancelConnectMutation, StravaCancelConnectMutationVariables>;
export const CheckStravaConnectedDocument = gql`
    mutation checkStravaConnected {
  checkStravaConnected
}
    `;
export type CheckStravaConnectedMutationFn = ApolloReactCommon.MutationFunction<CheckStravaConnectedMutation, CheckStravaConnectedMutationVariables>;

/**
 * __useCheckStravaConnectedMutation__
 *
 * To run a mutation, you first call `useCheckStravaConnectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckStravaConnectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkStravaConnectedMutation, { data, loading, error }] = useCheckStravaConnectedMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckStravaConnectedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckStravaConnectedMutation, CheckStravaConnectedMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckStravaConnectedMutation, CheckStravaConnectedMutationVariables>(CheckStravaConnectedDocument, baseOptions);
      }
export type CheckStravaConnectedMutationHookResult = ReturnType<typeof useCheckStravaConnectedMutation>;
export type CheckStravaConnectedMutationResult = ApolloReactCommon.MutationResult<CheckStravaConnectedMutation>;
export type CheckStravaConnectedMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckStravaConnectedMutation, CheckStravaConnectedMutationVariables>;
export const StravaConnectDocument = gql`
    mutation stravaConnect($crmUserId: String!, $stravaAuthCode: String!) {
  stravaConnect(stravaConnectData: {userId: $crmUserId, stravaAuthCode: $stravaAuthCode}) {
    accessToken
    refreshToken
  }
}
    `;
export type StravaConnectMutationFn = ApolloReactCommon.MutationFunction<StravaConnectMutation, StravaConnectMutationVariables>;

/**
 * __useStravaConnectMutation__
 *
 * To run a mutation, you first call `useStravaConnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStravaConnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stravaConnectMutation, { data, loading, error }] = useStravaConnectMutation({
 *   variables: {
 *      crmUserId: // value for 'crmUserId'
 *      stravaAuthCode: // value for 'stravaAuthCode'
 *   },
 * });
 */
export function useStravaConnectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StravaConnectMutation, StravaConnectMutationVariables>) {
        return ApolloReactHooks.useMutation<StravaConnectMutation, StravaConnectMutationVariables>(StravaConnectDocument, baseOptions);
      }
export type StravaConnectMutationHookResult = ReturnType<typeof useStravaConnectMutation>;
export type StravaConnectMutationResult = ApolloReactCommon.MutationResult<StravaConnectMutation>;
export type StravaConnectMutationOptions = ApolloReactCommon.BaseMutationOptions<StravaConnectMutation, StravaConnectMutationVariables>;
export const StravaSyncWorkoutsDocument = gql`
    mutation stravaSyncWorkouts {
  stravaSyncWorkoutsV3 {
    status
    total
  }
}
    `;
export type StravaSyncWorkoutsMutationFn = ApolloReactCommon.MutationFunction<StravaSyncWorkoutsMutation, StravaSyncWorkoutsMutationVariables>;

/**
 * __useStravaSyncWorkoutsMutation__
 *
 * To run a mutation, you first call `useStravaSyncWorkoutsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStravaSyncWorkoutsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stravaSyncWorkoutsMutation, { data, loading, error }] = useStravaSyncWorkoutsMutation({
 *   variables: {
 *   },
 * });
 */
export function useStravaSyncWorkoutsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StravaSyncWorkoutsMutation, StravaSyncWorkoutsMutationVariables>) {
        return ApolloReactHooks.useMutation<StravaSyncWorkoutsMutation, StravaSyncWorkoutsMutationVariables>(StravaSyncWorkoutsDocument, baseOptions);
      }
export type StravaSyncWorkoutsMutationHookResult = ReturnType<typeof useStravaSyncWorkoutsMutation>;
export type StravaSyncWorkoutsMutationResult = ApolloReactCommon.MutationResult<StravaSyncWorkoutsMutation>;
export type StravaSyncWorkoutsMutationOptions = ApolloReactCommon.BaseMutationOptions<StravaSyncWorkoutsMutation, StravaSyncWorkoutsMutationVariables>;