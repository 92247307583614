import {
    GET_MACHINE_LIST_REQUEST,
    GET_MACHINE_LIST_SUCCESS,
    GET_MACHINE_LIST_FAILED,
} from "./machine.type";
import { initialTypeMarchine } from "./machine.reducer";

export const getMachineListRequest = () => ({
    type: GET_MACHINE_LIST_REQUEST,
});
export const getMachineListSuccess = (payload: initialTypeMarchine[]) => ({
    type: GET_MACHINE_LIST_SUCCESS,
    payload,
});
export const getMachineListFailed = () => ({
    type: GET_MACHINE_LIST_FAILED,
});
