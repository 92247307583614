import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Sidebar from 'Layouts/Sidebar';
import React from 'react';
import style from './CollapseMenu.module.scss';

const CollapseMenu: React.FunctionComponent<{
    open: boolean,
    onClose: Function,
    currentUser: any,
    openAccountMenu: Function
}> = ({ open, onClose, currentUser, openAccountMenu }) => {
    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                onClose()
            };
    // const _RenderAvatar = () => {
    //     return currentUser ? (
    //         <div
    //             className="link-container pointer light-break"
    //             onClick={() => { openAccountMenu() }}
    //         >
    //             <div id="account-navigation">
    //                 <div id="acount-navigation__name">
    //                     <img
    //                         src={currentUser.avatarUrl || '/assets/icon/default-avatar.svg'}
    //                         alt={currentUser.name}
    //                         id="user-avatar"
    //                     />{' '}
    //                     <span className="small-link-content">
    //                         Hi, {currentUser.name || currentUser.username}
    //                     </span>
    //                 </div>
    //                 <div>
    //                     {' '}
    //                     <img
    //                         src="/images/icons/great-than.svg"
    //                         alt="Go to next icon"
    //                         id="user-navigation-icon"
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //     ) : null;
    // };

    return (
        <Drawer
            anchor={"top"}
            open={open}
            onClose={toggleDrawer(false)}
            transitionDuration={800}
            className={style['CollaspeMenuWrapper']}
        >
            <Box
                sx={{ height: "100vh" }}
                role="presentation"
                className={style['contentMenuWrapper']}
            >
                <Sidebar closeMenu={onClose} />
                <div className={style['closeIcon']}
                    //@ts-ignore
                    onClick={onClose}
                >
                    <CloseIcon />
                </div>
            </Box>
        </Drawer >
    )
}

export default CollapseMenu;