import React from "react";
import styles from "./AchievementsRecent.module.scss";
import AchievementsItem from "../Item";
import {
    AchievementModalData,
    RefModalAchievement,
} from "../Modal/AchievementsModal";

const imgRecent = "/images/icons/recent-achievement-icon.png";

interface AchievementRecentProps {
    recent: number[];
    setDataModal: React.Dispatch<
        React.SetStateAction<AchievementModalData | undefined>
    >;
    refModal: React.RefObject<RefModalAchievement>;
}
const AchievementsRecent = (props: AchievementRecentProps) => {
    const { recent = [], setDataModal, refModal } = props || {};

    const recentData = [...recent].reverse();

    return (
        <div className={styles["achievements-recent"]}>
            <div className={styles["achievements-recent-title"]}>
                <div className={styles["achievements-recent-title-image"]}>
                    <img src={imgRecent} alt="img-recent" loading="eager" 
                        style={{
                            width:"32px",
                            height:"32px",
                            objectFit:"cover"
                        }} 
                    />
                </div>
                <h4 className={styles["achievements-recent-title-text"]}>
                    RECENT ACHIEVEMENTS
                </h4>
            </div>
            <div className={styles["achievements-recent-content"]}>
                <div className={styles["achievements-list"]}>
                    {recentData.map((i: number) => {
                        return (
                            <AchievementsItem
                                id={i}
                                setDataModal={setDataModal}
                                refModal={refModal}
                                isCompleted={true}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default AchievementsRecent;
