import { gameServerApi } from "services/gameServerV2";
import { IGetMembershipDetailForC2 } from "services/gameServerV2/user";

export const useQueryMembershipDetailForC2 = () => {
    const handleQueryData = async ({
        orgId,
        page,
        perPage,
    }: IGetMembershipDetailForC2) => {
        const data = await gameServerApi.user.getMembershipDetailForC2({
            orgId,
            page,
            perPage,
        });
        return data;
    };
    return {
        handleQueryData,
    };
};

export const handleRenderPlanName = (planId: number, planList: any[]) => {
    const plan = planList.find((item: any) => item.id === planId);
    return plan?.name || "";
};
