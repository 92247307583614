import { BaseAPI } from "./base";
import { ENV } from "scripts/environment";

export class UtilAPI extends BaseAPI {
  constructor() {
    super(ENV.SERVER_DOMAIN);
  }
  async uploadImage(
    uploadUrl: string,
    formBody: {
      file: any;
      key: string;
      policy: string;
      acl: string;
      "x-goog-algorithm": string;
      "x-goog-credential": string;
      "x-goog-signature": string;
      "x-goog-date": string;
      "content-type": string;
    }
  ) {
    var form = new FormData();
    for (const key of Object.keys(formBody)) {
      let value = (formBody as any)[key];
      form.append(key, value);
    }
    const url: string = uploadUrl;
    const options: any = {
      method: "POST",
      headers: {},
      mode: "no-cors",
      body: form,
    };
    const res: any = await fetch(url, options);
    return res;
  }
}
