import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import useForgotPasswordHook from "./ForgotPassword.hook";
import useStyles from "./ForgotPassword.styles";
import { Helmet } from "react-helmet";
import LoginLayout from "Layouts/LoginLayout";
import style from "./ForgotPassword.module.scss";

const ForgotPassword: React.FunctionComponent = () => {
    const classes: any = useStyles();
    const {
        formState,
        isSentResetLink,
        handleSubmit,
        hasError,
        handleChange,
        backToLogin,
    } = useForgotPasswordHook();
    return (
        <LoginLayout>
            <div
                className={`${classes.root} ${style["ForgotPasswordWrapper"]}`}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Forgot password | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className={`${classes.content} ${style["content"]}`}>
                    <div
                        className={`${classes.contentBody} ${style["contentBody"]}`}
                    >
                        <form
                            className={`${classes.form} ${style["form"]}`}
                            onSubmit={handleSubmit}
                        >
                            <div className={classes.logo}>
                                <img src="https://avironactive.com/assets/img/icon/aviron-icon.svg" alt='icon' />
                            </div>
                            <Typography
                                className={classes.title + ' ' + style['title']}
                                variant="h2"
                                sx={{
                                    marginTop: "16px",
                                    marginBottom: "28px",
                                }}
                            >
                                FORGOT PASSWORD
                            </Typography>

                            <TextField
                                className={classes.textField}
                                error={hasError("email")}
                                fullWidth
                                helperText={
                                    hasError("email")
                                        ? formState.errors.email[0]
                                        : null
                                }
                                label="Email"
                                name="email"
                                onChange={handleChange}
                                type="text"
                                value={formState.values.email || ""}
                                variant="outlined"
                                sx={{
                                    marginTop: "16px",
                                }}
                                disabled={isSentResetLink}
                            />
                            {isSentResetLink ? (
                                <p>
                                    <b>
                                        We sent you an email with a link to
                                        reset your password
                                    </b>
                                </p>
                            ) : null}
                            {!isSentResetLink ? (
                                <Button
                                    className={classes.signInButton}
                                    color="primary"
                                    disabled={!formState.isValid}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        marginTop: "16px",
                                    }}
                                >
                                    SEND RESET LINK
                                </Button>
                            ) : (
                                <Button
                                    className={classes.signInButton}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    sx={{
                                        marginTop: "16px",
                                    }}
                                    onClick={backToLogin}
                                    disabled={!formState.isValid}
                                >
                                    LOG IN WITH ANOTHER ACCOUNT
                                </Button>
                            )}
                            <Typography
                                color="textSecondary"
                                variant="body1"
                                sx={{
                                    marginTop: "16px",
                                    textAlign: "right",
                                }}
                            >
                                <Link
                                    component={RouterLink}
                                    to="/login"
                                    variant="h6"
                                    className={style['forgotPassText']}
                                >
                                    Back To Login
                                </Link>
                            </Typography>
                        </form>
                    </div>
                </div>
            </div>
        </LoginLayout>
    );
};

export default ForgotPassword;
