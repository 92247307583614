import React from "react";
import { CONSTANTS } from "consts";
import useHandleActivePage from "utils/useHandleActivePage";
import { handleRenderRouteElement } from "../useRouteElement.hook";
import { IRouteItem } from "routes/useRouteElement.constant";

interface IWrapperMembership{
    routes: IRouteItem[];
}

const WrapperMembership = ({ routes }: IWrapperMembership) => {
    useHandleActivePage(CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIPS);

    return (
        <div className="page-content">{handleRenderRouteElement(routes)}</div>
    );
};
export default WrapperMembership;
