import http, { BaseGameServerAPI } from "./base";

export interface IWorkoutListRequestData {
    timeRange: number;
    page: number;
    pageSize: number;
    startTime?: number;
    endTime?: number;
}

export interface IWorkoutDetailRequestData {
    workoutId: string;
}

export class WorkoutHistoryAPI extends BaseGameServerAPI {
    constructor() {
        super();
    }
    public async getWorkoutList(data: IWorkoutListRequestData) {
        return await http.post('/get_workout_history',data)
    }
    public async getWorkoutDetail(
        data: IWorkoutDetailRequestData
    ) {
        return await http.post('/get_workout',{
            id: data.workoutId,
        })
    }

    public async getLastUserWorkout() {
        return await http.post('/get_last_user_workout')
    }

    public async getStatisticWorkoutSummary(timeRange: number) {
        return await http.post('/get_statistic_workout_summary',{
            timeRange: timeRange,
        })
    }

    public async getCompareStatisticWorkoutSummary(timeRange: number) {
        return await http.post('/compare_statistic_workout_summary', {
            timeRange: timeRange,
        })
    }

    public async getCalendarStatisticDetailWorkout(
        startTime: number,
        endTime: number
    ) {
        return await http.post('/statistic_detail_workout_calendar',{
            startTime,
            endTime
        })
    }

    public async exportCSV(data: any) {
        return await http.post('/export_workouts_summary',data)
    }
}
