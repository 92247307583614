import { CONSTANTS } from "consts";

export const changeProfileValidate = (values: any) => {
  let errors: any = {};
  const usernameReg = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s~]/; // check special characters
  const nameReg = /[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/;

  if (!values.username) {
    errors.username = 'Username must contain 3 - 13 characters - letter and number only';
  } else if (values.username.length < 3) {
    errors.username = 'Username must contain 3 - 13 characters - letter and number only';
  } else if (values.username.length > 13) {
    errors.username = 'Username must contain 3 - 13 characters - letter and number only';
  } else if (usernameReg.test(values.username)) {
    errors.username = 'Username must contain 3 - 13 characters - letter and number only';
  }

  if (nameReg.test(values.firstName)) {
    errors.firstName = 'First Name should not be blank, should have at least 1 letter or number and max length is 64';
  } else if (values.firstName.trim().length === 0){
    errors.firstName = 'First Name should not be blank, should have at least 1 letter or number and max length is 64';
  } else if (values.firstName.length > 64) {
    errors.firstName = 'First Name should not be blank, should have at least 1 letter or number and max length is 64';
  } else if (values.firstName.trim().length > 0 && values.lastName.trim().length === 0) {
    errors.lastName = 'Last Name should not be blank, should have at least 1 letter or number and max length is 64';
  } else if (values.firstName.length === 0) {
    errors.firstName = "First Name should not be blank, should have at least 1 letter or number and max length is 64"
  }

  if (nameReg.test(values.lastName)) {
    errors.lastName = 'Last Name should not be blank, should have at least 1 letter or number and max length is 64';
  } else if (values.lastName.trim().length === 0){
    errors.lastName = 'Last Name should not be blank, should have at least 1 letter or number and max length is 64';
  } else if (values.lastName.length > 64) {
    errors.lastName = 'Last Name should not be blank, should have at least 1 letter or number and max length is 64';
  } else if (values.lastName.trim().length > 0 && values.firstName.trim().length === 0) {
    errors.firstName = 'First Name should not be blank, should have at least 1 letter or number and max length is 64';
  } else if (values.lastName.length === 0) {
    errors.lastName = "Last Name should not be blank, should have at least 1 letter or number and max length is 64"
  }

  if (values.height_unit === CONSTANTS.PROFILE_PAGE.HEIGHT_UNITS.CM) {
    if (values.height < 0) {
      errors.height = "Please ensure that your height falls within the range of 0 to 393 inch or 0 to 999 cm"
    } else if (values.height > 999) {
      errors.height = "Please ensure that your height falls within the range of 0 to 393 inch or 0 to 999 cm"
    }
  } else {
    if (values.height < 0) {
      errors.height = "Please ensure that your height falls within the range of 0 to 393 inch or 0 to 999 cm"
    } else if (values.height > 393.31) {
      errors.height = "Please ensure that your height falls within the range of 0 to 393 inch or 0 to 999 cm"
    }
  }

  if (values.weight_unit === CONSTANTS.PROFILE_PAGE.WEIGHT_UNITS.LBS) {
    if (values.weight < 20) {
      errors.weight = "Please enter a weight between 20 and 750 lbs or 10 and 340 kg"
    } else if (values.weight > 750) {
      errors.weight = "Please enter a weight between 20 and 750 lbs or 10 and 340 kg"
    }
  } else {
    if (values.weight < 9.07) {
      errors.weight = "Please enter a weight between 20 and 750 lbs or 10 and 340 kg"
    } else if (values.weight > 340.19) {
      errors.weight = "Please enter a weight between 20 and 750 lbs or 10 and 340 kg"
    }
  }

  return errors;
}

