import React from "react";
import style from "./UserCard.module.scss";
import Avatar from "@mui/material/Avatar";

interface UserCard {
  avatar?: any;
  username?: any;
  email?: any;
  hideAvatar?: boolean;
}

const UserCard = ({
  avatar = "",
  username = "Scott347",
  email = "scott@revpop.com",
  hideAvatar,
}: UserCard) => {
  return (
    <div className={style["UserCard"]}>
      {!hideAvatar && (
        <div className={style["avatar"] + ' avatar'}>
          {avatar ? (
            <Avatar alt="Remy Sharp" src={avatar} />
          ) : (
            <Avatar sx={{ bgcolor: "#e0e0e0" }}>?</Avatar>
          )}
        </div>
      )}
      <div className={style["textBlock"]}>
        <div className={style["username"]}>{username || ""}</div>
        <div className={style["email"]}>{email}</div>
      </div>
    </div>
  );
};

export default UserCard;
