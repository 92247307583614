import { Backdrop, Fade, Modal, Button, Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import AvatarEditor from 'react-avatar-editor';
import "./style.scss";
import Stack from '@mui/material/Stack'

const useStyles: any = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        background: "#F3F4F7",
        width: "600px",
        padding: "32px",
        position: "relative",
        outline: "none"
    }
});

const ProfileChangeAvatarUI: React.FunctionComponent<{
    state?: any
    open: boolean
    setOpen: Function
    onSubmit: (imageUrl: string) => void
    isSubmitting?: boolean
}> = ({ state, open, setOpen, onSubmit, isSubmitting }) => {
    const classes = useStyles();
    const { avatarUrl } = state;
    const [scale, setScale] = React.useState(2)
    const avatarRef: any = React.useRef(null)
    const handleChangeImageSize = (event: any) => {
        setScale(event.target.value)
    }
    const onClickSave = async () => {
        let imageURL: string
        if (avatarRef && avatarRef.current) {
            let canvas = avatarRef.current.getImage().toDataURL("image/png")
            imageURL = await fetch(canvas)
                .then(res => res.blob())
                .then(blob => {
                    return URL.createObjectURL(blob)
                });
            onSubmit(imageURL)
            // isSubmitting && setOpen()
            
        }
    }
    const handleClose = () => {
        setOpen(false);
    }

    React.useEffect(()=>{
        if(!isSubmitting){
            setOpen()
        }

    },[isSubmitting])

    return (
        <Modal open={open} onClose={handleClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <div className="upload-avatar-modal">
                    <div style={{ textAlign: 'center', fontWeight: "bold", fontSize: "24px", color: "#161616", padding: "16px", fontFamily: 'GRIFTER' }}>
                        CHANGE AVATAR
                    </div>
                    <div className="avatar-wrapper" style={{
                        width: "400px",
                        height: "400px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: "16px 0"
                    }}>
                        <AvatarEditor
                            image={avatarUrl}
                            width={300}
                            height={300}
                            border={50}
                            color={[255, 255, 255, 0.6]} // RGBA
                            scale={scale}
                            rotate={0}
                            ref={avatarRef}
                        />
                    </div>
                    <div style={{
                        padding: "16px 0"
                    }}>
                        <Slider value={scale} aria-label="Avatar size" valueLabelDisplay="auto" min={1} max={10} onChange={handleChangeImageSize} step={1} />
                    </div>
                    <Stack spacing={2} direction={{ xs: 'column-reverse', md: 'row' }} alignItems="end" justifyContent="flex-end" width={{
                        xs: "100%",
                        md: "60%"
                    }} sx={{
                        float: "right"
                    }}>
                        <Button variant="outlined" onClick={handleClose} size="medium">CANCEL</Button>
                        <Button variant="contained" color="secondary" size="medium" onClick={onClickSave} disabled={isSubmitting}>{isSubmitting ? "SAVING ..." : "SAVE"}</Button>
                    </Stack>
                </div>
            </Fade>
        </Modal>
    );
};
export default ProfileChangeAvatarUI;
