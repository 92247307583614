import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const checkBtnUrl = "/images/icons/check-radio-btn.svg";
const uncheckBtnUrl = "/images/icons/uncheck-radio-btn.svg";

const RadioSelect = ({ key, label, fontSize, selectData, ...props }: any) => {
    const useStyles: any = makeStyles({
        radioGroup: {
            ["@media (max-width:768px)"]: {
                display: "flex",
                justifyContent: "space-between",
            },
        },
    });
    const classes = useStyles();

    return (
        <Box>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={12} mb="8px" pl="2px">
                    <Box flexShrink={0} mr="30px">
                        <Typography component="label" variant="h6">
                            {label}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} pl="4px">
                    <RadioGroup
                        {...props}
                        className={classes.radioGroup}
                        sx={{
                            justifyContent: "space-between",
                        }}
                    >
                        {selectData.map(
                            (item: {
                                value: string | number;
                                label: string;
                            }) => (
                                <FormControlLabel
                                    value={item.value}
                                    label={item.label}
                                    sx={{ marginRight: "0px" }}
                                    control={
                                        <Radio
                                            checkedIcon={
                                                <img
                                                    src={checkBtnUrl}
                                                    loading="eager"
                                                    alt="icon"
                                                />
                                            }
                                            icon={
                                                <img
                                                    src={uncheckBtnUrl}
                                                    loading="eager"
                                                    alt="icon"
                                                />
                                            }
                                        />
                                    }
                                />
                            )
                        )}
                    </RadioGroup>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RadioSelect;
