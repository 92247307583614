import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { gameServerApi } from "services/gameServerV2";
import WarnignIcon from "Components/Icons/WarningIcon.svg";

import {
    formatInviteMemberRows,
    initInviteMemberData,
    formatInviteMembership,
    formatDeleteMemberData,
    formatDeleteMultiMemberData,
} from "./formatData";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

interface IMembershipTable {
    dataTable: {
        column: Array<{ title: string; key: string; className?: string }>;
        rows: Array<any>;
        actions?: Array<{ name: string; action: any }>;
        deleteMultiAction?: any;
        loading?: boolean;
    };
}

export const useMembershipHook = () => {
    const [loading, setLoading] = useState(false);
    const [invitedMembership, setInvitedMembership] =
        useState<any>(initInviteMemberData);
    const { id: membershipId } = useParams() as any;
    const column = [
        {
            title: "",
            key: "selection",
        },
        {
            title: "User",
            key: "user",
        },
        {
            title: "Status",
            key: "status",
        },
    ];

    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const searchParam = searchParams.get("search") || "";
    const [queryParameter, setQueryParameter] = useState<{ search: string }>({
        search: searchParam || "",
    });

    const getInvitedMembership = (orgId = membershipId, search = "") => {
        setLoading(true);
        // Get membership familly data
        gameServerApi.user
            .getMembershipInviteList({
                orgId,
                search,
            })
            .then((res: any) => {
                setLoading(false);
                setInvitedMembership(
                    formatInviteMemberRows(formatInviteMembership(res?.data))
                );
            })
            .catch((err) => {
                setLoading(false);
                console.log("errr", err);
            });
    };

    const handleSearch = (newValue: any) => {
        setQueryParameter({ ...queryParameter, search: newValue });
    };

    const deleteMember = async (members: Array<string>) => {
        if (members?.length > 0) {
            const result = await Swal.fire({
                title: '<div style="font-size: 24px;styleName: titlepopup;font-family: GRIFTER;font-size: 24px;font-weight: 700;line-height: 24.48px;letter-spacing: 0.01em;text-align: center;">ARE YOUR SURE YOU WANT TO REMOVE?<div>',
                // icon: "warning",
                imageUrl: WarnignIcon,
                html: `<div style="color: #161616;font-size: 16px;">Are you sure you want to remove this member from your list? This person will no longer be able to use the services included in your membership package</div>`,
                showCancelButton: false,
                confirmButtonText: "REMOVE",
                showClass: {
                    popup: `
                      confirm-membership-popup
                    `,
                },
            });
            if (!result.isConfirmed) {
                return;
            }
            const userIds = formatDeleteMemberData(members);
            setLoading(true);
            try {
                await gameServerApi.user.deleteMembership({
                    orgId: membershipId,
                    deleteValue: [...userIds],
                });
                setLoading(false);
                setTimeout(()=>{
                    Swal.fire(
                        "Success",
                        `Deleted ${userIds.length} Membership(s)! `,
                        "success"
                    );
                }, 600)
                await getInvitedMembership();
            } catch (error) {
                setLoading(false);
                setTimeout(()=>{
                    Swal.fire(
                        "Error",
                        `Delete Membership Error! `,
                        "error"
                    );
                }, 600)
            }
        }
    };

    const deleteMultiMember = async (members: Array<string>) => {
        if (members?.length > 0) {
            const result = await Swal.fire({
                title: '<div style="font-size: 24px;styleName: titlepopup;font-family: GRIFTER;font-size: 24px;font-weight: 700;line-height: 24.48px;letter-spacing: 0.01em;text-align: center;">ARE YOUR SURE YOU WANT TO REMOVE?<div>',
                // icon: "warning",
                imageUrl: WarnignIcon,
                html: `<div style="color: #161616;font-size: 16px;">Are you sure you want to remove this member from your list? This person will no longer be able to use the services included in your membership package</div>`,
                showCancelButton: false,
                confirmButtonText: "REMOVE",
                showClass: {
                    popup: `
                      confirm-membership-popup
                    `,
                },
            });
            if (!result.isConfirmed) {
                return;
            }
            const userIds = formatDeleteMultiMemberData(members);
            // console.log({members, userIds})
            setLoading(true);
            try {
                await gameServerApi.user.deleteMembership({
                    orgId: membershipId,
                    deleteValue: [...userIds],
                });
                setLoading(false);
                setTimeout(()=>{
                    Swal.fire(
                        "Success",
                        `Deleted ${userIds.length} Membership(s)! `,
                        "success"
                    );
                }, 600)
                await getInvitedMembership();
            } catch (error) {
                setLoading(false);
                setTimeout(()=>{
                    Swal.fire(
                        "Error",
                        `Delete Membership Error! `,
                        "error"
                    );
                }, 600)
            }
        }
    };

    const resendInviteMember = async (members: Array<any>) => {
        const result = await Swal.fire({
            showClass: {
                popup: `
                  invite_memebership_popup
                `,
            },
            title: '<div style="font-size: 24px;styleName: titlepopup;font-family: GRIFTER;font-size: 24px;font-weight: 700;line-height: 24.48px;letter-spacing: 0.01em;">RESEND INVITE<div>',
            html: `<div style="color: #161616;font-size: 16px;">The invited member will receive a new access link. Your old invite will be invalidated and cannot be accessed.</div><div class="emailField">${members[0].rowData.email}</div>`,
            showCancelButton: false,
            confirmButtonText: "send",
        });
        if (!result.isConfirmed) {
            return;
        }
        setLoading(true);
        try {
            await gameServerApi.user.inviteMembership({
                orgId: membershipId,
                email: members[0].rowData.email,
            });
            setLoading(false);
            setTimeout(()=>{
                Swal.fire(
                    "Success",
                    `Invited ${members.length} Member! `,
                    "success"
                );
            }, 600)
            await getInvitedMembership();
        } catch (error) {
            toast.error(
                error?.response?.data?.error?.message ||
                    "Resend Member Error! ",
                {
                    // position: "bottom-left",
                    autoClose: 3000,
                }
            );
            setTimeout(()=>{
                Swal.fire(
                    "Error",
                    error?.response?.data?.error?.message ||
                    "Resend Member Error! ",
                    "error"
                );
            }, 600)
            setLoading(false);
        }
    };

    useEffect(() => {
        let urlParams = `?search=${queryParameter.search}`;
        history.replace(urlParams);
        getInvitedMembership(membershipId, queryParameter?.search);
    }, [queryParameter]);

    const propsTable: IMembershipTable = {
        dataTable: {
            column,
            rows: invitedMembership,
            actions: [
                { name: "remove", action: deleteMember },
                { name: "resend", action: resendInviteMember },
            ],
            deleteMultiAction: deleteMultiMember,
            loading: loading,
        },
    };

    return {
        loading,
        propsTable,
        membershipId,
        handleSearch,
        refetchInvitedMembership: getInvitedMembership,
    };
};
