import { CONSTANTS } from "consts";

export const useCheckAppHook = () => {
    const appId = Number(localStorage.getItem("appId"));
    const measureUnit = localStorage.getItem('measureUnit') || 'miles'
    // const isApkBikeergMachine = Number(appId) === CONSTANTS.SWITCH_MACHINE.bike.id;
    return {
        appId,
        measureUnit
    }
}