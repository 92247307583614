import { ACTION_TYPES } from "contexts/types/action-types";
import { BaseAction } from "../base/base.action";

export class AuthAction extends BaseAction {
    constructor() {
        super("AUTH")
    }
    loginSuccess = (_data: any) => {
        return { type: ACTION_TYPES.AUTH.LOGIN_SUCCESS, payload: _data };
    };
    setCrmSession = (_data: any) => {
        return { type: ACTION_TYPES.AUTH.SET_CRM_SESSION, payload: _data };
    }
    stravaConnected = (isConnected: boolean) => {
        return { type: ACTION_TYPES.AUTH.STRAVA_CONNECTED, payload: isConnected }
    };
    logbookConnected = (isConnected: boolean) => {
        return { type: ACTION_TYPES.AUTH.LOGBOOK_CONNECTED, payload: isConnected }
    };
    fitbitConnected = (isConnected: boolean) => {
        return { type: ACTION_TYPES.AUTH.FITBIT_CONNECTED, payload: isConnected }
    };
}


