import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import CurrencyNumber from "Components/CurrencyNumber";
import * as React from "react";
import { mobileDetect } from "scripts/mobileDetect";
import ReceiptCardC2 from "./ReceiptCardC2";
import { handleRenderPlanName } from "./MembershipDetailForC2.actions";
import { mapProductIdToPlanId } from "./MembershipDetailForC2.constant";
import { handleFormatdateFromEpochTime } from "utils/handleFormatDateFromEpochTime";

const columns = [
    { field: "id", headerName: "ID", width: 130 },
    { field: "date", headerName: "Date", width: 130 },
    { field: "startAt", headerName: "Period start", width: 130 },
    { field: "expireAt", headerName: "Period end", width: 130 },
    { field: "price", headerName: "Amount", width: 130 },
    { field: "status", headerName: "Status", width: 130 },
];

const ReceiptsForC2: React.FunctionComponent<{
    receipts: any[];
    status?: number;
    planName?: string;
}> = ({ receipts, status = 0, planName }) => {
    const isMobile = mobileDetect();

    const rowItemStyle = (index: number) => ({
        fontSize: "13px",
        borderBottom:
            index < receipts.length - 1 ? "solid 0.5px #808080 !important" : "",
    });

    return (
        <Box>
            {isMobile ? (
                <Box mt="16px">
                    {receipts.map((receipt, index) => {
                        return (
                            <ReceiptCardC2
                                {...receipt}
                                index={index}
                                // planList={planList}
                            />
                        );
                    })}
                </Box>
            ) : (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((collum, index) => {
                                    return (
                                        <TableCell
                                            sx={{
                                                paddingLeft:
                                                    index === 0 ? 0 : undefined,
                                                borderBottom:
                                                    "solid 0.5px #808080 !important",
                                            }}
                                        >
                                            <Typography variant="subtitle2">
                                                {collum.headerName}
                                            </Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {receipts.map((row, index) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        align="left"
                                        sx={{
                                            ...rowItemStyle(index),
                                            paddingLeft: 0,
                                        }}
                                    >
                                        {row.transactionId}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            ...rowItemStyle(index),
                                            paddingLeft: 0,
                                        }}
                                    >
                                        {handleFormatdateFromEpochTime(
                                            row.startAt
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={rowItemStyle(index)}
                                    >
                                        {handleFormatdateFromEpochTime(
                                            row.startAt
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={rowItemStyle(index)}
                                    >
                                        {handleFormatdateFromEpochTime(
                                            row.expireAt
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={rowItemStyle(index)}
                                    >
                                        <CurrencyNumber value={row.price} />
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={rowItemStyle(index)}
                                    >
                                        Paid
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default ReceiptsForC2;
