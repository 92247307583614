import React from "react";
import style from "./CardSimple.module.scss";

interface CardSimpleProps {
    children?: any;
    title?: string | any;
    styles?: React.CSSProperties;
}

const CardSimple = (props: CardSimpleProps) => {
    const { title, children, styles } = props;
    return (
        <div
            className={style["CardSimpleWrapper"] + " CardSimpleWrapper"}
            style={styles}
        >
            {title && typeof title === "string" ? (
                <div className={style["title"] + " titleCard"}>{title}</div>
            ) : (
                <>{title}</>
            )}
            <div className={style["content"] + " contentCardSimple"}>
                {children}
            </div>
        </div>
    );
};

export default CardSimple;
