import React, { useEffect, useState } from "react";
import style from "./MembershipCardMobile.module.scss";
import MembershipHeader from "./Header";
import InviteMember from "../InviteMember";
import MembershipTable from "../MembershipTable";
import InviteDetailModal from "./InviteDetailModal";

interface IMembershipCardMobile {
    dataTable: {
        column: Array<{ title: string; key: string; className?: string }>;
        rows: Array<any>;
        actions: Array<{ name: string; action: any }>;
    };
    handleSearch?: any;
    refetchMembership?: any;
}
const MembershipCardMobile = ({
    dataTable,
    handleSearch,
    refetchMembership,
}: IMembershipCardMobile) => {
    // console.log('aaaaaaapppppp', dataTable)
    const [openInviteMember, setOpenInviteMember] = useState(false);
    const [openInviteDetailModal, setOpenInviteDetailModal] = useState(false);
    const [inviteDetail, setInviteDetail] = useState<any>(null);
    const { actions } = dataTable;
    const handleOpenInviteMember = () => {
        setOpenInviteMember(true);
    };
    const handleClickRow = (row: Array<string>) => {
        setOpenInviteDetailModal(true);
        setInviteDetail(row);
    };
    const handleRemove = async () => {
        await actions[0].action([inviteDetail.selection]);
        setOpenInviteDetailModal(false);
    };
    const handleResend = async () => {
        await actions[1].action([inviteDetail.selection]);
        setOpenInviteDetailModal(false);
    };
    return (
        <div className={style["MembershipCardMobile"]}>
            <div className={style["header"]}>
                <MembershipHeader
                    handleClickInviteMemberButton={handleOpenInviteMember}
                    handleSearch={handleSearch}
                />
            </div>
            <div className={style["membershipTable"]}>
                <MembershipTable
                    dataTable={dataTable}
                    handleClickRow={handleClickRow}
                />
                <InviteDetailModal
                    open={openInviteDetailModal}
                    onClose={() => {
                        setOpenInviteDetailModal(false);
                    }}
                    data={inviteDetail}
                    handleRemove={handleRemove}
                    handleResend={handleResend}
                />
            </div>
            <InviteMember
                open={openInviteMember}
                onClose={() => {
                    setOpenInviteMember(false);
                }}
                refetchInvitedMembership={refetchMembership}
            />
        </div>
    );
};

export default MembershipCardMobile;
