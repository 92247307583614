import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { gameServerApi } from "services/gameServerV2";
import { formatMembershipFamilly, formatFamillyRows, initFamilyData } from "./formatData";
import Swal from "sweetalert2";
import WarnignIcon from "Components/Icons/WarningIcon.svg";
import { formatDeleteMemberData, formatDeleteMultiMemberData } from "../MembershipInviteList/formatData";

interface IMembershipTable {
  dataTable: {
      column: Array<{title: string, key: string, className?: string}>
      rows: Array<any>,
      actions?: Array<{name: string, action: any}>
      deleteMultiAction?: any
  }
}

export const useMembershipHook = () => {
    const [loading, setLoading] = useState(false);
    const [famillyMembership, setFamillyMembership] = useState<any>(formatFamillyRows(initFamilyData));
    const { id: membershipId } = useParams() as any;
    const column = [
        {
            title: "",
            key: "selection",
        },
        {
            title: "User",
            key: "user",
        },
        {
            title: "Role",
            key: "role",
        },
        {
            title: "Machine IDs",
            key: "machineId",
        },
        {
            title: "Device IDs",
            key: "deviceIds",
        },
    ];

    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search)
    const searchParam = searchParams.get('search') || "";
    const [queryParameter, setQueryParameter] = useState<{search: string}>({
        search: searchParam || '',
    });

    const getFamillyMembership = (orgId: string = membershipId, search: string = "") => {
        setLoading(true);
        // Get membership familly data
        gameServerApi.user
            .getMembershipFamilly({
                orgId,
                search,
            })
            .then((res: any) => {
                setLoading(false);
                setFamillyMembership(formatFamillyRows(formatMembershipFamilly(res?.data)));
            })
            .catch((err) => {
                setLoading(false);
                console.log("errr", err);
            });
    };

    const handleSearch = (newValue: any) => {
        setQueryParameter({...queryParameter, search: newValue})
    };

    const deleteMember = async (members: Array<string>) => {
        if (members?.length > 0) {
            const result = await Swal.fire({
                title: '<div style="font-size: 24px;styleName: titlepopup;font-family: GRIFTER;font-size: 24px;font-weight: 700;line-height: 24.48px;letter-spacing: 0.01em;text-align: center;">ARE YOUR SURE YOU WANT TO REMOVE?<div>',
                // icon: "warning",
                imageUrl: WarnignIcon,
                html: `<div style="color: #161616;font-size: 16px;">Are you sure you want to remove this member from your list? This person will no longer be able to use the services included in your membership package</div>`,
                showCancelButton: false,
                confirmButtonText: "REMOVE",
                showClass: {
                    popup: `
                      confirm-membership-popup
                    `,
                },
            });
            if (!result.isConfirmed) {
                return;
            }
            const userIds = formatDeleteMemberData(members);
            setLoading(true);
            try {
                await gameServerApi.user.deleteMembership({
                    orgId: membershipId,
                    deleteValue: [...userIds],
                });
                setLoading(false);
                setTimeout(()=>{
                    Swal.fire(
                        "Success",
                        `Deleted ${userIds.length} Membership(s)! `,
                        "success"
                    );
                }, 600)
                await getFamillyMembership();
            } catch (error) {
                setLoading(false);
                setTimeout(()=>{
                    Swal.fire(
                        "Error",
                        `Delete Membership Error! `,
                        "error"
                    );
                }, 600)
            }
        }
    };

    const deleteMultiMember = async (members: Array<string>) => {
        if (members?.length > 0) {
            const result = await Swal.fire({
                title: '<div style="font-size: 24px;styleName: titlepopup;font-family: GRIFTER;font-size: 24px;font-weight: 700;line-height: 24.48px;letter-spacing: 0.01em;text-align: center;">ARE YOUR SURE YOU WANT TO REMOVE?<div>',
                // icon: "warning",
                imageUrl: WarnignIcon,
                html: `<div style="color: #161616;font-size: 16px;">Are you sure you want to remove this member from your list? This person will no longer be able to use the services included in your membership package</div>`,
                showCancelButton: false,
                confirmButtonText: "REMOVE",
                showClass: {
                    popup: `
                      confirm-membership-popup
                    `,
                },
            });
            if (!result.isConfirmed) {
                return;
            }
            const userIds = formatDeleteMultiMemberData(members);
            // console.log({members, userIds})
            setLoading(true);
            try {
                await gameServerApi.user.deleteMembership({
                    orgId: membershipId,
                    deleteValue: [...userIds],
                });
                setLoading(false);
                setTimeout(()=>{
                    Swal.fire(
                        "Success",
                        `Deleted ${userIds.length} Membership(s)! `,
                        "success"
                    );
                }, 600)
                await getFamillyMembership();
            } catch (error) {
                setLoading(false);
                setTimeout(()=>{
                    Swal.fire(
                        "Error",
                        `Delete Membership Error! `,
                        "error"
                    );
                }, 600)
            }
        }
    };

    useEffect(()=>{
        let urlParams = `?search=${queryParameter.search}`;
        history.replace(urlParams);
        getFamillyMembership(
            membershipId,
            queryParameter?.search,
        )
    }, [queryParameter])

    const propsTable:IMembershipTable = {
        dataTable: {
          column,
          rows: famillyMembership,
          actions: [{ name: "remove", action: deleteMember },],
          deleteMultiAction: deleteMultiMember,
        },
    };

    return {
        loading,
        propsTable,
        membershipId,
        handleSearch,
        refetchMembership: getFamillyMembership,
    };
};
