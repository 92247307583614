import React, { FunctionComponent, memo, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import ChartComponent from "react-chartjs-2";
import "chartjs-plugin-annotation";

import { CONSTANTS } from "consts";
import "./workoutAreaChart.scss";
import maxBy from "lodash/maxBy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useWindowSize from "Hooks/useWindowSize";
import { UserAppContext } from "contexts";
import {
    convertKilometerToMile,
    convertMeterToKilometer,
    convertMeterToMile,
} from "utils/convertMeasure";

type PropsType = {
    // For metric select
    handleSelect: (option: number) => void;
    metricOptions: { label: string; value: number }[];
    currentSelect: number | null;
    isStravaConnected?: boolean;
    isLogbookConnected?: boolean;
    isFitbitConnected?: boolean;
    handleSyncToStrava?: () => void;
    handleSyncToLogbook?: () => void;
    handleSyncToFitbit?: () => void;
    chartData: {
        data: { x: number; y: number }[];
        avgData: number | undefined;
        information: {
            chartName: string;
            paraName: string;
            unit: string;
            isStravaSynced: boolean;
            isLogbookSynced: boolean;
            isFitbitSynced: boolean;
            game: {
                id: number;
                name: string;
                type: string;
                time: string;
                level: string;
                category: string;
            };
        };
    };
    workoutDetail: any;
};

const convertedTime = (timeInSec: number) => {
    if (!timeInSec) return "00:00";
    let minute = Math.floor(timeInSec / 60);
    let second = Math.round(timeInSec % 60);
    if (second === 60) {
        minute++;
        second = 0;
    }
    const minuteStr = minute >= 10 ? String(minute) : `0${String(minute)}`;
    const secondStr = second >= 10 ? String(second) : `0${String(second)}`;
    return `${minuteStr}:${secondStr}`;
};

const WorkoutChart: FunctionComponent<PropsType> = ({
    chartData,
    handleSelect,
    metricOptions,
    currentSelect,
    isStravaConnected = false,
    isLogbookConnected = false,
    isFitbitConnected = false,
    handleSyncToStrava = () => {},
    handleSyncToLogbook = () => {},
    handleSyncToFitbit = () => {},
    workoutDetail,
}) => {
    const { workoutState } = useContext(UserAppContext);
    console.log("chartDataatata", chartData);
    const formatDataChart = (isSplitMode: boolean, dataBase: any) => {
        let data = dataBase;
        const appId = localStorage.getItem("appId");
        const isBikerMachine =
            Number(localStorage.getItem("appId")) ===
                CONSTANTS.SWITCH_MACHINE.c2Bike.id ||
            CONSTANTS.SWITCH_MACHINE.bike.id;

        if (
            isBikerMachine &&
            currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexMeters
        ) {
            data = data.map((item: any) => {
                let yValue = item.y;
                switch (workoutState.currentUnit) {
                    case "miles":
                        yValue = convertMeterToMile(item.y, 0, false, true);
                        break;
                    case "kilometers":
                        yValue = convertMeterToKilometer(item.y, 0, false, true);
                        break;
                    default:
                        yValue = item.y;
                        break;
                }
                return { ...item, y: yValue };
            });
            console.log('yMileaBikeData', data);
        }
        if (appId === String(CONSTANTS.APP.AVIRON_BIKE)) {
            if (isSplitMode) {
                let res = [];
                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    res.push({
                        x: item.x,
                        y:
                            workoutState.currentUnit === "miles"
                                ? convertKilometerToMile(item.y)
                                : item.y,
                    });
                }
                return res;
            }
        } else {
            if (isSplitMode) {
                let res = [];
                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    res.push({
                        x: item.x,
                        y: item.y === 0 ? 0 : 5 - item.y <= 0 ? 0 : 5 - item.y,
                    });
                }
                return res;
            }
        }
        return data;
    };
    const formatAvgData = (avgDataC: number) => {
        const appId = localStorage.getItem("appId");
        if (
            appId === String(CONSTANTS.APP.AVIRON_APK) &&
            currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSecPer500
        ) {
            if (workoutState.currentUnit === "miles") {
                return convertKilometerToMile(avgDataC);
            } else {
                return avgDataC;
            }
        } else {
            return avgDataC;
        }
    };

    const { data: dataC, information, avgData: avgDataC } = chartData;
    const windowSize = useWindowSize();
    // console.log({dataC})
    const isSplitMode =
        currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSecPer500;
    const isHeartRate =
        currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexHeartRate;
    const avgData = isHeartRate
        ? undefined
        : avgDataC;
    const data = formatDataChart(isSplitMode, dataC);

    // console.log({dataC, avgData})
    //@ts-ignore
    const maxYValue = Math.max(avgData || 0, maxBy(data, "y")?.y || 0);
    // set another line for avgData
    const averageData =
        avgData && data
            ? [
                  { x: 0, y: avgData },
                  { x: data[data.length - 1].x, y: avgData },
              ]
            : [];

    const appId = localStorage.getItem("appId");
    const isC2App = React.useMemo(() => {
        if (typeof appId === "string") {
            return (
                [
                    CONSTANTS.APP.C2_ROWER,
                    CONSTANTS.APP.C2_SKIERG,
                    CONSTANTS.APP.C2_BIKEERG,
                ].indexOf(Number(appId)) !== -1
            );
        }
        return false;
    }, [appId]);

    const chartComponentData = (canvas: any) => {
        // create gradient for chart
        const ctx = canvas.getContext("2d");
        // console.log('ctx', ctx)
        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
        gradient.addColorStop(1, "rgba(245, 73, 15, 0)");
        gradient.addColorStop(0, "#F57D0F");
        return {
            labels: ["Average", information.paraName],
            datasets: [
                {
                    label: `Average: ${Math.round(avgData || 0)}`,
                    showLine: false,
                    data: [...averageData],
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointHitRadius: 10,
                },
                {
                    label: information.paraName,
                    showLine: true,
                    data: [...data],
                    borderColor: "#F5490F",
                    backgroundColor: gradient,
                    lineTension: 0.1,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointHitRadius: ctx.canvas.clientHeight,
                },
            ],
        };
    };

    const calculateStepSize = (maxValue: number) => {
        const startRange = 60;
        if (maxValue / startRange < 10) {
            return startRange;
        } else {
            return (
                startRange * Math.round(Math.round(maxValue / startRange) / 5)
            );
        }
    };

    const getMaxXTick = () => {
        const max = data[data.length - 1].x;
        return max;
    };

    const getXStepSize = () => {
        const maxXValue = data[data.length - 1].x;
        if (window.innerWidth < 500) {
            if (maxXValue < 1) {
                return undefined;
            }
            if (maxXValue < 2) {
                return 0.5;
            }
            if (maxXValue < 5) {
                return 1;
            }
            if (maxXValue > 300) {
                return calculateStepSize(maxXValue);
            }
            return Math.round(maxXValue / 6);
        }
        if (maxXValue > 300) {
            return calculateStepSize(maxXValue);
        }
        return undefined;
    };

    const getYStepSize = () => {
        if (isSplitMode) {
            return 1;
        } else {
            if (maxYValue <= 5) {
                return 1;
            } else {
                // if (window.innerWidth < 500) {
                    if (maxYValue < 1) {
                        return 0.2;
                    }
                    if (maxYValue < 2) {
                        return 0.5;
                    }
                    if (maxYValue < 5) {
                        return 1;
                    }
                    return Math.round(maxYValue / 5);
                // }
                // return undefined;
            }
        }
    };

    const getMaxValueY = () => {
        if (isSplitMode && appId !== String(CONSTANTS.APP.AVIRON_BIKE)) {
            return 5;
        }
        else {
            return undefined;
        }
    };
    const getAvgLabelValue = () => {
        if (avgData) {
            if (isSplitMode) {
                const avgValue = 5 - (Number(avgData) || 0);
                if (avgValue <= 0) {
                    return 0.2;
                } else {
                    return avgValue;
                }
            } else {
                if (Math.round(maxYValue) - avgData <= 5) {
                    return Math.floor(maxYValue * 0.95)
                } 
                else if (avgData/maxYValue < 0.01) {
                    const value = calculateStepSize(maxYValue);
                    return value/3
                }
                return avgData;
            }
        }
    };

    const renderLabelButton = (label: string) => {
        const isBikerMachine =
            Number(localStorage.getItem("appId")) ===
                CONSTANTS.SWITCH_MACHINE.c2Bike.id ||
            Number(localStorage.getItem("appId")) ===
                CONSTANTS.SWITCH_MACHINE.bike.id;

        if (isBikerMachine && label === "SPM") {
            return "RPM";
        }

        if (label === "SPLIT") {
            switch (workoutState.currentUnit) {
                case "miles":
                    return "MPH";
                case "kilometers":
                    return "KPH";
                default:
                    return label;
            }
        }

        if (label === "METERS") {
            switch (workoutState.currentUnit) {
                case "miles":
                    return "MILES";
                case "kilometers":
                    return "KILOMETERS";
                default:
                    return label;
            }
        }

        return label;
    };

    const getAvgLabelContent = () => {
        if (avgData) {
            if (isSplitMode) {
                if (appId !== String(CONSTANTS.APP.AVIRON_BIKE)) {
                    return `AVERAGE: ${
                        Math.abs(avgData) > 0
                            ? convertedTime(Math.abs(avgData) * 60)
                            : "00:00"
                    }`;
                } else {
                    return `AVERAGE: ${
                        workoutState.currentUnit === "miles"
                            ? convertKilometerToMile(avgData, 10)
                            : Math.floor(avgData * 10)/10
                    }`;
                }
            } else {
                return `AVERAGE: ${Math.round(avgData)}`;
            }
        }
    };

    const options: any = {
        layout: {
            padding: {
                right: 20, // Set the padding on the right side of the chart
            },
        },
        legend: {
            display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        // Plugin
        annotation: {
            events: ["mouseenter", "mouseover"],
            drawTime: "afterDatasetsDraw",
            annotations: [
                {
                    type: "line",
                    mode: "horizontal",
                    scaleID: avgData ? "y-axis-1" : "y-axis-0",
                    value: getAvgLabelValue(),
                    borderColor: "transparent",
                    label: {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        fontFamily: "Work Sans",
                        fontSize: 12,
                        fontColor: "#FFFFFF",
                        xPadding: 20,
                        yPadding: 8,
                        cornerRadius: 40,
                        enabled: true,
                        fontStyle: "normal",
                        content: getAvgLabelContent(),
                        position: "left",
                        xAdjust: 50,
                    },
                },
            ],
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 0,
                        // JUST FOR THIS SecPer500!
                        max: getMaxValueY(),
                        callback: (value: any, index: any, values: any) => {
                            if (appId === String(CONSTANTS.APP.AVIRON_BIKE)) {
                            } else {
                                if (isSplitMode) {
                                    if (value === 5) return 0;
                                    if (value === 4) return 1;
                                    if (value === 3) return 2;
                                    if (value === 2) return 3;
                                    if (value === 1) return 4;
                                    if (value === 0) return 5;
                                }
                            }
                            if (windowSize.width <= 500) {
                                if (value >= 1000) {
                                    return (
                                        (value / 1000)
                                            .toFixed(1)
                                            .replace(".0", "") + "K"
                                    );
                                }
                            }
                            return value;
                        },
                        stepSize: getYStepSize(),
                        fontFamily: "MonoSpec",
                        padding: 5,
                    },
                    scaleLabel: {
                        display: false,
                        labelString: renderLabelButton(information.unit),
                        fontSize: window.innerWidth < 500 ? 12 : 16,
                    },
                    gridLines: {
                        drawTicks: false,
                    },
                },
            ],

            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: "Time",
                        fontSize: window.innerWidth < 500 ? 12 : 16,
                        fontWeight: "600",
                        color: "#939393",
                    },
                    ticks: {
                        callback: (
                            tick: string,
                            index: number,
                            values: any[]
                        ) => {
                            // console.log({ values, tick, index });
                            if (index === values.length - 1) {
                                return null;
                            } else if (
                                Math.floor(values[1] % 60) === 0 &&
                                Math.floor(Number(tick) % 60) === 0 &&
                                Math.floor(values[1]) >= 60
                            ) {
                                return `${Number(tick) / 60}min`;
                            } else if (Number(tick) < 1) {
                                if (Number(tick) === 0) {
                                    return "0S";
                                }
                                return `${Number(tick).toFixed(1)}s`;
                            } else {
                                return `${Number(tick).toFixed(0)}s`;
                            }
                        },
                        min: 0,
                        max: getMaxXTick(),
                        stepSize: getXStepSize(),
                        fontFamily: "MonoSpec",
                        fontSize: 12,
                        padding: 10,
                    },
                    gridLines: {
                        drawTicks: false,
                        display: false,
                    },
                },
            ],
        },
        showAllTooltips: true,
        tooltips: {
            callbacks: {
                label: (context: any) => {
                    if (context.datasetIndex === 1) {
                        if (
                            isSplitMode &&
                            appId !== String(CONSTANTS.APP.AVIRON_BIKE)
                        ) {
                            const sec = Math.abs(5 - context.yLabel) * 60;
                            return ` ${renderLabelButton("SPLIT")}: ${
                                Math.abs(context.yLabel) > 0
                                    ? convertedTime(sec)
                                    : "05:00"
                            }`;
                        }
                        return ` ${renderLabelButton(information.unit)}: ${
                            Math.round(context.yLabel * 100) / 100
                        }`;
                    }
                    return "";
                },
                footer: (contextArr: any) => {
                    const context = contextArr[0];
                    if (context.datasetIndex === 1) {
                        return `     TIME: ${convertedTime(context.xLabel)}`;
                    }
                },
            },
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            footerFontStyle: "normal",
        },
    };

    const isActive = (item: number) => {
        return currentSelect === item;
    };

    const handleSelectOption = (item: any) => {
        const domTarget = document.getElementById(
            `workout-chart-option-${item.value}`
        );
        if (domTarget && window.innerWidth < 900) {
            domTarget.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
            });
        }
        handleSelect(item.value);
    };

    const renderTypeGameLabel = (label: string) => {
        if(appId === String(CONSTANTS.APP.AVIRON_BIKE)) {
            if(label.includes('METERS')) {
                const labelArr = label.split(" ");
                const number = Number(labelArr[0]);
                let numberRes ;
                switch (workoutState.currentUnit) {
                    case "miles":
                        numberRes =  `${convertMeterToMile(number, 100, true)} MILES`;
                        break;
                    case "kilometers":
                        numberRes = `${convertMeterToKilometer(number, 100, true)} KILOMETERS`;
                        break;
                    default:
                        numberRes =  `${convertMeterToMile(number, 100, true)} MILES`;
                        break;
                }
                return numberRes
            }
            if(label.includes('KPH')) {
                const labelArr = label.split(" ");
                const number = Number(labelArr[0]);
                let numberRes ;
                switch (workoutState.currentUnit) {
                    case "miles":
                        numberRes =  `${convertKilometerToMile(number, 10, true)} MPH`;
                        break;
                    case "kilometers":
                        numberRes = `${number} KPH`;
                        break;
                    default:
                        numberRes =  `${number} KPH`;
                        break;
                }
                return numberRes
            }
        }
        return label
    }

    // console.log('metricOptionskkkkkk', metricOptions)

    return (
        <Box className="workout-chart-component">
            <Stack
                direction="row"
                mb="16px"
                mt="16px"
                overflow="auto"
                display="flex"
                justifyContent="space-between"
                className={"stackTitleWrapper"}
            >
                <div className="title-container">
                    <div className="chart-name">{workoutDetail?.name}</div>
                    <div className="unitWrapper">
                        {workoutDetail?.tag?.map(
                            (item: string, index: number) => (
                                <div className="unit-name" key={index}>
                                    {renderTypeGameLabel(item)}
                                </div>
                            )
                        )}
                    </div>
                </div>
                {/* <div className="syncButtonWrapper">
                    {isStravaConnected && !information.isStravaSynced && (
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            onClick={handleSyncToStrava}
                            className="syncStravaButton"
                        >
                            <b>SYNC STRAVA</b>
                        </Button>
                    )}
                    {isStravaConnected && information.isStravaSynced && (
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            className="syncStravaButton syncStravaButton__synced"
                            startIcon={<CheckCircleIcon />}
                        >
                            <b>STRAVA SYNCED</b>
                        </Button>
                    )}
                    {isC2App &&
                        isLogbookConnected &&
                        !information.isLogbookSynced && (
                            <Button
                                variant="outlined"
                                color={"secondary"}
                                onClick={handleSyncToLogbook}
                                className="syncStravaButton"
                            >
                                <b>SYNC LOGBOOK</b>
                            </Button>
                        )}
                    {isC2App &&
                        isLogbookConnected &&
                        information.isLogbookSynced && (
                            <Button
                                variant="outlined"
                                color={"secondary"}
                                className="syncStravaButton syncStravaButton__synced"
                                startIcon={<CheckCircleIcon />}
                            >
                                <b>LOGBOOK SYNCED</b>
                            </Button>
                        )}

                    {isFitbitConnected && !information.isFitbitSynced && (
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            onClick={handleSyncToFitbit}
                            className="syncStravaButton"
                        >
                            <b>SYNC FITBIT</b>
                        </Button>
                    )}
                    {isFitbitConnected && information.isFitbitSynced && (
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            className="syncStravaButton syncStravaButton__synced"
                            startIcon={<CheckCircleIcon />}
                        >
                            <b>FITBIT SYNCED</b>
                        </Button>
                    )}
                </div> */}
            </Stack>
            <Stack
                direction="row"
                spacing={{
                    xs: "8px",
                    md: "8px",
                }}
                mb="16px"
                overflow="auto"
                display="flex"
                sx={{
                    flexWrap: {
                        xs: "nowrap",
                        md: "wrap",
                    },
                    justifyContent: {
                        xs: "space-between",
                        md: "start",
                    },
                    paddingBottom: {
                        xs: "4px",
                        md: 0,
                    },
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                className={"tabChartButtons"}
            >
                {metricOptions.map((item) => {
                    return (
                        <div
                            key={item.value}
                            id={`workout-chart-option-${item.value}`}
                        >
                            <Button
                                onClick={handleSelectOption.bind(null, item)}
                                variant="outlined"
                                sx={{
                                    borderColor: isActive(item.value)
                                        ? "#161616 !important"
                                        : "#d1d1d1 !important",
                                    color: isActive(item.value)
                                        ? "#161616 !important"
                                        : "rgb(79, 79, 79) !important",
                                    fontWeight: isActive(item.value)
                                        ? 600
                                        : 400,
                                }}
                                className={item.value===7 ? 'heartRateButton' : ''}
                            >
                                {renderLabelButton(item.label)}
                            </Button>
                        </div>
                    );
                })}
            </Stack>
            <div className="workoutAreaChartWrapper">
                <div className="workout-area-chart-label-y">
                    {renderLabelButton(information.unit)}
                </div>
                <div className="workoutAreaChartContent">
                    <ChartComponent
                        type="scatter"
                        data={chartComponentData}
                        options={options}
                    />
                </div>
            </div>
        </Box>
    );
};
export default memo(WorkoutChart);
