import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Field from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const TextField = ({ key, label, fontSize, error, ...props }: any) => {

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} mb="8px">
          <Box flexShrink={0} mr="30px"><Typography component="label" variant="h6">{label}</Typography></Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Field {...props}/>
          {error && <Box color="red" fontSize={12}>{error}</Box>}
        </Grid>
      </Grid>
    </Box>
  )
}

export default TextField;