import React from "react";

const PlusIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
        >
            <path
                d="M5.16602 5.16699V0.166992H6.83268V5.16699H11.8327V6.83366H6.83268V11.8337H5.16602V6.83366H0.166016V5.16699H5.16602Z"
                fill="white"
            />
        </svg>
    );
};

export default PlusIcon;
