import React, { Fragment } from "react";
import ScrollToTop from "Components/ScrollToTop";
import Header from "Layouts/Header";
import { allMainRoutes } from "../useRouteElement.constant";
import {
    handleRenderRouteElement,
    useRouteElementHooks,
} from "../useRouteElement.hook";
import Footer from "Layouts/Footer";

const WrapplerAllMain = () => {
    const { dataTokenId, classes } = useRouteElementHooks();
    return (
        <Fragment>
            <ScrollToTop />
            <Header />
            {dataTokenId && (
                <div className={classes.root + " contentAppWrapper"}>
                    <div className={classes.content}>
                        {handleRenderRouteElement(allMainRoutes)}
                    </div>
                </div>
            )}
            <Footer />
        </Fragment>
    );
};

export default WrapplerAllMain;
