import React from 'react'
import { Grid, Box, Typography, Paper } from "@mui/material"

const MobileDownload = () => {
    return <Paper>
        <Box padding={{
            xs: "20px",
            md: "40px"
        }} maxWidth={"900px"}>
            <Grid container display={"flex"} sx={{
                textAlign: {
                    xs: "center",
                    md: "left"
                },
                flexDirection: {
                    xs: "column-reverse",
                    md: "row"
                }
            }}>
                <Grid xs={12} md={7} display={"flex"} alignItems={"center"}>
                    <Box>
                        <Typography variant="h1" component="h1">MANAGE YOUR<br />INTEGRATIONS</Typography>
                        <Typography variant="body1" component="p" fontSize={"16px"} mt="16px">Say goodbye to hassle – manage all your integrations seamlessly with our app!</Typography>
                        <Box mt={"56px"}>
                            <Grid container gap="24px" justifyContent={{ xs: "center", md: "left" }}>
                                <Grid item>
                                    <a href="https://play.google.com/store/apps/details?id=com.avironactive.mobile&hl=en" target="_blank">
                                        <picture>
                                            <source
                                                media={`(max-width: 768px)`}
                                                type='image/webp'
                                                srcSet="/images/integrations/googlestore-mobile.png"
                                            />
                                            <img src="/images/integrations/googlestore.jpg" alt="Mobile download" width={"120px"} />
                                        </picture>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://apps.apple.com/vn/app/myaviron-companion/id6443441596" target="_blank">

                                        <picture>
                                            <source
                                                media={`(max-width: 768px)`}
                                                type='image/webp'
                                                srcSet="/images/integrations/appstore-mobile.png"
                                            />
                                            <img src="/images/integrations/appstore.jpg" alt="Mobile download" width={"120px"} />
                                        </picture>
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={"56px"}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item sx={{
                                    width: {
                                        xs: "50%",
                                        md: "fit-content"
                                    },
                                    paddingRight: {
                                        xs: "8%",
                                        md: "0"
                                    },
                                    textAlign: "left"
                                }}>
                                    <picture>
                                        <source
                                            media={`(max-width: 768px)`}
                                            type='image/webp'
                                            srcSet="/images/integrations/googlefit-mobile-2.png"
                                        />
                                        <img src="/images/integrations/googlefit.png" alt="Google Fit" width="100%" height="auto" />
                                    </picture>
                                </Grid>
                                <Grid item sx={{
                                    width: {
                                        xs: "50%",
                                        md: "fit-content"
                                    },
                                    marginTop: {
                                        xs: "0px",
                                        md: "3px"
                                    },
                                    paddingLeft: {
                                        xs: "8%",
                                        md: "0"
                                    },
                                    textAlign: "right"
                                }}>
                                    <picture>
                                        <source
                                            media={`(max-width: 768px)`}
                                            type='image/webp'
                                            srcSet="/images/integrations/strava-mobile-2.png"
                                        />
                                        <img src="/images/integrations/strava.png" alt="Strava" width="100%" height="auto" />
                                    </picture>

                                </Grid>
                                <Grid item sx={{
                                    width: {
                                        xs: "50%",
                                        md: "fit-content"
                                    },
                                    marginTop: {
                                        xs: "20px",
                                        md: "3px"
                                    },
                                    paddingRight: {
                                        xs: "8%",
                                        md: "0"
                                    },
                                    textAlign: "left"
                                }}>
                                    <picture>
                                        <source
                                            media={`(max-width: 768px)`}
                                            type='image/webp'
                                            srcSet="/images/integrations/concept2-mobile-2.png"
                                        />
                                        <img src="/images/integrations/concept2.png" alt="Concept2" width="100%" height="auto" />
                                    </picture>
                                </Grid>
                                <Grid item sx={{
                                    width: {
                                        xs: "50%",
                                        md: "fit-content"
                                    },
                                    marginTop: {
                                        xs: "10px",
                                        md: 0
                                    },
                                    paddingLeft: {
                                        xs: "8%",
                                        md: "0"
                                    },
                                    textAlign: "right"
                                }}>
                                    <picture>
                                        <source
                                            media={`(max-width: 768px)`}
                                            type='image/webp'
                                            srcSet="/images/integrations/fitbit-mobile-2.png"
                                        />
                                        <img src="/images/integrations/fitbit.png" alt="FitBit" width="100%" />
                                    </picture>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12} md={5} sx={{
                    marginBottom: {
                        xs: "48px",
                        md: 0
                    }
                }}>
                    <img src="/images/integrations/integration-mobile.webp" alt="Mobile download" width={"100%"} />
                </Grid>
            </Grid>
        </Box>
    </Paper >
}

export default MobileDownload;