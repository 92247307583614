import React from "react";

export const handleRenderErrorLabel = (title: string) => {
    if (!title) return null;
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ color: "red", fontWeight: 400, marginRight: 5 }}>
                {title}
            </p>
            <span style={{ color: "red", fontWeight: 400 }}>*</span>
        </div>
    );
};
