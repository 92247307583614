import React from "react";
const Icon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M15 6.66634H16.6667C16.8877 6.66634 17.0996 6.75414 17.2559 6.91042C17.4122 7.0667 17.5 7.27866 17.5 7.49967V17.4997C17.5 17.7207 17.4122 17.9326 17.2559 18.0889C17.0996 18.2452 16.8877 18.333 16.6667 18.333H3.33333C3.11232 18.333 2.90036 18.2452 2.74408 18.0889C2.5878 17.9326 2.5 17.7207 2.5 17.4997V7.49967C2.5 7.27866 2.5878 7.0667 2.74408 6.91042C2.90036 6.75414 3.11232 6.66634 3.33333 6.66634H5V5.83301C5 4.50693 5.52678 3.23516 6.46447 2.29747C7.40215 1.35979 8.67392 0.833008 10 0.833008C11.3261 0.833008 12.5979 1.35979 13.5355 2.29747C14.4732 3.23516 15 4.50693 15 5.83301V6.66634ZM9.16667 13.1097V14.9997H10.8333V13.1097C11.1511 12.9262 11.3994 12.6431 11.5398 12.3041C11.6802 11.9651 11.7048 11.5893 11.6099 11.2349C11.5149 10.8805 11.3057 10.5674 11.0146 10.344C10.7235 10.1207 10.3669 9.99963 10 9.99963C9.63311 9.99963 9.27647 10.1207 8.9854 10.344C8.69432 10.5674 8.48508 10.8805 8.39012 11.2349C8.29517 11.5893 8.3198 11.9651 8.4602 12.3041C8.6006 12.6431 8.84893 12.9262 9.16667 13.1097ZM13.3333 6.66634V5.83301C13.3333 4.94895 12.9821 4.10111 12.357 3.47599C11.7319 2.85086 10.8841 2.49967 10 2.49967C9.11594 2.49967 8.2681 2.85086 7.64298 3.47599C7.01786 4.10111 6.66667 4.94895 6.66667 5.83301V6.66634H13.3333Z"
                fill="#666666"
            />
        </svg>
    );
};

export default Icon;
