import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const UnderlineButton: React.FunctionComponent<{ onClick: Function, title: string }> = ({ onClick, title }) => {
    return (
        <Box textAlign="right">
            <Typography
                variant="body1"
                sx={{
                    marginTop: "32px",
                    textTransform: "uppercase",
                }}
            >
                <u
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={onClick.bind(null)}
                >
                    {title}
                </u>
            </Typography>
        </Box>
    )
}

export default UnderlineButton;