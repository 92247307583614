import { Box } from '@mui/material';
import { CONSTANTS } from 'consts';
import { UserAppContext } from 'contexts';
import useGetCrmSessionHook from 'Hooks/CrmSessionHook';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { CustomError, getExceptionFromError } from 'scripts/utils/handleErrors';
import { useStravaConnectMutation
    // , useStravaSyncWorkoutsMutation
 } from 'services/crmServer';
import Swal from 'sweetalert2';
import { getUrlVars } from 'utils/url';

const Strava = () => {
    const history = useHistory()
    const { auth } = useContext(UserAppContext);

    const [connectStrava] = useStravaConnectMutation();
    // const [syncAllWorkouts] = useStravaSyncWorkoutsMutation()

    const { crmToken } = useGetCrmSessionHook();

    useEffect(() => {
        const params = getUrlVars();
        if (params && auth.crmUserId && params.code) {
            connectStravaIntoCRM(params)
        } else if (params && auth.crmUserId && !params.code) {
            Swal.fire("Error", "You didn't authenticate with Strava", 'error').then(() => {
                history.push(CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY)
            })
        }
    }, [auth])

    const connectStravaIntoCRM = async (params: any) => {
        Swal.showLoading()
        try {
            await connectStrava({
                variables: {
                    crmUserId: auth.crmUserId,
                    stravaAuthCode: params.code
                },
                context: {
                    clientName: "crm-server",
                    headers: {
                        "authorization": "Bearer " + crmToken
                    }
                }
            })
            await Swal.fire("Successfully Synced", "", 'success');
            history.push(CONSTANTS.NAVIGATION_URL.INTEGRATIONS)
            // askSyncAllWorkouts()
        } catch (err) {
            const errDetail = getExceptionFromError(err) as CustomError;
            if (errDetail && errDetail.key === "strava_has_connected") {
                await Swal.fire("Error", "This Strava account has been connected with another Aviron account.", 'error')
            } else {
                await Swal.fire("Error", "Connect to Strava failed", 'error')
            }
            history.push(CONSTANTS.NAVIGATION_URL.INTEGRATIONS)
        }
    }

    // const askSyncAllWorkouts = () => {
    //     Swal.fire({
    //         title: "Do you want to sync all workouts to Strava?",
    //         text: "It's can take a day to sync all workouts.",
    //         confirmButtonText: "Sync now",
    //         denyButtonText: "No thank!",
    //         showDenyButton: true
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             handleSyncAllWorkouts()
    //         } else if (result.isDenied) {
    //             history.push(CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY)
    //         }
    //     })
    // }

    // const handleSyncAllWorkouts = () => {
    //     syncAllWorkouts({
    //         context: {
    //             clientName: CONSTANTS.SERVER.CRM_SERVER,
    //             headers: {
    //                 "authorization": "Bearer " + crmToken
    //             }
    //         }
    //     }).then(result => {
    //         if (result.data?.stravaSyncWorkoutsV3.status === "processing") {
    //             Swal.fire("Processing", `${result.data?.stravaSyncWorkoutsV3.total} workouts will be sync to Strava`, 'success');
    //         } else if (result.data?.stravaSyncWorkoutsV3.status === "empty") {
    //             Swal.fire("Nothing to sync", "All your workouts have been synced to Strava", 'info');
    //         }

    //     }).catch(err => {
    //         Swal.fire("Failed", "Sync workouts error", 'error');
    //     })
    // }

    return <Box sx={{
        width: "fit-content",
        margin: "32px auto",
        textAlign: "center"
    }}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Strava connect | Aviron</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        {/* <Typography mt="24px" mb="16px" variant="h4" component="h4">Strava connecting...</Typography>
        <Typography mb="16px" variant="body1" component="p">We will sync workout to your Strava account.</Typography> */}
    </Box>
}

export default Strava;