import React from "react";
import UserCard from "Components/MembershipPage/UserCard";
import Status from "Components/MembershipPage/Status";

export const initInviteMemberData = [
    // {
    //     userId: "",
    //     customId: "",
    //     username: "",
    //     email: "h",
    //     avatarUrl: "",
    //     status: 10,
    //     expireTime: 0,
    //     createTime: 0,
    //     updateTime: 0,
    //     empty: true,
    // },
];

export const formatInviteMemberRows = (data: any) => {
    const res = [];
    if (data) {
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            res.push({
                selection: { key: `row${i}`, value: "", rowData: item },
                user: {
                    value: (
                        <UserCard
                            email={item?.email}
                            avatar={item?.avatarUrl}
                            username={item?.username}
                        />
                    ),
                    key: `row${i}`,
                },
                status: { value: <Status status={item?.status} />, key: `row${i}` },
            });
        }
        return res;
    } else {
        return initInviteMemberData;
    }
};

export const formatStatus = (status: number, expire: number) => {
  if(status===0) {
    const dateNow = new Date().getTime();
    const expireDate = new Date(expire * 1000).getTime();
    if(expireDate >= dateNow) {
      return 2 //pedding
    } else {
      return 3 //expired
    }
  } else {
    return status
  }
};

export const formatInviteMembership = (data: any) => {
    if (data) {
        const members = data?.members || [];
        let res = [];
        for (let i = 0; i < members.length; i++) {
            const member = members[i];
            res.push({
                ...member,
                status: formatStatus(member?.status, member?.expireTime),
            });
        }
        return res;
    } else {
        return initInviteMemberData;
    }
};

export const formatDeleteMemberData = (data: any) => {
    let res = [];
    for(let i=0; i < data.length; i++) {
        const item = data[i];
        res.push(item?.rowData?.userId)
    }
    return res;
}

export const formatDeleteMultiMemberData = (data: any) => {
    let res = [];
    for(let i=0; i < data.length; i++) {
        const item = data[i];
        res.push(item?.data?.rowData?.userId)
    }
    return res;
}
