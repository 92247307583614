import { BaseReducer } from '../base/base.reducer';

class CheckoutReducer extends BaseReducer {
    constructor() {
        super("CHECKOUT", {
            current_page: 0
        })
    }
}



const checkoutReducer = new CheckoutReducer()

export {
    checkoutReducer
}