import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import Field from '@mui/material/TextField';
import { useFormik } from 'formik';
import React from 'react';
import { changePasswordValidate } from './changePasswordValidate';
import "./style.scss";
import Button from '@mui/material/Button'

const useStyles: any = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const ChangePasswordModal = (props: any) => {
  const { open, onClose, onClickChange } = props;
  const classes = useStyles();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  const formik = useFormik({
    initialValues: initialValues,
    validate: changePasswordValidate,
    onSubmit: (values) => {
      onClickChange(values)
    },
  });

  React.useEffect(() => {
    formik.resetForm();
  },[open])

  return (
    <Modal open={open} onClose={onClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="change-password-modal">

          <button className="close-btn" onClick={onClose}>
            <img src="/images/icons/x-icon.svg" width={18} height={18} alt='icon' />
          </button>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src="/images/icons/aviron-logo.svg"
              width={48} height={48}  alt='icon' />
          </div>

          <div style={{
            padding: '24px 20px 36px 20px',
            textAlign: 'center',
            fontWeight: "bold",
            fontSize: "24px",
            color: "#161616",
            fontFamily: 'GRIFTER'
          }}>
            CHANGE YOUR PASSWORD
          </div>


          <Box>
            <form onSubmit={formik.handleSubmit}>
              <div className="text-field-group">
                <Field
                  name="oldPassword" label="Current password"
                  variant="outlined" type="password"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.oldPassword} />
                {formik.errors.oldPassword &&
                  <div style={{ color: 'red', fontSize: "12px" }}>
                    {formik.errors.oldPassword}
                  </div>}
              </div>

              <div className="text-field-group">
                <Field
                  name="newPassword" label="New password"
                  variant="outlined" type="password"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.newPassword} />
                {formik.errors.newPassword &&
                  <div style={{ color: 'red', fontSize: "12px" }}>
                    {formik.errors.newPassword}
                  </div>}
              </div>

              <div className="text-field-group">
                <Field
                  name="confirmPassword" label="Confirm new password"
                  variant="outlined" type="password"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword} />
                {formik.errors.confirmPassword &&
                  <div style={{ color: 'red', fontSize: "12px" }}>
                    {formik.errors.confirmPassword}
                  </div>}
              </div>

              <div className="buttons-group">
                <Button type="submit" variant="contained" color="secondary"
                  sx={{
                    height: "64px"
                  }}
                >
                  SAVE NEW PASSWORD
                </Button>
                <Button type="button" onClick={onClose} variant="outlined"
                  sx={{
                    height: "64px"
                  }}>
                  CANCEL
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  )
}

export default ChangePasswordModal;