import Swal from 'sweetalert2';

export const errorHandle = (error: any) => {
    try {
        let errorParsed = JSON.parse(error.message);
        Swal.fire(errorParsed?.message || "Unknow error", "", "error");
    } catch (err) {
        if (error.message.toLowerCase().includes("username is not allowed")) {
            Swal.fire("Email address is invalid", "", "error");
        } else if (error.message.includes("GraphQL error: ")) {
            Swal.fire(error.message.replace("GraphQL error: ", ""), "", "error");
        } else {
            Swal.fire("Error", error.message, "error");
        }
    }
};
