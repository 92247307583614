import { initialAchievementList } from "./achievements.reducer";
import {
    GET_ACHIEVEMENT_LIST_REQUEST,
    GET_ACHIEVEMENT_LIST_SUCCESS,
    GET_ACHIEVEMENT_LIST_FAILED,
} from "./achievements.type";

export const getAchievementListRequest = () => ({
    type: GET_ACHIEVEMENT_LIST_REQUEST,
});
export const getAchievementListSuccess = (
    payload: initialAchievementList[]
) => ({
    type: GET_ACHIEVEMENT_LIST_SUCCESS,
    payload,
});
export const getAchievementListFailed = () => ({
    type: GET_ACHIEVEMENT_LIST_FAILED,
});
