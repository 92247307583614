import React, {  useRef } from "react";
import { Button, Paper } from "@mui/material";
import styles from "./RegisterBetaSuccess.module.scss";
import RegisterBetaModalCancel, {
    RefModal,
} from "../RegisterBetaModalCancel/RegisterBetaModalCancel";

const img = "/images/icons/beta-request-success.svg";
interface RegisterBetaSuccessProps {
    handleSetSteps: (value: number) => void;
}
const RegisterBetaSuccess = (props: RegisterBetaSuccessProps) => {
    const { handleSetSteps } = props;
    const refModal = useRef<RefModal>(null);

    return (
        <Paper
            sx={{
                padding: { xs: "16px", md: "20px" },
                marginBottom: "20px",
                boxShadow: "none",
                objectFit: "contain",
                background: "transparent"
            }}
        >
            <div className={styles["form-request-success"]}>
                <div className={styles["form-request-success-content"]}>
                    <div style={{ textAlign: "center" }}>
                        <img src={img} alt="image" />
                    </div>
                    <div>
                        <h2
                            className={
                                styles["form-request-success-content__title"]
                            }
                        >
                            BETA ACCESS APPLICATION COMPLETED
                        </h2>
                        <p
                            className={
                                styles[
                                    "form-request-success-content__description"
                                ]
                            }
                        >
                            Thank you for applying! 🎉 <br />
                            We've received your request for Beta access. <br />
                            If selected, we'll update you with next steps.
                        </p>
                    </div>
                    <div className={styles["form-request_button"]}>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={() => handleSetSteps(0)}
                            sx={{
                                background: "#F5490F",
                                borderRadius: "12px",
                                color: "#fff",
                                width: "100%",
                                marginBottom: "40px",
                            }}
                        >
                            BACK TO PROFILE
                        </Button>
                    </div>
                    <div
                        onClick={() => {
                            refModal.current?.setIsVisible(true);
                        }}
                    >
                        <p
                            style={{
                                color: "#161616",
                                fontSize: 16,
                                fontFamily: "MonoSpec",
                                fontWeight: 500,
                                textDecoration: "underline",
                                cursor: "pointer",
                                margin: 0,
                            }}
                        >
                            WITHDRAW APPLICATION
                        </p>
                    </div>
                </div>
            </div>
            <RegisterBetaModalCancel
                ref={refModal}
                handleSetSteps={handleSetSteps}
            />
        </Paper>
    );
};

export default RegisterBetaSuccess;
