import produce from "immer";
import {
    GET_ACHIEVEMENT_LIST_REQUEST,
    GET_ACHIEVEMENT_LIST_SUCCESS,
    GET_ACHIEVEMENT_LIST_FAILED,
} from "./achievements.type";
import { achievementListFormattedData } from "Screens/WorkoutHistory/Achievements/Achievements.actions";

export interface initialAchievementList extends achievementListFormattedData {}

const initialState: {
    data: initialAchievementList[];
    loading: boolean;
} = {
    data: [],
    loading: false,
};

type actionType = {
    type: string;
    payload: any;
};
const reducer = produce((state, action: actionType) => {
    switch (action.type) {
        case GET_ACHIEVEMENT_LIST_REQUEST: {
            state.loading = true;
            state.data = [];
            return state;
        }
        case GET_ACHIEVEMENT_LIST_SUCCESS: {
            state.loading = false;
            state.data = action.payload;
            return state;
        }

        case GET_ACHIEVEMENT_LIST_FAILED: {
            state.loading = false;
            state.data = [];
            return state;
        }

        default:
            return state;
    }
}, initialState);

export const achievementListReducer = { initialState, reducer };
