import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForgotPasswordMutation } from "services/webServer";
import { errorHandle } from "utils/errorHandle";
import validate from "validate.js";

const schema = {
    email: {
        presence: { allowEmpty: false, message: "is required" },
        email: true,
        length: {
            maximum: 64,
        },
    },
};

const useResetPasswordHook = () => {
    const history = useHistory();

    const [forgotPassword] = useForgotPasswordMutation();

    const [isSentResetLink, setIsSentResetLink] = useState<boolean>(false);
    const [formState, setFormState] = useState<any>({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState((formState: any) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleChange = (event: any) => {
        event.persist();
        setFormState((formState: any) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === "checkbox"
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        forgotPassword({
            variables: {
                email: formState.values.email,
            },
        })
            .then((result) => {
                setIsSentResetLink(true);
                // Swal.fire("Email sent", "We sent you an email to reset your password. Please check your inbox.", "info");
            })
            .catch((err) => {
                errorHandle(err);
            });
    };

    const hasError = (field: string) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    const backToLogin = () => {
        history.push("/login")
    }

    return {
        formState,
        isSentResetLink,
        handleSubmit,
        hasError,
        handleChange,
        backToLogin
    };
};

export default useResetPasswordHook;
