export const changePasswordValidate = (values: any) => {
  let errors: any = {};

  if (!values.oldPassword) {
    errors.oldPassword = 'Password is required'
  } else if (values.oldPassword.length < 8) {
    errors.oldPassword = 'Password must be at least 8 characters'
  }

  if (!values.newPassword) {
    errors.newPassword = 'Password is required'
  } else if (values.newPassword.length < 8) {
    errors.newPassword = 'Password must be at least 8 characters'
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'The password you entered does not match'
  };
 
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Password is required'
  } else if (values.confirmPassword.length < 8) {
    errors.confirmPassword = 'Password must be at least 8 characters'
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'The password you entered does not match'
  };

  return errors;
}
