import React from "react";
import style from "./Status.module.scss";
import { ReactComponent as PendingIcon } from "./icons/Pending.svg";
import { ReactComponent as AcceptIcon } from "./icons/Success.svg";
import { ReactComponent as ExpiredIcon } from "./icons/Expired.svg";

interface IStatus {
    status: number;
}

export const MEMBERSHIP_STATUS = {
    ACCEPT: 1,
    PENDING: 2,
    EXPIRE: 3,
}

const StatusConfig = {
    pending: {
        text: "Invitation pending",
        icon: <PendingIcon />,
    },
    accept: {
        text: "Has Access",
        icon: <AcceptIcon />,
    },
    expired: {
        text: "Invitation expired",
        icon: <ExpiredIcon />,
    },
};
const Status = ({ status = 89 }: IStatus) => {
    const renderStatus = (status: number) => {
        if (status === MEMBERSHIP_STATUS.ACCEPT) {
            return (
                <>
                    <div className={style["icon"]}>
                        {StatusConfig.accept.icon}
                    </div>
                    <div className={style["text"] + ' textStatus'}>
                        {StatusConfig.accept.text}
                    </div>
                </>
            );
        } else if (status === MEMBERSHIP_STATUS.PENDING) {
            return (
                <>
                    {" "}
                    <div className={style["icon"]}>
                        {StatusConfig.pending.icon}
                    </div>
                    <div className={style["text"] + ' textStatus'}>
                        {StatusConfig.pending.text}
                    </div>
                </>
            );
        } else if (status === MEMBERSHIP_STATUS.EXPIRE) {
            return (
                <>
                    <div className={style["icon"]}>
                        {StatusConfig.expired.icon}
                    </div>
                    <div className={style["text"] + ' textStatus'}>
                        {StatusConfig.expired.text}
                    </div>
                </>
            );
        } else {
            return "";
        }
    };
    return <div className={style["Status"] + ' StatusWrapper'}>{renderStatus(status)}</div>;
};

export default Status;
