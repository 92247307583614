import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './MembershipInviteConfirm.module.scss';
import { ReactComponent as TickSuccess } from './Icons/Success.svg'
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { CONSTANTS } from 'consts';
import { UserAppContext } from 'contexts';
import { getUrlVars } from 'utils/url';
import { ReactComponent as TickError } from './Icons/Expire.svg';
import { AvironLoading } from 'Components/Icons/AvironLoading';
import { gameServerApi } from "services/gameServerV2";

const MembershipInviteConfirm = () => {
  const history = useHistory();
  const params = getUrlVars();
  const [stateUpdate, setStateUpdate] = useState<"init" | "success" | "error">(
    'init'
  );
  const [resultConfirm, setResultConfirm] = useState<any>('');
  const { auth } = useContext(UserAppContext);

  const handleClickView = async () => {
    document.body.style.overflow = "";
    history.push(CONSTANTS.NAVIGATION_URL.MEMBERSHIP_FAMILLY.replace(':id', resultConfirm?.ownerId))
  };


  function usePreviousValue(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevAuth: any = usePreviousValue(auth);

  useEffect(() => {
    if (auth?.email) {
      if (params?.orgId) {
        gameServerApi.user.confirmInviteMembership({
          orgId: params?.orgId
        }).then((res)=>{
          setResultConfirm(res.data)
          setStateUpdate("success")
        }).catch((err)=>{
          setStateUpdate("error")
        })
      }
    }
  }, [auth]);

  useEffect(() => {
    if (stateUpdate === 'success' || stateUpdate === 'error') {
      document.body.style.overflow = "hidden";
    }
  }, [stateUpdate])

  return (
    <div className={style["ChangeEmailConfirmPage"]}>
      {stateUpdate === "success" && (
        <div className={style["ChangeEmailSuccessPage"]}>
          <div>
            <TickSuccess />
            <div className={style["title"]}>
              You are now a member of the Family Plan
            </div>
            <div className={style["buttonGroup"]}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickView}
              >
                VIEW
              </Button>
            </div>
          </div>
        </div>
      )}

      {stateUpdate === "init" && (
        <div className={style["ChangeEmailLoadingPage"]}>
          <AvironLoading />
        </div>
      )}

      {stateUpdate === "error" && (
        <div className={style["ChangeEmailSuccessPage"]}>
          <div>
            <TickError />
            <div className={style["title"]}>Invite link is expired</div>
            <div className={style["buttonGroup"]}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
                  document.body.style.overflow = "";
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MembershipInviteConfirm;