import React from "react";
import style from "./ExportButton.module.scss";
import { ReactComponent as DownloadIcon } from "./download.svg";

const ExportButton = (props:any) => {
    const {onClick} = props;
    return (
        <div className={style["ExportButtonWrapper"] + ' ExportButtonWrapper'}>
            <button onClick={onClick}>
              <DownloadIcon />
            </button>
        </div>
    );
};

export default ExportButton;
