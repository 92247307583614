import { useEffect, useState } from "react";
import { gameServerApi } from "services/gameServerV2";

const initialData = {
    exp: 0,
    expCoinsWallet: 0,
    level: "",
    levelId: 0,
    levelProgress: 0,
    nextExp: 0,
    nextLevel: { id: 0, name: "" },
    nextLevelId: 0,
    remainHour: 0,
    remainMinute: 0,
};
const useGameUserInfoHook = () => {
    const [gameUserInfo, setGameUserInfo] = useState(initialData);

    const handleQueryData = () => {
        gameServerApi.user
            .getGameUserInfo()
            .then((res) => {
                if(res && res.status === 200) {
                    //@ts-ignore
                    setGameUserInfo(res.data);
                } else {
                    setGameUserInfo(initialData);
                }
            })
            .catch((err) => {
                console.log(err);
                setGameUserInfo(initialData);
            });
    };

    useEffect(() => {
      handleQueryData();
    }, []);

    return {
        gameUserInfo,
    };
};
export default useGameUserInfoHook;
