import Swal from 'sweetalert2';

export const handleCRMServerError = (code: string, key: string, message: string): void => {
    switch (key) {
        case "workout_synced":
            Swal.fire({
                title: "SYNC ERROR",
                text: "Workout already synced",
                icon: "error"
            })
            break;
        default:
            // Swal.fire({
            //     title: "UNKNOWN ERROR",
            //     text: "Process error",
            //     icon: "error"
            // })
            break;
    }
    return;
}