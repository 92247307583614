import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HistorySection from "Components/HistorySection";
import LoadingHOC from "Components/LoadingHOC";
import ParameterSection, {
    defaulParameters,
} from "Components/ParameterSection";
import WorkoutChart from "Components/WorkoutChart";
import { UserAppContext } from "contexts";
import React, { Fragment, memo, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CardSimple from "Components/CardSimple";
import SelectField from "Components/FormElements/Select";
import ExportButton from "Components/WorkoutOverViewPage/ExportButton";
import ExportCSVModal from "Components/WorkoutOverViewPage/ExportCSVModal";
import { callExportCsv } from "../handleActions";
import { timeRangeData } from "../handleData";
import style from "./detailPage.module.scss";
import { useMainHook } from "./main.hook";
import useWindowSize from "Hooks/useWindowSize";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { CONSTANTS } from "consts";
import WorkoutAvironBikeChart from "Components/WorkoutAvironBikeChart";

const HistoryToolBarTop = memo(
    ({
        timeRange,
        onClickExportCSV,
        handleSelectTimeRange,
    }: {
        timeRange: number;
        onClickExportCSV: () => void;
        handleSelectTimeRange: (e: any) => void;
    }) => {
        return (
            <div className="timeRaneWrapper">
                <div className="selectField">
                    <SelectField
                        // @ts-ignore
                        options={timeRangeData.map(({ label, value }) => ({
                            name: label,
                            value: value,
                        }))}
                        value={timeRange || 0}
                        // @ts-ignore
                        onChange={handleSelectTimeRange}
                    />
                </div>
                <div className="downloadButton">
                    <ExportButton onClick={onClickExportCSV} />
                </div>
            </div>
        );
    }
);

const WorkoutDetail = () => {
    const { workoutDispatch } = useContext(UserAppContext);

    const [showModal, setShowModal] = useState(false);
    const windowSize = useWindowSize();

    const {
        isStravaConnected,
        isLogbookConnected,
        isFitbitConnected,
        historyList,
        chartOptions,
        loading,
        currentSelect,
        timeRange,
        chartData,
        isExpandActivity,
        workoutMetrics,
        workoutDetail,
        metaData,
        handleSyncToStrava,
        handleSyncToLogbook,
        handleSyncToFitbit,
        handleNextPage,
        handlePrevPage,
        handleFirstPage,
        handleLastPage,
        handleSelectChartOption,
        handleSelectHistoryItem,
        handleSelectTimeRange,
        hideSelectTimeRage,
    } = useMainHook();

    // console.log("workoutDetail1234",workoutDetail)
    // console.log("historyList", historyList)

    const onClickExportCSV = () => {
        setShowModal(true);
    };
    const appId = localStorage.getItem("appId");
    const isAvironBike = appId === String(CONSTANTS.APP.AVIRON_BIKE);
    const [openWorkoutDetailPopup, setOpenWorkoutDetailPopup] = useState(false);
    const onCloseWorkoutPopup = () => {
        setOpenWorkoutDetailPopup(false);
    };
    const onOpenWorkoutPopup = () => {
        setOpenWorkoutDetailPopup(true);
    };

    const exportCSVToEmail = async (email: string, token: string) => {
        await callExportCsv(
            { type: timeRange, username: "", userEmail: email },
            workoutDispatch,
            token
        );
    };

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }
            onCloseWorkoutPopup();
        };
    const _renderChartSection = () => {
        if (
            currentSelect.workoutItem !== null &&
            chartData[currentSelect.chartOption]
        ) {
            return (
                <Fragment>
                    {isAvironBike ? (
                        <WorkoutAvironBikeChart
                            handleSyncToStrava={handleSyncToStrava}
                            handleSyncToLogbook={handleSyncToLogbook}
                            handleSyncToFitbit={handleSyncToFitbit}
                            metricOptions={chartOptions}
                            handleSelect={handleSelectChartOption}
                            currentSelect={currentSelect.chartOption}
                            chartData={chartData[currentSelect.chartOption]}
                            isStravaConnected={isStravaConnected}
                            isLogbookConnected={isLogbookConnected}
                            isFitbitConnected={isFitbitConnected}
                            workoutDetail={workoutDetail}
                        />
                    ) : (
                        <WorkoutChart
                            handleSyncToStrava={handleSyncToStrava}
                            handleSyncToLogbook={handleSyncToLogbook}
                            handleSyncToFitbit={handleSyncToFitbit}
                            metricOptions={chartOptions}
                            handleSelect={handleSelectChartOption}
                            currentSelect={currentSelect.chartOption}
                            chartData={chartData[currentSelect.chartOption]}
                            isStravaConnected={isStravaConnected}
                            isLogbookConnected={isLogbookConnected}
                            isFitbitConnected={isFitbitConnected}
                            workoutDetail={workoutDetail}
                        />
                    )}
                </Fragment>
            );
        } else {
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={style["emptyChartWrapper"]}
                >
                    <Box
                        textAlign="center"
                        fontSize={"1.286rem"}
                        sx={{ fontFamily: "GRIFTER" }}
                        lineHeight={"28px"}
                    >
                        EMPTY CHART
                    </Box>
                </Box>
            );
        }
    };

    useEffect(() => {
        if (loading.history || loading.chart) {
            setOpenWorkoutDetailPopup(false);
        } else {
            setOpenWorkoutDetailPopup(true);
        }
    }, [loading.history, loading.chart]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Workout history detail | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <Grid
                container
                spacing={"1.714rem"}
                className={style["containerGridMain"]}
            >
                <Grid item lg={4} md={4} sm={12} className="workoutListCard">
                    <HistorySection
                        metaData={metaData}
                        historyData={historyList}
                        currentSelect={currentSelect.workoutItem}
                        handleSelect={handleSelectHistoryItem}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        handleFirstPage={handleFirstPage}
                        handleLastPage={handleLastPage}
                        onOpenWorkoutPopup={onOpenWorkoutPopup}
                        showWorkoutDateOnTitle={
                            hideSelectTimeRage ? true : false
                        }
                        ToolbarTop={
                            hideSelectTimeRage ? (
                                ""
                            ) : (
                                <HistoryToolBarTop
                                    timeRange={timeRange}
                                    onClickExportCSV={onClickExportCSV}
                                    handleSelectTimeRange={
                                        handleSelectTimeRange
                                    }
                                />
                            )
                        }
                        loading={loading.history}
                    />
                </Grid>
                {windowSize.width > 1023 && (
                    <Grid item lg={8} md={8} sm={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <CardSimple
                                    title={"STATISTICS"}
                                    styles={{ padding: "0.857rem" }}
                                >
                                    <ParameterSection
                                        workoutMetrics={
                                            loading.chart
                                                ? defaulParameters
                                                : workoutMetrics
                                        }
                                        workoutDetail={workoutDetail}
                                        isExpand={isExpandActivity}
                                        loading={loading.chart}
                                        mode="detailPage"
                                    />
                                </CardSimple>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className="CardGridCustom workoutChartCard"
                                mt={"1.714rem"}
                            >
                                {_renderChartSection()}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {windowSize.width <= 1023 && (
                    <Drawer
                        anchor={"right"}
                        open={openWorkoutDetailPopup}
                        onClose={toggleDrawer(false)}
                        transitionDuration={800}
                        className={"WorkoutDetailMoreWrapper"}
                    >
                        <div
                            className={"workoutContentContainer"}
                            id={"workoutContentContainerId"}
                        >
                            <div
                                className="closeWorkoutMoreIcon"
                                onClick={onCloseWorkoutPopup}
                            >
                                <CloseIcon />
                            </div>
                            <div className="workoutMoreChart">
                                {_renderChartSection()}
                            </div>
                            <div className="spaceRowDrawer"></div>
                            <div className="workoutMoreStatBoard">
                                <CardSimple title={"STATISTICS"}>
                                    <ParameterSection
                                        workoutMetrics={workoutMetrics}
                                        workoutDetail={workoutDetail}
                                        isExpand={isExpandActivity}
                                        mode="detailPage"
                                    />
                                </CardSimple>
                            </div>
                        </div>
                    </Drawer>
                )}
            </Grid>
            <ExportCSVModal
                open={showModal}
                onClose={() => setShowModal(false)}
                exportCSVToEmail={exportCSVToEmail}
            />
            {(loading.history || loading.chart) && <AvironLoading />}
        </div>
    );
};
export default memo(WorkoutDetail);
