import http, { BaseGameServerAPI } from "./base";

export class AppAPI extends BaseGameServerAPI {
    constructor() {
        super();
    }
    public async getMarchineList() {
        return await http.post('/get_all_app', {
            platform: 1,
            appType: 8,
            appVersion: "1.0.0",
        })

    }
}
