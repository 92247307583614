import http, { BaseGameServerAPI } from "./base";
import {WorkoutHistory} from 'services/gameServerV3/proto/generated/workout/workoutData_pb'

export interface IWorkoutListRequestData {
    timeRange: number;
    page: number;
    pageSize: number;
    startTime?: number;
    endTime?: number;
}

export interface IWorkoutDetailRequestData {
    workoutId: string;
}
export interface IWorkoutDetailProtoRequest {
    workoutId: string,
    unitType?: 0 | 1 | 2 // using for tag list game label: 0. metter, 1. mile, 2. kilometer
    userId?: string
}

export class WorkoutHistoryAPI extends BaseGameServerAPI {
    constructor() {
        super();
    }
    public async getWorkoutList(data: IWorkoutListRequestData) {
        return await http.post('/get_workout_history',data)
    }

    public async getWorkoutDetail(
        data: IWorkoutDetailProtoRequest
    ) {
        const res: any = await http.post('/get_workout_protobuf',{
            id: data.workoutId,
            unitType: data?.unitType,
            userId: data?.userId
        })
        const protoData  = await WorkoutHistory.deserializeBinary(res?.data).toObject();
        return {
            workoutHistory: protoData
        }
    }

    public async getLastUserWorkout() {
        return await http.post('/get_last_user_workout')
    }

    public async getStatisticWorkoutSummary(timeRange: number) {
        return await http.post('/get_statistic_workout_summary',{
            timeRange: timeRange,
        })
    }

    public async getCompareStatisticWorkoutSummary(timeRange: number) {
        return await http.post('/compare_statistic_workout_summary', {
            timeRange: timeRange,
        })
    }

    public async getCalendarStatisticDetailWorkout(
        startTime: number,
        endTime: number
    ) {
        return await http.post('/statistic_detail_workout_calendar',{
            startTime,
            endTime
        })
    }

    public async exportCSV(data: any) {
        return await http.post('/export_workouts_summary',data)
    }
}
