import React from "react";
import style from "./MembershipTable.module.scss";
import Table from "Components/base/Table";

interface IMembershipTable {
    dataTable: {
        column: Array<{ title: string; key: string; className?: string }>;
        rows: Array<any>;
        actions: Array<{ name: string; action: any }>;
        deleteMultiAction?: any;
    };
    handleClickRow?: any
}
const MembershipTable = ({ dataTable, handleClickRow }: IMembershipTable) => {
    const { column, rows, actions, deleteMultiAction } = dataTable;
    return (
        <div className={style["MembershipTable"]}>
            <Table
                columns={column}
                rowsData={rows}
                actions={actions}
                deleteMultiAction={deleteMultiAction}
                handleClickRow={handleClickRow}
            />
        </div>
    );
};

export default MembershipTable;
