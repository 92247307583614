import React from "react";
const Icon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.15565 2.15533C7.46814 1.84274 7.89199 1.66709 8.33398 1.66699H11.6673C12.1093 1.66699 12.5333 1.84259 12.8458 2.15515C13.1584 2.46771 13.334 2.89163 13.334 3.33366V5.00033H15.834C16.055 5.00033 16.267 5.08812 16.4232 5.2444C16.5795 5.40068 16.6673 5.61264 16.6673 5.83366C16.6673 6.05467 16.5795 6.26663 16.4232 6.42291C16.267 6.57919 16.055 6.66699 15.834 6.66699V16.667C15.834 17.109 15.6584 17.5329 15.3458 17.8455C15.0333 18.1581 14.6093 18.3337 14.1673 18.3337H5.83398C5.39196 18.3337 4.96803 18.1581 4.65547 17.8455C4.34291 17.5329 4.16732 17.109 4.16732 16.667V6.66699C3.9463 6.66699 3.73434 6.57919 3.57806 6.42291C3.42178 6.26663 3.33398 6.05467 3.33398 5.83366C3.33398 5.61264 3.42178 5.40068 3.57806 5.2444C3.73434 5.08812 3.9463 5.00033 4.16732 5.00033H6.66732V3.33366C6.66741 2.89167 6.84307 2.46781 7.15565 2.15533ZM8.33398 5.00033H11.6673V3.33366H8.33398V5.00033ZM9.16732 8.33366C9.16732 8.11265 9.07952 7.90068 8.92324 7.7444C8.76696 7.58812 8.555 7.50033 8.33398 7.50033C8.11297 7.50033 7.90101 7.58812 7.74473 7.7444C7.58845 7.90068 7.50065 8.11265 7.50065 8.33366V15.0003C7.50065 15.2213 7.58845 15.4333 7.74473 15.5896C7.90101 15.7459 8.11297 15.8337 8.33398 15.8337C8.555 15.8337 8.76696 15.7459 8.92324 15.5896C9.07952 15.4333 9.16732 15.2213 9.16732 15.0003V8.33366ZM12.5007 8.33366C12.5007 8.11265 12.4129 7.90068 12.2566 7.7444C12.1003 7.58812 11.8883 7.50033 11.6673 7.50033C11.4463 7.50033 11.2343 7.58812 11.0781 7.7444C10.9218 7.90068 10.834 8.11265 10.834 8.33366V15.0003C10.834 15.2213 10.9218 15.4333 11.0781 15.5896C11.2343 15.7459 11.4463 15.8337 11.6673 15.8337C11.8883 15.8337 12.1003 15.7459 12.2566 15.5896C12.4129 15.4333 12.5007 15.2213 12.5007 15.0003V8.33366Z"
                fill="#E60A32"
            />
        </svg>
    );
};

export default Icon;
