import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Field from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import style from "./InviteMember.module.scss";
import { formSchema } from "./formSchema";
import { gameServerApi } from "services/gameServerV2";
import { useParams } from "react-router-dom";
import { AvironLoading } from "Components/Icons/AvironLoading";
import InviteSuccessToast from "../InviteSuccessToast";
import { CONSTANTS } from "consts";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

interface InviteMemberProps {
    open: boolean;
    onClose: any;
    refetchInvitedMembership: any;
    inviteData?: {
        email: string;
        title: string;
        content: string;
        btnText: string;
    };
    mode?: string;
}

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const InviteMember = (props: InviteMemberProps) => {
    const { open, onClose, refetchInvitedMembership, inviteData } = props;
    const classes = useStyles();
    const params = useParams();
    const [showAvironLoading, setShowAvironLoading] = useState(false);
    const [statusInvite, setStatusInvite] = useState<
        "init" | "success" | "error"
    >("init");

    const initialValues = {
        email: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema,
        onSubmit: async (values) => {
            await handleSubmitForm(values);
        },
    });

    const handleSubmitForm = async (values: { email: string }) => {
        setShowAvironLoading(true);
        try {
            await gameServerApi.user.inviteMembership({
                //@ts-ignore
                orgId: params?.id,
                email: values.email,
            });
            onClose();
            setShowAvironLoading(false);
            await refetchInvitedMembership();
            setTimeout(()=>{
                Swal.fire(
                    "Success",
                    `Invited a Member! `,
                    "success"
                );
            }, 600)
        } catch (error) {
            onClose();
            setTimeout(()=>{
                Swal.fire(
                    "Error",
                    error?.response?.data?.error?.message ||
                    "Resend Member Error! ",
                    "error"
                );
            }, 600)
            setShowAvironLoading(false);
        }
    };

    React.useEffect(() => {
        formik.resetForm();
    }, [open]);

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal + " " + style["modalCustom"]}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 800,
                }}
            >
                <Fade in={open}>
                    <div className={style["change-password-modal"]}>
                        <div className={style["close-btn"]} onClick={onClose}>
                            <img
                                src="/images/icons/x-icon.svg"
                                width={18}
                                height={18}
                                alt="icon"
                            />
                        </div>

                        <div className={style["titleModal"]}>{inviteData?.title || 'invite member'}</div>
                        <div className={style["desc"]}>
                            {inviteData?.content || 'Add member to your account and let get fit together!'}
                        </div>
                        <Box>
                            <form onSubmit={formik.handleSubmit}>
                                <div className={style["text-field-group"]}>
                                    <Field
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    {formik.errors.email && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                                marginTop: 8,
                                            }}
                                        >
                                            {formik.errors.email}
                                        </div>
                                    )}
                                </div>
                                <div className={style["buttons-group"]}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        sx={{
                                            height: "64px",
                                        }}
                                    >
                                        {inviteData?.btnText || 'Invite'}
                                    </Button>
                                </div>
                            </form>
                        </Box>
                    </div>
                </Fade>
            </Modal>
            {showAvironLoading && <AvironLoading />}
            <InviteSuccessToast
                state={statusInvite}
                resetState={() => {
                    setStatusInvite("init");
                }}
                urlView={
                    CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_LIST.replace(
                        ":id",
                        //@ts-ignore
                        params?.id
                    ) + "?tab=inviteList"
                }
            />
        </>
    );
};

export default InviteMember;
