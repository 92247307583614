import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const NumberAndUnit = ({
  label,
  fontSize,
  selectData,
  error,
  selectFieldName,
  selectFieldOnChange,
  selectFieldValue,
  txtFieldName,
  txtFieldValue,
  txtFieldOnChange,
  onClickMinus,
  onClickPlus,
  txtOnBlur
}: any) => {

  // Remove up/down arrow in number text field
  const useStyles: any = makeStyles({
    input: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
    },
    button: {
      minWidth: 20,
      minHeight: 20,
      border: 'none',
      textAlign: 'center',
      margin: 5,
      padding: 0,
      background: 'black',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      color: 'white'
    }
  })
  const classes = useStyles();

  return (
    <Box>
      <Grid container alignItems="center" >
        <Grid item xs={12} sm={12} md={12} mb="8px">
          <Box flexShrink={0} mr="30px">
            <Typography component="label" variant="h6">{label}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={2} >

            <Grid item xs={6} alignItems="center" pl="14px">
              <Box display="flex" alignItems="center">

                <button type="button"
                  style={{ marginRight: "15px" }}
                  className={classes.button}
                  onClick={onClickMinus}>
                  <div className={classes.buttonText}>-</div>
                </button>

                <TextField
                  name={txtFieldName}
                  type="number"
                  className={classes.input} fullWidth
                  onBlur={txtOnBlur}
                  onChange={txtFieldOnChange}
                  value={txtFieldValue}
                  InputProps={{ inputProps: { min: 0, step: "0.01", style: { textAlign: 'center', color: "#F5490F" } } }}
                  sx={{ background: "#FFFFFF", }}
                  variant="outlined" size="small"
                />

                <button type="button"
                  style={{ marginLeft: "15px" }}
                  className={classes.button}
                  onClick={onClickPlus}>
                  <div className={classes.buttonText}>+</div>
                </button>

              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth size="small" variant="outlined">
                <Select name={selectFieldName}
                  value={selectFieldValue} onChange={selectFieldOnChange}
                  sx={{ background: "#FFFFFF", }}
                  inputProps={{
                    style: {
                      height: "50px",
                      padding: '0 14px',
                      fontSize
                    },
                  }}
                >
                  {selectData.map((item: { value: string | number, label: string }) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {error && <Box color="red" fontSize={12} ml={3}>{error}</Box>}
          </Grid>
        </Grid>

      </Grid>
    </Box>
  )
}

export default NumberAndUnit;