export const getUrlVars = () => {
    let vars: any = {}
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m: string, key: string, value: string) {
        vars[key] = value
        return ''
      }
    )
    return vars
  }
