import React, {useEffect} from "react";
import { UserAppContext, Action } from "contexts";


const useHandleActivePage = (SIDEBAR_PAGEID: number) => {

  const { globalDispatch, global } = React.useContext(UserAppContext);
  useEffect(() => {
      if (global.current_page !== SIDEBAR_PAGEID) {
          globalDispatch(
              Action.global.setState({
                  current_page: SIDEBAR_PAGEID,
              })
          );
      }
  }, [global.current_page, globalDispatch]);

}

export default useHandleActivePage