import React, { useContext, useMemo, useState } from "react";
import {
    Button,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
} from "@mui/material";
import styles from "./RegisterBetaAccessForm.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "Components/FormElements/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import { validationRegisterAccessFormSchema } from "./RegisterAccessForm.validate";
import { handleRenderErrorLabel } from "utils/handleRenderError";
import { useCreateBetaRequestMutation } from "services/webServer";
import Swal from "sweetalert2";
import { UserAppContext } from "contexts";
import { handleRenderTitleLoading } from "utils/handleRenderTitle";
import { useWindowSize } from "scripts/useWindowSize";

const checkBtnUrl = "/images/icons/check-radio-circle.svg";
const uncheckBtnUrl = "/images/icons/uncheck-radio-btn.svg";

interface RegisterBetaAccessFormProps {
    handleSetSteps: (value: number) => void;
}

const initialValues = {
    discord: "",
    workoutPerWeek: "",
    machineSerial: "",
    isAgree: true,
};

const RegisterBetaAccessForm = (props: RegisterBetaAccessFormProps) => {
    const windowSize = useWindowSize();
    const isModile = windowSize.width < 767;

    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const { auth } = useContext(UserAppContext);
    const userId = useMemo(() => auth?.id || "", [auth]);
    const [createBetaRequest, { loading }] = useCreateBetaRequestMutation();
    const { handleSetSteps } = props;
    const radioOptions = useMemo(
        () => [
            {
                id: 0,
                label: "0-1",
                value: "0-1",
            },
            {
                id: 1,
                label: "2-3",
                value: "2-3",
            },
            {
                id: 2,
                label: "4-5",
                value: "4-5",
            },
            {
                id: 3,
                label: "6-7",
                value: "6-7",
            },
            {
                id: 4,
                label: "7+",
                value: "7+",
            },
        ],
        []
    );

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationRegisterAccessFormSchema,
        validateOnChange: shouldValidateOnChange,
        validateOnBlur: true,
        validateOnMount: false,
        onSubmit: async (values) => {
            try {
                const { data } = await createBetaRequest({
                    variables: {
                        userId,
                        discord: values.discord,
                        machineSerial: values.machineSerial,
                        workoutPerWeek: values.workoutPerWeek,
                    },
                });
                if (data) {
                    handleSetSteps(2);
                }
            } catch (error) {
                Swal.fire("Oops", "The application encountered an error. Please try again later.", "error");
            }
        },
    });

    React.useEffect(() => {
        formik.resetForm();
    }, []);

    return (
        <Paper
            sx={{
                padding: { xs: "16px", md: "20px" },
                marginBottom: "20px",
                boxShadow: "none",
                objectFit: "contain",
                background: "transparent"
            }}
        >
            <div className={styles["form-request"]}>
                <div className={styles["form-request-header"]}>
                    <div
                        className={styles["form-request-icon"]}
                        onClick={() => handleSetSteps(0)}
                    >
                        <ArrowBackIosIcon />
                    </div>
                    <h2 className={styles["form-request-title"]}>
                        APPLY FOR BETA ACCESS
                    </h2>
                </div>
                <div className={styles["form-request-content"]}>
                    <div className={styles["form-request-content-item"]}>
                        <TextField
                            name="discord"
                            label="What is your Discord Username?"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formik.values.discord}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.discord &&
                            handleRenderErrorLabel(formik.errors.discord)}
                    </div>

                    <div className={styles["form-request-content-item"]}>
                        <TextField
                            name="machineSerial"
                            placeholder={
                                isModile
                                    ? 'Start with "ATS"'
                                    : `Located under your screen and should start with "ATS"`
                            }
                            label="What is your Rower's Serial Number (S/N)?"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formik.values.machineSerial}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.machineSerial &&
                            handleRenderErrorLabel(formik.errors.machineSerial)}
                    </div>
                    <div className={styles["form-request-content-item"]}>
                        <p>
                            How many workouts per week do you complete at least?
                        </p>
                        <div>
                            <RadioGroup>
                                <div
                                    className={
                                        styles[
                                        "form-request-content-radio-options"
                                        ]
                                    }
                                >
                                    {radioOptions.map(
                                        (item: {
                                            value: string | number;
                                            label: string;
                                        }) => (
                                            <FormControlLabel
                                                name="workoutPerWeek"
                                                className={
                                                    styles[
                                                    "form-request-content-radio-options__item"
                                                    ]
                                                }
                                                onChange={formik.handleChange}
                                                label={item.label}
                                                value={
                                                    formik.values.workoutPerWeek
                                                }
                                                control={
                                                    <Radio
                                                        value={item.value}
                                                        checkedIcon={
                                                            <img
                                                                style={{
                                                                    width: 18,
                                                                    height: 18,
                                                                }}
                                                                src={
                                                                    checkBtnUrl
                                                                }
                                                                loading="eager"
                                                                alt="img"
                                                            />
                                                        }
                                                        icon={
                                                            <img
                                                                style={{
                                                                    width: 18,
                                                                    height: 18,
                                                                }}
                                                                src={
                                                                    uncheckBtnUrl
                                                                }
                                                                loading="eager"
                                                                alt="img"

                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            </RadioGroup>
                            {formik.errors.workoutPerWeek &&
                                handleRenderErrorLabel(
                                    formik.errors.workoutPerWeek
                                )}
                        </div>
                    </div>
                    <div className={styles["form-request-content-item"]}>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <Checkbox
                                style={{
                                    marginRight: 10,
                                    marginBottom: 30,
                                }}
                                name="isAgree"
                                onChange={formik.handleChange}
                                defaultChecked={true}
                            />
                            <p
                                style={{
                                    fontSize: 16,
                                    fontFamily: "Work Sans",
                                    fontWeight: 400,
                                }}
                            >
                                Do you agree to keep all BETA-related
                                information confidential, and refrain from
                                sharing it with anyone outside of your
                                designated Aviron Beta Testing Group and Aviron
                                Employees? This includes avoiding any
                                discussions or postings about it on social
                                media.
                            </p>
                        </div>
                    </div>
                    <div className={styles["form-request-content-button"]}>
                        <Button
                            variant="contained"
                            disabled={
                                Boolean(!formik.values.isAgree) || loading
                            }
                            onClick={() => {
                                setShouldValidateOnChange(true);
                                formik.handleSubmit();
                            }}
                            sx={{
                                background: "#FF4106",
                                borderRadius: "12px",
                                color: "#fff",
                                width: "100%",
                            }}
                        >
                            {handleRenderTitleLoading(
                                "COMPLETE APPLICATION",
                                loading
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </Paper>
    );
};

export default RegisterBetaAccessForm;
