import { useState } from "react";
import { gameServerApi } from 'services/gameServerV2';

const useStatisticWorkoutSummaryHook = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const getStatisticWorkoutSummary = async (timeRangeId: number) => {
        setLoading(true)
            gameServerApi.workoutHistory
                .getStatisticWorkoutSummary(timeRangeId)
                .then((res) => {
                    if(res && res.status === 200) {
                        //@ts-ignore
                        setData(res.data?.data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
    }
    // console.log({GameUserInfo})
    return {
        getStatisticWorkoutSummary,
        data,
        loading,
    };
};
export default useStatisticWorkoutSummaryHook;