import React, { useEffect, useState } from "react";
import style from "./WeekStreakCard.module.scss";
import CardSimple from "Components/CardSimple";
import WeekStreakLevels from "Components/WeekStreakLevels";
import { gameServerApi } from "services/gameServerV2";
import _ from "lodash";

const WeekStreakCard = (props: any) => {
    const [weekStreak, setWeekStreak] = useState({
        value: 0,
        bestValue: 0,
        metadata: null,
    });

    const handleQueryData = () => {
        gameServerApi.user.getUserRecord(["week_streak"]).then((res: any) => {
            if (res?.data && !_.isEmpty(res.data)) {
                setWeekStreak(res?.data?.week_streak);
            } else {
                setWeekStreak({
                    value: 0,
                    bestValue: 0,
                    metadata: null,
                });
            }
        });
    };

    useEffect(() => {
        handleQueryData();
    }, []);
    const { value: streak, bestValue: bestStreak, metadata } = weekStreak;
    return (
        <div className={style["WeekStreakCardWrapper"]}>
            <CardSimple>
                <div className={style["weekStreak"]}>
                    <div className={style["achieve"]}>
                        <div className={style["iconWrapper"]}>
                            <WeekStreakLevels
                                level={streak || 0}
                                metadata={metadata}
                            />
                        </div>
                    </div>
                    <div className={style["rightContent"]}>
                        <div className={style["text"]}>
                            {streak < 1
                                ? "START NEW STREAK"
                                : streak + "-WEEK STREAK"}
                        </div>
                        {bestStreak > 0 && (
                            <div className={style["bestAchieve"]}>
                                BEST STREAK: <>{bestStreak || 0}</> WEEKS
                            </div>
                        )}
                    </div>
                </div>
            </CardSimple>
        </div>
    );
};

export default WeekStreakCard;
