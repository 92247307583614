import React from "react";
const Icon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M14.168 5.00033H18.3346V6.66699H16.668V17.5003C16.668 17.7213 16.5802 17.9333 16.4239 18.0896C16.2676 18.2459 16.0556 18.3337 15.8346 18.3337H4.16797C3.94695 18.3337 3.73499 18.2459 3.57871 18.0896C3.42243 17.9333 3.33464 17.7213 3.33464 17.5003V6.66699H1.66797V5.00033H5.83464V2.50033C5.83464 2.27931 5.92243 2.06735 6.07871 1.91107C6.23499 1.75479 6.44695 1.66699 6.66797 1.66699H13.3346C13.5556 1.66699 13.7676 1.75479 13.9239 1.91107C14.0802 2.06735 14.168 2.27931 14.168 2.50033V5.00033ZM7.5013 9.16699V14.167H9.16797V9.16699H7.5013ZM10.8346 9.16699V14.167H12.5013V9.16699H10.8346ZM7.5013 3.33366V5.00033H12.5013V3.33366H7.5013Z"
                fill="#E60A32"
            />
        </svg>
    );
};

export default Icon;
