import React from "react";
import UserCard from "Components/MembershipPage/UserCard";
import MachineId from "Components/MembershipPage/MachineId";

export const initFamilyData = [
    // {
    //     userId: "",
    //     username: "",
    //     email: "",
    //     customId: "",
    //     avatarUrl: "",
    //     machines: [],
    //     devices: [],
    //     role: "",
    // },
];

export const formatFamillyRows = (data: any) => {
    const res = [];
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        res.push({
            selection: {
                key: `row${i}`,
                value: "",
                rowData: item,
                isBlock: item.role === "Owner",
            },
            user: {
                value: (
                    <UserCard
                        email={item?.email}
                        avatar={item?.avatarUrl}
                        username={item?.username}
                    />
                ),
                key: `row${i}`,
            },
            role: { value: item?.role, key: `row${i}` },
            machineId: {
                value: <MachineId textArray={item?.machines || ['---']} />,
                key: `row${i}`,
            },
            deviceIds: { value: <MachineId textArray={item?.deviceIds || ['---']} />, key: `row${i}` },
        });
    }
    return res;
};

export const formatMembershipFamilly = (data: any) => {
    if (data) {
        const members = data?.members || [];
        let res = [];
        for (let i = 0; i < members.length; i++) {
            const member = members[i];
            res.push({
                ...member,
                role:
                    String(member?.userId) ===
                    String(data?.currentMembership?.owner?.userId)
                        ? "Owner"
                        : "Member",
            });
        }
        return res;
    } else {
        return initFamilyData;
    }
};
