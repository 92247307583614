import { Backdrop, Fade, Modal, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import "./style.scss";

const useStyles: any = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const SuccessModal: React.FunctionComponent<{
  open: boolean,
  onClose: any,
  title?: string,
  message: string
}> = ({ open, onClose, title, message }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="success-modal-component">

          <button className="close-btn" onClick={onClose}>
            <img src="/images/icons/x-icon.svg" width={18} height={18} alt="icon" />
          </button>
          <Box textAlign="center" >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src="/images/icons/aviron-logo.svg" width={48} height={48} alt="icon" />
            </div>
            {title && <Typography component="h3" variant="h3" textAlign="center" pt="16px">{title}</Typography>}
            <div style={{ padding: '16px 30px 0', textAlign: 'center' }}>
              {message}
            </div>
          </Box>
        </div>
      </Fade>
    </Modal>
  )
}

export default SuccessModal;