import { Box, Grid } from '@mui/material';
import PageTitle from 'Components/PageTitle';
import React from 'react';
import { Helmet } from 'react-helmet';
import StravaCard from './StravaCard';
import LogbookCard from './LogbookCard';
import FitbitCard from './FitbitCard';
import useHandleActivePage from 'utils/useHandleActivePage';
import { CONSTANTS } from 'consts';
import MobileDownload from './MobileDownload'

const IntegrationMain: React.FunctionComponent = () => {

    useHandleActivePage(CONSTANTS.SIDEBAR_PAGEID.INTEGRATIONS);

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Integrations | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            {/* <Box sx={{
                marginBottom: "16px",
            }}>
                <PageTitle>Integrations</PageTitle>
            </Box> */}
            <Box>
                <Grid container sx={{
                    display: "flex",
                    gap: "16px",
                    justifyContent: "center"
                    // gridTemplateColumns: "1fr 1fr 1fr",
                    // gridColumnGap: "16px",
                    // columnGap: "16px",
                    // gridAutoRows: "auto"
                }}>
                    <MobileDownload/>
                    {/* <Grid item xs={12} md={4}>
                        <StravaCard />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <LogbookCard />
                    </Grid> */}
                </Grid>
            </Box>
        </div>
    )
}

export default IntegrationMain;