import { CONSTANTS } from "consts";
import React from "react";
import { IRouteItem } from "routes/useRouteElement.constant";
import { handleRenderRouteElement } from "routes/useRouteElement.hook";
import useHandleActivePage from "utils/useHandleActivePage";

interface IWrapperOrder {
    routes: IRouteItem[];
}
const WrapperOrder = ({ routes }: IWrapperOrder) => {
    useHandleActivePage(CONSTANTS.SIDEBAR_PAGEID.ORDERS);

    return (
        <div className="page-content">{handleRenderRouteElement(routes)}</div>
    );
};
export default WrapperOrder;
