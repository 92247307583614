import { CheckoutAction } from "./checkout/checkout.action";
import { GlobalAction } from "./globals/global.action";
import { HeaderAction } from "./headers/header.action";
import { ProductAction } from "./products/product.action";
import { ProfileAction } from "./profiles/profile.action";
import { SubsciptionAction } from "./subsciptions/subscription.action";
import { AuthAction } from "./auth/auth.action";

class Action {
    static checkout = new CheckoutAction()
    static global = new GlobalAction()
    static header = new HeaderAction()
    static product = new ProductAction()
    static profile = new ProfileAction()
    static subscription = new SubsciptionAction()
    static auth = new AuthAction();
}
export default Action
