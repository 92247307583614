import List from "@mui/material/List";
import ListItemButton, {
    ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { CONSTANTS } from "consts";
import { UserAppContext } from "contexts";
import * as React from "react";
import styles from "./sidebarDesktop.module.scss";
import { useLocation } from "react-router-dom";
import useWindowSize from "Hooks/useWindowSize";
import MeasurementUnit from "Components/MeasurementUnit";

const SidebarItem = styled(ListItemButton)<ListItemButtonProps>(
    ({ theme }) => ({
        height: "3.143rem",
        borderRadius: "5.714rem",
        textAlign: "center",
        margin: 0,
        fontSize: "1rem",
        textTransform: "uppercase",
        fontFamily: "GRIFTER !important",
        fontWeight: 700,
        letterSpacing: "0.01em",
        lineHeight: "1.143",
        padding: "0.857rem 0.857rem 0.571rem 0.857rem",
        wordBreak: "keep-all",
        display: "flex",
        color: "#4F4F4F",
        "&:hover": {
            background: theme.palette.primary.light,
            // color: theme.palette.secondary.contrastText
        },
    })
);

const NavUI: React.FunctionComponent<{
    clickMenu?: (pageId: number) => void;
}> = ({ clickMenu = () => {} }) => {
    const {
        global: { current_page: currentPage },
    } = React.useContext(UserAppContext);
    const windowSize = useWindowSize();
    const location = useLocation();
    React.useEffect(() => {
        if (location.pathname && windowSize?.width > 1250) {
            setTimeout(() => {
                const navBarElement = document.getElementById(
                    location.pathname
                );
                navBarElement?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }, 500);
        }
    }, [location]);
    const appId = localStorage.getItem("appId");
    const isAvironBike = Number(appId || 0) === CONSTANTS.APP.AVIRON_BIKE;
    return (
        <div
            className={
                styles["sidebarDesktopWrapper"] + " sidebarDesktopWrapper"
            }
            id={"sidebarDesktopWrapperId"}
        >
            <Box
                className={styles.sidebarMenu}
                sx={{
                    height: "100%",
                    display: {
                        xs: "block",
                        md: "inline-block",
                    },
                    flexDirection: "row",
                }}
            >
                <List
                    sx={{
                        width: "auto",
                        background: "#fff",
                        padding: "0px 0px",
                        position: "sticky",
                        display: "flex",
                    }}
                    className="listMenuWrapper"
                >
                    <SidebarItem
                        className={`${
                            currentPage === CONSTANTS.SIDEBAR_PAGEID.PROFILE
                                ? styles.menuItemActive
                                : ""
                        }`}
                        onClick={clickMenu.bind(
                            null,
                            CONSTANTS.SIDEBAR_PAGEID.PROFILE
                        )}
                        id={CONSTANTS.NAVIGATION_URL.PROFILE}
                    >
                        <ListItemText primary="Profile" disableTypography />
                    </SidebarItem>
                    <SidebarItem
                        className={`${
                            currentPage ===
                            CONSTANTS.SIDEBAR_PAGEID.WORKOUT_HISTORY
                                ? styles.menuItemActive
                                : ""
                        }`}
                        onClick={clickMenu.bind(
                            null,
                            CONSTANTS.SIDEBAR_PAGEID.WORKOUT_HISTORY
                        )}
                        id={CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY}
                    >
                        <ListItemText
                            primary="Workout history"
                            disableTypography
                        />
                    </SidebarItem>
                    <SidebarItem
                        className={`${
                            currentPage === CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIPS
                                ? styles.menuItemActive
                                : ""
                        }`}
                        onClick={clickMenu.bind(
                            null,
                            CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIPS
                        )}
                        id={CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}
                    >
                        <ListItemText primary="Memberships" disableTypography />
                    </SidebarItem>
                    <SidebarItem
                        className={`${
                            currentPage === CONSTANTS.SIDEBAR_PAGEID.ORDERS
                                ? styles.menuItemActive
                                : ""
                        }`}
                        onClick={clickMenu.bind(
                            null,
                            CONSTANTS.SIDEBAR_PAGEID.ORDERS
                        )}
                        id={CONSTANTS.NAVIGATION_URL.ORDERS}
                    >
                        <ListItemText primary="Orders" disableTypography />
                    </SidebarItem>
                    <SidebarItem
                        className={`${
                            currentPage ===
                            CONSTANTS.SIDEBAR_PAGEID.PAYMENT_METHOD
                                ? styles.menuItemActive
                                : ""
                        }`}
                        onClick={clickMenu.bind(
                            null,
                            CONSTANTS.SIDEBAR_PAGEID.PAYMENT_METHOD
                        )}
                        id={CONSTANTS.NAVIGATION_URL.PAYMENT_METHOD}
                    >
                        <ListItemText
                            primary="Payment method"
                            disableTypography
                        />
                    </SidebarItem>
                    <SidebarItem
                        className={`${
                            currentPage ===
                            CONSTANTS.SIDEBAR_PAGEID.INTEGRATIONS
                                ? styles.menuItemActive
                                : ""
                        }`}
                        onClick={clickMenu.bind(
                            null,
                            CONSTANTS.SIDEBAR_PAGEID.INTEGRATIONS
                        )}
                        id={CONSTANTS.NAVIGATION_URL.INTEGRATIONS}
                    >
                        <ListItemText
                            primary="Integrations"
                            disableTypography
                        />
                    </SidebarItem>
                        {windowSize?.width <= 1250 && isAvironBike &&
                            <div className={styles['measureItem']}>
                                <MeasurementUnit />
                            </div>
                        }
                </List>
            </Box>
        </div>
    );
};
export default NavUI;
