import { Box } from '@mui/material';
import { CONSTANTS } from 'consts';
import { UserAppContext } from 'contexts';
import useGetCrmSessionHook from 'Hooks/CrmSessionHook';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { ENV } from 'scripts/environment';
import { CustomError, getExceptionFromError } from 'scripts/utils/handleErrors';
import { useConnectFitbitMutation } from 'services/crmServer';
import Swal from 'sweetalert2';
import { getUrlVars } from 'utils/url';

const Fitbit = () => {
    const history = useHistory()
    const { auth } = useContext(UserAppContext);

    const [connectFitbit] = useConnectFitbitMutation();

    const { crmToken } = useGetCrmSessionHook();

    useEffect(() => {
        const params = getUrlVars();
        console.log("params: ", params)
        if (params && auth.crmUserId && params.code) {
            connectFitbitIntoCRM(params)
        } else if (params && auth.crmUserId && !params.code) {
            Swal.fire("Error", "You didn't authenticate with Fitbit", 'error').then(() => {
                history.push(CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY)
            })
        }
    }, [auth])

    const connectFitbitIntoCRM = async (params: any) => {
        Swal.showLoading()
        try {
            const REDIRECT_URI: string = window.location.origin + ENV.FITBIT.REDIRECT_URL;
            await connectFitbit({
                variables: {
                    code: params.code
                },
                context: {
                    clientName: "crm-server",
                    headers: {
                        "authorization": "Bearer " + crmToken
                    }
                }
            })
            await Swal.fire("Successfully Synced", "", 'success');
            history.push(CONSTANTS.NAVIGATION_URL.INTEGRATIONS)
            // askSyncAllWorkouts()
        } catch (err) {
            const errDetail = getExceptionFromError(err) as CustomError;
            if (errDetail && errDetail.key === "fitbit_has_connected") {
                await Swal.fire("Error", "This Fitbit account has been connected with another Aviron account.", 'error')
            } else {
                await Swal.fire("Error", "Connect to Fitbit failed", 'error')
            }
            history.push(CONSTANTS.NAVIGATION_URL.INTEGRATIONS)
        }
    }

    return <Box sx={{
        width: "fit-content",
        margin: "32px auto",
        textAlign: "center"
    }}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Fitbit connect | Aviron</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        {/* <Typography mt="24px" mb="16px" variant="h4" component="h4">Fitbit connecting...</Typography>
        <Typography mb="16px" variant="body1" component="p">We will sync workout to your Fitbit account.</Typography> */}
    </Box>
}

export default Fitbit;