import React, { useState } from "react";
import style from "./MachineId.module.scss";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useWindowSize } from "scripts/useWindowSize";

export const handleRenderModelByAppIds = (appIds: Array<string>) => {
    let res = "";
    for (let i = 0; i < appIds.length; i++) {
        if (i !== appIds.length - 1) {
            res = res + appIds[i] + ", ";
        } else {
            res = res + appIds[i];
        }
    }
    return res;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      background: 'white',
      boxShadow: '0px 4px 20px 0px #8D8D8D33',
      padding: '16px',
      borderRadius: '8px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
        boxShadow: '0px 4px 20px 0px #8D8D8D33',
      },
  });

interface ModelsProps {
    textArray: Array<string>;
}
const MachineId = ({ textArray }: ModelsProps) => {
    const renderContentTooltip = (arr: Array<string>) => {
        return <ul className={style['contentTooltip']}>
            {arr.map((item, key)=>{
                return <li key={key}>
                    {item}
                </li>
            })}
        </ul>
    };

    const appIdsText = handleRenderModelByAppIds(textArray);
    const contentToolTip = renderContentTooltip(textArray);
    const windowSize = useWindowSize();
    // const [openAlert, setOpentAlert] = useState(false);
    // const coppyText = (event: any) => {
    //     event.stopPropagation();
    //     if (event.target.value === event.currentTarget.value && !openAlert) {
    //         navigator.clipboard.writeText(appIdsText);
    //         setOpentAlert(true);
    //         setTimeout(() => {
    //             setOpentAlert(false);
    //         }, 800);
    //         return false;
    //     }
    // };
    return (
        <div className={style["MachineId"]}>
            {windowSize?.width >=900 ?
                     <CustomWidthTooltip
                     title={contentToolTip}
                     arrow={true}
                     placement="top-end"
                 >
                     <div className={style["machineRow"]}>
                         <div className={style["shortText"]}>{appIdsText}</div>
                         {textArray.length > 1 && (
                             <div className={style["numberText"]}>
                                 +{textArray.length - 1}
                             </div>
                         )}
                     </div>
                 </CustomWidthTooltip> :
                <div>{appIdsText}</div>
        }
        </div>
    );
};

export default MachineId;
