import { CONSTANTS } from 'consts';
import { BaseReducer } from '../base/base.reducer';

class SubscriptionReducer extends BaseReducer {
    constructor() {
        super("SUBSCRIPTION", {
            current_tab: CONSTANTS.SUBSCRIPTION_PAGE.CURRENT_TAB.NONE,
        });
    }
}

const subscriptionReducer = new SubscriptionReducer();

export { subscriptionReducer };
