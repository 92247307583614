import {
    Backdrop,
    Box,
    Button,
    Fade,
    IconButton,
    Modal,
    Typography,
    Grid
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import "./style.scss";
import Close from "@mui/icons-material/Close";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});


const ReactiveMembershipNoticeModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
}> = ({ open, onClose }) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="reactive-membership-notice-component">
                    <IconButton onClick={onClose} className="close-button">
                        <Close></Close>
                    </IconButton>
                    <div className="reactive-membership-notice-component__main">
                        <Box className="reactive-membership-notice-component__main__head">
                            <Grid container>
                                <Grid item xs={6}>
                                    <img src="https://avironactive.com/assets/icon/aviron-logo.svg" alt="" className="reactive-aviron-logo"/>
                                    <h3>WELCOME BACK</h3>
                                    <h2>30 DAYS<br/>FREE</h2>
                                </Grid>
                                <Grid item xs={6}>
                                    <img src="/images/membership/hand-gift.png" alt="" width="100%"/>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            textAlign={"center"}
                            className="reactive-membership-notice-component__main__item reactive-membership-notice-component__main__item--content"
                        >
                            <Typography fontSize={"16px"} mb="12px">
                                We've missed you! We've added a ton of new product features since we last saw you, including streaming service expansion, Apple Watch integration, new addictive games and programs, and so much more.
                            </Typography>
                            <Typography fontSize={"16px"} mb="12px">
                                Enjoy <b>30 days free</b> on us to experience Aviron's latest features for yourself. After this screen, <b>simply select a previous membership option</b> so we know which machine to apply the free membership to.
                            </Typography>
                            <Typography fontSize={"16px"}>
                                Don't miss out, this deal expires soon. Thank you!
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={onClose}
                                color="secondary"
                                sx={{
                                    marginTop: "56px",
                                    minWidth: "280px !important",
                                }}
                            >
                                OKAY
                            </Button>
                            <br />
                        </Box>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ReactiveMembershipNoticeModal;
