import produce from "immer";
import {
    GET_MACHINE_LIST_REQUEST,
    GET_MACHINE_LIST_SUCCESS,
    GET_MACHINE_LIST_FAILED,
} from "./machine.type";

export interface initialTypeMarchine {
    id: number;
    name: string;
    group: string;
    image: string;
}
const initialState: {
    data: initialTypeMarchine[];
    loading: boolean;
} = {
    data: [],
    loading: false,
};

type actionType = {
    type: string;
    payload: any;
};
const reducer = produce((state, action: actionType) => {
    switch (action.type) {
        case GET_MACHINE_LIST_REQUEST: {
            state.loading = true;
            state.data = [];
            return state;
        }
        case GET_MACHINE_LIST_SUCCESS: {
            state.loading = false;
            state.data = action.payload;
            return state;
        }

        case GET_MACHINE_LIST_FAILED: {
            state.loading = false;
            state.data = [];
            return state;
        }

        default:
            return state;
    }
}, initialState);

export const machineReducer = { initialState, reducer };
