import axios, { AxiosRequestConfig } from 'axios';
import { Action, UserAppContext } from 'contexts';
import moment from 'moment';
import { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';

const useGetCrmSessionHook = () => {
    const localToken = localStorage.getItem("crmToken");
    const [token, setToken] = useState<string | null>(localToken);
    const { authDispatch, auth } = useContext(UserAppContext);

    useEffect(() => {
        if (auth.crmToken && auth.crmTokenExpires) {
            if (moment(auth.crmTokenExpires).isBefore(moment())) {
                getCrmSession()
            } else {
                setToken(auth.crmToken);
            }
        }
    }, [auth])

    const getCrmSession = async (isRedirect: boolean = true) => {
        console.log("get crm session")
        try {
            const options: AxiosRequestConfig = {
                url: process.env.REACT_APP_SERVER_DOMAIN + "/graphql",
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    query: `
                        query getCrmSession {
                            getCrmSession {
                                accessToken
                                refreshToken
                                expires
                            }
                        }
                    `,
                    variables: {
                    }
                }),
                withCredentials: true
            }

            const res = await axios(options);
            if (res.data) {
                setToken(res.data.data.getCrmSession.accessToken);
                authDispatch(Action.auth.setCrmSession({
                    token: res.data.data.getCrmSession.accessToken,
                    expires: moment.unix(Number(res.data.data.getCrmSession.expires))
                }));
                localStorage.setItem("crmToken", res.data.data.getCrmSession.accessToken)
            }
            else {
                if (isRedirect) {
                    redirectPage()
                }
            }

        } catch (err) {
            if (isRedirect) {
                redirectPage()
            }
        };

    }

    const redirectPage = async () => {
        await Swal.fire("Please log in", "We will redirect you back to our login page where you can log into your account", "info");
        // window.location.href = `${ENV.STOREFRONT_DOMAIN}?redirect=${window.location.href}`;
        window.location.href = `/login?redirect=${window.location.href}`;
    }

    return {
        crmToken: token,
        getCrmSession
    };
}

export default useGetCrmSessionHook;