import React, {
    Ref,
    forwardRef,
    useContext,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import {
    Backdrop,
    Box,
    Button,
    Fade,
    FormControl,
    Modal,
    Stack,
    TextField,
    Typography,
    Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import styles from "./RegistaBetaModalCancel.module.scss";
import { useLeaveBetaProgramMutation } from "services/webServer";
import { handleRenderTitleLoading } from "utils/handleRenderTitle";
import { useFormik } from "formik";
import { validationRegisterBetaModalCancel } from "./RegisterBetaModalCancel.validate";
import Swal from "sweetalert2";
import { handleRenderErrorLabel } from "utils/handleRenderError";
import { UserAppContext } from "contexts";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        justifyContent: "center",
        minHeight: 100,
        maxHeight: 500,
        marginTop: 80,
    },
});

export interface RefModal {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RegisterBetaModalCancelProps {
    handleSetSteps: (value: number) => void;
}
const initialValues = {
    reason: "",
    otherReason: "",
};

const RegisterBetaModalCancel = (
    props: RegisterBetaModalCancelProps,
    ref: Ref<RefModal>
) => {
    const classes = useStyles();

    const { auth } = useContext(UserAppContext);
    const userId = useMemo(() => auth?.id, [auth]);

    const { handleSetSteps } = props;
    const [isVisible, setIsVisible] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

    const [leaveBetaProgram, { loading }] = useLeaveBetaProgramMutation();

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationRegisterBetaModalCancel,
        validateOnChange: shouldValidateOnChange,
        validateOnBlur: true,
        validateOnMount: false,
        onSubmit: async (values) => {
            const { otherReason, reason } = formik.values;
            const cancelReason = reason === "other" ? otherReason : reason;

            try {
                await leaveBetaProgram({
                    variables: {
                        reason: cancelReason,
                        userId,
                    },
                });
                handleCloseModal();
                handleSetSteps(0);
            } catch (error) {
                Swal.fire("Error", "Submit cancel request failed", "error");
            }
        },
    });
    const optionList = useMemo(
        () => [
            // {
            //     label: "Select options",
            //     value: "",
            //     disabled: true,
            // },
            {
                label: "Technical Issues",
                value: "Technical Issues",
            },
            {
                label: "Unmet Expectations",
                value: "Unmet Expectations",
            },
            {
                label: "Lack of Time",
                value: "Lack of Time",
            },
            {
                label: "Lack of Interest",
                value: "Lack of Interest",
            },
            {
                label: "Feedback Fatigue",
                value: "Feedback Fatigue",
            },
            {
                label: "Data Loss Issues",
                value: "Data Loss Issues",
            },
            {
                label: "Other",
                value: "other",
            },
        ],
        []
    );

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    const handleCloseModal = () => {
        setIsVisible(false);
    };

    return (
        <Modal
            open={isVisible}
            onClose={handleCloseModal}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isVisible}>
                <div className={styles["register-beta-modal-cancel"]}>
                    <div
                        className={styles["register-beta-modal-cancel__close"]}
                    >
                        <button className="" onClick={handleCloseModal}>
                            <img
                                src="/images/icons/x-icon.svg"
                                width={18}
                                height={18}
                                alt="icon"
                            />
                        </button>
                    </div>

                    <div style={{ padding: "20px", outline: "none" }}>
                        <h2
                            style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                color: "#161616",
                                fontFamily: "GRIFTER",
                            }}
                        >
                            WITHDRAW FROM THE BETA PROGRAM
                        </h2>
                        <p
                            style={{
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: "Work Sans",
                                color: "#161616",
                            }}
                        >
                            We're sorry to see you go! We truly appreciate all your efforts in helping us grow. If you could, we'd love to hear your feedback about your BETA experience.
                        </p>
                        <FormControl fullWidth size="small" variant="outlined">
                            <Typography fontSize={16}>
                                Please choose your reason for canceling
                            </Typography>
                            <br />
                            <Select
                                name="reason"
                                required
                                placeholder="Select a reason"
                                // error={!!formError.reason}
                                size="medium"
                                displayEmpty
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                value={formik.values.reason}
                                onChange={formik.handleChange}
                                renderValue={
                                    formik.values.reason !== ""
                                        ? undefined
                                        : () => <span>Select a reason</span>
                                }
                            >
                                {optionList.map((item, index) => (
                                    <MenuItem value={item.value} key={index}>
                                        <span>{item.label}</span>
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.errors.reason &&
                                handleRenderErrorLabel(formik.errors.reason)}
                        </FormControl>
                        {formik.values.reason === "other" && (
                            <Box margin="20px 0 32px 0">
                                <Typography fontSize={16}>
                                    Please provide any additional details here
                                </Typography>
                                <br />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="otherReason"
                                    value={formik.values.otherReason}
                                    onChange={formik.handleChange}
                                    multiline={true}
                                    maxRows={4}
                                />
                                {formik.errors.otherReason &&
                                    handleRenderErrorLabel(
                                        formik.errors.otherReason
                                    )}
                            </Box>
                        )}
                        <Stack
                            spacing={2}
                            direction={{
                                xs: "column-reverse",
                                md: "row",
                            }}
                            alignItems="end"
                            justifyContent="flex-end"
                            width="100%"
                            sx={{
                                float: "right",
                                marginTop: "40px",
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={handleCloseModal}
                            >
                                EXIT
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setShouldValidateOnChange(true);
                                    formik.handleSubmit();
                                }}
                                color="secondary"
                                disabled={loading}
                            >
                                {handleRenderTitleLoading(
                                    "CANCEL MY BETA",
                                    loading
                                )}
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default forwardRef(RegisterBetaModalCancel);
