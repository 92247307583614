import React, { useMemo } from "react";
import styles from "./AchievementsAll.module.scss";
import AchievementsItem from "../Item";
import {
    AchievementModalData,
    RefModalAchievement,
} from "../Modal/AchievementsModal";
import { separatedItem } from "../Achievements.actions";

const imgAll = "/images/icons/all-achievement-icon.png";
interface AchievementsAllProps {
    separated: separatedItem[];
    setDataModal: React.Dispatch<
        React.SetStateAction<AchievementModalData | undefined>
    >;
    refModal: React.RefObject<RefModalAchievement>;
}

const AchievementsAll = (props: AchievementsAllProps) => {
    const { separated, setDataModal, refModal } = props;

    const totalAchievementReceived = useMemo(() => {
        return separated.reduce((prev, current) => {
            const { value = [] } = current || {};
            return prev + (value?.length || 0);
        }, 0);
    }, [separated]);

    return (
        <div className={styles["achievements-all"]}>
            <div className={styles["achievements-all-title"]}>
                <div className={styles["achievements-all-title-image"]}>
                    <img src={imgAll} alt="img-all" 
                        style={{
                            width:"32px",
                            height:"32px",
                            objectFit:"cover"
                        }} 
                    />
                </div>
                <h4 className={styles["achievements-all-title-text"]}>
                    ALL ACHIEVEMENTS
                </h4>
            </div>
            <div className={styles["achievements-all-content"]}>
                <p>
                    You've successfully earned {totalAchievementReceived}{" "}
                    achievements
                </p>

                {separated.map((item: separatedItem) => {
                    const { typeName, value = [], next = [] } = item || {};

                    return (
                        <div key={item.id}>
                            <h5 className={styles["achievements-category"]}>
                                {typeName.toUpperCase()}
                            </h5>
                            <div className={styles["achievements-list"]}>
                                {value?.map((i: number) => (
                                    <AchievementsItem
                                        key={i}
                                        id={i}
                                        setDataModal={setDataModal}
                                        refModal={refModal}
                                        isCompleted={true}
                                    />
                                ))}
                                {/* List of unachieved achievements  */}
                                {next?.map((i: number) => (
                                    <AchievementsItem
                                        key={i}
                                        id={i}
                                        setDataModal={setDataModal}
                                        refModal={refModal}
                                        isCompleted={false}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AchievementsAll;
