import { CONSTANTS } from 'consts';
import { BaseReducer } from '../base/base.reducer';

class ProductReducer extends BaseReducer {
    constructor() {
        super("PRODUCT", {
            current_tab: CONSTANTS.EQUIPMENT_PAGE.CURRENT_TAB.NONE,
        });
    }
}

const productReducer = new ProductReducer();
export { productReducer };
