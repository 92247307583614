import React from "react";
const Icon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M2.66667 2C1.74167 2 1 2.74167 1 3.66667V13.6667C1 14.1087 1.17559 14.5326 1.48816 14.8452C1.80072 15.1577 2.22464 15.3333 2.66667 15.3333H11.4167C11.1416 14.6732 11 13.9651 11 13.25C11 11.8134 11.5707 10.4357 12.5865 9.41984C13.6023 8.40402 14.9801 7.83333 16.4167 7.83333C16.8377 7.83458 17.2572 7.88492 17.6667 7.98333V3.66667C17.6667 3.22464 17.4911 2.80072 17.1785 2.48816C16.866 2.17559 16.442 2 16 2H2.66667ZM2.66667 3.66667L9.33333 7.83333L16 3.66667V5.33333L9.33333 9.5L2.66667 5.33333V3.66667ZM16 8.66667L14.125 10.5417L16 12.4167V11.1667C16.5525 11.1667 17.0824 11.3862 17.4731 11.7769C17.8638 12.1676 18.0833 12.6975 18.0833 13.25C18.0833 13.5833 18.0083 13.9 17.8667 14.1833L18.775 15.0917C19.125 14.5667 19.3333 13.9333 19.3333 13.25C19.3333 11.4083 17.8417 9.91667 16 9.91667V8.66667ZM13.225 11.4083C12.875 11.9333 12.6667 12.5667 12.6667 13.25C12.6667 15.0917 14.1583 16.5833 16 16.5833V17.8333L17.875 15.9583L16 14.0833V15.3333C15.4475 15.3333 14.9176 15.1138 14.5269 14.7231C14.1362 14.3324 13.9167 13.8025 13.9167 13.25C13.9167 12.9167 13.9917 12.6 14.1333 12.3167L13.225 11.4083Z"
                fill="#161616"
            />
        </svg>
    );
};

export default Icon;
