const ACTION_TYPES: any = {
    GLOBAL: {
        SET_STATE: 'GLOBAL_SET_STATE',
        OPEN_MESSAGE_BOX: 'GLOBAL_OPEN_MESSAGE_BOX',
        CLOSE_MESSAGE_BOX: 'GLOBAL_CLOSE_MESSAGE_BOX',
    },
    HEADER: {
        SET_STATE: 'HEADER_SET_STATE',
        TOOGLE_DRAWER_MENU: 'HEADER_TOOGLE_DRAWER_MENU',
        NAVIGATE_PAGE: "HEADER_NAVIGATE_PAGE"
    },
    PROFILE: {
        SET_STATE: 'PROFILE_SET_STATE'
    },
    EQUIPMENT: {
        SET_STATE: 'EQUIPMENT_SET_STATE'
    },
    PRODUCT: {
        SET_STATE: 'PRODUCT_SET_STATE'
    },
    CHECKOUT: {
        SET_STATE: 'CHECKOUT_SET_STATE'
    },
    AUTH: {
        LOGIN_SUCCESS: "LOGIN_SUCCESS",
        SET_CRM_SESSION: "SET_CRM_SESSION",
        STRAVA_CONNECTED: "AUTH_STRAVA_CONNECTED",
        LOGBOOK_CONNECTED: "AUTH_LOGBOOK_CONNECTED",
        FITBIT_CONNECTED: "AUTH_FITBIT_CONNECTED"
    }
}

export {
    ACTION_TYPES
}
