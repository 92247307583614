import { BaseAction } from "../base/base.action";
import { ACTION_TYPES } from "../types/action-types";

export class HeaderAction extends BaseAction {
    constructor() {
        super("HEADER")
    }
    
    toogleDrawerMenu = (isOpen: boolean) => {
        return { type: ACTION_TYPES.HEADER.TOOGLE_DRAWER_MENU, payload: isOpen };
    };

    navigatePage = (pageId: number) => {
        return { type: ACTION_TYPES.HEADER.NAVIGATE_PAGE, payload: pageId };
    }

}


