import { CONSTANTS } from "consts";
import { removeArrayDuplicates } from "./removeArrayDuplicate";

export const formatAppIds = (appIds: any[]) => {
    if (appIds) {
        for (let i = 0; i < appIds.length; i++) {
            const appId = appIds[i];
            if (
                appId === CONSTANTS.APP.C2_BIKEERG ||
                appId === CONSTANTS.APP.C2_ROWER ||
                appId === CONSTANTS.APP.C2_SKIERG
            ) {
                appIds[i] = 2;
            }
        }
        return removeArrayDuplicates(appIds);
    } else {
        return [];
    }
};
