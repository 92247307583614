

import { IUserProfile } from "routes/useRouteElement.hook";
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS
} from "./userProfile.type";

export const getUserProfileRequest = () => ({
    type: GET_USER_PROFILE_REQUEST,
});

export const getUserProfileSuccess = (
    payload: IUserProfile
) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload,
});

export const getUserProfileFailed = () => ({
    type: GET_USER_PROFILE_FAILED,
});

export const updateUserProfileSuccess = (payload: IUserProfile) => ({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload, 
})