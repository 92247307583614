import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import CurrencyNumber from "Components/CurrencyNumber";
import React from "react";
import { handleRenderPlanName } from "./MembershipDetailForC2.actions";
import { mapProductIdToPlanId } from "./MembershipDetailForC2.constant";
import { handleFormatdateFromEpochTime } from "utils/handleFormatDateFromEpochTime";

const CardFieldTitle = styled(Typography)(({ theme }) => ({
    fontWeight: "500 !important",
    color: "#808080 !important",
    fontFamily: "Work Sans !important",
    fontSize: "12px !important",
}));
const CardFieldContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Work Sans !important",
    fontSize: "14px !important",
    minHeight: "14px",
}));

const ReceiptCardC2: React.FunctionComponent<{
    transactionId: string;
    productId: string;
    startAt: number;
    expireAt: number;
    price: number;
    status: number;
    index: number;
    planList: any[];
}> = ({
    transactionId,
    productId,
    startAt,
    expireAt,
    price,
    status,
    index,
    planList = [],
}) => {
    return (
        <Box
            sx={{
                // height: "90px",
                padding: "16px 0",
                borderBottom: "solid 1px",
                borderTop: index === 0 ? "solid 1px" : "none",
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <CardFieldTitle>ID</CardFieldTitle>
                    <CardFieldContent>{transactionId}</CardFieldContent>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardFieldTitle>Plan</CardFieldTitle>
                    <CardFieldContent>
                        {handleRenderPlanName(
                            mapProductIdToPlanId[productId as string],
                            planList
                        )}
                    </CardFieldContent>
                </Grid>
                <Grid item xs={6}>
                    <CardFieldTitle>Period start</CardFieldTitle>
                    <CardFieldContent>
                        {handleFormatdateFromEpochTime(startAt)}
                    </CardFieldContent>
                </Grid>
                <Grid item xs={6}>
                    <CardFieldTitle>Period end</CardFieldTitle>
                    <CardFieldContent>
                        {handleFormatdateFromEpochTime(expireAt)}
                    </CardFieldContent>
                </Grid>
                <Grid item xs={6}>
                    <CardFieldTitle>Amount</CardFieldTitle>
                    <CardFieldContent>
                        <CurrencyNumber value={price} />
                    </CardFieldContent>
                </Grid>
                <Grid item xs={6}>
                    <CardFieldTitle>Status</CardFieldTitle>
                    <CardFieldContent>Paid</CardFieldContent>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReceiptCardC2;
