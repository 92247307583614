import useGetCrmSessionHook from "Hooks/CrmSessionHook";
import { CONSTANTS } from "consts";
import { Action, UserAppContext } from "contexts";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { mobileDetect } from "scripts/mobileDetect";
import { useCheckFitbitConnectedMutation, useCheckLogbookConnectedMutation, useCheckStravaConnectedMutation, useFitbitSyncWorkoutMutation, useGameSyncWorkoutMutation, useLogbookSyncWorkoutMutation } from 'services/crmServer';
import Swal from 'sweetalert2';
import {
    handleChangeChartOption,
    useHandleGetWorkoutDetail,
    useHandleGetWorkoutList,
} from "../handleActions";

export const useMainHook = () => {
    const isMobile = mobileDetect();
    const history = useHistory();

    const [syncStrava] = useGameSyncWorkoutMutation();
    const [syncLogbook] = useLogbookSyncWorkoutMutation();
    const [syncFitbit] = useFitbitSyncWorkoutMutation();
    const [checkStravaConnected] = useCheckStravaConnectedMutation();
    const [checkLogbookConnected] = useCheckLogbookConnectedMutation();
    // const [checkFitbitConnected] = useCheckFitbitConnectedMutation();
    const { handleGetWorkoutList } = useHandleGetWorkoutList();
    const { handleGetWorkoutDetail } = useHandleGetWorkoutDetail();

    const { crmToken } = useGetCrmSessionHook();

    const { auth, workoutState, authDispatch, workoutDispatch, timezone } =
        useContext(UserAppContext);
    const timeZone = timezone.timezone;
    console.log('auth===', auth)
    const {
        chartData,
        chartOptions,
        workoutMetrics,
        historyList,
        loading,
        currentSelect,
        metaData,
        workoutDetail,
    } = workoutState;
    const [timeRange, setTimeRange] = useState(0);
    const [isExpandActivity, setIsExpandActivity] = useState(!isMobile);
    const [isStravaConnected, setIsStravaConnected] = useState(false);
    const [isLogbookConnected, setIsLogbookConnected] = useState(false);
    const [isFitbitConnected, setIsFitbitConnected] = useState(false);
    const [hideSelectTimeRage, setHideSelectTimeRage] = useState(false);
    const location = useLocation();
    const search = location.search;
    const searchParams = new URLSearchParams(search);
    const pageParam = searchParams.get("page")
        ? Number(searchParams.get("page"))
        : 1;
    const startTime = searchParams.get("startTime") || "";
    const endTime = searchParams.get("endTime") || "";
    const pageSize = 10;

    useEffect(() => {
        // && auth.crmUserId 
        if (auth && crmToken) {
            handleGetWorkoutList(
                {
                    isChangePage: true,
                    timeRange: timeRange,
                    page: pageParam,
                    userData: auth,
                    crmToken,
                    startTime,
                    endTime,
                    pageSize,
                    isGetMetric: false,
                    timeZone,
                },
                workoutDispatch
            );
            if (startTime !== "" && endTime !== "") {
                setHideSelectTimeRage(true);
            }
        }
    }, [location, auth, crmToken]);

    useEffect(() => {
        if (auth && auth.crmUserId && crmToken) {
            checkStravaConnected({
                context: {
                    clientName: "crm-server",
                    headers: {
                        authorization: "Bearer " + crmToken,
                    },
                },
            }).then((result) => {
                if (result && result.data) {
                    setIsStravaConnected(result.data.checkStravaConnected);
                    authDispatch(Action.auth.stravaConnected(true));
                }
            });
        }
    }, [auth, crmToken]);

    useEffect(() => {
        if (auth && auth.crmUserId && crmToken) {
            checkLogbookConnected({
                context: {
                    clientName: "crm-server",
                    headers: {
                        authorization: "Bearer " + crmToken,
                    },
                },
            }).then((result) => {
                if (result && result.data) {
                    setIsLogbookConnected(result.data.checkLogbookConnected);
                    authDispatch(Action.auth.logbookConnected(true));
                }
            });
        }
    }, [auth, crmToken]);

    // useEffect(() => {
    //     if (auth && auth.crmUserId && crmToken) {
    //         checkFitbitConnected({
    //             context: {
    //                 clientName: "crm-server",
    //                 headers: {
    //                     authorization: "Bearer " + crmToken,
    //                 },
    //             },
    //         }).then((result) => {
    //             if (result && result.data) {
    //                 setIsFitbitConnected(result.data.checkFitbitConnected);
    //                 authDispatch(Action.auth.fitbitConnected(true));
    //             }
    //         });
    //     }
    // }, [auth, crmToken]);

    const handleSelectTimeRange = (event: any) => {
        const timeRange = event.target.value;
        setTimeRange(timeRange);
        if (crmToken) {
            handleGetWorkoutList(
                {
                    timeRange,
                    page: 1,
                    userData: auth,
                    crmToken,
                    pageSize,
                    isGetMetric: false,
                    timeZone,
                },
                workoutDispatch
            );
        }
    };
    const handleSelectHistoryItem = (workoutId: string) => { 
        if (workoutId !== currentSelect.workoutItem && workoutId) {
            handleGetWorkoutDetail(
                { workoutId, userData: auth },
                workoutDispatch
            );
        }

        
    };

    const handleSelectChartOption = (value: number) => {
        handleChangeChartOption({ value }, workoutDispatch);
    };

    const handlePrevPage = () => {
        history.push(
            `${CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_DETAIL}?page=${
                metaData.currentPage - 1
            }&startTime=${startTime}&endTime=${endTime}`
        );
        window.scrollTo({ top: 0, left: 0 });
    };

    const handleNextPage = () => {
        history.push(
            `${CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_DETAIL}?page=${
                metaData.currentPage + 1
            }&startTime=${startTime}&endTime=${endTime}`
        );
        window.scrollTo({ top: 0, left: 0 });
    };

    const handleFirstPage = () => {
        history.push(
            `${
                CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_DETAIL
            }?page=${1}&startTime=${startTime}&endTime=${endTime}`
        );
        window.scrollTo({ top: 0, left: 0 });
    };
    const handleLastPage = () => {
        history.push(
            `${CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_DETAIL}?page=${metaData.lastPage}&startTime=${startTime}&endTime=${endTime}`
        );
        window.scrollTo({ top: 0, left: 0 });
    };
    const toggleViewMoreActivity = () => {
        setIsExpandActivity(!isExpandActivity);
    };

    const handleSyncToStrava = async () => {
        const appId = localStorage.getItem("appId");
        let machine = "rower";
        if (appId && Number(appId) === CONSTANTS.APP.C2_BIKEERG) {
            machine = "bike";
        }

        try {
            await syncStrava({
                variables: {
                    webUserId: auth.id,
                    workoutId: workoutDetail.id,
                    machine,
                    workoutName: workoutDetail.name
                },
                context: {
                    clientName: "crm-server",
                    headers: {
                        authorization: "Bearer " + crmToken,
                    },
                },
            });
            Swal.fire(
                "Processing",
                "Your workout will be sync to Strava",
                "success"
            );
        } catch (err) {}
    };

    const handleSyncToLogbook = async () => {
        const appId = localStorage.getItem("appId");
        let machine = "rower";
        if (appId && Number(appId) === CONSTANTS.APP.C2_BIKEERG) {
            machine = "bike";
        }
        try {
            await syncLogbook({
                variables: {
                    webUserId: auth.id,
                    workoutId: workoutDetail.id,
                    machine,
                    workoutName: workoutDetail.name
                },
                context: {
                    clientName: "crm-server",
                    headers: {
                        authorization: "Bearer " + crmToken,
                    },
                },
            });
            Swal.fire(
                "Processing",
                "Your workout will be sync to Logbook",
                "success"
            );
        } catch (err) {}
    };
 
    const handleSyncToFitbit = async () => {
        const appId = localStorage.getItem("appId");
        let machine = "rower";
        if (appId && Number(appId) === CONSTANTS.APP.C2_BIKEERG) {
            machine = "bike";
        }
        try {
            syncFitbit({
                variables: {
                    webUserId: auth.id,
                    workoutId: workoutDetail.id,
                    machine,
                    workoutName: workoutDetail.name
                },
                context: {
                    clientName: "crm-server",
                    headers: {
                        authorization: "Bearer " + crmToken,
                    },
                },
            }).then((res: any)=>{
                if(res?.data?.fitbitSyncWorkout?.status === 'completed') {
                    Swal.fire(
                        "Processing",
                        "Your workout will be sync to Fitbit",
                        "success"
                    );
                } else {
                    Swal.fire(
                        "Failed",
                        "Sync failed",
                        "error"
                    );
                }
            }).catch((err: any)=>{
                Swal.fire(
                    "Failed",
                    "Sync Failed.",
                    "error"
                );
            });
        } catch (err) {
            Swal.fire(
                "Failed",
                "Sync Failed.",
                "error"
            );
        }
    };

    return {
        isStravaConnected,
        isFitbitConnected,
        historyList,
        chartOptions,
        loading,
        currentSelect,
        timeRange,
        isMobile,
        chartData,
        isExpandActivity,
        workoutMetrics,
        workoutDetail,
        metaData,
        handleSyncToStrava,
        toggleViewMoreActivity,
        handleNextPage,
        handlePrevPage,
        handleFirstPage,
        handleLastPage,
        handleSelectChartOption,
        handleSelectHistoryItem,
        handleSelectTimeRange,
        hideSelectTimeRage,
        isLogbookConnected,
        handleSyncToLogbook,
        handleSyncToFitbit,
    };
};
