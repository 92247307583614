import { Paper, Grid, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/styles'
import CurrencyNumber from 'Components/CurrencyNumber';

const CardFieldTitle = styled(Typography)(({ theme }) => ({
    fontWeight: "500 !important",
    color: "#939393 !important",
    fontFamily: "Work Sans !important",
    fontSize: "14px !important"
}));
const CardFieldContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Work Sans !important",
    fontSize: "14px !important"
}));

const OrderCard: React.FunctionComponent<{
    placedAt: string,
    status: string,
    totalAmount: string,
    code: string,
    stateColors: any,
    state: string
    handClickDetail: Function,
    id: string
}> = ({
    placedAt,
    totalAmount,
    status,
    code,
    stateColors,
    handClickDetail,
    id
}) => {
        return (
            <Paper sx={{
                borderRadius: "8px",
                height: "116px",
                padding: "16px",
                margin: "16px 0"
            }}
                onClick={handClickDetail.bind(null, id)}
            >
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography sx={{
                            color: stateColors ? stateColors.find((color: any) => { return color.id === status })?.color : ""
                        }} mb="8px">{status}</Typography>
                        <Typography sx={{
                            color: "#1877F2",
                            cursor: "pointer"
                        }} ><u>{id}</u></Typography>
                    </Grid>
                    <Grid item>
                        {/* <Button startIcon={<Eye />} sx={{
                            borderRadius: "32px",
                            background: "#EDEDED",
                            textTransform: "unset"
                        }}>View Detail</Button> */}
                    </Grid>
                </Grid>
                <Grid container spacing={1} mt="16px">
                    <Grid item xs={6}>
                        <CardFieldTitle>Order date</CardFieldTitle>
                        <CardFieldContent>{placedAt}</CardFieldContent>
                    </Grid>
                    <Grid item xs={6}>
                        <CardFieldTitle>Total amount</CardFieldTitle>
                        <CardFieldContent><CurrencyNumber value={Number(totalAmount)} /></CardFieldContent>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

export default OrderCard