import React, { useEffect, useState } from "react";
import style from "./MembershipCardDesktop.module.scss";
import MembershipTable from "Components/MembershipPage/MembershipTable";
import MembershipHeader from "Components/MembershipPage/Header";
import InviteMember from "../InviteMember";

interface IMembershipCardDesktop {
    dataTable: {
        column: Array<{ title: string; key: string; className?: string }>;
        rows: Array<any>;
        actions: Array<{ name: string; action: any }>;
    };
    handleSearch?: any;
    refetchInvitedMembership?: any
}
const MembershipCardDesktop = ({
    dataTable,
    handleSearch,
    refetchInvitedMembership,
}: IMembershipCardDesktop) => {
    // console.log('aaaaaaapppppp', dataTable)
    const [openInviteMember, setOpenInviteMember] = useState(false);
    const handleOpenInviteMember = () => {
        setOpenInviteMember(true);
    };
    return (
        <div className={style["MembershipCardDesktop"]}>
            <div className={style["header"]}>
                <MembershipHeader
                    handleClickInviteMemberButton={handleOpenInviteMember}
                    handleSearch={handleSearch}
                />
            </div>
            <MembershipTable dataTable={dataTable} />
            <InviteMember
                open={openInviteMember}
                onClose={() => {
                    setOpenInviteMember(false);
                }}
                refetchInvitedMembership={refetchInvitedMembership}
            />
        </div>
    );
};

export default MembershipCardDesktop;
