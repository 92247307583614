import {
    Backdrop,
    Box,
    Button,
    Fade,
    IconButton,
    Modal,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import "./style.scss";
import Close from "@mui/icons-material/Close";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const offers = [
    {
        description: (
            <>
                <p className="cancel-offer-success-desc">
                    Awesome, you're back on the team! 🎉 <br />
                    Your 50% off for the next two months is locked in.
                </p>
                <p className="cancel-offer-success-desc">
                    It's not just about the bucks you're saving. It's about you getting back to your winning streak with us. We're totally here for it and can't wait to cheer you on!
                </p>
            </>
        ),
        availableFor: [1, 49],
    },
    {
        description: (
            <>
                <p className="cancel-offer-success-desc">
                    Great News! You've snagged a sweet deal - 25% off for the
                    next year! <br />
                    Get ready to jump back in and make some waves. It's going to
                    be an epic year, and we can't wait to see what you'll
                    accomplish.
                </p>
            </>
        ),
        availableFor: [2, 4, 50],
    },
];

const CancelOfferMembershipSuccessModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
    // email: string;
    planId: number;
}> = ({ open, onClose, planId }) => {
    const classes = useStyles();
    const offer = React.useMemo(() => {
        return offers.find((item) => {
            return item.availableFor.includes(planId);
        });
    }, [planId]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="cancel-offer-success-modal-component">
                    <IconButton onClick={onClose} className="close-button">
                        <Close></Close>
                    </IconButton>
                    <div className="cancel-offer-success-modal-componen__main">
                        <Box
                            textAlign={"center"}
                            className="cancel-offer-success-modal-componen__main__item cancel-offer-success-modal-componen__main__item--content"
                        >
                            <img src="/images/icons/mail-success.svg" alt="icon" />
                            <Typography
                                component="h1"
                                variant="h1"
                                mb="8px"
                                mt="56px"
                            >
                                GREAT TO HEAR!
                            </Typography>
                            <Typography fontSize={"16px"}>
                                {offer?.description}
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={onClose}
                                color="secondary"
                                sx={{
                                    marginTop: "56px",
                                    minWidth: "280px !important",
                                }}
                            >
                                Close
                            </Button>
                            <br />
                        </Box>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default CancelOfferMembershipSuccessModal;
