import React, { useEffect, useLayoutEffect, useState } from "react";
import "./LineChart.scss";
import ApexCharts from "apexcharts";
import { toCapitalizeText } from "utils/toCapitalizeText";
import useWindowSize from "Hooks/useWindowSize";
import { convertedSecondToTime } from "consts/dateTime";

interface LineChart {
    data: any;
    xData: any;
    yData: any;
    activeTab: any;
    labelChart?: any;
    tickAmount?: number;
    timeRange?: any;
    peroidOptions?: any;
}

const LineChart = ({
    data,
    xData,
    yData,
    activeTab,
    labelChart,
    tickAmount,
    timeRange,
    peroidOptions,
}: LineChart) => {
    const [chart, setChart] = useState<any>();
    // const [loading, setLoading] = useState(false);
    const windowSize = useWindowSize();
    const modeChart = activeTab.value;
    const modeCompare = peroidOptions.modeCompare;
    const { data1, data2 } = data;
    // console.log({data1, data2})
    const maxValueChart = Math.max(
        data1[data1.length - 1],
        data2[data2.length - 1]
    );
    // console.log('maxValueChart', maxValueChart)
    // console.log('activeTab', activeTab)
    //@ts-ignore
    const getClassNameXLabelChart = () => {
        if (modeCompare === "today") {
            return "apexcharts-xaxis-label-today";
        } else if (modeCompare === "7day") {
            return "apexcharts-xaxis-label-7days";
        } else if (modeCompare === "365day") {
            return "apexcharts-xaxis-label-365days";
        } else if (modeCompare === "180day") {
            return "apexcharts-xaxis-label-180days";
        } else if (modeCompare === "90day") {
            return "apexcharts-xaxis-label-90days";
        } else if (modeCompare === "30day") {
            return "apexcharts-xaxis-label-30days";
        } else {
            return "apexcharts-xaxis-label";
        }
    };
    const getWidhtLegend = () => {
        if (modeCompare === "today") {
            return 60;
        } else if (
            modeCompare === "180day" ||
            modeCompare === "365day" ||
            modeCompare === "90day"
        ) {
            return 30;
        } else {
            return 30;
        }
    };
    const getLengendText = (text: string, date: any) => {
        if (modeCompare !== "today") {
            const startTime = date[0];
            const endTime = date[1];
            return `${text} (${startTime} - ${endTime})`;
        } else {
            return `${text}`;
        }
    };
    const getMaxValueLevel = () => {
        if (maxValueChart <= 5) {
            return 5;
        } else {
            const tempString = parseInt(String(maxValueChart));
            const lengthValue = String(tempString).length;
            const maxTemp = 1 * Number(`1e${lengthValue - 2}`);
            if(maxValueChart >=50) {
                for (let i = 5; ;) {
                    // const numberStep = i * 5;
                    if (
                        i > maxValueChart &&
                        (i / 5) % 5 === 0 &&
                        (i/5) % maxTemp === 0
                    ) {
                        return i;
                    }
                    i=i + 5
                }
            } else if(maxValueChart >=40) {
                for (let i = 5; ;) {
                    const numberStep = i + 5;
                    if (
                        numberStep > maxValueChart &&
                        (numberStep/5) % 5 === 0
                    ) {
                        return numberStep;
                    }
                    i=i + 5
                }
            } else {
                for (let i = 5; ;) {
                    const numberStep = i + 5;
                    if (
                        numberStep > maxValueChart
                    ) {
                        return numberStep;
                    }
                    i=i + 5
                }
            }
        }
    };
    const options = {
        series: [
            {
                name: getLengendText(labelChart[0], timeRange?.timeRange1),
                data: data?.data2,
            },
            {
                name: getLengendText(labelChart[1], timeRange?.timeRange2),
                data: data?.data1,
            },
        ],
        colors: ["#FF4106", "#939393"],
        chart: {
            height: 571,
            type: "line",
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            id: "customLineChart",
            events: {
                beforeMount: function (chartContext: any, config: any) {
                    // console.log("beforeMount");
                    //@ts-ignore
                    const oldChartElement =
                        document.getElementById("customLineChart")?.firstChild;
                    if (oldChartElement) {
                        // oldChartElement.remove();
                    }
                },
                mounted: () => {
                    // console.log('mounted');
                },
                updated: () => {
                    // console.log('updated');
                    // setLoading(false);
                },
            },
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 500,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 3,
            lineCap: "round",
            dashArray: [0, 8],
        },
        grid: {
            show: true,
            borderColor: "#E6E6E6",
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        xaxis: {
            categories: xData,
            tickAmount: tickAmount,
            labels: {
                style: {
                    fontSize: "16px",
                    fontFamily: "Work Sans",
                    fontWeight: "600",
                    cssClass: getClassNameXLabelChart(),
                },
            },
            title: {
                show: false,
                text: "Times",
                offsetY: 10,
                style: {
                    fontSize: "16px",
                    lineHeight: "19px",
                    fontFamily: "Work Sans",
                    // color: "#939393",
                    color: "transparent",
                    fontWeight: 500,
                    cssClass: "apexcharts-xaxis-title",
                },
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            decimalsInFloat: 0,
            min: 0,
            max: getMaxValueLevel(),
            tickAmount: 5,
            title: {
                show: false,
                text: yData.label,
                offsetX: 0,
                style: {
                    fontSize: "16px",
                    lineHeight: "19px",
                    fontFamily: "Work Sans",
                    color: "transparent",
                    fontWeight: "normal",
                    width: 50,
                    cssClass: "apexcharts-yaxis-title",
                },
            },
            labels: {
                show: true,
                style: {
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontFamily: "MonoSpec",
                    cssClass: "apexcharts-yaxis-label",
                    transform: "none",
                },
                formatter: function (value: any) {
                    return value.toFixed(0).replace(".00", "");
                },
            },
            axisBorder: {
                show: true,
                color: "#E6E6E6",
                offsetX: 0,
                offsetY: 0,
            },
        },
        legend: {
            markers: {
                width: 57,
                fillColors: ["none", "none"],
                onclick: (chart: any) => {},
            },
            onItemClick: {
                toggleDataSeries: false,
            },
            onItemHover: {
                highlightDataSeries: false,
            },
        },
        tooltip: {
            followCursor: true,
            x: {
                show: true,
            },
            y: {
                show: true,
                title: {
                    formatter: (seriesName: any) => {
                        if (modeCompare !== "today") {
                            const { timeRange1, timeRange2 } = timeRange;
                            const text1 = `(${timeRange1[0]} - ${timeRange1[1]})`;
                            const text2 = `(${timeRange2[0]} - ${timeRange2[1]})`;
                            seriesName = seriesName.replace(text1, "");
                            seriesName = seriesName.replace(text2, "");
                        }
                        return seriesName;
                    },
                },
                formatter: function (val: any) {
                    if (modeChart === "times") {
                        return convertedSecondToTime(
                            val * 60,
                            val < 60 ? false : true
                        );
                    }
                    return val;
                },
            },
        },
        responsive: [
            {
                breakpoint: 500,
                minWidth: 50,
                options: {
                    chart: {
                        height: 395,
                    },
                    yaxis: {
                        min: 0,
                        max: getMaxValueLevel(),
                        tickAmount: 5,
                        decimalsInFloat: 0,
                        labels: {
                            show: true,
                            style: {
                                colors: [],
                                // fontSize: "18px",
                                // lineHeight: "16px",
                                fontFamily: "MonoSpec",
                                cssClass: "apexcharts-mobile-yaxis-label",
                            },
                            formatter: function (value: any) {
                                if (value >= 1000) {
                                    return (
                                        (value / 1000)
                                            .toFixed(1)
                                            .replace(".00", "")
                                            .replace(".0", "") + "K"
                                    );
                                } else {
                                    return value.toFixed(0).replace(".00", "");
                                }
                            },
                        },
                        axisBorder: {
                            show: true,
                            color: "#E6E6E6",
                            offsetX: 0,
                            offsetY: 0,
                        },
                    },
                    legend: {
                        markers: {
                            width: getWidhtLegend(),
                        },
                    },
                    stroke: {
                        curve: "smooth",
                        width: 2,
                        lineCap: "butt",
                        dashArray: [0, 8],
                    },
                },
            },
        ],
    };
    // const optionsInit = {
    //     series: [
    //         {
    //             data: innitData,
    //         },
    //     ],
    // };
    useLayoutEffect(() => {
        setChart(
            //@ts-ignore
            new ApexCharts(document.querySelector("#customLineChart"), options)
        );
    }, []);
    useEffect(() => {
        //@ts-ignore
        chart &&
            //@ts-ignore
            chart.render().then(() => {
                //@ts-ignore
                chart.resetSeries();
            });
        const domChartEl = document.getElementById("apexchartscustomLineChart");
        if (domChartEl) {
            //@ts-ignore
            chart.resetSeries();
        }
    }, [chart]);
    useLayoutEffect(() => {
        const domChartEl = document.getElementById("apexchartscustomLineChart");
        if (domChartEl && activeTab && data && chart) {
            //@ts-ignore
            chart.resetSeries();
            //@ts-ignore
            chart.updateOptions(options, true, true, true);
        }
    }, [data, activeTab, chart]);
    return (
        <div className={"LineChartWrapper"}>
            <div className="workout-line-chart-label-y-wrapper">
                <div className="workoutLineChart-label-y">
                    {windowSize?.width <= 500
                        ? toCapitalizeText(yData.label)
                        : yData.label}
                </div>
            </div>
            <div id={"customLineChart"}></div>
        </div>
    );
};

export default LineChart;
