import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { CONSTANTS } from 'consts';

interface IGoBackProps {
    url: string;
}

const GoBack: React.FunctionComponent<IGoBackProps> = ({url} ) => {
    const history = useHistory();

    const goBack = () => { 
        if(url.includes("membership")){
            history.push(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
        } else if(url.includes("order")){
            history.push(CONSTANTS.NAVIGATION_URL.ORDERS);
        }
    }
    
    return (
        <Box sx={{
            display: "flex",
            cursor: "pointer",
            width: "fit-content"
        }}
            onClick={goBack}>
            <img src="/images/icons/chevron-left.svg" alt="icon" />
            <Typography color="#1877F2" fontSize="16px" sx={{
                lineHeight: "24px",
                cursor: "pointer",
                "&:hover": {
                    color: "#808080"
                }
            }} >Back</Typography>
        </Box>
    )
}

export default GoBack;