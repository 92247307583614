import { Button, TextField, Typography } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import useStyles from "./BetaCode.styles";
import RegisterBetaModalCancel from "../RegisterBetaModalCancel";
import { RefModal } from "../RegisterBetaModalCancel/RegisterBetaModalCancel";
import CRC32 from "crc-32";
import { handleRenderErrorLabel } from "utils/handleRenderError";

import RegisterBetaModalGenerateCode from "../RegisterBetaModalGenerateCode";
import { RefModalGenerate } from "../RegisterBetaModalGenerateCode/RegisterBetaModalGenerateCode";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
interface RequestBetaEnterCodeProps {
    handleSetSteps: (value: number) => void;
}
const RequestBetaEnterCode = (props: RequestBetaEnterCodeProps) => {
    const { handleSetSteps } = props;

    const classes = useStyles();
    const refModal = useRef<RefModal>(null);
    const refModalGenerate = useRef<RefModalGenerate>(null);

    const [code, setCode] = useState<string | undefined>(undefined);
    const [codeGenerate, setCodeGenerate] = useState<string>("");

    const handleChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setCode(e.target.value);
    };

    const checkIsError = useMemo(() => !code || code.length !== 8, [code]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Beta Code | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className={classes.content}>
                <div className={classes.contentBody}>
                    <form className={classes.form}>
                        <div
                            style={{ textAlign: "left", cursor: "pointer" }}
                            onClick={() => handleSetSteps(0)}
                        >
                            <ArrowBackIosIcon
                                fontSize="small"
                                style={{ color: "#666666" }}
                            />
                        </div>
                        <div className={classes.logo}>
                            <img
                                src="https://avironactive.com/assets/img/icon/aviron-icon.svg"
                                alt="icon"
                            />
                        </div>
                        <Typography
                            className={classes.title}
                            variant="h2"
                            sx={{
                                marginTop: "16px",
                                marginBottom: "28px",
                            }}
                        >
                            AVIRON BETA PROGRAM
                        </Typography>
                        <div className={classes.introduction}>
                            <ul className={classes.introductionUl}>
                                <li>
                                    <div className="number">1</div>
                                    <div className="text">
                                        Log out of your current profile on the
                                        rower.
                                    </div>
                                </li>
                                <li>
                                    <div className="number">2</div>
                                    <div className="text">
                                        Log in using the credentials:
                                        <br />
                                        Username: support
                                        <br />
                                        Password: support
                                    </div>
                                </li>
                                <li>
                                    <div className="number">3</div>
                                    <div className="text">
                                        A code should appear on your screen.
                                        Please enter that code below in the code
                                        generator and click “Generate”.
                                    </div>
                                </li>
                                <li>
                                    <div className="number">4</div>
                                    <div className="text">
                                        Another code should appear. Please enter
                                        this code on your rower.
                                    </div>
                                </li>
                                <li>
                                    <div className="number">5</div>
                                    <div className="text">
                                        Navigate to "Settings," located at the
                                        bottom left corner of the screen.
                                    </div>
                                </li>
                                <li>
                                    <div className="number">6</div>
                                    <div className="text">
                                        Select “Change Environment” until the
                                        “Mode” displays “BETA”.
                                    </div>
                                </li>
                                <li>
                                    <div className="number">7</div>
                                    <div className="text">
                                        Click “Reset” to restart the screen and
                                        complete the process.
                                    </div>
                                </li>
                                <li>
                                    <div className="number">8</div>
                                    <div className="text">
                                        Allow a few minutes for the system to
                                        download the new update.
                                    </div>
                                </li>
                                <li>
                                    <div className="number">9</div>
                                    <div className="text">
                                        To revert back to live mode, navigate to
                                        “Settings”, select “Production Mode”,
                                        confirm the change in the pop-up window,
                                        and restart your screen.
                                    </div>
                                </li>
                                <div></div>
                            </ul>
                        </div>
                        <h2 className={classes.title}>
                            PLEASE ENTER YOUR CODE BELOW
                        </h2>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            placeholder="APK CODE"
                            name="code"
                            onChange={handleChange}
                            type="text"
                            variant="outlined"
                            data-cy="login-code-field"
                            sx={{
                                marginTop: "16px",
                            }}
                        />
                        {checkIsError &&
                            code !== undefined &&
                            handleRenderErrorLabel(
                                "Must have exactly 8 characters."
                            )}
                        <Button
                            className={classes.signInButton}
                            fullWidth
                            size="large"
                            variant="contained"
                            data-cy="login-submit-button"
                            sx={{
                                marginTop: "24px",
                                background: "#F5490F",
                                borderRadius: 15,
                                height: 50,
                            }}
                            onClick={() => {
                                if (code === "00000000") {
                                    return;
                                }

                                const codeValue = code + "gReVTNzx";
                                const hash = (
                                    CRC32.buf(
                                        Buffer.from(codeValue, "ascii"),
                                        0
                                    ) >>> 0
                                ).toString(16);
                                if (!checkIsError) {
                                    setCodeGenerate(hash);
                                    refModalGenerate.current?.setIsVisible(
                                        true
                                    );
                                } else {
                                    setCode("");
                                }
                            }}
                        >
                            GENERATE
                        </Button>
                        <div
                            onClick={() => {
                                setCodeGenerate("");
                                refModal.current?.setIsVisible(true);
                            }}
                        >
                            <p
                                style={{
                                    color: "#161616",
                                    fontSize: 16,
                                    fontFamily: "MonoSpec",
                                    fontWeight: 500,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                            >
                                WITHDRAW APPLICATION
                            </p>
                        </div>
                    </form>
                </div>
            </div>
            <RegisterBetaModalCancel
                ref={refModal}
                handleSetSteps={handleSetSteps}
            />
            <RegisterBetaModalGenerateCode
                ref={refModalGenerate}
                codeGenerate={codeGenerate}
            />
        </div>
    );
};

export default RequestBetaEnterCode;
