import './style.scss';
import React from 'react';

const JoinForm = () => {

  const handleJoinFormEvent = () => {
    const element = document.getElementById("klaviyo-desktop-form");
    if (element) {
      element.addEventListener("DOMNodeInserted", () => {
        const formCount = element.childElementCount;
        if (element && formCount && formCount > 1) {
          element.removeChild(element.getElementsByTagName('div')[0]);
        }
      })
    }
  }

  const removeJoinFormEvent = () => {
    const element = document.getElementById("klaviyo-desktop-form");
    if (element) {
      element.removeEventListener("DOMNodeInserted", () => {
      })
    }
  }

  React.useEffect(() => {
    handleJoinFormEvent();
    return () => {
      removeJoinFormEvent();
    }
  }, [])

  return (
    <div className="join-form-component">
      <div className="desktop-form-container">
        <div className="klaviyo-form-VcW4RP" id="klaviyo-desktop-form"></div>
      </div>
      <div className="mobile-form-container">
        <div className="klaviyo-form-Rhzgth"></div>
      </div>
    </div>
  )
}
export default JoinForm;