import style from "./LoginLayout.module.scss";
import React from "react";
import LeftContent from "./LeftContent";
interface LoginLayoutProps {
    children: any;
}
const LoginLayout = ({ children }: LoginLayoutProps) => {
    return (
        <div className={style["LoginLayoutWrapper"]}>
            <div className={style["LoginBody"]}>
                <div className={style["leftContent"]}>
                    <LeftContent />
                </div>
                <div className={style["rightContent"]}>{children}</div>
            </div>
        </div>
    );
};

export default LoginLayout;
