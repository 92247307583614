import React, { useReducer, Dispatch } from "react";

import { checkoutReducer } from "./checkout/checkout.reducer";
import { globalReducer } from "./globals/global.reducer";
import { headerReducer } from "./headers/header.reducer";
import { productReducer } from "./products/product.reducer";
import { profileReducer } from "./profiles/profile.reducer";
import { subscriptionReducer } from "./subsciptions/subsciption.reducer";
import { workoutReducer } from "./workouts/workout.reducer";
import { authReducer } from "./auth/auth.reducer";
import { timezoneReducer } from "./timezone/timezone.reducer";

import Action from "./actions";
import { machineReducer } from "./machines/machine.reducer";
import { achievementListReducer } from "./achievements/achievements.reducer";
import { userProfileReducer } from "./userProfile/userProfile.reducer";

type AppContextProps = {
    global: any;
    globalDispatch: React.Dispatch<any>;
    checkout: any;
    checkoutDispatch: React.Dispatch<any>;
    header: any;
    headerDispatch: React.Dispatch<any>;
    product: any;
    productDispatch: React.Dispatch<any>;
    profile: any;
    profileDispatch: React.Dispatch<any>;
    subscription: any;
    subscriptionDispatch: React.Dispatch<any>;
    workoutState: any;
    workoutDispatch: Dispatch<any>;
    auth: any;
    authDispatch: Dispatch<any>;
    timezone: any;
    timezoneDispatch: Dispatch<any>;
    machineList: any;
    machineListDispatch: Dispatch<any>;
    achievementList: any;
    achievementListDispatch: Dispatch<any>;
    apolloClient: any;
    userProfile: any;
    userProfileDispatch: Dispatch<any>;
};

interface AppContextProviderProps {
    children: any;
    apolloClient: any;
}

const UserAppContext: React.Context<AppContextProps> = React.createContext(
    {} as any
);

const UserAppContextProvider: React.FunctionComponent<
    AppContextProviderProps
> = ({ children, apolloClient }) => {
    const [checkout, checkoutDispatch] = React.useReducer(
        checkoutReducer.reducer,
        checkoutReducer.initState
    );
    const [global, globalDispatch] = React.useReducer(
        globalReducer.reducer,
        globalReducer.initState
    );
    const [header, headerDispatch] = React.useReducer(
        headerReducer.reducer,
        headerReducer.initState
    );
    const [product, productDispatch] = React.useReducer(
        productReducer.reducer,
        productReducer.initState
    );
    const [profile, profileDispatch] = React.useReducer(
        profileReducer.reducer,
        profileReducer.initState
    );
    const [subscription, subscriptionDispatch] = React.useReducer(
        subscriptionReducer.reducer,
        subscriptionReducer.initState
    );
    const [auth, authDispatch] = React.useReducer(
        authReducer.reducer,
        authReducer.initState
    );
    const [workoutState, workoutDispatch] = useReducer(
        workoutReducer.reducer,
        workoutReducer.initialState
    );
    const [timezone, timezoneDispatch] = useReducer(
        timezoneReducer.reducer,
        timezoneReducer.initialState
    );
    const [machineList, machineListDispatch] = useReducer(
        machineReducer.reducer,
        machineReducer.initialState
    );
    const [achievementList, achievementListDispatch] = useReducer(
        achievementListReducer.reducer,
        achievementListReducer.initialState
    );
    const [userProfile, userProfileDispatch] = useReducer(
        userProfileReducer.reducer,
        userProfileReducer.initialState
    )

    return (
        <UserAppContext.Provider
            value={{
                checkout,
                checkoutDispatch,
                global,
                globalDispatch,
                header,
                headerDispatch,
                product,
                productDispatch,
                profile,
                profileDispatch,
                subscription,
                subscriptionDispatch,
                workoutState,
                workoutDispatch,
                auth,
                authDispatch,
                timezone,
                timezoneDispatch,
                machineList: machineList,
                machineListDispatch,
                achievementList: achievementList,
                achievementListDispatch,
                apolloClient,
                userProfile,
                userProfileDispatch
            }}
        >
            {children}
        </UserAppContext.Provider>
    );
};
export { UserAppContextProvider, UserAppContext, Action };
