import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { Fragment } from 'react';

const LoadingHOC = (props: any) => {
  const { children, isLoading } = props;
  if (!isLoading) return (<Fragment>{children}</Fragment>);
  return (
    <Fragment>
      <Box position="relative" height="100%" width="100%">
        <Box position="absolute" width="100%" height="100%" zIndex={1}
          display="flex" justifyContent="center" alignItems="center">
          <CircularProgress style={{ color: '#F5490F' }} size={50} />
        </Box>
        <Fragment>{children}</Fragment>
      </Box>
    </Fragment>
  )
}
export default LoadingHOC;