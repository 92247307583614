const GLOBAL_FUNCTIONS = {
    SetObject: (sourceObject: any, desObject?: any) => {
        const obj = Object.assign({}, sourceObject);
        if (desObject !== undefined) {
            const keys = Object.keys(desObject);
            keys.forEach(k => {
                obj[k] = desObject[k];
            });
        }
        return obj;
    },
    FormatNumToThousand: (pNum: number, pUnitStr?: string) => {
        const unitStr = pUnitStr === undefined ? ',' : pUnitStr;
        return pNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${unitStr}`);
    },
    GetRandomInt: (max: number) => {
        return Math.floor(Math.random() * Math.floor(max));
    },
    CheckingIsEmail: (value: string) => {
        const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(value);
    },
    getCookie(name: string) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    },
    setCookie(name: string, value: string, days: number) {
        var d = new Date;
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
    },
    deleteCookie(name: string) { this.setCookie(name, '', -1); }
};

export default GLOBAL_FUNCTIONS;
