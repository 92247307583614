import { AxiosResponse } from "axios";
import { UserAppContext } from "contexts";
import {
    getMachineListFailed,
    getMachineListRequest,
    getMachineListSuccess,
} from "contexts/machines/machine.actions";
import { initialTypeMarchine } from "contexts/machines/machine.reducer";
import React from "react";
import { gameServerApi } from "services/gameServerV2";

const handleCookDataMachineList = (data: initialTypeMarchine[]) => {
    return data.filter(
        (item) => item.group !== "mobile" && Boolean(item.image)
    );
};

export const useQueryMachineList = () => {
    const { machineListDispatch: dispatch } = React.useContext(UserAppContext);

    const handleQueryMachineList = async () => {
        dispatch(getMachineListRequest());
        try {
            const { data, status } = await gameServerApi.app.getMarchineList() as AxiosResponse;
            if (status === 200 && data.length >= 0) {
                const dataFormatted = handleCookDataMachineList(data);
                dispatch(getMachineListSuccess(dataFormatted));
            }
        } catch (error) {
            dispatch(getMachineListFailed());
        }
    };

    React.useEffect(() => {
        handleQueryMachineList();
    }, []);
};
