import { ACTION_TYPES } from '../types/action-types';
import { BaseAction } from '../base/base.action';

export class GlobalAction extends BaseAction {
    constructor() {
        super("GLOBAL")
    }
    openMessageBox = (_data: any) => {
        return { type: ACTION_TYPES.GLOBAL.OPEN_MESSAGE_BOX, payload: _data };
    };
    closeMessageBox = () => {
        return { type: ACTION_TYPES.GLOBAL.CLOSE_MESSAGE_BOX, payload: null };
    };

}


