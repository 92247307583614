import style from "./Tabs.module.scss";
import React from "react";
import { Tab, Tabs as TabsMui } from "@mui/material";
import { setTimeout } from "timers";
import useWindowSize from "Hooks/useWindowSize";
interface TabsProps {
    options: Array<{ active: number; value: any; label: string }>;
    handleChangeTab?: any;
    isScrollTab?: boolean;
}
const Tabs = ({ options, handleChangeTab, isScrollTab }: TabsProps) => {
    const [active, setActive] = React.useState(0);
    const windowSize = useWindowSize();
    const handleChange = (event: React.SyntheticEvent, newActive: number) => {
        const domTarget = document.getElementById(`tabScroll${newActive}`);
        //@ts-ignore
        const tabWrapper =
            document.getElementsByClassName("MuiTabs-scroller")[1];
        if (isScrollTab) {
            if (newActive !== 0) {
                setTimeout(() => {
                    //@ts-ignore
                    if (domTarget && windowSize.width <= 1000) {
                        if (
                            //@ts-ignore
                            tabWrapper.offsetWidth / 2 <
                            domTarget.getBoundingClientRect().left
                        ) {
                            tabWrapper.scrollTo({
                                //@ts-ignore
                                left:
                                    domTarget.getBoundingClientRect().left -
                                    domTarget.offsetWidth / 2,
                                top: window.screenTop,
                                behavior: "smooth",
                            });
                        } else {
                            tabWrapper.scrollTo({
                                left: -(
                                    domTarget.getBoundingClientRect().left -
                                    domTarget.offsetWidth / 2
                                ),
                                top: window.screenTop,
                                behavior: "smooth",
                            });
                        }
                    }
                }, 500);
            }
        }
        setActive(newActive);
        handleChangeTab && handleChangeTab(options[newActive]);
    };
    return (
        <div className={style["TabsChart"] + " TabsChart"}>
            <TabsMui
                value={options[active].active}
                onChange={handleChange}
                variant="scrollable"
                // scrollButtons="auto"
                aria-label="scrollable auto chart tabs"
                id={"tabScrollWrapper"}
            >
                {options.map((item, key) => {
                    return (
                        <Tab
                            id={"tabScroll" + key}
                            key={key}
                            label={item.label}
                        />
                    );
                })}
            </TabsMui>
        </div>
    );
};

export default Tabs;
