import { CONSTANTS } from "consts";
import { UserAppContext } from "contexts";
import { initialTypeMarchine } from "contexts/machines/machine.reducer";
import {
    changePageFailed,
    changePageRequest,
    changePageSuccess,
    getWorkoutDetailFailed,
    getWorkoutDetailRequest,
    getWorkoutDetailSuccess,
    getWorkoutListFailed,
    getWorkoutListRequest,
    getWorkoutListSuccess,
    selectChartOption
} from "contexts/profileWorkout";
import { Dispatch, useContext, useMemo } from "react";
import { useCheckWorkoutSyncedMutation, useFitbitCheckWorkoutSyncedMutation, useLogbookCheckWorkoutSyncedMutation } from "services/crmServer";
import { gameServerApi } from "services/gameServerV2";
import { gameServerApi as gameServerApiProto } from "services/gameServerV3";
import Swal from "sweetalert2";
import { formatStringDatetimeRequest } from "utils";
import { WorkoutHistoriesData } from "./Main/types";
import {
    formatAvironBikeWokoutDetailProtoc,
    formatRelaxTimeData,
    formatWokoutDetail,
    formatWorkoutList,
    mappingChartOption,
} from "./handleData";

const { WORKOUT_HISTORY_LIMIT, WORKOUT_PARAMETER } = CONSTANTS;
type GetWorkListData = {
    timeRange: number;
    page: number;
    isChangePage?: boolean;
    userData: any;
    crmToken: string;
    startTime?: string | number;
    endTime?: string | number;
    pageSize?: number;
    isGetMetric?: boolean;
    timeZone?: string;
};

// let attemped = 0;
const MAX_RETRY_ATTEMP = 3;

export enum GroupType {
    c2 = "c2",
    apk = "apk",
}

export const useRenderDefaultMachine = () => {
    const { machineList } = useContext(UserAppContext);
    const appId = localStorage.getItem("appId");

    const defaultDevice: initialTypeMarchine = useMemo(() => {
        const data = machineList.data.find(
            (item: initialTypeMarchine) => item.id === Number(appId)
        );
        return data || machineList.data[0] || "apk";
    }, [machineList, appId]);

    return defaultDevice;
};

const useHandleFormatDataWorkoutList = () => {
    const handleFormatDataWorkoutList = (data: any) => {
        const { metaData, historyData } = data;
        const { workoutHistoryData, ...extraData } = historyData;
        const workoutFormated = formatWorkoutList(workoutHistoryData);
        return {
            metaData,
            workoutMetrics: extraData,
            workoutHistories: workoutFormated,
        };
    };
    return {
        handleFormatDataWorkoutList,
    };
};

//--- GET WORKOUT LIST ---//
export const useHandleGetWorkoutList = () => {
    const { handleFormatDataWorkoutList } =
        useHandleFormatDataWorkoutList() || {};

    const handleGetWorkoutList = async (
        data: GetWorkListData,
        dispatch: Dispatch<any>
    ) => {
        let {
            timeRange,
            page,
            isChangePage = false,
            startTime,
            endTime,
            pageSize,
            isGetMetric = true,
            timeZone,
        } = data;

        if (isChangePage) {
            dispatch(changePageRequest());
        } else {
            dispatch(getWorkoutListRequest({ timeRange }));
        }
        if (startTime && timeZone) {
            startTime = formatStringDatetimeRequest(startTime as string);
        } else {
            startTime = undefined;
        }

        if (endTime && timeZone) {
            endTime = formatStringDatetimeRequest(endTime as string);
        } else {
            endTime = undefined;
        }

        const params = {
            timeRange,
            page,
            pageSize: pageSize ? pageSize : WORKOUT_HISTORY_LIMIT,
            startTime,
            endTime,
            isGetMetric,
        };

        try {
            const { data: oldData }: { data: WorkoutHistoriesData } =
                await gameServerApi.workoutHistory.getWorkoutList(params);

            const data = handleFormatDataWorkoutList(oldData);

            if (isChangePage) {
                dispatch(
                    changePageSuccess({
                        metaData: data.metaData || {},
                        historyList: data.workoutHistories || [],
                    })
                );
            } else {
                dispatch(
                    getWorkoutListSuccess({
                        metaData: data.metaData || {},
                        workoutMetrics: isGetMetric
                            ? formatWorkoutMetric(data.workoutMetrics)
                            : {},
                        historyList: data.workoutHistories || [],
                    })
                );
            }
            // attemped = 0;
        } catch (err) {
            Swal.fire("Get workout list error", err as string, "error");

            dispatch(changePageFailed());
            dispatch(getWorkoutListFailed());
        }
    };
    return {
        handleGetWorkoutList,
    };
};

const MILE_TO_METER: number = 1609.34;

const formatWorkoutMetric = (workoutMetrics: any) => {
    // const appId = localStorage.getItem("appId");
    // const isC2BikeergMachine =
    //     Number(appId) === CONSTANTS.SWITCH_MACHINE.c2Bike.id;
    // const isBikegMachine =
    //     Number(appId) === CONSTANTS.SWITCH_MACHINE.bike.id;
    let formatedMetrics = { ...workoutMetrics };

    // if (isBikegMachine) {
    //     formatedMetrics.totalMeter = workoutMetrics.totalMeter/MILE_TO_METER;
    //     formatedMetrics.totalStrokes = workoutMetrics.maxSPM;
    //     formatedMetrics.avgSecp500 = workoutMetrics.avgSpeed;
    // }
    // console.log("formated metrics: ", formatedMetrics)
    return formatedMetrics;
};

const useHandleFormatDataWorkoutDetail = () => {
    const handleFormatDataWorkoutDetail = (data: any) => {
        const dataFormated = formatWokoutDetail(data);
        return dataFormated;
    };
    return {
        handleFormatDataWorkoutDetail,
    };
};

const useHandleFormatDataAvironBikeWorkoutProtocDetail = () => {
    const handleFormatDataAvironBikeWorkoutProtocDetail = (data: any) => {
        const dataFormated = formatAvironBikeWokoutDetailProtoc(data);
        return dataFormated;
    };
    return {
        handleFormatDataAvironBikeWorkoutProtocDetail,
    };
};

let getWorkoutAttemp = 0;
//--- GET WORKOUT DETAIL ---//
export const useHandleGetWorkoutDetail = () => {
    const appId = localStorage.getItem("appId");
    const isC2BikeergMachine =
        Number(appId) === CONSTANTS.SWITCH_MACHINE.c2Bike.id;
    const isBikegMachine = Number(appId) === CONSTANTS.SWITCH_MACHINE.bike.id;
    const { handleFormatDataWorkoutDetail } =
        useHandleFormatDataWorkoutDetail();
    const { handleFormatDataAvironBikeWorkoutProtocDetail } =
        useHandleFormatDataAvironBikeWorkoutProtocDetail();
    const [logbookCheckWorkoutSynced] = useLogbookCheckWorkoutSyncedMutation();
    const [fitbitCheckWorkoutSynced] = useFitbitCheckWorkoutSyncedMutation();
    const [checkWorkoutSynced] = useCheckWorkoutSyncedMutation();

    const handleGetWorkoutDetail = async (
        data: { workoutId: string; userData: any },
        dispatch: Dispatch<any>
    ) => {
        const { workoutId, userData } = data;
        const params = { workoutId: workoutId };

        dispatch(getWorkoutDetailRequest({ workoutItem: workoutId }));

        const { data: checkWorkoutSyncedData } = await checkWorkoutSynced({
            variables: {
                workoutId,
            },
            context: {
                clientName: CONSTANTS.SERVER.CRM_SERVER,
                headers: {
                    authorization: "Bearer " + userData.crmToken,
                },
            },
        });
        const isStravaSynced = checkWorkoutSyncedData?.checkWorkoutSynced;

        const { data: logbookCheckWorkoutSyncedData } =
            await logbookCheckWorkoutSynced({
                variables: {
                    workoutId,
                },
                context: {
                    clientName: CONSTANTS.SERVER.CRM_SERVER,
                    headers: {
                        authorization: "Bearer " + userData.crmToken,
                    },
                },
            });
        const isLogbookSynced =
            logbookCheckWorkoutSyncedData?.logbookCheckWorkoutSynced;

        const { data: fitbitCheckWorkoutSyncedData } =
            await fitbitCheckWorkoutSynced({
                variables: {
                    workoutId,
                },
                context: {
                    clientName: CONSTANTS.SERVER.CRM_SERVER,
                    headers: {
                        authorization: "Bearer " + userData.crmToken,
                    },
                },
            });
        const isFitbitSynced =
            fitbitCheckWorkoutSyncedData?.fitbitCheckWorkoutSynced;

        try {
            getWorkoutAttemp++;
            let workoutDetail: any = null;
            // debugger
            if (isBikegMachine) {
                // debugger
                const data =
                    await gameServerApiProto.workoutHistory.getWorkoutDetail(
                        params
                    );
                workoutDetail = handleFormatDataAvironBikeWorkoutProtocDetail(data);
                // console.log('xvxvxvxvxvx: ', workoutDetail);
            } else {
                // const data = {}
                const data =
                    await gameServerApiProto.workoutHistory.getWorkoutDetail(params);
                workoutDetail = handleFormatDataWorkoutDetail(data);
            }

            // Data for display chart
            const chartParameters = {
                kiloJoules: workoutDetail.kiloJoules,
                watts: workoutDetail.watts,
                calories: workoutDetail.calories,
                calPerHour: workoutDetail.calPerHour,
                meters: workoutDetail.meters,
                secPer500: workoutDetail.secPer500,
                spm: workoutDetail.spm,
                // heartRates: workoutDetail.heartRates,
            };

            // Display metrics
            const workoutMetrics = {
                totalTime: workoutDetail.time,
                totalMeter: workoutDetail.totalMeter,
                totalOutput: workoutDetail.totalOutput,
                totalCalories: workoutDetail.totalCalories,
                totalStrokes: workoutDetail.totalStrokes,
                avgWatt: workoutDetail.avgWatt,
                avgCalpHour: workoutDetail.avgCalpHour,
                avgSecp500: workoutDetail.avgSecp500,
                avgSPM: workoutDetail.avgSPM,
                avgHeartRate: workoutDetail.avgHeartRate,
                avgSpeed: workoutDetail.avgSpeed,
            };
            // mapping for average data and unit
            const mappingValue = mappingWorkoutData(
                workoutDetail,
                isC2BikeergMachine
            );

            // mapping data for chart display: x = time, y = data
            const mergeData = (
                timeArr: number[],
                groupedTimeData: number[],
                dataArr: number[],
                value: number
            ) => {
                if (
                    value === WORKOUT_PARAMETER.kDIndexKiloJoules ||
                    value === WORKOUT_PARAMETER.kDIndexCalories ||
                    value === WORKOUT_PARAMETER.kDIndexMeters
                ) {
                    let timeData = [...timeArr];
                    let metricData = [...dataArr];

                    const lastTimeDataItem = timeData[metricData.length - 1];
                    const lastMetricDataItem =
                        metricData[metricData.length - 1];

                    // Adding last point for array
                    if (lastTimeDataItem < workoutDetail.time) {
                        timeData.push(workoutDetail.time);
                        metricData.push(lastMetricDataItem);
                    }

                    // first timer must be 0
                    if (timeData[0] !== 0) {
                        timeData.unshift(0);
                        metricData.unshift(0);
                    }

                    const resultDataArray = [...metricData];
                    const resultTimeArray = [...timeData];

                    // convert meter to miles for Bike

                    const finalData = resultDataArray.map((item, index) => ({
                        x: resultTimeArray[index],
                        y: item,
                    }));
                    return finalData;
                }

                if (
                    value === WORKOUT_PARAMETER.kDIndexWatt ||
                    value === WORKOUT_PARAMETER.kDIndexCalPerHour ||
                    value === WORKOUT_PARAMETER.kDIndexSecPer500 ||
                    value === WORKOUT_PARAMETER.kDIndexSPM
                ) {
                    // Clone data for non-side effect
                    let timeData = [...workoutDetail.dataTimer];
                    let metricData = [...dataArr];

                    // Add last data
                    const lastTimeDataItem = timeData[metricData.length - 1];

                    if (lastTimeDataItem + 2 < workoutDetail.time) {
                        timeData.push(lastTimeDataItem + 2);
                        timeData.push(workoutDetail.time);
                        metricData.push(0);
                        metricData.push(0);
                    }

                    // first timer must be 0
                    if (timeData[0] !== 0) {
                        timeData.unshift(0);
                        metricData.unshift(0);
                    }
                    const relaxData = formatRelaxTimeData(timeData, metricData);
                    metricData = relaxData.valueArr;
                    timeData = relaxData.timeArr;

                    if (value === WORKOUT_PARAMETER.kDIndexSecPer500) {
                        if (appId === String(CONSTANTS.APP.AVIRON_BIKE)) {
                            return metricData.map((item, index) => ({
                                x: timeData[index],
                                y: item,
                            }));
                        } else {
                            // For the up side down chart, because this metric is go from 5 -> 0
                            return metricData.map((item, index) => ({
                                x: timeData[index],
                                y: item > 0 ? item / 60 : 0,
                            }));
                        }
                    }
                    const finalData = metricData.map((item, index) => ({
                        x: timeData[index],
                        y: item,
                    }));
                    return finalData;
                }

                // --- HEART RATE --- //
                if (
                    value === WORKOUT_PARAMETER.kDIndexHeartRate &&
                    workoutDetail.heartRates
                ) {
                    const heartRates = workoutDetail?.heartRates;
                    const globalTimers = workoutDetail.dataTimer;
                    const lastTimer = Math.round(
                        globalTimers[globalTimers.length - 1]
                    );
                    let res = [];
                    let timeIndex = 0;
                    for (let i = 0; i < heartRates.length; i++) {
                        if (i * 3 < lastTimer) {
                            res.push({
                                x: i * 3,
                                y: heartRates[i],
                            });
                        }
                        timeIndex = i;
                    }
                    res.push({
                        x: lastTimer,
                        y: heartRates[timeIndex],
                    });
                    return res;
                }

                return [];
            };

            // Final step for get data to draw
            const convertData = (object: any) => {
                let chartData: { [key: number]: any } = {};
                let chartOptions = [];
                let gameData;

                const groupedTimeData = !workoutDetail.isGroupedData
                    ? workoutDetail.dataTimer
                    : getDataTimer(
                        workoutDetail.strokes,
                        workoutDetail.dataTimer
                    );
                for (let key in object) {
                    if (object.hasOwnProperty(key)) {
                        // create chart options to select
                        chartOptions.push({
                            value: mappingValue[key].value,
                            label: mappingChartOption[mappingValue[key].value],
                        });

                        chartData[mappingValue[key].value] = {
                            data: mergeData(
                                workoutDetail.dataTimer,
                                groupedTimeData,
                                object[key],
                                mappingValue[key].value
                            ),
                            avgData: mappingValue[key].avgData,
                            information: {
                                // chartName: gameData.name,
                                paraName:
                                    mappingChartOption[mappingValue[key].value],
                                unit: mappingValue[key].unit,
                                game: gameData,
                                isStravaSynced,
                                isLogbookSynced,
                                isFitbitSynced: false,
                                createTime: workoutDetail.createTime,
                            },
                        };
                    }
                }
                return { chartData, chartOptions };
            };

            const dataForConvert = workoutDetail.heartRates
                ? {
                    ...chartParameters,
                    heartRates: workoutDetail.heartRates,
                }
                : { ...chartParameters };
            const { chartData, chartOptions } = convertData(dataForConvert);
            dispatch(
                getWorkoutDetailSuccess({
                    chartOptions,
                    chartData,
                    workoutMetrics,
                    workoutDetail,
                })
            );
            getWorkoutAttemp = 0;
        } catch (err) {
            const errorCode = (err as any)?.response?.data?.code;
            if (errorCode === 16) {
                // const userId = userData?.id;
                if (getWorkoutAttemp === MAX_RETRY_ATTEMP) {
                    dispatch(getWorkoutDetailFailed());
                    return;
                }
                // connectToGameSever(userId);
                // ALERT! maybe we have a recursive func here
                // handleGetWorkoutDetail(data, dispatch, group);
            } else {
                Swal.fire("get workout detail error", "", "error");
            }

            // dispatch(getWorkoutDetailFailed());
            Swal.fire("get workout detail error", "", "error");
            dispatch(getWorkoutDetailFailed());
        }
    };
    return {
        handleGetWorkoutDetail,
    };
};

export const handleChangeChartOption = (
    data: { value: number },
    dispatch: Dispatch<any>
) => {
    dispatch(
        selectChartOption({
            chartOption: data.value,
        })
    );
};

export const callExportCsv = async (
    data: { type: number; username: string; userEmail: string },
    dispatch: Dispatch<any>,
    token: string
) => {
    // const payload = data
    // dispatch(exportCsvToMail(payload));

    try {
        // attemped++;
        // await callAPI('POST', 'export_workouts_summary', payload);
        await gameServerApi.workoutHistory.exportCSV(data);
    } catch (err) {
        Swal.fire("Export workout error. Please try again!", "", "error");
    }
};

const getDataTimer = (strokes: any, timeArray: number[]) => {
    // if (!workoutDetail.isGroupedData) {
    //     return timeArray;
    // }
    const timeSpacing = 5;
    let groupedTimeData = [];

    for (let i = 1; i < strokes + 1; i++) {
        groupedTimeData.push(i * timeSpacing);
    }
    return groupedTimeData;
};

const mappingWorkoutData = (
    workoutDetail: any,
    isC2BikeergMachine: boolean
): {
    [key: string]: { value: number; avgData: any; unit: string };
} => {
    const appId = localStorage.getItem("appId");
    return {
        kiloJoules: {
            value: 0,
            avgData: undefined,
            unit: "KILOJOULES",
        },
        watts: {
            value: 1,
            avgData: workoutDetail.avgWatt,
            unit: "WATTS",
        },
        calories: {
            value: 2,
            avgData: undefined,
            unit: "CALORIES",
        },
        calPerHour: {
            value: 3,
            avgData: workoutDetail.avgCalpHour,
            unit: "CALS/HOUR",
        },
        meters: {
            value: 4,
            avgData: undefined,
            unit: "METERS",
        },
        secPer500: {
            value: 5,
            // For the up side down chart, because this metric is go from 10 -> 0
            avgData:
                appId === String(CONSTANTS.APP.AVIRON_BIKE)
                    ? workoutDetail.avgSecp500
                    : Math.round((workoutDetail.avgSecp500 / 60) * 100) / 100,
            // unit: "MINS",
            unit: "SPLIT",
        },
        spm: {
            value: 6,
            avgData: workoutDetail.avgSPM,
            unit: isC2BikeergMachine ? "RPM" : "SPM",
        },
        heartRates: {
            value: 7,
            avgData: workoutDetail?.avgHeartRate,
            unit: "BPM",
        },
    };
};
