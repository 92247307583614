import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import {
    achievementFormattedData,
    useQueryAchievementType,
    useQueryAllAchievement,
} from "./Achievements.actions";
import AchievementsRecent from "./Recent";
import AchievementsAll from "./All";
import { AvironLoading } from "Components/Icons/AvironLoading";
import AchievementsModal from "./Modal";
import {
    AchievementModalData,
    RefModalAchievement,
} from "./Modal/AchievementsModal";

const Achievements = () => {
    const { handleQueryData } = useQueryAllAchievement();
    const { achievementType
        //  handleRefreshData
    } = useQueryAchievementType();

    const [dataModal, setDataModal] = useState<
        AchievementModalData | undefined
    >(undefined);
    const refModal = useRef<RefModalAchievement>(null);

    const isLoading = achievementType === undefined;
    const { recent = [], separated = [] } =
        (achievementType as achievementFormattedData) || {};

    useEffect(() => {
        handleQueryData();
    }, []);

    return (
        <div className={styles["achievemtnts"]}>
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Workout History - Achievements Tab | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                {isLoading && <AvironLoading />}

                <div className="rightMainContent">
                    <div className={styles["achievement-content"]}>
                        <AchievementsRecent
                            recent={recent}
                            setDataModal={setDataModal}
                            refModal={refModal}
                        />
                        <AchievementsAll
                            separated={separated}
                            setDataModal={setDataModal}
                            refModal={refModal}
                        />
                    </div>
                </div>
            </div>
            <AchievementsModal
                ref={refModal}
                dataModal={dataModal as AchievementModalData}
            />
        </div>
    );
};

export default Achievements;
