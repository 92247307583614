import React from 'react';
import { Modal, Backdrop, Fade, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import "./style.scss";

const useStyles: any = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const SetPasswordModal = (props: any) => {
  const { open, onClose } = props;
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="set-pwd-modal-component">

          <button className="close-btn" onClick={onClose}>
            <img src="/images/icons/x-icon.svg" width={18} height={18} alt="icon" />
          </button>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src="/images/icons/aviron-logo.svg" width={48} height={48} alt="icon" />
          </div>

          <div style={{ padding: '20px' }}>
            <div style={{ textAlign: 'center', fontWeight: "bold", fontSize: "24px", color: "#161616", fontFamily: 'GRIFTER' }}>
              SET PASSWORD
            </div>
          </div>

          <div style={{ padding: '0 30px', textAlign: 'center', fontFamily: "Work Sans" }}>
            We sent you an email to set your password.<br /> Please check your inbox.
          </div>

          <div className="buttons-group">
            <Button type="button" variant="contained"
              onClick={onClose}
              sx={{
                height: "64px",
                background: "#F5490F",
                borderRadius: "12px"
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default SetPasswordModal;