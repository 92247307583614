import * as React from "react";
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import "./style.scss";

const PageNotFound = () => {
    return (
        <Box id="not-found-page" textAlign="center">
            <div id="not-found-page__image">
                <img
                    alt="Not found page"
                    className="background-image"
                    src="https://cdn.rowaviron.com/web/share/404.png"
                    width="100%"
                    height="100%"
                />
            </div>
            <div id="not-found-page__content">
                <Typography variant="h1" component="h1">404</Typography>
                <Typography variant="h3" component="h3">Page doesn't exists</Typography>
                <Typography variant="body2" component="p">Sorry, the content you’re looking for doesn’t exist. Either it was removed, or you mistyped the link.</Typography>
            </div>
            <Box>
                <Link to="/profile"><Button variant="contained">BACK TO PROFILE</Button></Link>
            </Box>
        </Box>
    );
}

export default PageNotFound;