import GLOBAL_FUNCTIONS from "scripts/global-functions";
import { ACTION_TYPES } from "../types/action-types";
import { BaseReducer } from '../base/base.reducer';

const initialState = {
    is_initiated_page: false,
    user_id: "",
    user_email: "",
    user_first_name: "",
    user_last_name: "",
    user_role: "",
    user_avatar: "",
    message_box_open: false,
    message_box_type: undefined,
    message_box_title: "",
    message_box_message: "",
    message_box_button_type: "",
    message_box_functions: undefined,
    message_box_options: undefined,
    current_page: ""
};

class GlobalReducer extends BaseReducer {
    constructor() {
        super("GLOBAL", initialState, {
            openMessageBox: ACTION_TYPES.GLOBAL.OPEN_MESSAGE_BOX,
            closeMessageBox: ACTION_TYPES.GLOBAL.CLOSE_MESSAGE_BOX,
        });
    }
    reducer = (
        state: any = this.initState,
        action: {
            type: string;
            payload: any;
        }
    ) => {
        switch (action.type) {
            case this.actions.setState:
                state = GLOBAL_FUNCTIONS.SetObject(state, action.payload);
                break;
            case this.actions.openMessageBox:
                state = this.OpenMessageBox(state, action);
                break;
            case this.actions.closeMessageBox:
                state = this.closeMessageBox(state, action);
                break;
            default:
                break;
        }
        return state;
    };
    closeMessageBox = (
        state: any,
        action: {
            type: string;
            payload: any;
        }
    ) => {
        const newState = Object.assign(state, {
            message_box_open: false,
            message_box_type: undefined,
            message_box_title: "",
            message_box_message: "",
            message_box_button_type: "",
            message_box_functions: undefined,
            message_box_options: undefined,
        });
        return newState;
    };
    OpenMessageBox = (
        state: any,
        action: {
            type: string;
            payload: any;
        }
    ) => {
        const newState = Object.assign({}, state);
        const { payload = {} } = action;
        const {
            open = true,
            type,
            title,
            message,
            button_type,
            functions,
            options,
        } = payload;

        newState.message_box_open = open;
        if (type !== undefined) {
            newState.message_box_type = type;
        }
        if (title !== undefined) {
            newState.message_box_title = title;
        }
        if (message !== undefined) {
            newState.message_box_message = message;
        }
        if (button_type !== undefined) {
            newState.message_box_button_type = button_type;
        }
        if (functions !== undefined) {
            newState.message_box_functions = functions;
        }
        if (options !== undefined) {
            newState.message_box_options = options;
        }
        return newState;
    };
}

const globalReducer = new GlobalReducer();

export { globalReducer };
