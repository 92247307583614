import React, { Ref, forwardRef, useImperativeHandle, useState } from "react";
import { Backdrop, Button, Fade, Modal, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./RegisterModalGenerateCode.module.scss";

const iconCopy = "/images/icons/copy-icon.svg";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        justifyContent: "center",
        minHeight: 100,
        maxHeight: 500,
        marginTop: 80,
    },
});

export interface RefModalGenerate {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RegisterBetaModalGenerateCodeProps {
    codeGenerate: string;
}

const RegisterBetaModalGenerateCode = (
    props: RegisterBetaModalGenerateCodeProps,
    ref: Ref<RefModalGenerate>
) => {
    const { codeGenerate } = props;
    const [isVisible, setIsVisible] = useState(false);
    const [copyText, setCopyText] = useState("Copy");
    const classes = useStyles();

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    const handleCopyValue = () => {
        navigator.clipboard.writeText(codeGenerate);
        setCopyText("Copied");
    };

    const handleCloseModal = () => {
        setIsVisible(false);
        setCopyText("Copy");
    };

    return (
        <Modal
            open={isVisible}
            onClose={handleCloseModal}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isVisible}>
                <div className={styles["register-beta-modal-generate"]}>
                    <div
                        className={
                            styles["register-beta-modal-generate__close"]
                        }
                    >
                        <button className="" onClick={handleCloseModal}>
                            <img
                                src="/images/icons/x-icon.svg"
                                width={18}
                                height={18}
                                alt="icon"
                            />
                        </button>
                    </div>

                    <div style={{ padding: "20px", outline: "none" }}>
                        <h2
                            style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                color: "#161616",
                                fontFamily: "GRIFTER",
                            }}
                        >
                            GENERATE BETA CODE SUCCESSFUL
                        </h2>
                        <p>Please paste this code on your rower</p>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0 10px",
                                    border: "2px solid #c3c3c3",
                                    borderRadius: 4,
                                    marginBottom: "50px",
                                }}
                            >
                                <p
                                    style={{
                                        fontWeight: 600,
                                        color: "#161616",
                                        fontSize: 20,
                                        letterSpacing: 6,
                                    }}
                                >
                                    {codeGenerate}
                                </p>
                                <div style={{ position: "relative" }}>
                                    <Tooltip
                                        title={copyText}
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    backgroundColor:
                                                        "transparent",
                                                    color: "#F5490F",
                                                    fontWeight: "bold",
                                                    fontSize: "11px",
                                                    position: "absolute",
                                                    right: "-30px",
                                                    top: "-10px",
                                                },
                                            },
                                        }}
                                    >
                                        <img
                                            src={iconCopy}
                                            alt="icon"
                                            style={{ cursor: "pointer" }}
                                            onClick={handleCopyValue}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={handleCloseModal}
                                    sx={{
                                        background: "#F5490F",
                                        borderRadius: "12px",
                                        color: "#fff",
                                        width: "40%",
                                    }}
                                >
                                    OKAY
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default forwardRef(RegisterBetaModalGenerateCode);
