import merge from 'lodash/merge';
export class BaseAPI {
    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }
    baseUrl: string
    protected _parseUrl(path: string) {
        return `${this.baseUrl}/${path}`
    }
    protected _paserQuery(query: any = {}) {
        let parsedQuery = merge({}, query);
        if (query.filter) {
            parsedQuery.filter = JSON.stringify(query.filter);
        }
        if (query.order) {
            parsedQuery.order = JSON.stringify(query.order);
        }
        if (query.scopes) {
            parsedQuery.scopes = JSON.stringify(query.scopes);
        }
        if (query.fields) {
            parsedQuery.fields = JSON.stringify(query.fields);
        }
        if (query.items) {
            parsedQuery.items = JSON.stringify(query.items);
        }
        if (query.populates) {
            parsedQuery.populates = JSON.stringify(query.populates);
        }
        if (query.limit) {
            parsedQuery.limit = JSON.stringify(query.limit);
        }
        if (query.offset) {
            parsedQuery.offset = JSON.stringify(query.offset);
        }
        return parsedQuery;
    }
    protected _serialize(obj: any) {
        const keys = Object.keys(obj);
        let query = "?";
        for (const key of keys) {
            query +=
                typeof obj[key] === "string"
                    ? `${key}=${obj[key]}&`
                    : `${key}=${JSON.stringify(obj[key])}&`;
        }
        return query;
    }
    protected async _exec(url: string, options: any) {
        try {
            return await fetch(url, options).then(result => {
                return result.json();
            });
        } catch (err) {
            throw err;
        }
    }

}
