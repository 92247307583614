import React from 'react';
import { Typography, Box } from '@mui/material'

const PageTitle: React.FunctionComponent = ({ children }) => {
    return (
        <Box display={"flex"} alignItems="center">
            <Typography component="h1" sx={{
                fontWeight: 300,
                fontSize: '28px',
                letterSpacing: '-0.24px',
                lineHeight: '32px',
                fontFamily: "Work Sans",
                display: {
                    xs: "none",
                    md: "block"
                },
                textTransform: "uppercase"
            }}>{children}</Typography>
        </Box>
    )
}

export default PageTitle;