// source: workout/workoutData.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.workout.GameConfigholder', null, global);
goog.exportSymbol('proto.workout.GameDifficult', null, global);
goog.exportSymbol('proto.workout.GameMode', null, global);
goog.exportSymbol('proto.workout.WorkoutCollection', null, global);
goog.exportSymbol('proto.workout.WorkoutData', null, global);
goog.exportSymbol('proto.workout.WorkoutHistory', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.workout.WorkoutCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.workout.WorkoutCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.workout.WorkoutCollection.displayName = 'proto.workout.WorkoutCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.workout.WorkoutData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.workout.WorkoutData.repeatedFields_, null);
};
goog.inherits(proto.workout.WorkoutData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.workout.WorkoutData.displayName = 'proto.workout.WorkoutData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.workout.GameConfigholder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.workout.GameConfigholder.repeatedFields_, null);
};
goog.inherits(proto.workout.GameConfigholder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.workout.GameConfigholder.displayName = 'proto.workout.GameConfigholder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.workout.WorkoutHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.workout.WorkoutHistory.repeatedFields_, null);
};
goog.inherits(proto.workout.WorkoutHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.workout.WorkoutHistory.displayName = 'proto.workout.WorkoutHistory';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.workout.WorkoutCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.workout.WorkoutCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.workout.WorkoutCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.workout.WorkoutCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    collection: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && proto.workout.WorkoutHistory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.workout.WorkoutCollection}
 */
proto.workout.WorkoutCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.workout.WorkoutCollection;
  return proto.workout.WorkoutCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.workout.WorkoutCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.workout.WorkoutCollection}
 */
proto.workout.WorkoutCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollection(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = new proto.workout.WorkoutHistory;
      reader.readMessage(value,proto.workout.WorkoutHistory.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.workout.WorkoutCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.workout.WorkoutCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.workout.WorkoutCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.workout.WorkoutCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollection();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.workout.WorkoutHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string collection = 1;
 * @return {string}
 */
proto.workout.WorkoutCollection.prototype.getCollection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutCollection} returns this
 */
proto.workout.WorkoutCollection.prototype.setCollection = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.workout.WorkoutCollection.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutCollection} returns this
 */
proto.workout.WorkoutCollection.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional WorkoutHistory value = 3;
 * @return {?proto.workout.WorkoutHistory}
 */
proto.workout.WorkoutCollection.prototype.getValue = function() {
  return /** @type{?proto.workout.WorkoutHistory} */ (
    jspb.Message.getWrapperField(this, proto.workout.WorkoutHistory, 3));
};


/**
 * @param {?proto.workout.WorkoutHistory|undefined} value
 * @return {!proto.workout.WorkoutCollection} returns this
*/
proto.workout.WorkoutCollection.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.workout.WorkoutCollection} returns this
 */
proto.workout.WorkoutCollection.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.workout.WorkoutCollection.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.workout.WorkoutData.repeatedFields_ = [1,2,3,5,8,9,10,14,15,21,25,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.workout.WorkoutData.prototype.toObject = function(opt_includeInstance) {
  return proto.workout.WorkoutData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.workout.WorkoutData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.workout.WorkoutData.toObject = function(includeInstance, msg) {
  var f, obj = {
    spmList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    speedList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    wattsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    avgspm: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    metersList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    avgwatt: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    avgspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    caloriesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    datatimerList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f,
    secper500List: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    totaltime: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    activetime: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    avgsecp500: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    calperhourList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 14)) == null ? undefined : f,
    kilojoulesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 15)) == null ? undefined : f,
    totalmeters: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    totaloutputs: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    avgcalperhour: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totalcalories: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    strokes: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    dataheartrateList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 21)) == null ? undefined : f,
    avgheartrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    maxspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    maxspm: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    resistanceList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    inclineList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 26)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.workout.WorkoutData}
 */
proto.workout.WorkoutData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.workout.WorkoutData;
  return proto.workout.WorkoutData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.workout.WorkoutData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.workout.WorkoutData}
 */
proto.workout.WorkoutData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSpm(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSpeed(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWatts(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAvgspm(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMeters(values[i]);
      }
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAvgwatt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAvgspeed(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCalories(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDatatimer(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSecper500(values[i]);
      }
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaltime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setActivetime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAvgsecp500(value);
      break;
    case 14:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCalperhour(values[i]);
      }
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addKilojoules(values[i]);
      }
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmeters(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaloutputs(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAvgcalperhour(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcalories(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStrokes(value);
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDataheartrate(values[i]);
      }
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAvgheartrate(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxspeed(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxspm(value);
      break;
    case 25:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addResistance(values[i]);
      }
      break;
    case 26:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIncline(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.workout.WorkoutData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.workout.WorkoutData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.workout.WorkoutData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.workout.WorkoutData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpmList();
  if (f.length > 0) {
    writer.writePackedFloat(
      1,
      f
    );
  }
  f = message.getSpeedList();
  if (f.length > 0) {
    writer.writePackedFloat(
      2,
      f
    );
  }
  f = message.getWattsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      3,
      f
    );
  }
  f = message.getAvgspm();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMetersList();
  if (f.length > 0) {
    writer.writePackedFloat(
      5,
      f
    );
  }
  f = message.getAvgwatt();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAvgspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getCaloriesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      8,
      f
    );
  }
  f = message.getDatatimerList();
  if (f.length > 0) {
    writer.writePackedFloat(
      9,
      f
    );
  }
  f = message.getSecper500List();
  if (f.length > 0) {
    writer.writePackedFloat(
      10,
      f
    );
  }
  f = message.getTotaltime();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getActivetime();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getAvgsecp500();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getCalperhourList();
  if (f.length > 0) {
    writer.writePackedFloat(
      14,
      f
    );
  }
  f = message.getKilojoulesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      15,
      f
    );
  }
  f = message.getTotalmeters();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getTotaloutputs();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getAvgcalperhour();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotalcalories();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getStrokes();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getDataheartrateList();
  if (f.length > 0) {
    writer.writePackedFloat(
      21,
      f
    );
  }
  f = message.getAvgheartrate();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getMaxspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getMaxspm();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
  f = message.getResistanceList();
  if (f.length > 0) {
    writer.writePackedInt32(
      25,
      f
    );
  }
  f = message.getInclineList();
  if (f.length > 0) {
    writer.writePackedFloat(
      26,
      f
    );
  }
};


/**
 * repeated float spm = 1;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getSpmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setSpmList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addSpm = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearSpmList = function() {
  return this.setSpmList([]);
};


/**
 * repeated float speed = 2;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getSpeedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setSpeedList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addSpeed = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearSpeedList = function() {
  return this.setSpeedList([]);
};


/**
 * repeated float watts = 3;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getWattsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setWattsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addWatts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearWattsList = function() {
  return this.setWattsList([]);
};


/**
 * optional float avgSPM = 4;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getAvgspm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setAvgspm = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated float meters = 5;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getMetersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setMetersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addMeters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearMetersList = function() {
  return this.setMetersList([]);
};


/**
 * optional float avgWatt = 6;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getAvgwatt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setAvgwatt = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float avgSpeed = 7;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getAvgspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setAvgspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * repeated float calories = 8;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getCaloriesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setCaloriesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addCalories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearCaloriesList = function() {
  return this.setCaloriesList([]);
};


/**
 * repeated float dataTimer = 9;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getDatatimerList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setDatatimerList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addDatatimer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearDatatimerList = function() {
  return this.setDatatimerList([]);
};


/**
 * repeated float secPer500 = 10;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getSecper500List = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setSecper500List = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addSecper500 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearSecper500List = function() {
  return this.setSecper500List([]);
};


/**
 * optional float totalTime = 11;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getTotaltime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setTotaltime = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float activeTime = 12;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getActivetime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setActivetime = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float avgSecp500 = 13;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getAvgsecp500 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setAvgsecp500 = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * repeated float calPerHour = 14;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getCalperhourList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setCalperhourList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addCalperhour = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearCalperhourList = function() {
  return this.setCalperhourList([]);
};


/**
 * repeated float kiloJoules = 15;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getKilojoulesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setKilojoulesList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addKilojoules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearKilojoulesList = function() {
  return this.setKilojoulesList([]);
};


/**
 * optional float totalMeters = 16;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getTotalmeters = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setTotalmeters = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float totalOutputs = 17;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getTotaloutputs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setTotaloutputs = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float avgCalPerHour = 18;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getAvgcalperhour = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setAvgcalperhour = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalCalories = 19;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getTotalcalories = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setTotalcalories = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float strokes = 20;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getStrokes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setStrokes = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * repeated float dataHeartRate = 21;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getDataheartrateList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setDataheartrateList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addDataheartrate = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearDataheartrateList = function() {
  return this.setDataheartrateList([]);
};


/**
 * optional float avgHeartRate = 22;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getAvgheartrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setAvgheartrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional float maxSpeed = 23;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getMaxspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setMaxspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional float maxSpm = 24;
 * @return {number}
 */
proto.workout.WorkoutData.prototype.getMaxspm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setMaxspm = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * repeated int32 resistance = 25;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getResistanceList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setResistanceList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addResistance = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearResistanceList = function() {
  return this.setResistanceList([]);
};


/**
 * repeated float incline = 26;
 * @return {!Array<number>}
 */
proto.workout.WorkoutData.prototype.getInclineList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 26));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.setInclineList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.addIncline = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutData} returns this
 */
proto.workout.WorkoutData.prototype.clearInclineList = function() {
  return this.setInclineList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.workout.GameConfigholder.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.workout.GameConfigholder.prototype.toObject = function(opt_includeInstance) {
  return proto.workout.GameConfigholder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.workout.GameConfigholder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.workout.GameConfigholder.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gamemodeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    gametype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gamedifficult: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gameduration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gameoptionsMap: (f = msg.getGameoptionsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.workout.GameConfigholder}
 */
proto.workout.GameConfigholder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.workout.GameConfigholder;
  return proto.workout.GameConfigholder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.workout.GameConfigholder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.workout.GameConfigholder}
 */
proto.workout.GameConfigholder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameid(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.workout.GameMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGamemode(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGametype(value);
      break;
    case 4:
      var value = /** @type {!proto.workout.GameDifficult} */ (reader.readEnum());
      msg.setGamedifficult(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGameduration(value);
      break;
    case 6:
      var value = msg.getGameoptionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.workout.GameConfigholder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.workout.GameConfigholder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.workout.GameConfigholder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.workout.GameConfigholder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGamemodeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getGametype();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGamedifficult();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGameduration();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getGameoptionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string gameID = 1;
 * @return {string}
 */
proto.workout.GameConfigholder.prototype.getGameid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.GameConfigholder} returns this
 */
proto.workout.GameConfigholder.prototype.setGameid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated GameMode gameMode = 2;
 * @return {!Array<!proto.workout.GameMode>}
 */
proto.workout.GameConfigholder.prototype.getGamemodeList = function() {
  return /** @type {!Array<!proto.workout.GameMode>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.workout.GameMode>} value
 * @return {!proto.workout.GameConfigholder} returns this
 */
proto.workout.GameConfigholder.prototype.setGamemodeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.workout.GameMode} value
 * @param {number=} opt_index
 * @return {!proto.workout.GameConfigholder} returns this
 */
proto.workout.GameConfigholder.prototype.addGamemode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.GameConfigholder} returns this
 */
proto.workout.GameConfigholder.prototype.clearGamemodeList = function() {
  return this.setGamemodeList([]);
};


/**
 * optional int64 gameType = 3;
 * @return {number}
 */
proto.workout.GameConfigholder.prototype.getGametype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.workout.GameConfigholder} returns this
 */
proto.workout.GameConfigholder.prototype.setGametype = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional GameDifficult gameDifficult = 4;
 * @return {!proto.workout.GameDifficult}
 */
proto.workout.GameConfigholder.prototype.getGamedifficult = function() {
  return /** @type {!proto.workout.GameDifficult} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.workout.GameDifficult} value
 * @return {!proto.workout.GameConfigholder} returns this
 */
proto.workout.GameConfigholder.prototype.setGamedifficult = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 GameDuration = 5;
 * @return {number}
 */
proto.workout.GameConfigholder.prototype.getGameduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.workout.GameConfigholder} returns this
 */
proto.workout.GameConfigholder.prototype.setGameduration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * map<string, string> gameOptions = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.workout.GameConfigholder.prototype.getGameoptionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.workout.GameConfigholder} returns this
 */
proto.workout.GameConfigholder.prototype.clearGameoptionsMap = function() {
  this.getGameoptionsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.workout.WorkoutHistory.repeatedFields_ = [11,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.workout.WorkoutHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.workout.WorkoutHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.workout.WorkoutHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.workout.WorkoutHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scoretitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scorevalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isfullmatch: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    workoutdata: (f = msg.getWorkoutdata()) && proto.workout.WorkoutData.toObject(includeInstance, f),
    timezoneId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hsCollection: jspb.Message.getFieldWithDefault(msg, 7, ""),
    timezoneCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createtimeunix: jspb.Message.getFieldWithDefault(msg, 9, 0),
    racingposition: jspb.Message.getFieldWithDefault(msg, 10, ""),
    currentgamemodeList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    timezoneOffset: jspb.Message.getFieldWithDefault(msg, 12, 0),
    gameconfigholder: (f = msg.getGameconfigholder()) && proto.workout.GameConfigholder.toObject(includeInstance, f),
    totalcompetitors: jspb.Message.getFieldWithDefault(msg, 14, 0),
    timemachinegamedata: jspb.Message.getFieldWithDefault(msg, 15, ""),
    metadata: jspb.Message.getFieldWithDefault(msg, 16, ""),
    createtime: jspb.Message.getFieldWithDefault(msg, 17, ""),
    createtimetzuser: jspb.Message.getFieldWithDefault(msg, 18, 0),
    tmmatchid: jspb.Message.getFieldWithDefault(msg, 19, ""),
    tmuserid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    id: jspb.Message.getFieldWithDefault(msg, 21, ""),
    name: jspb.Message.getFieldWithDefault(msg, 22, ""),
    gametype: jspb.Message.getFieldWithDefault(msg, 23, 0),
    iconurl: jspb.Message.getFieldWithDefault(msg, 24, ""),
    tagList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    machineid: jspb.Message.getFieldWithDefault(msg, 26, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.workout.WorkoutHistory}
 */
proto.workout.WorkoutHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.workout.WorkoutHistory;
  return proto.workout.WorkoutHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.workout.WorkoutHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.workout.WorkoutHistory}
 */
proto.workout.WorkoutHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoretitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorevalue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfullmatch(value);
      break;
    case 5:
      var value = new proto.workout.WorkoutData;
      reader.readMessage(value,proto.workout.WorkoutData.deserializeBinaryFromReader);
      msg.setWorkoutdata(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezoneId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHsCollection(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezoneCode(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatetimeunix(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRacingposition(value);
      break;
    case 11:
      var values = /** @type {!Array<!proto.workout.GameMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCurrentgamemode(values[i]);
      }
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimezoneOffset(value);
      break;
    case 13:
      var value = new proto.workout.GameConfigholder;
      reader.readMessage(value,proto.workout.GameConfigholder.deserializeBinaryFromReader);
      msg.setGameconfigholder(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcompetitors(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimemachinegamedata(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadata(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatetime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatetimetzuser(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTmmatchid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTmuserid(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGametype(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconurl(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.workout.WorkoutHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.workout.WorkoutHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.workout.WorkoutHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.workout.WorkoutHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScoretitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScorevalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsfullmatch();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getWorkoutdata();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.workout.WorkoutData.serializeBinaryToWriter
    );
  }
  f = message.getTimezoneId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHsCollection();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTimezoneCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatetimeunix();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getRacingposition();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrentgamemodeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      11,
      f
    );
  }
  f = message.getTimezoneOffset();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getGameconfigholder();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.workout.GameConfigholder.serializeBinaryToWriter
    );
  }
  f = message.getTotalcompetitors();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getTimemachinegamedata();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMetadata();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCreatetime();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCreatetimetzuser();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getTmmatchid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTmuserid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getGametype();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getIconurl();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getMachineid();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
};


/**
 * optional string matchId = 1;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getMatchid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setMatchid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scoreTitle = 2;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getScoretitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setScoretitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scoreValue = 3;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getScorevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setScorevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isFullMatch = 4;
 * @return {boolean}
 */
proto.workout.WorkoutHistory.prototype.getIsfullmatch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setIsfullmatch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional WorkoutData workoutData = 5;
 * @return {?proto.workout.WorkoutData}
 */
proto.workout.WorkoutHistory.prototype.getWorkoutdata = function() {
  return /** @type{?proto.workout.WorkoutData} */ (
    jspb.Message.getWrapperField(this, proto.workout.WorkoutData, 5));
};


/**
 * @param {?proto.workout.WorkoutData|undefined} value
 * @return {!proto.workout.WorkoutHistory} returns this
*/
proto.workout.WorkoutHistory.prototype.setWorkoutdata = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.clearWorkoutdata = function() {
  return this.setWorkoutdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.workout.WorkoutHistory.prototype.hasWorkoutdata = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string _timezone_id = 6;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getTimezoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setTimezoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string _hs_collection = 7;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getHsCollection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setHsCollection = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string _timezone_code = 8;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getTimezoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setTimezoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 createTimeUnix = 9;
 * @return {number}
 */
proto.workout.WorkoutHistory.prototype.getCreatetimeunix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setCreatetimeunix = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string racingPosition = 10;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getRacingposition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setRacingposition = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated GameMode currentGameMode = 11;
 * @return {!Array<!proto.workout.GameMode>}
 */
proto.workout.WorkoutHistory.prototype.getCurrentgamemodeList = function() {
  return /** @type {!Array<!proto.workout.GameMode>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<!proto.workout.GameMode>} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setCurrentgamemodeList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!proto.workout.GameMode} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.addCurrentgamemode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.clearCurrentgamemodeList = function() {
  return this.setCurrentgamemodeList([]);
};


/**
 * optional int64 _timezone_offset = 12;
 * @return {number}
 */
proto.workout.WorkoutHistory.prototype.getTimezoneOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setTimezoneOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional GameConfigholder gameConfigHolder = 13;
 * @return {?proto.workout.GameConfigholder}
 */
proto.workout.WorkoutHistory.prototype.getGameconfigholder = function() {
  return /** @type{?proto.workout.GameConfigholder} */ (
    jspb.Message.getWrapperField(this, proto.workout.GameConfigholder, 13));
};


/**
 * @param {?proto.workout.GameConfigholder|undefined} value
 * @return {!proto.workout.WorkoutHistory} returns this
*/
proto.workout.WorkoutHistory.prototype.setGameconfigholder = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.clearGameconfigholder = function() {
  return this.setGameconfigholder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.workout.WorkoutHistory.prototype.hasGameconfigholder = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 totalCompetitors = 14;
 * @return {number}
 */
proto.workout.WorkoutHistory.prototype.getTotalcompetitors = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setTotalcompetitors = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string timeMachineGameData = 15;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getTimemachinegamedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setTimemachinegamedata = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string metadata = 16;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string createTime = 17;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getCreatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setCreatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 createTimeTzUser = 18;
 * @return {number}
 */
proto.workout.WorkoutHistory.prototype.getCreatetimetzuser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setCreatetimetzuser = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string tmMatchId = 19;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getTmmatchid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setTmmatchid = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string tmUserId = 20;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getTmuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setTmuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string id = 21;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string name = 22;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional int32 gameType = 23;
 * @return {number}
 */
proto.workout.WorkoutHistory.prototype.getGametype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setGametype = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string iconUrl = 24;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getIconurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setIconurl = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated string tag = 25;
 * @return {!Array<string>}
 */
proto.workout.WorkoutHistory.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.clearTagList = function() {
  return this.setTagList([]);
};


/**
 * optional string machineId = 26;
 * @return {string}
 */
proto.workout.WorkoutHistory.prototype.getMachineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.workout.WorkoutHistory} returns this
 */
proto.workout.WorkoutHistory.prototype.setMachineid = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * @enum {number}
 */
proto.workout.GameDifficult = {
  NONE: 0,
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
  PAIN: 4
};

/**
 * @enum {number}
 */
proto.workout.GameMode = {
  SINGLEPLAYER: 0,
  MULTIPLAYER: 1,
  LIVE: 2,
  ENDLESS: 3,
  TIMEMACHINE: 4
};

goog.object.extend(exports, proto.workout);
