import {
    Backdrop,
    Box,
    Button,
    Fade,
    IconButton,
    Modal,
    Typography,
    Grid
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import "./style.scss";
import Close from "@mui/icons-material/Close";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});


const ReactiveMembershipNoticeWithoutPromoModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
}> = ({ open, onClose }) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="reactive-membership-notice-wop-component">
                    <IconButton onClick={onClose} className="close-button">
                        <Close></Close>
                    </IconButton>
                    <div className="reactive-membership-notice-wop-component__main">
                        <Box className="reactive-membership-notice-wop-component__main__head">
                            <Grid container>
                                <Grid item xs={6}>
                                    <img src="https://avironactive.com/assets/icon/aviron-logo.svg" alt="" className="reactive-aviron-logo" />
                                    <h2>WELCOME BACK</h2>
                                </Grid>
                                <Grid item xs={6} pl={{
                                    md: "56px",
                                    xs: "24px"
                                }}>
                                    <img src="/images/membership/hand-gift.png" alt="" width="100%" />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            textAlign={"center"}
                            className="reactive-membership-notice-wop-component__main__item"
                        >
                            <Typography fontSize={"16px"} mb="12px">
                                We've missed you!  We've added a ton of new product features since we last saw you, including streaming service expansion, Apple Watch integration, new addictive games and programs, and so much more.
                            </Typography>
                            <Typography fontSize={"16px"} mb="12px">
                                We invite you to reactivate your membership and rediscover what Aviron has to offer. Simply select a previous membership option after this screen, and let's get you back to experiencing the best in connected fitness.
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={onClose}
                                color="secondary"
                                sx={{
                                    marginTop: "56px",
                                    minWidth: "280px !important",
                                }}
                            >
                                OKAY
                            </Button>
                            <br />
                        </Box>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ReactiveMembershipNoticeWithoutPromoModal;
