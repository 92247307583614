import dayjsLib from 'dayjs';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
const dayjs = dayjsLib;
dayjs.extend(utc)
dayjs.extend(timezone)

const generateDateObjByTimezone = (date:any, timeZone: any) => {
  if(timeZone) {
    //@ts-ignore
    const rs = dayjs(date).tz(timeZone);
    return rs;
  } else {
    return null;
  }
}
export {generateDateObjByTimezone};