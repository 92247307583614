import React from 'react';
import { Modal, Backdrop, Fade, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import "./style.scss";

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const useStyles: any = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const ExportCSVModal = (props: any) => {
  const { open, onClose, exportCSVToEmail } = props;
  const classes = useStyles();

  const [email, setEmail] = React.useState<string>("");
  const [isSent, setIsSent] = React.useState<boolean>(false);
  const [emailValid, setEmailValid] = React.useState<string>("");

  const handleChangeEmail = (ev: any) => {
    const { value } = ev.target
    setEmail(value)
  }

  const onClickClose = () => {
    onClose(true)
    setIsSent(false)
  }

  const handleSubmit = async () => {
    setEmailValid('')
    if (!email || !emailRegExp.test(email)) {
      setEmailValid('Please input valid email !')
      return
    }
    await exportCSVToEmail(email)
    setIsSent(true)
  }

  return (
    <Modal open={open} onClose={onClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="set-pwd-modal-component">

          <button className="close-btn" onClick={onClickClose}>
            <img src="/images/icons/x-icon.svg" width={18} height={18} alt='icon' />
          </button>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src="/images/icons/aviron-logo.svg" width={48} height={48} alt='icon' />
          </div>

          <div style={{ padding: '20px' }}>
            <div style={{ textAlign: 'center', fontWeight: "bold", fontSize: "24px", color: "#161616", fontFamily: 'GRIFTER' }}>
            {!isSent ? 'PLEASE ENTER YOUR EMAIL' : 'Workout History Sent'}
            </div>
          </div>
          {!isSent ? (<div style={{ padding: '20px 40px' }}>
              <TextField
                  name="confirmEmail" label="Enter your email"
                  variant="outlined" type="text"
                  fullWidth
                  onChange={(event) => handleChangeEmail(event)}
                  value={email} />
              </div>) : null }

            {/* { isSent ? (<div style={{ padding: '0 30px', textAlign: 'center', fontFamily: "Work Sans" }}>
              We sent you an email with workout history attachment.<br /> Please check your inbox.
            </div>) : null } */}
            { emailValid ? (<div style={{ padding: '0 30px', textAlign: 'center', fontFamily: "Work Sans", color: "red" }}>
              {emailValid}
            </div>) : null }

            <div className="buttons-group" style={{display: 'flex', justifyContent: 'center'}}>
              {!isSent ? (<Button type="submit" variant="contained" color="secondary"
                onClick={handleSubmit}
                  sx={{
                    width: '45%',
                    height: "50px",
                    background: "#F5490F"
                  }}
                >
                  OK
                </Button>) : null }
                {isSent ? (<Button type="button" variant="contained"
                onClick={onClickClose}
                sx={{
                  width: '45%',
                  height: "50px",
                  background: "black",
                  borderRadius: "12px"
                }}
              >
                CLOSE
              </Button>) : null}
            </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ExportCSVModal;