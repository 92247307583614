import React from "react";
import style from "./Header.module.scss";
import InputSearch from "Components/base/InputSearch";
import InviteButton from "Components/base/Button/InviteButton";
import VerticalTabs from "Components/base/TabsLink/VerticalTabs";
import { CONSTANTS } from "consts";
import { useParams } from "react-router-dom";

interface IHeader {
    handleClickInviteMemberButton: any
    handleSearch?: any
}

const Header = ({ handleClickInviteMemberButton, handleSearch }: any) => {
    const params = useParams();
    const dataTab = [
        {
            label: "Family Member",
            //@ts-ignore
            link: `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_FAMILLY}`.replace(':id', params?.id),
        },
        {
            label: "INVITED LIST",
             //@ts-ignore
            link: `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_LIST}`.replace(':id', params?.id),
        },
    ];
    return (
        <div className={style["Header"]}>
            <div className={style['searchHeader']}>
                <div className={style["subTitle"]}>Member List</div>
                <div className={style["toolbar"]}>
                    <div className={style["search"]}>
                        <InputSearch
                            placeholder={"Search by email or user name"}
                            handleSearch={handleSearch}
                        />
                    </div>
                    <InviteButton onClick={handleClickInviteMemberButton} />
                </div>
            </div>
            <div className={style["membershipTab"]}>
                <VerticalTabs
                    orientation={"horizontal"}
                    dataTab={dataTab}
                    tabName={"profileTab"}
                />
            </div>
        </div>
    );
};

export default Header;
