import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getOperationAST } from "graphql";
import { SubscriptionClient } from "subscriptions-transport-ws";
import ws from "ws";
import { ENV } from './environment'
import { handleCRMServerError } from "./utils/handleCRMServerError";
import { getExceptionFromError } from "./utils/handleErrors";

let wsClient: SubscriptionClient | null = null;

export function resetWebsocketConnection(): void {
    if (wsClient) {
        wsClient.close(false, false);
    }
}

function makeVendureLink(VENDURE_URL: string) {
    const httpLink = new HttpLink({
        uri: `${VENDURE_URL}/graphql`,
        credentials: 'include',
    });
    return httpLink;
}

function makeClientSideLink(ROOT_URL: string) {
    const httpLink = new HttpLink({
        uri: `${ROOT_URL}/graphql`,
        credentials: "include",
        //credentials: "same-origin",
    });
    wsClient = new SubscriptionClient(
        `${ROOT_URL.replace(/^http/, "ws")}/graphql`,
        {
            reconnect: true,
        },
        typeof WebSocket !== "undefined" ? WebSocket : ws
    );
    const wsLink = new WebSocketLink(wsClient);

    // Using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent.
    const mainLink = split(
        // split based on operation type
        ({ query, operationName }) => {
            const op = getOperationAST(query, operationName);
            return (op && op.operation === "subscription") || false;
        },
        wsLink,
        httpLink
    );
    return mainLink;
}

export const createApolloClient = () => {
    const ROOT_URL = ENV.SERVER_DOMAIN;
    const VENDURE_URL = ENV.VENDURE_DOMAIN;
    const CRM_SERVER_URL = ENV.CRM_SERVER_DOMAIN;
    const onErrorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
                console.error(
                    `[GraphQL error]: message: ${message}, location: ${JSON.stringify(
                        locations
                    )}, path: ${JSON.stringify(path)}`
                )
            );
        if (networkError) console.error(`[Network error]: ${networkError}`);
    });
    const onCRMErrorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors){
            const extension: any = getExceptionFromError(graphQLErrors[0])
            handleCRMServerError(extension.code,extension.key,extension.message)
        }
        if (networkError) console.error(`[Network error]: ${networkError}`);
    });
    const mainLink = makeClientSideLink(ROOT_URL);
    const vendureLink = makeVendureLink(VENDURE_URL);
    const crmServerLink = makeVendureLink(CRM_SERVER_URL);

    // const authCrmLink = setContext((_, { headers }) => {
    //     // get the authentication token from local storage if it exists
    //     const token = localStorage.getItem('crmToken');
    //     // return the headers to the context so httpLink can read them
    //     return {
    //       headers: {
    //         ...headers,
    //         authorization: token ? `bearer ${token}` : "",
    //       }
    //     }
    //   });

    const client = new ApolloClient({
        link: ApolloLink.split(
            (operation) => operation.getContext().clientName === 'vendure',
            ApolloLink.from([onErrorLink, vendureLink]),
            ApolloLink.split(
                (operation) => operation.getContext().clientName === 'crm-server',
                // ApolloLink.from([onCRMErrorLink ,concat(authCrmLink, crmServerLink)]),
                ApolloLink.from([onCRMErrorLink , crmServerLink]),
                ApolloLink.from([onErrorLink, mainLink]),
            )
        ),
        cache: new InMemoryCache({
            dataIdFromObject: (o) =>
                o.__typename === "Query"
                    ? "ROOT_QUERY"
                    : o.id
                        ? `${o.__typename}:${o.id}`
                        : null,
        }).restore({}),
    });

    return client;
}
