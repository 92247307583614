import * as yup from "yup";

export const validationRegisterBetaModalCancel = yup.object().shape({
    reason: yup.string().required("Required"),
    otherReason: yup.string().test("other-reason", "Require", function (value) {
        const { reason } = this.parent;
        if (reason !== "other") return true;

        return Boolean(value);
    }),
});
