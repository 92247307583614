import useCompareStatisticWorkoutHook from "Hooks/CompareStatisticWorkoutHook/index";

export const useComparePeriodHook = () => {
    // const [workoutCompare, setWorkoutCompare] = useState();
    const {data, getCompareStatisticWorkoutSummary, loading} = useCompareStatisticWorkoutHook();
    const refechtWorkoutCompateData = (day: number) => {
        getCompareStatisticWorkoutSummary(day);
    }
    if (data) {
        return {
            //@ts-ignore
            workoutCompare: data,
            loading,
            refetchWorkoutCompare: refechtWorkoutCompateData,

        };
    } else {
        return {
            workoutCompare: null,
            loading,
            refetchWorkoutCompare: refechtWorkoutCompateData,
        };
    }
};
