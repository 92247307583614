import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import validate from "validate.js";
import CRC32 from 'crc-32';

const schema = {
    code: {
        presence: { allowEmpty: false, message: "is required" },
        length: {
            minimun: 8,
            maximum: 8
        },
    },
};

const useGenerateBetaCodeHook = () => {
    // const [hash, setHash] = useState<string | null>(null);

    const [formState, setFormState] = useState<any>({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState((formState: any) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleChange = (event: any) => {
        event.persist();
        setFormState((formState: any) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === "checkbox"
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (formState.values.code === "00000000") {
            return
        }

        const code = formState.values.code + "gReVTNzx";
        const hash =  (CRC32.buf(Buffer.from(code,"ascii"), 0)>>>0).toString(16);
        Swal.fire({ title: "Your code here", html: `<h2 style="font-family:Work Sans;font-size:28px;color:#F5490F;">${hash}</h2>`, icon: "success" });
        // setHash(hash);
    };

    const hasError = (field: string) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    return {
        formState,
        handleSubmit,
        hasError,
        handleChange,
    };
};

export default useGenerateBetaCodeHook;
