import React, { useEffect, useState } from "react";
import style from "./DaysSinceLastWorkoutCard.module.scss";
import CardSimple from "Components/CardSimple";
import { ReactComponent as ToDayIcon } from "./icons/0day.svg";
import { ReactComponent as OneDayIcon } from "./icons/1day.svg";
import { ReactComponent as ThreeDayIcon } from "./icons/3day.svg";
import { ReactComponent as EightDayIcon } from "./icons/8day.svg";
import { generateDateObjByTimezone } from "utils/generateDateObjByTimezone";
import { gameServerApi } from "services/gameServerV2";
import { UserAppContext } from "contexts";

const DaysSinceLastWorkoutCard = (props: any) => {
    const { timezone } = React.useContext(UserAppContext);
    const timeZone = timezone?.timezone;
    const [lastUserWorkoutInfo, setLastUserWorkoutInfo] = useState({
        icon: "",
        text: "---",
        createTime: "---",
    });

    const handleQueryData = () => {
        gameServerApi.workoutHistory.getLastUserWorkout().then((res) => {
            if (res?.status === 200) {
                //@ts-ignore
                setLastUserWorkoutInfo(formatLastWorkout(res?.data));
            } else {
                setLastUserWorkoutInfo({
                    icon: "",
                    text: "---",
                    createTime: "---",
                });
            }
        });
    };

    useEffect(() => {
        handleQueryData();
    }, []);

    const formatDateTime = (dateParam: any) => {
        const dateTime = generateDateObjByTimezone(dateParam, timeZone);
        if (dateTime) {
            return dateTime.format("DD MMM YYYY");
        }
        return "---";
    };
    const formatLastWorkout = (lastWorkout: any) => {
        if (lastWorkout) {
            const { diffDay, createTime, diffMonth, today } =
                lastWorkout;
            if (diffMonth) {
                return {
                    icon: <EightDayIcon />,
                    text: `${
                        diffMonth === 1
                            ? diffMonth + " MONTH"
                            : diffMonth + " MONTHS"
                    } SINCE YOUR LAST WORKOUT`,
                    createTime: createTime,
                };
            }
            if (diffDay === 0) {
                if (!today) {
                    return {
                        icon: <ToDayIcon />,
                        text: (
                            <>
                                THE BEST DAY TO START <br />
                                IS TODAY
                            </>
                        ),
                    };
                } else {
                    return {
                        icon: <ToDayIcon />,
                        text: (
                            <>
                                YOUR LAST WORKOUT <br />
                                WAS TODAY
                            </>
                        ),
                        createTime: createTime,
                    };
                }
            } else if (diffDay === 1) {
                return {
                    icon: <OneDayIcon />,
                    text: (
                        <>
                            YOUR LAST WORKOUT WAS <br />
                            YESTERDAY
                        </>
                    ),
                    createTime: createTime,
                };
            } else if (diffDay === 2) {
                return {
                    icon: <OneDayIcon />,
                    text: (
                        <>
                            {diffDay} DAYS SINCE YOUR LAST <br />
                            WORKOUT
                        </>
                    ),
                    createTime: createTime,
                };
            } else if (3 <= diffDay && diffDay <= 7) {
                return {
                    icon: <ThreeDayIcon />,
                    text: (
                        <>
                            {diffDay} DAYS SINCE YOUR LAST <br />
                            WORKOUT
                        </>
                    ),
                    createTime: createTime,
                };
            } else if (diffDay > 7) {
                return {
                    icon: <EightDayIcon />,
                    text: (
                        <>
                            {diffDay} DAYS SINCE YOUR LAST <br />
                            WORKOUT
                        </>
                    ),
                    createTime: createTime,
                };
            }
        }
    };
    return (
        <div className={style["DaysSinceLastWorkoutCard"]}>
            <CardSimple>
                {timeZone && lastUserWorkoutInfo && (
                    <div className={style["weekStreak"]}>
                        <div className={style["achieve"]}>
                            {lastUserWorkoutInfo?.icon}
                        </div>
                        <div className={style["rightContent"]}>
                            <div className={style["text"]}>
                                {lastUserWorkoutInfo?.text}
                                {lastUserWorkoutInfo?.createTime && (
                                    <span className={style["bestAchieve"]}>
                                        {" - "}
                                        {formatDateTime(
                                            lastUserWorkoutInfo?.createTime
                                        )}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </CardSimple>
        </div>
    );
};

export default DaysSinceLastWorkoutCard;
