import React, { Ref, forwardRef, useImperativeHandle } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

import { makeStyles } from "@mui/styles";
import styles from "./AchievementsModal.module.scss";
import { achievementListFormattedData } from "../Achievements.actions";

export interface RefModalAchievement {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
    },
});
export interface AchievementModalData {
    data: achievementListFormattedData;
    isCompleted: boolean;
}
interface AchievementsModalProps {
    dataModal: AchievementModalData;
}
const AchievementsModal = (
    props: AchievementsModalProps,
    ref: Ref<RefModalAchievement>
) => {
    const { dataModal } = props;
    const { data, isCompleted } = dataModal || {};

    const { description, name, iconLarge, iconGreyLarge } = data || {};

    const classes = useStyles();

    const [isVisible, setIsVisible] = React.useState(false);
    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    const img = isCompleted ? iconLarge : iconGreyLarge;

    return (
        <Modal
            open={isVisible}
            onClose={() => setIsVisible(false)}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={styles.modal}>
                <div className={styles["modal-header"]}>
                    <button
                        className="close-btn"
                        onClick={() => setIsVisible(false)}
                    >
                        <img
                            src="/images/icons/x-icon.svg"
                            width={16}
                            height={16}
                            alt="icon"
                        />
                    </button>
                </div>
                <div className={styles["modal-content"]}>
                    <img src={img} alt={img} loading="eager"  />
                    <h4 className={styles["modal-content-name"]}>{name}</h4>
                    <p>{description}</p>
                </div>
            </div>
        </Modal>
    );
};

export default forwardRef(AchievementsModal);
