import produce from "immer";
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS
} from "./userProfile.type";
import { IUserProfile } from "routes/useRouteElement.hook";


const initialState: {
    data: IUserProfile | undefined;
    loading: boolean;
} = {
    data: undefined,
    loading: false,
};

type actionType = {
    type: string;
    payload: any;
};
const reducer = produce((state, action: actionType) => {
    switch (action.type) {
        case GET_USER_PROFILE_REQUEST: {
            state.loading = true;
            state.data = undefined;
            return state;
        }
        case GET_USER_PROFILE_SUCCESS: {
            state.loading = false;
            state.data = action.payload;
            return state;
        }

        case GET_USER_PROFILE_FAILED: {
            state.loading = false;
            state.data = undefined;
            return state;
        }

        case UPDATE_USER_PROFILE_SUCCESS: {
            state.data = action.payload;
            return state
        }

        default:
            return state;
    }
}, initialState);

export const userProfileReducer = { initialState, reducer };
