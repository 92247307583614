import { UserAppContext } from "contexts";
import { useContext, useEffect, useState } from "react";
import { gameServerApi } from "services/gameServerV2";
import {
    getAchievementListRequest,
    getAchievementListSuccess,
    getAchievementListFailed,
} from "contexts/achievements/achievements.actions";
import { AxiosResponse } from "axios";

export interface separatedItem {
    id: string;
    type: string;
    typeName: string;
    value: number[];
    next: number[];
}

export interface achievementTypeResponse {
    achievement: {
        latest: number;
        recent: number[];
        separated: separatedItem[];
        value: number[];
    };
}

export interface achievementFormattedData {
    separated: separatedItem[];
    recent: number[];
}

const handleFormatDataAchievementType = (data: achievementTypeResponse) => {
    const { achievement } = data || {};
    let { separated = [], recent = [] } = achievement || {};

    return {
        separated: separated.map((item: separatedItem) => {
            const { value = [], next = [], type = "", typeName = "" } = item;
            return {
                id: type,
                typeName,
                value,
                next,
                type,
            };
        }),
        recent,
    };
};

export const useQueryAchievementType = () => {
    const [achievementType, setAchievementType] = useState<
        achievementFormattedData | undefined
    >(undefined);
    const handleQueryData = () => {
        try {
            gameServerApi.user.getAchievementType().then((res) => {
                const { data, status } = res as AxiosResponse;
                if (status === 200) {
                    const dataFormatted: achievementFormattedData =
                        handleFormatDataAchievementType(data);
                    setAchievementType(dataFormatted);
                } else {
                    setAchievementType(undefined);
                }
            });
        } catch (error) {
            setAchievementType(undefined);
        }
    };

    const handleRefreshData = () => {
        handleQueryData();
    };

    useEffect(() => {
        handleQueryData();
    }, []);

    return { achievementType, handleRefreshData };
};

// =====================================================

interface achievementListResponse {
    metadata: {
        currentPage: number;
        pageSize: number;
        firstPage: number;
        lastPage: number;
        totalRecords: number;
    };
    achievements: {
        id: number;
        name: string;
        type: string;
        description: string;
        explanation: string;
        icon: string;
        iconGrey: string;
        condition: any[];
        appId: string;
        status: string;
        clientIndex: string;
        createTime: Date;
        updateTime: Date;
    }[];
}

export interface achievementListFormattedData {
    id: number;
    type: string;
    name: string;
    description: string;
    icon: string;
    iconGrey: string;
    iconLarge: string;
    iconGreyLarge: string;
}
const handleFormatDataAchievementList = (
    data: achievementListResponse["achievements"]
) => {
    return data.map((item) => {
        const { id, name, type, description, icon, iconGrey } = item;
        const data: achievementListFormattedData = {
            id,
            type,
            name,
            description,
            icon,
            iconGrey,
            iconLarge: icon.replace(".png", "_256x256.png"),
            iconGreyLarge: iconGrey.replace(".png", "_256x256.png"),
        };
        return data;
    });
};

export const useQueryAllAchievement = () => {
    const { achievementListDispatch: dispatch } = useContext(UserAppContext);

    const handleQueryData = () => {
        dispatch(getAchievementListRequest());

        try {
            gameServerApi.user
                .getAchievementList()
                .then(
                    (res: {
                        data: achievementListResponse;
                        status: number;
                    }) => {
                        const { data, status } = res;

                        if (status === 200) {
                            const dataFormatted =
                                handleFormatDataAchievementList(
                                    data?.achievements
                                ) || [];
                            dispatch(getAchievementListSuccess(dataFormatted));
                        }
                    }
                );
        } catch (error) {
            dispatch(getAchievementListFailed());
        }
    };
    return {
        handleQueryData,
    };
};
