import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import { styled } from '@mui/styles';
import "./style.scss"
import JoinForm from './JoinForm';
import { Facebook } from './SocialIcons/Facebook';
import { Instagram } from './SocialIcons/Instagram';
import { Discord } from './SocialIcons/Discord';
import { ENV } from 'scripts/environment';
import { Reddit } from './SocialIcons/Reddit';

const FooterItem = styled(Typography)(({ theme }) => ({
    color: "#FFF !important",
    fontFamily: "MonoSpec !important",
    fontSize: "14px !important",
    textTransform: "uppercase",
    padding: "5px 0"
}));

const Footer: React.FunctionComponent<any> = ({ }) => {
    return (
        <Box sx={{
            background: "#010101",
            position: "relative",
            zIndex: 1
        }}>
            <Box sx={{
                padding: {
                    xs: "30px",
                    sm: "65px 65px 0"
                },
                maxWidth: "1440px",
                margin: "0 auto"
            }}>
                <Grid container justifyContent="space-between" flexDirection={{
                    xs: "column-reverse",
                    sm: "row"
                }}>
                    <Grid item xs={12} sm={0} md={3} sx={{
                        display: {
                            xs: "block",
                            sm: "none",
                            md: "block"
                        }
                    }}>
                        <Box sx={{
                            marginTop: "30px",
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <Box className="emblem-container">
                                <img className="emblem-img" alt="aviron-emblem" loading="lazy" src="https://cdn.rowaviron.com/web/home-page/emblem-img.png" />
                                <img className="emblem-circle-img" alt="aviron-emblem" loading="lazy" src="https://cdn.rowaviron.com/web/home-page/emblem-circle-2018.png" />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Grid item xs={12}>
                            <JoinForm />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={3} mt={{
                                    xs: "30px",
                                    sm: 0
                                }} order={{
                                    xs: 1,
                                    sm: 0
                                }}>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/about`}><FooterItem>About us</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/membership`}><FooterItem>Membership</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/press`}><FooterItem>Press</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/faq`}><FooterItem>Faq</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/blog`}><FooterItem>Blog</FooterItem></a>
                                </Grid>
                                <Grid item xs={12} sm={3} mt={{
                                    xs: "30px",
                                    sm: 0
                                }} order={{
                                    xs: 2,
                                    sm: 1
                                }}>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/support/general/term-of-service/`}><FooterItem>Terms of service</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/support/general/privacy-policy/`}><FooterItem>Privacy policy</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/support/general/shipping-policy/`}><FooterItem>Shipping policy</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/support/general/return-policy/`}><FooterItem>Return policy</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/rowing-101/`}><FooterItem>Rowing 101</FooterItem></a>
                                </Grid>
                                <Grid item xs={12} sm={3} mt={{
                                    xs: "30px",
                                    sm: 0
                                }} order={{
                                    xs: 3,
                                    sm: 2
                                }}>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/support`}><FooterItem>Support</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/support/general/warranty`}><FooterItem>Warranty</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/try-aviron`}><FooterItem>Try the Aviron</FooterItem></a>
                                    <a href={`${ENV.STOREFRONT_DOMAIN}/contact`}><FooterItem>Contact us</FooterItem></a>
                                    <a href="https://aviron.breezy.hr/"><FooterItem>Careers</FooterItem></a>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} order={{
                                    xs: 0,
                                    sm: 3
                                }}>
                                    <FooterItem>Join the community</FooterItem>
                                    <Grid container justifyContent="space-between" mt="14px">
                                        <Grid item>
                                            <a href="https://www.facebook.com/groups/avironactive" target="_blank" rel="noopener noreferrer"> <Facebook /></a>
                                        </Grid>
                                        <Grid item>
                                            <a href="https://discord.gg/H2puTMmR" target="_blank" rel="noopener noreferrer"><Discord /></a>
                                        </Grid>

                                        <Grid item>
                                            <a href="https://www.instagram.com/aviron.active/" target="_blank" rel="noopener noreferrer"> <Instagram /></a>
                                        </Grid>
                                        <Grid item>
                                            <a href="https://www.reddit.com/r/AvironActive/" target="_blank" rel="noopener noreferrer"><Reddit /></a>
                                        </Grid>

                                    </Grid>
                                    <FooterItem mt="24px">Call us</FooterItem>
                                    <a href="tel:8779554222"> <Typography sx={{
                                        fontSize: "18px",
                                        color: "#FFF",
                                        fontFamily: "GRIFTER"
                                    }}>877-955-4222</Typography></a>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt="50px" pb="25px" textAlign={{
                    xs: "center",
                    sm: "start",
                }} pl={{
                    xs: "0",
                    sm: "50px",
                }}>
                    <Typography sx={{
                        color: "#939393",
                        fontSize: "12px",
                        fontFamily: "MonoSpec",
                    }}>Aviron Interactive Inc.<Typography sx={{
                        color: "#939393",
                        fontSize: "12px",
                        fontFamily: "MonoSpec",
                        display: {
                            xs: "block",
                            sm: "inline-block"
                        }
                    }}>All rights reserved 2022.</Typography></Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Footer;