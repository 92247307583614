import React, { useContext } from "react";
import { CONSTANTS } from "consts";
import useHandleActivePage from "utils/useHandleActivePage";
import { UserAppContext } from "contexts";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { Box } from "@mui/material";
import ProfileCard from "Components/ProfileCard";
import { IRouteItem } from "../useRouteElement.constant";
import { handleRenderRouteElement } from "../useRouteElement.hook";
import styles from "../ProtectedRouteElement.module.scss";

interface IWrapperWorkoutHistory {
    routes: IRouteItem[];
}

const WrapperWorkoutHistory = ({ routes }: IWrapperWorkoutHistory) => {
    const { machineList, userProfile } = useContext(UserAppContext);

    useHandleActivePage(CONSTANTS.SIDEBAR_PAGEID.WORKOUT_HISTORY);

    if (
        userProfile.loading ||
        (machineList.data.length === 0 && machineList.loading)
    ) {
        return <AvironLoading />;
    }

    return (
        <div>
            <Box
                sx={{
                    padding: {
                        md: "40px 0px",
                        xs: "0",
                    },
                    width: "100%",
                    overflow: "auto",
                }}
            >
                <div
                    className={
                        styles["overViewPageWrapper"] +
                        " workout-history-layout"
                    }
                >
                    <div className="leftMainContent">
                        <ProfileCard />
                    </div>
                    {handleRenderRouteElement(routes)}
                </div>
            </Box>
        </div>
    );
};
export default WrapperWorkoutHistory;
