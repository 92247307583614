import {
  GET_WORKOUT_LIST_REQUEST,
  GET_WORKOUT_LIST_SUCCESS,
  GET_WORKOUT_LIST_FAILED,
  CLEAR_WORKOUT_LIST,
  GET_WORKOUT_DETAIL_REQUEST,
  GET_WORKOUT_DETAIL_SUCCESS,
  GET_WORKOUT_DETAIL_FAILED,
  CHANGE_PAGE_REQUEST,
  CHANGE_PAGE_SUCCESS,
  CHANGE_PAGE_FAILED,
  SELECT_CHART_OPTION,
  SELECT_WORKOUT_UNIT,
  // EXPORT_CSV_TO_MAIL
} from 'contexts/types/workoutProfile';

// Workout list action
export const getWorkoutListRequest = (payload: any) => ({
  type: GET_WORKOUT_LIST_REQUEST,
  payload
});
export const getWorkoutListSuccess = (payload: any) => ({
  type: GET_WORKOUT_LIST_SUCCESS,
  payload
});
export const getWorkoutListFailed = () => ({
  type: GET_WORKOUT_LIST_FAILED,
});
export const clearWorkoutList = () => ({
  type: CLEAR_WORKOUT_LIST,
});

// Workout detail action
export const getWorkoutDetailRequest = (payload: any) => ({
  type: GET_WORKOUT_DETAIL_REQUEST,
  payload
});
export const getWorkoutDetailSuccess = (payload: any) => ({
  type: GET_WORKOUT_DETAIL_SUCCESS,
  payload
});
export const getWorkoutDetailFailed = () => ({
  type: GET_WORKOUT_DETAIL_FAILED
});

// Change page action
export const changePageRequest = () => ({
  type: CHANGE_PAGE_REQUEST
});
export const changePageSuccess = (payload: any) => ({
  type: CHANGE_PAGE_SUCCESS,
  payload
});
export const changePageFailed = () => ({
  type: CHANGE_PAGE_FAILED,
});

// Select option action
export const selectChartOption = (payload: any) => ({
  type: SELECT_CHART_OPTION,
  payload
});

export const selectWorkoutUnit = (payload: any) => ({
  type: SELECT_WORKOUT_UNIT,
  payload
});

// Select option action
// export const exportCsvToMail = (payload: any) => ({
//   type: EXPORT_CSV_TO_MAIL,
//   payload
// });