import { useState } from "react";
import { gameServerApi } from 'services/gameServerV2';
const formatWorkoutDate = (workouts: any) => {
    const items = workouts.items;
    const streaks = workouts.streaks || [];
    let workoutDates = [];
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.totalWorkouts > 0) {
            workoutDates.push(new Date(item.date + ' 00:00:00'));
        }
    }
    return {
        workoutDates: workoutDates,
        streaks: streaks,
    };
};
const CalendartWorkoutHook = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const getCalendarStatisticDetailWorkout = async (startDate: number, endDate: number) => {
        setLoading(true)
        try {
            gameServerApi.workoutHistory.getCalendarStatisticDetailWorkout(startDate, endDate).then((res)=>{
                if(res && res.status === 200) {
                    // console.log('res========', res);
                    //@ts-ignore
                    setData(formatWorkoutDate(res.data));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch((err)=>{
                console.log('error when getCalendarStatisticDetailWorkout', JSON.parse(JSON.stringify(err)))
                setLoading(false);
            });
        } catch (err) {
            console.log('error when handle getCalendarStatisticDetailWorkout', JSON.parse(JSON.stringify(err)))
            setLoading(false);
        }
    }
    return {
        getCalendarStatisticDetailWorkout,
        data,
        loading,
    };
};
export default CalendartWorkoutHook;