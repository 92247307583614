import palette from './palette';

export default {
    h1: {
        color: palette.text.primary,
        fontWeight: 600,
        fontSize: "35px",
        letterSpacing: "-0.24px",
        lineHeight: "40px",
        fontFamily: "GRIFTER",
    },
    h2: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "29px",
        letterSpacing: "-0.24px",
        lineHeight: "32px",
        fontFamily: "GRIFTER",
    },
    h3: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "24px",
        letterSpacing: "3px",
        lineHeight: "28px",
        fontFamily: "GRIFTER",
    },
    h4: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: "-0.06px",
        lineHeight: "24px",
    },
    h5: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "16px",
        letterSpacing: "-0.05px",
        lineHeight: "20px",
    },
    h6: {
        color: "#808080",
        fontWeight: 500,
        fontSize: "15px",
        letterSpacing: "-0.05px",
        lineHeight: "20px",
        fontFamily: "Work Sans",
    },
    subtitle1: {
        color: palette.text.primary,
        fontSize: "16px",
        letterSpacing: "-0.05px",
        lineHeight: "25px",
        fontFamily: "Work Sans",
    },
    subtitle2: {
        color: palette.text.secondary,
        fontWeight: 400,
        fontSize: "12px",
        letterSpacing: "-0.05px",
        lineHeight: "24px",
        fontFamily: "Work Sans",
    },
    body1: {
        color: palette.text.primary,
        fontSize: "14px",
        letterSpacing: "-0.05px",
        lineHeight: "21px",
        fontFamily: "Work Sans",
    },
    body2: {
        color: palette.text.secondary,
        fontSize: "12px",
        letterSpacing: "-0.04px",
        lineHeight: "18px",
        fontFamily: "Work Sans",
    },
    button: {
        color: palette.text.primary,
        fontSize: "14px",
        fontFamily: "MonoSpec",
    },
    caption: {
        color: palette.text.secondary,
        fontSize: "11px",
        letterSpacing: "0.33px",
        lineHeight: "13px",
    },
    overline: {
        color: palette.text.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
    label: {},
};
