import { toast, ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import style from "./InviteSuccessToast.module.scss";
import { Link } from "react-router-dom";

interface InviteSuccessToast {
    state: "init" | "success" | "error";
    resetState?: any;
    urlView?: string
}
const InviteSuccessToast = ({ state, resetState, urlView }: any) => {
    useEffect(() => {
        if (state === "success") {
            document.getElementById("InviteMemberNotifyButton")?.click();
            setTimeout(()=>{
              resetState && resetState();
            }, 500)
        }
    }, [state]);
    const notify = () => {
        toast(
            <div className={style["content"]}>
                <div className={style["text"]}>
                    Invitation sent successfully
                </div>
                <div className={style["view"]}>
                  <Link to={urlView}>View</Link>
                </div>
            </div>,
            {
                position: "bottom-left",
                className: style["InviteSuccessToast"],
            }
        );
    };

    return (
        <>
            <button
                onClick={notify}
                id={"InviteMemberNotifyButton"}
                style={{ display: "none" }}
            >
                Notify
            </button>
            <ToastContainer autoClose={false} hideProgressBar={true} />
        </>
    );
};

export default InviteSuccessToast;
