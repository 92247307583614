import CanvasCompress from 'canvas-compress';

const compressor = new CanvasCompress({
    type: CanvasCompress.MIME.JPEG,
    width: 1000,
    height: 618,
    quality: 0.9,
});

export const compressImage = async (originImage: Blob) => {
    return await compressor.process(originImage).then(({ source, result }: any) => {
        // const { blob, width, height } = source;
        const { blob } = result;
        return blob
    });
}