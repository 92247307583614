import { makeStyles } from "@mui/styles";
const useStyles: any = makeStyles(
    (theme: any) =>
    ({
        root: {
            backgroundColor: "#fff",
            height: "100vh",
        },
        grid: {
            height: "100%",
        },
        backgroundContainer: {
            [theme.breakpoints.down("md")]: {
                display: "none",
            },
        },
        background: {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: "url(/images/background.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        },
        quoteInner: {
            textAlign: "center",
            flexBasis: "600px",
        },
        quoteText: {
            color: theme.palette.white,
            fontWeight: 300,
        },
        name: {
            marginTop: theme.spacing(3),
            color: theme.palette.white,
        },
        bio: {
            color: theme.palette.white,
        },
        contentContainer: {},
        content: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
        },
        logoImage: {
            marginLeft: theme.spacing(4),
        },
        contentBody: {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            maxWidth: "700px",
            [theme.breakpoints.down("md")]: {
                justifyContent: "center",
            },
        },
        form: {
            flexBasis: "100%",
        },
        logo: {
            width: "100%",
            textAlign: "center"
        },
        title: {
            marginTop: theme.spacing(3),
            textAlign: "center",
            letterSpacing: "4px"
        },
        socialButtons: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
        socialButon: {
            width: "100%",
        },
        socialIcon: {
            marginRight: theme.spacing(1),
        },
        sugestion: {
            marginTop: theme.spacing(2),
        },
        textField: {
            marginTop: theme.spacing(2),
        },
        signInButton: {
            margin: theme.spacing(2, 0),
            height: "62px"
        },
        loginError: {
            color: "#f5490f",
            textAlign: "start",
            fontSize: "14px"
        }
    } as any)
);

export default useStyles;
