import React, { useEffect } from "react";
import style from "./MembershipInviteList.module.scss";
import { Box, Grid, Paper, Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import GoBack from "Components/GoBack";
import { useLocation } from "react-router-dom";
import PageTitle from "Components/PageTitle";
import { useMembershipHook } from "./inviteMembership.hook";
import MembershipCardDesktop from "Components/MembershipPage/MembershipCardDesktop";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { useWindowSize } from "scripts/useWindowSize";
import MembershipCardMobile from "Components/MembershipPage/MembershipCardMobile";

const MembershipInviteList = () => {
  const location = useLocation();
  const {
    propsTable,
    membershipId,
    loading,
    handleSearch,
    refetchInvitedMembership,
  } = useMembershipHook();

  const windowSize = useWindowSize();

  return (
    <div className={style["MembershipInviteList"]}>
      <Box
        padding={{
          xs: "16px 32px",
          md: 0,
        }}
        className={style['MembershipWrapper']}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Membership detail | Aviron</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <GoBack url={location?.pathname + `/${membershipId}`} />
        <Stack direction={{ xs: "column-reverse", md: "column" }}>
          <Box mt="24px">
            <Grid container>
              <Grid item md={8}>
                <PageTitle>Membership detail</PageTitle>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        {windowSize?.width >= 900 ?
          <Paper
            sx={{
              padding: "32px 38px",
              margin: "24px 0",
              position: "relative",
              borderRadius: "8px",
              boxShadow: "unset",
            }}
          >
            <MembershipCardDesktop
              //@ts-ignore
              dataTable={propsTable.dataTable}
              handleSearch={handleSearch}
              refetchInvitedMembership={refetchInvitedMembership}
            />
          </Paper>
          :
          <MembershipCardMobile     //@ts-ignore
            dataTable={propsTable.dataTable}
            handleSearch={handleSearch}
            refetchMembership={refetchInvitedMembership} />
        }
      </Box>
      {loading && <AvironLoading />}
    </div>
  );
};

export default MembershipInviteList;
