import { Grid } from "@mui/material";
import React from "react";
import OlympianCard from "../OlympianCard";
import WeekStreakCard from "../WeekStreakCard";
import DaysSinceLastWorkoutCard from "../DaysSinceLastWorkoutCard";
import style from "./WeekStreakHoc.module.scss";

const WeekStreakHoc = (props: any) => {
    const { gameUserInfo } = props;
    return (
        <div className={style["WeekStreakHocWrapper"]}>
            <Grid container spacing={{ xs: 2 }}>
                <Grid item sm={12} lg={4} className={style["gameLevelGrid"]}>
                    <OlympianCard gameUserInfo={gameUserInfo} />
                </Grid>
                <Grid item sm={12} lg={4} className={style["streakLevelGrid"]}>
                    <WeekStreakCard />
                </Grid>
                <Grid item sm={12} lg={4} className={style["siceWorkoutGrid"]}>
                    <DaysSinceLastWorkoutCard />
                </Grid>
            </Grid>
        </div>
    );
};

export default WeekStreakHoc;
