import { UtilAPI } from "./util";

class API {
  constructor() {
    this.util = new UtilAPI();
  }
  util: UtilAPI;
}

export const api = new API();
