const ENV: any = {
    SERVER_DOMAIN: process.env.REACT_APP_SERVER_DOMAIN,
    STOREFRONT_DOMAIN: process.env.REACT_APP_STOREFRONT_DOMAIN,
    VENDURE_DOMAIN: process.env.REACT_APP_VENDURE_DOMAIN,
    CRM_SERVER_DOMAIN: process.env.REACT_APP_CRM_SERVER_DOMAIN,
    PAYMENT: {
        STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY
    },
    STRAVA: {
        CLIENT_ID: process.env.REACT_APP_STRAVA_CLIENT_ID,
        REDIRECT_URL: "/strava"
    },
    LOGBOOK: {
        CLIENT_ID: process.env.REACT_APP_LOGBOOK_CLIENT_ID,
        URL: process.env.REACT_APP_LOGBOOK_URL,
        REDIRECT_URL: "/logbook"
    },
    FITBIT: {
        CLIENT_ID: process.env.REACT_APP_FITBIT_CLIENT_ID,
        URL: process.env.REACT_APP_FITBIT_URL,
        REDIRECT_URL: "/fitbit",
        STATE: process.env.REACT_APP_FITBIT_STATE
    },
    DOMAIN: process.env.REACT_APP_CRM_URL,
}

export {
    ENV
}
