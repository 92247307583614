import React from "react";
import style from "./StatBoard.module.scss";
import CardSimple from "Components/CardSimple";
import { Grid } from "@mui/material";
import SelectField from "Components/FormElements/Select";
import LoadingHOC from "Components/LoadingHOC";
import ParameterSection from "Components/ParameterSection";
const StatBoard = (props: any) => {
    const {
        timeRangeData,
        currentSelect,
        handleSelectTimeRange,
        loading,
        workoutMetrics,
        workoutDetail,
    } = props;
    return (
        <div className={style["StatBoard"]}>
            <CardSimple
                title={
                    <>
                        <Grid
                            container
                            justifyContent="space-between"
                            mb={"1.714rem"}
                            alignItems={"center"}
                            className={style["titleStatBoard"]}
                        >
                            <Grid
                                item
                                sm={6}
                                md={7}
                                lg={9}
                                className={"titleCardItem"}
                            >
                                <div className="titleCard">STATISTICS</div>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                md={5}
                                lg={3}
                                mt={"-0.714rem"}
                                className={style["selectField"]}
                            >
                                <SelectField
                                    // @ts-ignore
                                    options={timeRangeData.map(
                                        ({ label, value }: any) => ({
                                            name: label,
                                            value: value,
                                        })
                                    )}
                                    value={currentSelect.timeRange}
                                    onChange={handleSelectTimeRange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <LoadingHOC isLoading={loading.chart}>
                                    <ParameterSection
                                        workoutMetrics={workoutMetrics}
                                        workoutDetail={workoutDetail}
                                        // isExpand={isExpandActivity}
                                    />
                                </LoadingHOC>
                            </Grid>
                        </Grid>
                    </>
                }
            ></CardSimple>
        </div>
    );
};

export default StatBoard;
