import React from 'react';
import { Modal, Backdrop, Fade, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import "./style.scss";


const useStyles: any = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const RemovePasswordModal = (props: any) => {
  const { open, onClose, onClickYes } = props;
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="remove-pwd-modal-component">

          <button className="close-btn" onClick={onClose}>
            <img src="/images/icons/x-icon.svg" width={18} height={18} alt="icon" />
          </button>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src="/images/icons/aviron-logo.svg" width={48} height={48} alt="icon" />
          </div>

          <div style={{ padding: '32px' }}>
            <div style={{ textAlign: 'center', fontWeight: "bold", fontSize: "24px", color: "#161616", fontFamily: 'GRIFTER' }}>
              REMOVE PASSWORD
            </div>
          </div>

          <div style={{ padding: '0 30px', textAlign: 'center', fontFamily: 'Work Sans' }}>
            You're about to remove your password.
            Going forward,<b> we'll email you a temporary code.</b>
          </div>

          <div className="buttons-group">
            <Button type="button" variant="contained"
              onClick={onClickYes}
              sx={{
                height: "64px",
                background: "#F5490F",
                borderRadius: "12px"
              }}
            >
              Yes
            </Button>
            <Button type="button" onClick={onClose} variant="outlined"
              sx={{
                height: "64px",
                borderRadius: "12px"
              }}>
              CANCEL
            </Button>

          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default RemovePasswordModal;