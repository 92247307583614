import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import style from "./verticalTabs.module.scss";

interface VerticalTabs {
    dataTab: Array<{
        label: string;
        link?: any;
    }>;

    activeValue?: any;
    tabName: string;
    orientation: any;
}

const getInitTabActive = (linkArray: any, pathName: string) => {
    for (let i = 0; i < linkArray.length; i++) {
        if (linkArray[i]?.link?.includes(pathName)) {
            return i;
        }
    }
    return false;
};

const VerticalTabs = (props: VerticalTabs) => {
    const { dataTab, activeValue, tabName, orientation } = props;
    const [value, setValue] = useState(activeValue || 0);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname && dataTab) {
            const tabActive = getInitTabActive(dataTab, location.pathname) || 0;
            setValue(tabActive);
        }
    }, [location]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        history.replace(dataTab[newValue].link);
    };

    return (
        <div
            className={
                orientation === "vertical"
                    ? style["verticalTabs"] + " verticalTabs"
                    : style["horizontalTabs"] + " horizontalTabs"
            }
        >
            <Tabs
                orientation={orientation}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
            >
                {dataTab &&
                    dataTab.map((tab, key) => {
                        return (
                            <Tab
                                key={tabName + key}
                                label={tab.label}
                                id={tabName + key}
                            />
                        );
                    })}
            </Tabs>
        </div>
    );
};

export default VerticalTabs;
