import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserAppContext } from "contexts";
import { useQueryMachineList } from "Screens/App.actions";
import { useGetIdTokenQuery } from "services/webServer";
import {
    getUserProfileFailed,
    getUserProfileRequest,
    getUserProfileSuccess,
} from "contexts/userProfile/userProfile.actions";
import { gameServerApi } from "services/gameServerV2";
import { IRedirectRoute, IRouteItem, allRedirectRoutes } from "./useRouteElement.constant";
import { CONSTANTS } from "consts";

const useStyles: any = makeStyles(
    (theme: Theme) =>
        ({
            root: {
                display: "flex",
                [theme.breakpoints.up("md")]: {
                    display: "flex",
                    marginTop: "80px",
                },
                marginTop: "76px",
                background: "#F3F4F7",
                justifyContent: "center",
            },
            content: {
                maxWidth: "1440px",
                width: "100%",
                display: "inline-block",
            },
        } as any)
);

export interface IUserProfile {
    birthDay: string;
    firstName: string;
    lastName: string;
    email: string;
    gender: number;
    weight: number;
    weightUnit: string;
    height: number;
    heightUnit: string;
    username: string;
    avatarUrl: string;
}

export const useRouteElementHooks = () => {
    const classes = useStyles();
    const { userProfileDispatch: dispatch } = React.useContext(UserAppContext);
    useQueryMachineList();

    const { data: dataTokenId } = useGetIdTokenQuery();

    useEffect(() => {
        if (dataTokenId) {
            localStorage.setItem(
                "idToken",
                dataTokenId?.getIdToken?.idToken || ""
            );
        }
    }, [dataTokenId]);

    useEffect(() => {
        dispatch(getUserProfileRequest());

        gameServerApi.user
            .getGameUserProfile()
            .then((res) => {
                const { profile, firstName, lastName, username, avatarUrl } =
                    res.data;
                const {
                    birthDay,
                    email,
                    gender,
                    weight,
                    weightUnit,
                    height,
                    heightUnit,
                } = profile;
                const objectFormatted: IUserProfile = {
                    birthDay,
                    firstName,
                    lastName,
                    email,
                    gender,
                    weight,
                    weightUnit,
                    height,
                    heightUnit,
                    username,
                    avatarUrl,
                };
                dispatch(getUserProfileSuccess(objectFormatted));
            })
            .catch(() => {
                dispatch(getUserProfileFailed());
            });
    }, []);

    return {
        dataTokenId,
        classes,
    };
};

export const handleRenderRouteElement = (routes: IRouteItem[]) => {
    return (
        <Switch>
            {routes.map((route: IRouteItem, key: number) => {
                const {
                    path,
                    element: Component,
                    protectedRoute: ProtectedRoute,
                    children = [],
                    exact = false,
                } = route;
                const props = {
                    key,
                    path,
                    exact,
                };
                // In case have protectedRoute (Example: Profile, Workout History, Memberships, ...)
                if (ProtectedRoute) {
                    return <ProtectedRoute {...props} component={Component} />;
                }

                // In case don't have protectedRoute (Example: Login, ForgotPassword, Order, ... )
                return (
                    <Route
                        {...props}
                        render={(childProps) => (
                            <Component {...childProps} routes={children} />
                        )}
                    />
                );
            })}

            {/* Define redirect routes here */}
            {allRedirectRoutes.map((route: IRedirectRoute, key: number) => (
                <Redirect
                    key={key}
                    to={route.redirectTo}
                />
            ))}

            {/* If route not match. We will redirect to page not found  */}
            <Redirect to={CONSTANTS.NAVIGATION_URL.NOT_FOUND} />
        </Switch>
    );
};
