import { WorkoutHistoryAPI } from "./workoutHistory";
import { UserAPI } from "./user";
import { AppAPI } from "./app";

class GameServerApi {
    constructor() {
        this.workoutHistory = new WorkoutHistoryAPI();
        this.user = new UserAPI();
        this.app = new AppAPI();
    }
    workoutHistory: WorkoutHistoryAPI;
    user: UserAPI;
    app: AppAPI;
}

export const gameServerApi = new GameServerApi();
