import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import React from "react";
import style from "./InviteDetailModal.module.scss";
import ResendIcon from "Components/Icons/Resend";
import TrashIcon from "Components/Icons/TrashCan";
import { MEMBERSHIP_STATUS } from "Components/MembershipPage/Status";

interface InviteMemberProps {
    open: boolean;
    onClose?: any;
    handleRemove?: any;
    handleResend?: any;
    data?: any;
}

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const InviteDetailModal = (props: InviteMemberProps) => {
    const { open, onClose, handleRemove, handleResend, data } = props;
    console.log("ccccccpppppp", data);
    const classes = useStyles();
    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal + " " + style["modalCustom"]}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 800,
                }}
            >
                <Fade in={open}>
                    <div className={style["InviteDetailModal"]}>
                        <div className={style["close-btn"]} onClick={onClose}>
                            <img
                                src="/images/icons/x-icon.svg"
                                width={18}
                                height={18}
                            />
                        </div>
                        <div className={style["row"]}>
                            <div className={style["title"]}>User</div>
                            <div className={style["content"]}>
                                {data?.user?.value}
                            </div>
                        </div>
                        <div className={style["row"]}>
                            <div className={style["title"]}>Status</div>
                            <div className={style["content"]}>
                                {data?.status?.value}
                            </div>
                        </div>
                        <div className={style["buttonGroup"]}>
                            <div>
                                {data?.selection?.rowData?.status ===
                                    MEMBERSHIP_STATUS.EXPIRE && (
                                    <div
                                        className={style["buttonCustom"]}
                                        style={{ marginBottom: "16px" }}
                                        onClick={handleResend}
                                    >
                                        <ResendIcon />
                                        Resend invite
                                    </div>
                                )}
                                <div
                                    className={style["buttonCustom"]}
                                    onClick={handleRemove}
                                >
                                    <TrashIcon />
                                    Remove member
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default InviteDetailModal;
