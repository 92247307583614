import * as React from "react";
import Popover from "@mui/material/Popover";
import style from "./Actions.module.scss";
import { ReactComponent as ActionIcon } from "./threeDot.svg";
import TrashIcon from "Components/Icons/TrashCan";
import ResendIcon from "Components/Icons/Resend";
import { MEMBERSHIP_STATUS } from "Components/MembershipPage/Status";

interface ActionsProps {
    content: Array<{ name: string; action: any }>;
    selection?: {
        isBlock?: boolean,
        rowData?: {
            status?: number
        }
    };
}
const Actions = ({ content, selection }: ActionsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    // console.log('bbbbbbbbbbbbbbbb', selection);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div className={style["Actions"]}>
            {/* @ts-ignore */}
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
                <ActionIcon />
            </div>
            {!selection?.isBlock && (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    className={style["actionsMenu"] + " actionsMenu"}
                >
                    {content.map((item, key) => {
                        return (
                            <>
                                {item.name === "remove" && !selection?.isBlock && (
                                    <div
                                        className={style["itemAction"]}
                                        key={key}
                                        onClick={() =>
                                            item?.action([selection])
                                        }
                                    >
                                        <div className={style["icon"]}>
                                            <TrashIcon />
                                        </div>
                                        <div className={style["item"]}>
                                            Remove
                                        </div>
                                    </div>
                                )}
                                {item.name === "resend" && selection?.rowData?.status === MEMBERSHIP_STATUS.EXPIRE && (
                                    <div
                                        className={style["itemAction"]}
                                        key={key}
                                        onClick={() =>
                                            item?.action([selection])
                                        }
                                    >
                                        <div className={style["icon"]}>
                                            <ResendIcon />
                                        </div>
                                        <div className={style["item"]}>
                                            Resend Invite
                                        </div>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </Popover>
            )}
        </div>
    );
};

export default Actions;
