import { BaseReducer } from '../base/base.reducer';
import { CONSTANTS } from 'consts';

class ProfileReducer extends BaseReducer {
    constructor() {
        super("PROFILE", {
            current_tab: CONSTANTS.PROFILE_PAGE.CURRENT_TAB.NONE,
        });
    }
}

const profileReducer = new ProfileReducer();

export { profileReducer };
