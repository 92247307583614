import * as React from 'react';
import style from './style.module.scss';
interface customScrollBarProps {
    heightWrapper?: any,
    children?: any,
    open?: any,
    heightThumb?: number,
    idParent?: string,
}
const CustomScrollBar = (props:customScrollBarProps) => {
  const { heightWrapper, children,
    //  idParent="",
    //  open
  } = props;
  const [scrollTop, setScrollTop] = React.useState(0);
//   const [heightBody, setHeightBody] = React.useState('auto');
  const [heightScrollBarWrapper, setHeightScrollBarWrapper] = React.useState(0)
  const [heightThumb, setHeightThumb] = React.useState(0)
  React.useEffect(()=>{
    const scrollBarWrapperDom = document.getElementById('scrollBarWrapperId');
    if(scrollBarWrapperDom) {
      const newHeight = scrollBarWrapperDom.offsetHeight;
      //@ts-ignore
      setHeightScrollBarWrapper(newHeight)
    }
  },[heightWrapper])
  const contentBodyRef = React.useRef();
  React.useEffect(()=>{
    if(heightThumb&& heightWrapper) {
      if(heightThumb >=heightWrapper) {
        //@ts-ignore
        document.getElementById('scrollBarTrackId').style.display='none';
        //@ts-ignore
        document.getElementById('scrollBarThumbId').style.display='none';
      } else {
        //@ts-ignore
        document.getElementById('scrollBarTrackId').style.display='block';
        //@ts-ignore
        document.getElementById('scrollBarThumbId').style.display='block';
      }
    }
  }, [heightThumb])
  React.useEffect(()=> {
    //@ts-ignore
    const heightBodyNew = contentBodyRef.current?.offsetHeight
    if(heightBodyNew && heightWrapper) {
        setHeightThumb(heightScrollBarWrapper * (heightScrollBarWrapper / heightBodyNew))
    }
  }, [heightScrollBarWrapper])
  //@ts-ignore
  const handleScroll = (event:any, isDefault?: boolean) => {
    
    if(isDefault) {
      setScrollTop(0)
    } else {
      const scrollTopNew = event.target.scrollTop;
      //@ts-ignore
      const heightBodyNew = contentBodyRef.current?.offsetHeight
      if(heightBodyNew) {
          setScrollTop(scrollTopNew / heightBodyNew);
      }
    }
  };
  return (
    <div
      style={{
        height: heightWrapper,
        position: 'relative',
        width: '100%',
      }}
      className={style['scrollBarWrapper'] + ` ${'scrollBarWrapper'}`}
      id={'scrollBarWrapperId'}
    >
      <div
        style={{
          height: heightScrollBarWrapper,
          overflow: 'scroll',
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
        onScroll={(event)=>handleScroll(event, false)}
        className={style['contentScrollWrapper'] + ` ${'contentScrollWrapper'}`}
      >
        <div
          style={{
            height: 'auto',
            position: 'absolute',
            width: '100%',
          }}
          className={style['contentBody'] + ` ${'contentBody'}`}
          //@ts-ignore
          ref={contentBodyRef}
          id={'contentBodyId'}
        >
          {children}
        </div>
      </div>
      <div
        className={style['scrollBarTrack'] + ` ${'scrollBarTrack'}`}
        style={{
          height: heightScrollBarWrapper,
        }}
        id={'scrollBarTrackId'}
      ></div>
      <div
        style={{
          height: heightThumb,
          top: scrollTop * 100 + '%',
        }}
        className={style['scrollBarThumb'] + ` ${'scrollBarThumb'}`}
        id={'scrollBarThumbId'}
      ></div>
    </div>
  )
}

export default CustomScrollBar;