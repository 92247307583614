import { Button, TextField } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import useGenerateBetaCodeHook from "./BetaCode.hook";
import useStyles from "./BetaCode.styles";

const BetaCode = () => {
    const classes: any = useStyles();
    const {
        handleSubmit,
        handleChange,
        hasError,
        formState
    } = useGenerateBetaCodeHook();

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Beta Code | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className={classes.content}>
                <div className={classes.contentBody}>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit}
                    >
                        <div className={classes.introduction}>
                            <ul className={classes.introductionUl}>
                                <li><div className="number">1</div><div className="text">Log out of your current account.</div><div className="line"/></li>
                                <li><div className="number">2</div><div className="text">Log in with the username "support" and password "support".</div><div className="line"/></li>
                                <li><div className="number">3</div><div className="text">A code will be displayed on the screen.</div><div className="line"/></li>
                                <li><div className="number">4</div><div className="text">Enter the code into the beta code generator.</div><div className="line"/></li>
                                <li><div className="number">5</div><div className="text">Paste the beta code back onto the screen.</div><div className="line"/></li>
                                <li><div className="number">6</div><div className="text">Click on the "Settings" option located in the bottom left corner of the screen.</div><div className="line"/></li>
                                <li><div className="number">7</div><div className="text">Select the "Change Environment" option until the "BETA" environment appears.</div><div className="line"/></li>
                                <li><div className="number">8</div><div className="text">Click the "Restart" button to complete the process.</div><div className="line line-last"/></li>
                                <div></div>
                            </ul>
                        </div>
                        <h2 className={classes.title}>
                            PLEASE INTER YOUR CODE
                        </h2>
                        <TextField
                            className={classes.textField}
                            error={hasError("code")}
                            fullWidth
                            helperText={
                                hasError("code")
                                    ? formState.errors.code[0]
                                    : null
                            }
                            // label="APK CODE"
                            placeholder="APK CODE"
                            name="code"
                            onChange={handleChange}
                            onBlur={handleChange}
                            type="text"
                            value={formState.values.code || ""}
                            variant="outlined"
                            data-cy="login-code-field"
                            sx={{
                                marginTop: "16px"
                            }}
                        />

                        <Button
                            className={classes.signInButton}
                            color="primary"
                            disabled={!formState.isValid}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            data-cy="login-submit-button"
                            sx={{
                                marginTop: "24px"
                            }}
                        >
                            GENERATE
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default BetaCode;