import {
    GET_WORKOUT_LIST_REQUEST,
    GET_WORKOUT_LIST_SUCCESS,
    GET_WORKOUT_LIST_FAILED,
    GET_WORKOUT_DETAIL_REQUEST,
    CLEAR_WORKOUT_LIST,
    GET_WORKOUT_DETAIL_SUCCESS,
    GET_WORKOUT_DETAIL_FAILED,
    CHANGE_PAGE_REQUEST,
    CHANGE_PAGE_SUCCESS,
    CHANGE_PAGE_FAILED,
    SELECT_CHART_OPTION,
    SELECT_WORKOUT_UNIT,
} from "contexts/types/workoutProfile";

import produce from "immer";
import { CONSTANTS } from "consts";
const { WORKOUT_PARAMETER } = CONSTANTS;

const initialState: any = {
    currentSelect: {
        timeRange: 0,
        chartOption: null,
        workoutItem: null,
    },
    loading: {
        chart: false,
        history: false,
    },
    metaData: {},
    workoutMetrics: {},
    workoutDetail: {},

    chartOptions: [],
    chartData: {},

    historyList: null,
    workoutsSynced: [],
    currentUnit: "meters"
};

type actionType = {
    type: string;
    payload: any;
};
const reducer = produce((state, action: actionType) => {
    switch (action.type) {
        case GET_WORKOUT_LIST_REQUEST: {
            const { timeRange } = action.payload;
            state.loading.chart = true;
            state.loading.history = true;
            state.historyList = [];
            state.currentSelect = {
                timeRange: timeRange,
                workoutItem: null,
                chartOption: null,
            };
            return state;
        }

        case GET_WORKOUT_LIST_SUCCESS: {
            state.loading.chart = false;
            state.loading.history = false;
            state.metaData = action.payload.metaData;
            state.workoutMetrics = action.payload.workoutMetrics;
            state.historyList = action.payload.historyList;
            state.workoutsSynced = action.payload.workoutsSynced;
            state.workoutDetail = {};
            return state;
        }

        case GET_WORKOUT_LIST_FAILED: {
            state.loading.chart = false;
            state.loading.history = false;
            return state;
        }
        case CLEAR_WORKOUT_LIST: {
            return initialState;
        }

        case GET_WORKOUT_DETAIL_REQUEST: {
            const { workoutItem } = action.payload;
            state.loading.chart = true;
            // state.currentSelect.workoutItem = workoutItem;
            state.currentSelect = {
                ...state.currentSelect,
                workoutItem: workoutItem,
            };
            return state;
        }

        case GET_WORKOUT_DETAIL_SUCCESS: {
            const { chartData, chartOptions, workoutMetrics, workoutDetail } =
                action.payload;
            state.loading.chart = false;
            state.chartData = chartData;
            state.chartOptions = chartOptions;
            state.workoutMetrics = workoutMetrics;
            state.workoutDetail = workoutDetail;
            state.currentSelect = {
                ...state.currentSelect,
                chartOption: WORKOUT_PARAMETER.kDIndexKiloJoules,
            };
            return state;
        }

        case GET_WORKOUT_DETAIL_FAILED: {
            state.loading.chart = false;
            return state;
        }

        case CHANGE_PAGE_REQUEST: {
            state.loading.history = true;
            return state;
        }

        case CHANGE_PAGE_SUCCESS: {
            const { metaData, historyList } = action.payload;
            state.loading.history = false;
            state.metaData = metaData;
            state.historyList = historyList;
            state.workoutsSynced = action.payload.workoutsSynced;
            return state;
        }

        case CHANGE_PAGE_FAILED: {
            state.loading.history = false;
            return state;
        }

        case SELECT_CHART_OPTION: {
            const { chartOption } = action.payload;
            state.currentSelect.chartOption = chartOption;
            return state;
        }

        case SELECT_WORKOUT_UNIT: {
            state.currentUnit = action.payload;
            return state;
        }

        default:
            return state;
    }
}, initialState);

export const workoutReducer = { initialState, reducer };
