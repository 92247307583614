import React from "react";
import style from "./Checkbox.module.scss";
import { CheckboxProps as CheckboxMuiProps } from "@mui/material";
import { Checkbox as CheckboxMui } from "@mui/material";

interface CheckboxProps extends CheckboxMuiProps {}

const Checkbox = (props: CheckboxProps) => {
    return (
        <div className={style["Checkbox"]}>
            <CheckboxMui {...props} />
        </div>
    );
};

export default Checkbox;
