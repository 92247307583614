import GLOBAL_FUNCTIONS from "scripts/global-functions";

export class BaseReducer {
    name: string;
    initState?: any;
    actions: any;
    constructor(name: string, initState: any, actions: any = {}) {
        this.name = name;
        this.initState = initState;
        this.actions = Object.assign(
            {
                setState: `${this.name}_SET_STATE`,
            },
            actions
        );
    }
    reducer = (
        state: any = this.initState,
        action: {
            type: string;
            payload: any;
        }
    ) => {
        switch (action.type) {
            case this.actions.setState:
                state = GLOBAL_FUNCTIONS.SetObject(state, action.payload);
                break;
            default:
                return this.customReducer(state, action);
        }

        return state;
    };
    customReducer(state: string, action: any) {
        return state;
    }
}