import { makeStyles } from "@mui/styles";
const useStyles: any = makeStyles(
    (theme: any) =>
        ({
            root: {
                backgroundColor: "#fff",
                height: "100vh",
            },
            grid: {
                height: "100%",
            },
            backgroundContainer: {
                [theme.breakpoints.down("md")]: {
                    display: "none",
                },
            },
            background: {
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: "url(/images/background.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            },
            introduction: {
                textAlign: "start",
                // background: "#F4F4F4",
                padding: "20px",
                border: "solid 1px #C3C3C3",
                borderRadius: "12px",
            },
            introductionUl: {
                listStyleType: "none",
                position: "relative",
                paddingLeft: 0,
                "& li": {
                    margin: "20px 0",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    "& .text": {
                        // paddingTop: "8px",
                        width: "100%",
                    },
                    "& .number": {
                        // marginTop: "6px",
                        fontFamily: "GRIFTER",
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: "28px",
                        letterSpacing: "0.01em",
                        marginRight: "16px",
                        zIndex: 2,
                        border: "solid 1px #C3C3C3",
                        borderRadius: "50%",
                        width: "26px",
                        height: "23px",
                        // background: "#F4F4F4",
                        // padding: "0px",
                        display: "flex",
                        justifyContent: "center",
                        // paddingTop: "8px"
                    },
                    "& .line": {
                        position: "absolute",
                        height: "100px",
                        width: "1px",
                        background: "#CCC",
                        left: "7px",
                        top: "15px",
                        zIndex: 1,
                    },
                    "& .line-last": {
                        background: "#F4F4F4",
                    },
                },
            },
            name: {
                marginTop: theme.spacing(3),
                color: theme.palette.white,
            },
            bio: {
                color: theme.palette.white,
            },
            contentContainer: {},
            content: {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
            },
            logoImage: {
                marginLeft: theme.spacing(4),
            },
            contentBody: {
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                maxWidth: "700px",
                margin: "24px auto 24px",
                [theme.breakpoints.down("md")]: {
                    justifyContent: "center",
                    width: "100%",
                },
            },
            form: {
                background: "#fff",
                // max-width: "750px",
                maxWidth: "700px",
                minWidth: "300px",

                padding: "20px",
                flexBasis: "100%",
            },
            logo: {
                width: "100%",
                textAlign: "center",
            },
            title: {
                textAlign: "center",
                letterSpacing: "4px",
                fontSize: "24px",
                marginTop: "32px",
                marginBottom: "16px",
                fontFamily: "GRIFTER",
            },
            textField: {
                marginTop: theme.spacing(2),
                "& input": {
                    textAlign: "center",
                },
            },
            signInButton: {
                margin: theme.spacing(2, 0),
                height: "62px",
            },
            loginError: {
                color: "#f5490f",
                textAlign: "start",
                fontSize: "14px",
            },
        } as any)
);

export default useStyles;
