import { GET_TIMEZONE_REQUEST, GET_TIMEZONE_SUCCESS, GET_TIMEZONE_FAILED } from "./timezone.type";
export const getTimezoneRequest = (payload: any) => ({
    type: GET_TIMEZONE_REQUEST,
    payload
});
export const getTimezoneSuccess = (payload: any) => ({
  type: GET_TIMEZONE_SUCCESS,
  payload
});
export const getTimezoneFailed = (payload: any) => ({
  type: GET_TIMEZONE_FAILED,
  payload
});
