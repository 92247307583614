import { CONSTANTS } from 'consts';

const { WORKOUT_QUERY_RANGE, WORKOUT_PARAMETER } = CONSTANTS;

export const timeRangeData = [
  {
    label: "ALL TIME",
    value: WORKOUT_QUERY_RANGE.QUERY_RANGE_ALL
  },
  {
    label: "LAST 24 HOURS",
    value: WORKOUT_QUERY_RANGE.QUERY_RANGE_LAST_24H
  },
  {
    label: "LAST 7 DAYS",
    value: WORKOUT_QUERY_RANGE.QUERY_RANGE_ALL_LAST_7DAYS
  },
  {
    label: "LAST 30 DAYS",
    value: WORKOUT_QUERY_RANGE.QUERY_RANGE_ALL_LAST_30DAYS
  },
];

export const chartOptionsData = [
  {
    label: "OUTPUT",
    value: WORKOUT_PARAMETER.kDIndexKiloJoules
  },
  {
    label: "WATTS",
    value: WORKOUT_PARAMETER.kDIndexWatt,
  },
  {
    label: "CALORIES",
    value: WORKOUT_PARAMETER.kDIndexCalories
  },
  {
    label: "CALS/HOUR",
    value: WORKOUT_PARAMETER.kDIndexCalPerHour
  },
  {
    label: "METERS",
    value: WORKOUT_PARAMETER.kDIndexMeters
  },
  {
    label: "SPLIT",
    value: WORKOUT_PARAMETER.kDIndexSecPer500
  },
  {
    label: "SPM",
    value: WORKOUT_PARAMETER.kDIndexSPM
  },
  {
    label: "HEART RATE",
    value: WORKOUT_PARAMETER.kDIndexHeartRate
  },
]


export const mappingChartOption = {
  [WORKOUT_PARAMETER.kDIndexKiloJoules]: "OUTPUT (KJ)",
  [WORKOUT_PARAMETER.kDIndexWatt]: "WATTS",
  [WORKOUT_PARAMETER.kDIndexCalories]: "CALORIES",
  [WORKOUT_PARAMETER.kDIndexCalPerHour]: "CALS/HOUR",
  [WORKOUT_PARAMETER.kDIndexMeters]: "METERS",
  [WORKOUT_PARAMETER.kDIndexSecPer500]: "SPLIT",
  [WORKOUT_PARAMETER.kDIndexSPM]: "SPM",
  [WORKOUT_PARAMETER.kDIndexHeartRate]: "HEART RATE"
};

export const formatWorkoutList = (workoutHistoryData: any) => {
  let workoutFormated: any = [];
  if (workoutHistoryData) {
    workoutHistoryData.map((item: any) => {
      const workoutData = item.workoutData;
      delete item.workoutData;
      const res = {
        ...item,
        ...workoutData,
        totalMeter: workoutData?.totalMeters,
        totalStrokes: workoutData?.strokes,
        avgCalpHour: workoutData?.avgCalPerHour,
        totalOutput: workoutData?.totalOutputs,
        totalWatt: workoutData?.totalWatts,
      }
      delete res.totalMeters;
      delete res.strokes;
      delete res.avgCalPerHour;
      delete res.totalOutputs;
      delete res.totalWatts;
      workoutFormated.push(res);
    })
  } else {
    workoutFormated = null
  }
  return workoutFormated;
}

export const formatWokoutDetail = (data: any) => {
  console.log('avironCommonWorkoutData', data)
  const workoutData = data?.workoutHistory?.workoutdata;
  const workoutHistory = data?.workoutHistory;
  const res = {
    id: workoutHistory?.matchid,
    gameId: data?.gameconfigholder?.gameid,
    paramValue: data?.gameconfigholder?.paramValue,
    gameLevel: data?.gameconfigholder?.gameLevel,
    createTime: data?.createtimetzuser,
    name: workoutHistory?.name,
    type: data?.gameconfigholder?.gametype,
    rank: 0,
    rankType: 0,
    meter: 0,
    output: 0,
    strokes: workoutData?.strokes || 0,
    time: 0,

    avgCalpHour: workoutData?.avgcalperhour,
    avgSPM: workoutData?.avgspm,
    avgSecp500: workoutData?.avgsecp500,
    avgWatt: workoutData?.avgwatt,
    avgHeartRate: workoutData?.avgheartRate,
    totalMeter: workoutData?.totalmeters || 0,
    totalStrokes: workoutData?.strokes,
    totalTime: workoutData?.totaltime,
    totalWatt: workoutData?.totalwatt,
    totalCalories: workoutData?.totalcalories,
    totalOutput: workoutData?.totaloutputs,
    recordHRDeltaTime: 0,
    isGroupedData: false,
    timePeriod: 0,

    heartRates: workoutData?.dataheartrateList?.length > 0 ? workoutData?.dataheartrateList : undefined,
    calPerHour: workoutData?.calperhourList,
    calories: workoutData?.caloriesList,
    dataTimer: workoutData?.datatimerList,
    kiloJoules: workoutData?.kilojoulesList,
    meters: workoutData?.metersList,
    secPer500: workoutData?.secper500List,
    spm: workoutData?.spmList,
    watts: workoutData?.wattsList,
    scoreValue: workoutHistory?.scorevalue,
    scoreTitle: workoutHistory?.scoretitle,
    tag: workoutHistory?.tagList,
    activeTime: workoutData?.activetime
  }
  return res;
}

export const formatAvironBikeWokoutDetailProtoc = (data: any) => {
  const workoutData = data?.workoutHistory?.workoutdata;
  const workoutHistory = data?.workoutHistory;
  console.log('avironBikeWorkoutData', data)
  const res = {
    id: workoutHistory?.matchid,
    gameId: data?.gameconfigholder?.gameid,
    paramValue: data?.gameconfigholder?.paramValue,
    gameLevel: data?.gameconfigholder?.gameLevel,
    createTime: data?.createTime,
    name: workoutHistory?.name,
    type: data?.gameconfigholder?.gametype,
    rank: 0,
    rankType: 0,
    meter: 0,
    output: 0,
    strokes: 0,
    time: 0,   
    avgCalpHour: workoutData?.avgcalperhour,
    avgSPM: workoutData?.avgspm,
    avgSecp500: workoutData?.avgspeed,
    avgWatt: workoutData?.avgwatt,
    avgHeartRate: workoutData?.avgheartRate,
    avgSpeed: workoutData?.avgspeed,
    speedList: workoutData?.speedList,
    totalMeter: workoutData?.totalmeters,
    totalStrokes: workoutData?.maxspm,
    totalTime: workoutData?.totaltime,
    totalWatt: workoutData?.totalwatt,
    totalCalories: workoutData?.totalcalories,
    totalOutput: workoutData?.totaloutputs,
    recordHRDeltaTime: 0,
    isGroupedData: false,
    timePeriod: 0,
    heartRates: workoutData?.dataheartrateList?.length > 0 ? workoutData?.dataheartrateList : undefined,
    calPerHour: workoutData?.calperhourList,
    calories: workoutData?.caloriesList,
    dataTimer: workoutData?.datatimerList,
    kiloJoules: workoutData?.kilojoulesList,
    meters: workoutData?.metersList,
    secPer500: workoutData?.speedList,
    spm: workoutData?.spmList,
    watts: workoutData?.wattsList,
    scoreValue: workoutHistory?.scorevalue,
    scoreTitle: workoutHistory?.scoretitle,
    tag: workoutHistory?.tagList,
    activeTime: workoutData?.activetime
  }
  return res;
}

export const formatRelaxTimeData = (timeData: Array<number>, valueData: Array<number>) => {
  const timeArr = [...timeData]; // copy timeData
  const valueArr = [...valueData]; // copy valueData
  for (let i = 0; i < timeArr.length; i++) {
    if (timeArr[i + 1] - timeArr[i] > 5 && timeArr[i + 1] - timeArr[i] <= 7) {
      timeArr.splice(i + 1, 0, timeArr[i] + 5)
      valueArr.splice(i + 1, 0, 0);
    }
    else if (timeArr[i + 1] - timeArr[i] > 7) {
      const tplus5 = timeArr[i] + 5;
      const tsub2 = timeArr[i + 1] - 2;
      timeArr.splice(i + 1, 0, tplus5);
      timeArr.splice(i + 2, 0, tsub2);
      valueArr.splice(i + 1, 0, 0)
      valueArr.splice(i + 1, 0, 0)
    }
  }
  return { timeArr, valueArr }
}