import { GET_TIMEZONE_REQUEST } from "./timezone.type";

import produce from "immer";

const initialState: any = {
    timezone: undefined,
};

type actionType = {
    type: string;
    payload: any;
};
const reducer = produce((state, action: actionType) => {
    switch (action.type) {
        case GET_TIMEZONE_REQUEST: {
            return {...state, timezone: action.payload};
        }

        default:
            return state;
    }
}, initialState);

export const timezoneReducer = { initialState, reducer };
