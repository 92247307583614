import React, { memo, useContext, useMemo } from "react";
import { achievementListFormattedData } from "../Achievements.actions";
import styles from "./AchievementItem.module.scss";
import { RefModalAchievement } from "../Modal/AchievementsModal";
import { UserAppContext } from "contexts";

interface AchievementsItemProps {
    id: number;
    setDataModal: (data: any) => void;
    refModal: React.RefObject<RefModalAchievement>;
    isCompleted: boolean;
}
const AchievementsItem = (props: AchievementsItemProps) => {
    const { id, refModal, setDataModal, isCompleted } = props;
    const { achievementList } = useContext(UserAppContext);

    const data = useMemo(
        () =>
            achievementList?.data?.find(
                (item: achievementListFormattedData) => item.id === id
            ),
        [achievementList, id]
    ) as achievementListFormattedData;

    const handleClickAchievementItem = () => {
        setDataModal({ data, isCompleted });
        refModal?.current?.setIsVisible(true);
    };

    const img = isCompleted ? `${data?.icon.slice(0, -4)}_128x128.png` : `${data?.iconGrey.slice(0, -4)}_128x128.png`;

    return (
        <div
            className={styles["achievements-item"]}
            onClick={handleClickAchievementItem}
        >
            <img src={img} loading="eager" alt="img"
            style={{
                width:"64px",
                height:"64px",
                objectFit:"cover"
            }}
            />
            <p style={{ textAlign: "center" }}>{data?.name || ""}</p>
        </div>
    );
};

export default memo(AchievementsItem);
