/**
 * convert text string to CapitalizeText, exp: aaa to Aaa, AAA to Aaa, aAa to Aaa
 */
export const toCapitalizeText = (text: string) => {
    if(text && text.length > 0) {
      const word = text.toLocaleLowerCase();
      const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
      return capitalized;
    }
    return '';
};
