
import { Action, UserAppContext } from "contexts";
import * as React from "react";
import { withRouter } from "react-router-dom";
import { CONSTANTS } from "consts";
import SidebarDesktop from "./Sidebar.desktop";

const Sidebar = (props: any) => {
    const { globalDispatch } = React.useContext(UserAppContext);
    const clickMenu: any = (pageId: number) => {
        const { history, closeMenu } = props;
        switch (pageId) {
            case CONSTANTS.SIDEBAR_PAGEID.PROFILE:
                history.push({ pathname: CONSTANTS.NAVIGATION_URL.PROFILE });
                closeMenu && closeMenu();
                break;
            case CONSTANTS.SIDEBAR_PAGEID.WORKOUT_HISTORY:
                history.push({ pathname: CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY });
                closeMenu && closeMenu();
                break;
            case CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIPS:
                history.push({ pathname: CONSTANTS.NAVIGATION_URL.MEMBERSHIPS });
                closeMenu && closeMenu();
                break;
            case CONSTANTS.SIDEBAR_PAGEID.ORDERS:
                history.push({ pathname: CONSTANTS.NAVIGATION_URL.ORDERS });
                closeMenu && closeMenu();
                break;
            case CONSTANTS.SIDEBAR_PAGEID.PAYMENT_METHOD:
                history.push({ pathname: CONSTANTS.NAVIGATION_URL.PAYMENT_METHOD });
                closeMenu && closeMenu();
                break;
            case CONSTANTS.SIDEBAR_PAGEID.INTEGRATIONS:
                history.push({ pathname: CONSTANTS.NAVIGATION_URL.INTEGRATIONS });
                closeMenu && closeMenu();
                break;
            default:
                history.push({ pathname: CONSTANTS.NAVIGATION_URL.NOT_FOUND });
                closeMenu && closeMenu();
                break;
        }
        globalDispatch(
            Action.global.setState({
                current_page: pageId,
            })
        );
    };
    return (
        <>
            <SidebarDesktop clickMenu={clickMenu} />
        </>
    );
};
export default withRouter(Sidebar);
